import { useEffect, useMemo } from "react"
import {
  Calendar,
  CalendarsFilterByArgs,
  CalendarsQuery,
} from "@daybridge/client-api"
import { UseInfiniteQueryOptions } from "@tanstack/react-query"
import { GraphQLError } from "@daybridge/graphql"
import { useInfiniteCalendarsQuery } from "./useInfiniteCalendarsQuery"

/**
 * `useCalendars` fetches all of a user's calendars from the server.
 * The calendars can be filtered through passing a filter argument. When
 * no filter is passed, it will load all undeleted calendars by default.
 */

interface UseCalendarOutput {
  calendars: Calendar[]
  isLoading: boolean
}

export const useCalendars = <
  TData extends CalendarsQuery,
  TError = string | GraphQLError,
>(
  filterBy: CalendarsFilterByArgs = { deleted: false },
  options?: UseInfiniteQueryOptions<CalendarsQuery, TError, TData>,
): UseCalendarOutput => {
  // Use an infinite query to handle pagination.
  const {
    isLoading,
    data: calendarData,
    hasNextPage,
    fetchNextPage,
    isRefetching,
  } = useInfiniteCalendarsQuery(
    {
      args: {
        filterBy,
      },
    },
    options,
  )

  // Fetch new data when there is more available.
  useEffect(() => {
    if (hasNextPage && !isRefetching) {
      void fetchNextPage()
    }
  }, [
    calendarData?.pageParams.length,
    hasNextPage,
    fetchNextPage,
    isRefetching,
  ])

  // Get the calendars from the returned data.
  const calendars = useMemo(() => {
    return (
      calendarData?.pages?.flatMap((page: CalendarsQuery) => {
        if (!page.calendars || !page.calendars.edges) {
          return []
        }

        return page.calendars.edges
          .map((edge): Calendar | undefined => edge.node)
          .filter((x): x is Calendar => !!x)
      }) || []
    )
  }, [calendarData?.pages])

  // Return the calendars and whether the query is loading,
  // so we can display a loader if necessary.
  return useMemo(() => {
    return {
      calendars,
      isLoading,
    }
  }, [calendars, isLoading])
}
