import React, { useMemo } from "react"
import { DateTime } from "luxon"
import { DaysVisible } from "@daybridge/client-api"
import { useHourLevelItems } from "../../../items/hooks/useHourLevelItems"
import { useHourLevelRenderer } from "../../hooks/rendering/useHourLevelRenderer"
import { useHourLevelMouseInteractions } from "../../hooks/mouse-interactions/useHourLevelMouseInteractions"
import { useSelectedRegionSelectionInProgress } from "../../state/selectedRegion"
import {
  ItemDragMode,
  useItemDraggingInProgressAllDay,
  useItemDraggingInProgressMode,
} from "../../../items/state/dragging"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { CurrentTimeIndicatorLine } from "../CurrentTimeIndicatorLine"
import { useSkeletonItems } from "../../../items/hooks/useSkeletonItems"
import { placementFromTime } from "../../placement/placementFromTime"
import {
  ItemCreationStateStage,
  useItemCreationState,
} from "../../../items/state/creation"
import { useViewportPosition } from "../../hooks/rendering/useViewportPosition"
import { daysVisibleToNumber } from "../../utils/daysVisible"
import { useHourLevelScrollHeight } from "../../hooks/rendering/useHourLevelScrollHeight"
import { TIME_LABELS_GUTTER_WIDTH } from "./TimeLabels"

// The gutters for each day are set here. These need to be
// defined programatically so that all day entries can be aligned properly.
export const RENDERABLE_AREA_LEFT_GUTTER = 0
export const RENDERABLE_AREA_RIGHT_GUTTER = 8

// Constraints for rendering items at the hour level.
const MIN_WIDTH = 50

export interface DayProps {
  // `dayLocal` and `nowLocal` are a Luxon objects
  // Importantly, it should be in local time with the correct time zone set.
  // It's used to populate the header for the day, as well as to make sure we
  // render the correct number of grid cells.
  dayLocal: DateTime
  nowLocal: DateTime

  // These dates are used to retrieve items from the cache. It means that
  // the cache can be shared across individual days rather than every day
  // making its own network call.
  cacheStartLocal: DateTime
  cacheEndLocal: DateTime
}

/**
 * The `PlanningDay` component is responsible for rendering a columnar day with
 * the correct number of grid cells arranged correctly. It takes into account
 * days that may have 23 or 25 hours and makes sure the height is always the same.
 */
export const PlanningDay: React.FC<DayProps> = React.memo((props: DayProps) => {
  const startLocal = useMemo(
    () => props.dayLocal.startOf("day"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.dayLocal.toISO()],
  )
  const endLocal = useMemo(() => startLocal.plus({ days: 1 }), [startLocal])

  // `renderableAreaRef` is the part of the day in which entries
  // can be rendered. It's the same area as the `canvasRef` but
  // excludes the gutter on either side. The gutter is empty space
  // besides entries that the user can always click in even if the
  // space is occupied by an entry.
  const renderableAreaRef = React.useRef<HTMLDivElement>(null)

  // Initialise mouse handlers
  const { onMouseDown, onMouseMove, onMouseUp } = useHourLevelMouseInteractions(
    props.dayLocal,
    renderableAreaRef.current,
  )

  // Keep track of selection state, so we can adjust the cursor accordingly
  const selectionInProgress = useSelectedRegionSelectionInProgress()
  const moveInProgress = useItemDraggingInProgressMode()
  const moveAllDay = useItemDraggingInProgressAllDay()
  const [itemCreationState] = useItemCreationState()

  let cursor: string | undefined
  if (itemCreationState?.stage === ItemCreationStateStage.ItemDetails) {
    cursor = undefined
  } else if (moveInProgress === ItemDragMode.Move) {
    cursor = "cursor-move"
  } else if (
    (moveInProgress && moveAllDay) ||
    selectionInProgress === "allDay"
  ) {
    cursor = "cursor-col-resize"
  } else if (moveInProgress || selectionInProgress === "hourLevel") {
    cursor = "cursor-row-resize"
  }

  // Compute the dimensions of the renderable area, so we know where to
  // place things on the canvas.
  const { viewportWidth } = useViewportPosition()
  const [daysVisible] = usePreference("daysVisible")
  const renderableAreaWidth = useMemo(() => {
    // Returns the width of the column excluding padding (i.e. the width of the
    // items).
    return (
      viewportWidth &&
      Math.floor(
        (viewportWidth - TIME_LABELS_GUTTER_WIDTH) /
          daysVisibleToNumber(daysVisible || DaysVisible.Seven) -
          RENDERABLE_AREA_RIGHT_GUTTER,
      )
    )
  }, [daysVisible, viewportWidth])

  // Compute the current time indicator position, only if the current time
  // falls on this day.
  const timeIndicatorPositionPercent =
    props.nowLocal >= startLocal && props.nowLocal < endLocal
      ? placementFromTime(props.nowLocal, startLocal, endLocal) * 100
      : undefined

  const height = useHourLevelScrollHeight()

  // See https://twitter.com/kieranmch/status/1484637184909062144
  const filter: [DateTime, DateTime] = useMemo(
    () => [startLocal, endLocal],
    [startLocal, endLocal],
  )

  // Grab the items we need to render for the day
  const { items, isLoading } = useHourLevelItems(
    props.cacheStartLocal,
    props.cacheEndLocal,
    filter,
  )

  // If the items are still loading (initially, re-renders don't set
  // isLoading), we should render a skeleton loader.
  const skeletonItems = useSkeletonItems(startLocal, endLocal)

  const values = useMemo(
    () => ({
      rangeStartLocal: startLocal,
      rangeEndLocal: endLocal,
      renderableAreaWidth: renderableAreaWidth || 0,
      minWidth: MIN_WIDTH,
    }),
    [startLocal, endLocal, renderableAreaWidth],
  )

  // Set up a renderer
  const render = useHourLevelRenderer(values)

  // Invoke the renderer, memoise the result
  const renderables = useMemo(
    () => render(isLoading ? skeletonItems : items),
    [skeletonItems, isLoading, items, render],
  )

  return (
    /*
      The outermost div is a container for the whole day. We set
      - z-0: this forces the stacking context to reset so that the day header
         can sit on top of other content without interfering with popovers etc.
      */
    <div
      className={`
        group
        relative z-0
        flex flex-col flex-1 flex-shrink-0
        transition-[height,background-size] duration-150 ease-in-out
        ${cursor ? cursor : ""}
      `}
      style={{
        height,
        backgroundSize: `${height / 24}px ${height / 24}px`,
        backgroundImage:
          "linear-gradient(to bottom, var(--color-tint-alpha) 1px, transparent 1px)",
      }}
    >
      {/* Hide the topmost divider line */}
      <div className="absolute w-full h-px bg-surface" />
      <div
        role={"none"}
        className="
          w-full
          absolute top-0 bottom-0
        "
        style={{
          paddingLeft: RENDERABLE_AREA_LEFT_GUTTER,
          paddingRight: RENDERABLE_AREA_RIGHT_GUTTER,
        }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <div
          ref={renderableAreaRef}
          className="renderable-area w-full h-full relative z-0"
        >
          {renderables}
        </div>

        {timeIndicatorPositionPercent !== undefined && (
          <CurrentTimeIndicatorLine
            topPercent={timeIndicatorPositionPercent}
            className="absolute -left-1 right-1"
          />
        )}
      </div>
    </div>
  )
})

PlanningDay.displayName = "PlanningDay"
