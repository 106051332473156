import { IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Menu,
  MenuItem,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { ForwardedRef, forwardRef, memo } from "react"
import { EditMode } from "../../types/editMode"

interface ItemEditPaneSaveButtonProps {
  // `recurring` is a boolean that determines whether the save button will
  // turn into a dropdown showing the different schedule modes.
  recurring?: boolean

  // `isFutureEnabled` should be true if the item provider supports
  // editing of "this and future" instances.
  isFutureEnabled: boolean

  // `onSave` is called when the user clicks the save button. If a mode was
  // selected, this mode will be provided with the callback.
  onSave: (mode?: EditMode) => void
}

const ItemEditPaneSaveButton = forwardRef(
  (
    props: ItemEditPaneSaveButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const className = `ml-auto mr-2`
    return props.recurring ? (
      <Menu
        side="bottom"
        align="end"
        content={
          <>
            <MenuItem
              onSelect={() => props.onSave(EditMode.Instance)}
              icon={IconId.Copy}
            >
              Just this one
            </MenuItem>
            {props.isFutureEnabled && (
              <MenuItem
                onSelect={() => props.onSave(EditMode.Future)}
                icon={IconId.ArrowRight}
              >
                This and everything after
              </MenuItem>
            )}
            <MenuItem
              onSelect={() => props.onSave(EditMode.Series)}
              icon={IconId.Infinity}
            >
              Everything
            </MenuItem>
            <MenuItem icon={IconId.Cross}>Cancel</MenuItem>
          </>
        }
      >
        {({ open }) => (
          <Button
            ref={ref}
            variant={ButtonVariant.OutlineAlpha}
            size={ButtonSize.Base}
            className={className}
          >
            Save
            <I
              type={open ? IconId.ChevronUp : IconId.ChevronDown}
              className="w-2 h-2 ml-2"
            />
          </Button>
        )}
      </Menu>
    ) : (
      <Button
        ref={ref}
        variant={ButtonVariant.OutlineAlpha}
        size={ButtonSize.Base}
        className={className}
        onPress={() => props.onSave()}
      >
        Save
      </Button>
    )
  },
)
ItemEditPaneSaveButton.displayName = "ItemEditPaneSaveButton"

export const CreateSaveButton = memo(
  ItemEditPaneSaveButton,
) as typeof ItemEditPaneSaveButton
