import React from "react"

interface BlockGrabHandlesProps {
  // `isDayLevel` is true if the card is being rendered at the
  // day level (at the top of the timeline).
  isDayLevel?: boolean

  // `onDrag` is a callback to be invoked when the block is dragged.
  onDrag?: (e: React.MouseEvent, direction: "start" | "end" | "move") => void
}

/**
 * `BlockGrabHandles` are used for resizing block items.
 * TODO: These handles are currently hidden from screen readers.
 * We could make this more accessible. Possible interesting article:
 * https://medium.com/salesforce-ux/4-major-patterns-for-accessible-drag-and-drop-1d43f64ebf09
 */
export const BlockGrabHandles: React.FC<BlockGrabHandlesProps> = React.memo(
  (props) => {
    return (
      <>
        {props.onDrag && (
          <>
            <div
              role="none"
              className={`
                absolute z-20
                ${
                  props.isDayLevel
                    ? "w-5 h-full left-0 cursor-col-resize"
                    : "w-full h-2 top-0 cursor-row-resize"
                }
                z-20
              `}
              onMouseDown={(e: React.MouseEvent) => {
                e.stopPropagation()

                // Left clicks only
                if (e.button !== 0) {
                  return
                }

                props.onDrag && props.onDrag(e, "start")
              }}
            />
            <div
              role="none"
              className={`
                absolute z-20
                ${
                  props.isDayLevel
                    ? "w-5 h-full right-0 cursor-col-resize"
                    : "w-full h-2 bottom-0 cursor-row-resize"
                }
                z-20
              `}
              onMouseDown={(e: React.MouseEvent) => {
                e.stopPropagation()

                // Left clicks only
                if (e.button !== 0) {
                  return
                }

                props.onDrag && props.onDrag(e, "end")
              }}
            />
          </>
        )}
      </>
    )
  },
)
BlockGrabHandles.displayName = "GrabHandles"
