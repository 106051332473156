import {
  dateTimeToDateTimeInfo,
  resolveDateTimeInfo,
} from "@daybridge/datetime"
import { useCallback } from "react"
import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  useCreateItemMutation,
  CreateItemMutation,
  CreateItemMutationVariables,
  useItemsQuery,
  CreateItemArgs,
  DateTimeArgs,
  Color,
  IconId,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { fromPromise } from "@daybridge/toast"
import { DateTime } from "luxon"
import { useResetTimeline } from "../../timeline/hooks/useResetTimeline"
import { useCalendarSelected } from "../../timeline/hooks/selected-calendars/useCalendarSelected"
import { useSetCalendarSelected } from "../../timeline/hooks/selected-calendars/useSetCalendarSelected"
import { useOpenItem } from "../state/opening"
import usePreference from "../../settings/hooks/preferences/usePreference"

export const useCreateItem = (
  options?: UseMutationOptions<
    CreateItemMutation,
    string | GraphQLError,
    CreateItemMutationVariables,
    unknown
  >,
) => {
  const reset = useResetTimeline()
  const [colorByCategory] = usePreference("colorByCategory")
  const queryClient = useQueryClient()
  const { mutateAsync } = useCreateItemMutation({
    onSuccess: async () => {
      reset()
      await queryClient.invalidateQueries(useItemsQuery.getKey({ args: {} }))
    },
    ...options,
  })
  const calendarSelected = useCalendarSelected()
  const setCalendarSelected = useSetCalendarSelected()
  const [openItem, setOpenItem] = useOpenItem()

  return useCallback(
    (args: CreateItemArgs, idempotencyKey: string, timeZone: string) => {
      let start: DateTimeArgs | null | undefined
      let end: DateTimeArgs | null | undefined

      if (!args.start || !args.end) {
        return
      }

      if (args.end?.date) {
        // End date is inclusive in this form, but exclusive on the API.
        // Add 1 day to the end date if we are using date submission mode.
        start = args.start
        end = {
          date: DateTime.fromISO(args.end.date).plus({ days: 1 }).toISODate(),
        }
      } else if (args.series?.rules?.length) {
        // If this item is recurring then we need to submit as a floating time.
        const startLocal = resolveDateTimeInfo(args.start, timeZone)
        const endLocal = resolveDateTimeInfo(args.end, timeZone)
        start = dateTimeToDateTimeInfo(startLocal, {
          floating: true,
          includeZone: true,
        })
        end = dateTimeToDateTimeInfo(endLocal, {
          floating: true,
          includeZone: true,
        })
      } else {
        start = args.start
        end = args.end
      }

      // Remove the series if it isn't included.
      const create = { ...args, start, end }
      if (create.series === null) delete create.series

      const resultPromise = mutateAsync({
        input: {
          idempotencyKey,
          create,
        },
      }).then((data) => {
        // If the calendar is hidden, show it after the event has been created.
        if (!calendarSelected(args.calendar)) {
          void setCalendarSelected(args.calendar, true)
        }
        const isRecurring = args.series?.rules?.length
        if (data.createItem && !isRecurring) {
          // We can only reliably open the item if it's not recurring.
          setOpenItem({
            id: data.createItem.id,
            icon: data.createItem.node.icon?.id || IconId.Calendar,
            title: data.createItem.node.title,
            color:
              (colorByCategory
                ? data.createItem.node.category?.node.color
                : data.createItem.node.calendar?.node.color) || Color.Stone,
          })
        } else if (openItem) {
          // If we can't open the item and the sidebar is open, close it.
          setOpenItem(undefined)
        }
      })
      void fromPromise(resultPromise, {
        successTitle: `${args.title || "Item"} saved`,
      })
    },
    [
      mutateAsync,
      calendarSelected,
      openItem,
      setCalendarSelected,
      setOpenItem,
      colorByCategory,
    ],
  )
}
