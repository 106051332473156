import { Color, IconId } from "@daybridge/client-api"
import { atom, useRecoilState } from "recoil"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"

export type PartialItem = Pick<ItemWithResolvedTimes, "id" | "title"> & {
  icon: IconId
  color: Color
}

export const openItemAtom = atom<PartialItem | undefined>({
  key: "openItem",
  default: undefined,
})
export const useOpenItem = () => useRecoilState(openItemAtom)
