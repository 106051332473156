import { IconId } from "@daybridge/client-api"
import {
  SidebarTabContentContainer,
  SidebarTabGroup,
  SidebarTabList,
  SidebarTabTrigger,
} from "@daybridge/components-core"
import React from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { SidebarAccountMenu } from "./SidebarAccountMenu"
import { SidebarCalendarList } from "./SidebarCalendarList"
import { SidebarContactList } from "./SidebarContactList"
import { SidebarViewSettings } from "./SidebarViewSettings"

const SidebarFn = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDivElement>) => {
    const [open, setOpen] = usePreference("sidebarOpen")

    return (
      <SidebarTabGroup
        ref={ref}
        defaultTab="calendars"
        open={open === undefined || open === null ? true : open}
        onOpenChange={(value) => {
          void setOpen(value)
        }}
      >
        {/* Triggers */}
        <SidebarTabList>
          <SidebarTabTrigger
            title="Calendars"
            value="calendars"
            icon={IconId.Calendar}
          />
          <SidebarTabTrigger
            title="Contacts"
            value="contacts"
            icon={IconId.Users}
          />
          <SidebarTabTrigger
            title="Layout"
            value="layout"
            icon={IconId.Settings}
          />
          <div className="flex-1" />
          <SidebarAccountMenu />
        </SidebarTabList>

        {/* Content */}
        <SidebarTabContentContainer
          open={open === null || open === undefined ? true : open}
        >
          <SidebarCalendarList />
          <SidebarContactList />
          <SidebarViewSettings />
        </SidebarTabContentContainer>
      </SidebarTabGroup>
    )
  },
)
SidebarFn.displayName = "Sidebar"

export const Sidebar = React.memo(SidebarFn) as typeof SidebarFn
