import React, { useState } from "react"
import { Formik } from "formik"
import { v4 } from "uuid"
import { CreateCalendarMutationVariables } from "@daybridge/client-api"
import {
  Button,
  ButtonVariant,
  ButtonSize,
  InlineInput,
} from "@daybridge/components-core"
import { object, string } from "yup"
import { useCreateCalendar } from "../../hooks/useCreateCalendar"

interface CreateCalendarFormProps {
  onClose: () => void
}

export const CreateCalendarForm: React.FC<CreateCalendarFormProps> = React.memo(
  (props) => {
    const [idempotencyKey] = useState(v4())
    const createCalendar = useCreateCalendar({
      onSuccess: () => props.onClose(),
    })

    return (
      <Formik<CreateCalendarMutationVariables>
        initialValues={{
          input: {
            idempotencyKey,
            create: {
              name: "",
            },
          },
        }}
        validationSchema={object().shape({
          input: object().shape({
            create: object().shape({
              name: string()
                .max(64, "Please choose a shorter name")
                .required("Please choose a name for your new calendar"),
            }),
          }),
        })}
        onSubmit={(values) => createCalendar(values)}
      >
        {({ getFieldProps, getFieldMeta, handleSubmit }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <InlineInput
              {...getFieldProps("input.create.name")}
              {...getFieldMeta("input.create.name")}
              required
              maxLength={64}
              placeholder={"Choose a name for your new calendar..."}
              className="border-b border-tint"
            />
            <div className="px-6 py-4 flex items-center justify-end space-x-2">
              <Button
                variant={ButtonVariant.Clear}
                size={ButtonSize.Base}
                onPress={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Base}
                type="submit"
              >
                Create
              </Button>
            </div>
          </form>
        )}
      </Formik>
    )
  },
)
CreateCalendarForm.displayName = "CreateCalendar"
