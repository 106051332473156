import { UseInfiniteQueryOptions } from "@tanstack/react-query"
import { DateTime } from "luxon"
import { useMemo } from "react"
import { ItemsQuery } from "@daybridge/client-api/src/gen/operations"
import { ItemOrVirtualItem } from "../types/itemOrVirtualItem"
import { shouldRenderAsAllDayItem } from "../utils/shouldRenderAsAllDayItem"
import { useItems } from "./useItems"
import { useVirtualItems } from "./useVirtualItems"

/**
 * `useHourLevelItems` fetches the real and virtual items that need to be
 * rendered on the timeline (at the "hour level")
 */
export const useHourLevelItems = (
  cacheStartLocal: DateTime,
  cacheEndLocal: DateTime,
  filter?: [DateTime, DateTime],
  options?: UseInfiniteQueryOptions<ItemsQuery>,
): { isLoading: boolean; items: ItemOrVirtualItem[] } => {
  // Fetch real items for the period
  const { items, isLoading } = useItems(
    cacheStartLocal,
    cacheEndLocal,
    filter,
    options,
  )
  // Fetch virtual items for the period
  const virtualItems = useVirtualItems(cacheStartLocal, cacheEndLocal, filter)

  // Keep only items & virtual items that need to be rendered on the timeline
  const filteredItems = useMemo(
    () => items.filter(shouldRenderAtHourLevel),
    [items],
  )
  const filteredVirtualItems = useMemo(
    () => virtualItems.filter(shouldRenderAtHourLevel),
    [virtualItems],
  )

  // Combine result
  const filteredItemsAndVirtualItems = useMemo(
    () => [...filteredItems, ...filteredVirtualItems],
    [filteredItems, filteredVirtualItems],
  )
  return { items: filteredItemsAndVirtualItems, isLoading }
}

/**
 * An item should be rendered at hour level if it should not be rendered
 * at the day level. This approach saves us duplicating the logic and potentially
 * introducing divergences/errors.
 */
const shouldRenderAtHourLevel = (item: ItemOrVirtualItem): boolean => {
  return !shouldRenderAsAllDayItem(item)
}
