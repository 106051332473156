import {
  CreateItemArgs,
  PatchItemArgs,
  IconId,
  Place,
  Calendar,
  DateTimeInfo,
} from "@daybridge/client-api"
import { FormikProps } from "formik"
import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  IconPicker,
  Input,
  Popover,
  ScrollArea,
  Tooltip,
  TriggerType,
  AvatarGroup,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { colorToClass } from "@daybridge/colors"
import { useAnalytics } from "@daybridge/analytics"
import { DateTime } from "luxon"
import { useIconSets } from "../../../../hooks/useIconSets"
import { useItemSchema } from "../../hooks/useItemSchema"
import { EditMode } from "../../types/editMode"
import { DetailsPaneHeader } from "../../../timeline/components/details-pane/DetailsPaneHeader"
import { useEditingItem } from "../../state/editing"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { ItemEditPaneFormUpdater } from "./ItemEditPaneFormUpdater"
import { ItemIconButton } from "./header/ItemIconButton"
import { DateBlock } from "./date/DateBlock"
import { CreateBlockButton } from "./common/CreateBlockButton"
import { LocationBlock } from "./location/LocationBlock"
import { RepeatBlock } from "./repeat/RepeatBlock"
import { CreateSaveButton } from "./ItemEditPaneSaveButton"
import { CalendarCombobox } from "./header/CalendarCombobox"
import { CategoryPicker } from "./category/CategoryPicker"

export type ItemTimes = {
  startLocal: DateTime
  endLocal: DateTime
  start: DateTimeInfo
  end: DateTimeInfo
  isAllDayItem: boolean
  renderAsAllDay: boolean
}

type ItemEditPaneFormProps<T extends CreateItemArgs | PatchItemArgs> =
  FormikProps<T> & {
    setMode: (mode?: EditMode) => void
    trigger: TriggerType
    selectableCalendars: Calendar[][]
    itemTimes: ItemTimes
  }

function ItemEditPaneFormFn<T extends CreateItemArgs | PatchItemArgs>(
  props: ItemEditPaneFormProps<T>,
  ref: React.ForwardedRef<HTMLFormElement>,
): React.ReactElement {
  const {
    getFieldProps,
    getFieldMeta,
    itemTimes,
    values,
    selectableCalendars,
    setFieldValue,
    submitForm,
    setMode,
  } = props

  const [editingItem] = useEditingItem()
  const { data: schema } = useItemSchema()
  const iconSets = useIconSets()
  const { track } = useAnalytics()

  // Analytics: track when this form opens
  useEffect(() => {
    track("item-form.open", {
      mode: editingItem ? "edit" : "create",
      itemId: editingItem ? editingItem.id : undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ------------------------------
  // Title
  // ------------------------------
  const titleRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    titleRef.current?.select()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ------------------------------
  // Location
  // ------------------------------
  const [location, setLocation] = useState<string | Place | undefined>(
    editingItem?.location?.place?.node || editingItem?.location?.name,
  )

  const [hasLocationBlock, setHasLocationBlock] = useState<boolean>(!!location)
  const [hasRepeatBlock, setHasRepeatBlock] = useState<boolean>(
    !!editingItem?.series,
  )

  const selectedCalendar = useMemo(() => {
    return selectableCalendars.flat().find((x) => x.id === values.calendar)
  }, [selectableCalendars, values.calendar])

  const [colorByCategory] = usePreference("colorByCategory")
  const color = useMemo(() => {
    return colorByCategory
      ? values.category && schema?.categories[values.category].color
      : selectedCalendar?.color
  }, [
    colorByCategory,
    schema?.categories,
    selectedCalendar?.color,
    values.category,
  ])

  return (
    <form
      ref={ref}
      className={`
        h-full flex flex-col bg-surface relative
        ${color ? `content-${colorToClass(color)}` : ""}
      `}
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="absolute inset-x-0 h-[40rem] bg-gradient-to-b from-content-background via-surface"></div>
      {!editingItem && (
        <ItemEditPaneFormUpdater setFieldValue={setFieldValue} />
      )}
      <DetailsPaneHeader
        trigger={props.trigger}
        accentColor={
          values.category
            ? schema?.categories[values.category].color || undefined
            : undefined
        }
      >
        <Tooltip
          content={
            selectedCalendar?.people &&
            "Events in shared calendars cannot be moved"
          }
        >
          <CalendarCombobox
            selectableCalendars={selectableCalendars}
            defaultSelectedKey={values.calendar || undefined}
            onSelect={(calendarId) => setFieldValue("calendar", calendarId)}
          >
            {({ open }) => (
              <Button
                variant={ButtonVariant.OutlineAlpha}
                size={ButtonSize.Base}
                disabled={!!selectedCalendar?.people}
                className={`!text-left mr-2 overflow-hidden`}
              >
                <AvatarGroup
                  className="mr-2 -ml-2.5"
                  profiles={selectedCalendar?.people?.map(
                    (p) => p.profile?.node,
                  )}
                />
                <div className="truncate">{selectedCalendar?.name}</div>
                <I
                  type={open ? IconId.ChevronUp : IconId.ChevronDown}
                  className="w-2 h-2 ml-2 flex-shrink-0"
                />
              </Button>
            )}
          </CalendarCombobox>
        </Tooltip>
        <CreateSaveButton
          isFutureEnabled={
            false
            // TODO Enable after we add support for editing this
            // and future instances of recurring Google Events.
            // editingItem?.calendar?.node.provider.node.type === "google"
          }
          onSave={(mode?: EditMode) => {
            // Analytics: track when this form gets saved
            track("item-form.save", {
              mode: editingItem ? "edit" : "create",
              itemId: editingItem ? editingItem.id : undefined,
              recurring: values.series ? "true" : undefined,
              itemsUpdated: mode || undefined,
            })
            setMode(mode)
            void submitForm()
          }}
          recurring={editingItem && !!editingItem.series?.node}
        />
      </DetailsPaneHeader>
      <ScrollArea className="flex-1 overflow-hidden 2xl:rounded-t-2xl">
        <div className="px-6 pt-5 pb-3 mt-px w-full flex flex-row items-center space-x-4">
          <Popover
            content={({ onClose }) => (
              <IconPicker
                iconSets={iconSets}
                selectedIcon={values.icon || IconId.Calendar}
                onIconSelect={(icon, wasClick) => {
                  setFieldValue("icon", icon)
                  if (wasClick) {
                    onClose()
                  }
                }}
              />
            )}
            side="bottom"
            align="start"
          >
            {({ open }) => (
              <ItemIconButton icon={values.icon || IconId.Plus} open={open} />
            )}
          </Popover>
          <div className="h-12 flex-1 flex flex-col justify-center">
            <CategoryPicker
              selectedCategory={values.category || undefined}
              onCategorySelect={(categoryId) =>
                setFieldValue("category", categoryId)
              }
            />
            <Input
              ref={titleRef}
              placeholder="Title"
              className={`w-full bg-transparent h-6 text-lg font-semibold text-high-contrast placeholder:text-content-body/40`}
              {...getFieldProps("title")}
              {...getFieldMeta("title")}
            />
          </div>
        </div>
        <div className="px-4 py-2 space-y-4">
          <DateBlock
            item={editingItem}
            itemTimes={itemTimes}
            color={color}
            startValue={values.start}
            endValue={values.end}
            onChange={(newStart, newEnd) => {
              setFieldValue("start", newStart)
              setFieldValue("end", newEnd)
            }}
          />
          {hasLocationBlock && (
            <LocationBlock
              location={location}
              onSelect={(location) => {
                setLocation(location)
                setFieldValue(
                  "location",
                  typeof location === "string"
                    ? { name: location }
                    : {
                        place: {
                          provider: location.provider,
                          id: location.id,
                        },
                      },
                )
              }}
              onClose={() => {
                setHasLocationBlock(false)
                setLocation(undefined)
                setFieldValue("location", null)
              }}
            />
          )}
          {hasRepeatBlock && (
            <RepeatBlock
              color={color}
              seriesValue={values.series}
              onChange={(rules) => setFieldValue("series.rules", [rules])}
              startValue={values.start}
              itemTimes={itemTimes}
              onClose={() => {
                setHasRepeatBlock(false)
                setFieldValue("series", null)
              }}
            />
          )}
        </div>
        <div className="px-4 space-y-px pt-2 pb-4">
          {!hasLocationBlock && (
            <CreateBlockButton
              onPress={() => setHasLocationBlock(true)}
              icon={IconId.MapPin}
            >
              Add location
            </CreateBlockButton>
          )}
          {!hasRepeatBlock && (
            <CreateBlockButton
              onPress={() => setHasRepeatBlock(true)}
              icon={IconId.Repeat}
            >
              Add repeat
            </CreateBlockButton>
          )}
        </div>
      </ScrollArea>
    </form>
  )
}
ItemEditPaneFormFn.displayName = "ItemEditPaneForm"

export const ItemEditPaneForm = memo(forwardRef(ItemEditPaneFormFn)) as <
  T extends CreateItemArgs | PatchItemArgs,
>(
  props: ItemEditPaneFormProps<T> & {
    ref?: React.ForwardedRef<HTMLDivElement>
  },
) => ReturnType<typeof ItemEditPaneFormFn>
