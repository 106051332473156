import { DateTime } from "luxon"
import { useMemo } from "react"
import { VirtualItem, VirtualItemType } from "../types/virtualItem"
import { useSelectedRegionForRendererInRange } from "../../timeline/state/selectedRegion"
import { useItemDragDraftInRange } from "../state/dragging"

export const useVirtualItems = (
  cacheStartLocal: DateTime,
  cacheEndLocal: DateTime,
  filter?: [DateTime, DateTime],
): VirtualItem[] => {
  // Selected region virtual item
  const selectedRegion = useSelectedRegionForRendererInRange(
    filter || [cacheStartLocal, cacheEndLocal],
  )

  const [dragState, draggingItem] =
    useItemDragDraftInRange(filter || [cacheStartLocal, cacheEndLocal]) || []

  return useMemo(() => {
    const result: VirtualItem[] = []

    if (selectedRegion) {
      result.push({
        __typename: "VirtualItem",
        id: "selected-region",
        type: VirtualItemType.SelectedRegion,
        ...selectedRegion,
        excludeFromConflictResolution: true,
      })
    }

    if (
      dragState &&
      draggingItem &&
      !(
        // We only want to render a virtual item if the item
        // has actually moved. Otherwise it would interfere
        // with clicking the item to open the item details.
        (
          dragState.item.startLocal.equals(draggingItem.startLocal) &&
          dragState.item.endLocal.equals(draggingItem.endLocal)
        )
      )
    ) {
      result.push({
        __typename: "VirtualItem",
        type: VirtualItemType.DraftItem,
        id: draggingItem.id + "_draft",
        start: draggingItem.start || undefined,
        end: draggingItem.end || undefined,
        startLocal: draggingItem.startLocal,
        endLocal: draggingItem.endLocal,
        isAllDayItem: draggingItem.isAllDayItem,
        renderAsAllDay: draggingItem.renderAsAllDay,

        // Item to render
        item: draggingItem,

        // Items being dragged around should be rendered on top of other items.
        excludeFromConflictResolution: true,
      })
    }

    return result
  }, [selectedRegion, draggingItem, dragState])
}
