import { IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonVariant,
  ButtonSize,
  FadeAndScaleAnimation,
  ButtonProps,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { ForwardedRef, forwardRef, memo } from "react"

type CreateBlockButtonProps = Partial<ButtonProps> & {
  icon: IconId
}

const CreateBlockButtonFn = forwardRef(
  (
    { icon, children, ...rest }: CreateBlockButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <FadeAndScaleAnimation>
        <Button
          block
          {...rest}
          variant={ButtonVariant.Headless}
          size={ButtonSize.Base}
          ref={ref}
          className="px-4 py-2 flex items-center active:bg-surface !justify-start border-transparent border hover:border-tint rounded-xl text-base space-x-3 text-low-contrast"
        >
          <I type={icon} className="w-4 h-4 text-icon-decorative" />
          <span>{children}</span>
        </Button>
      </FadeAndScaleAnimation>
    )
  },
)
CreateBlockButtonFn.displayName = "CreateBlockButton"

export const CreateBlockButton = memo(
  CreateBlockButtonFn,
) as typeof CreateBlockButtonFn
