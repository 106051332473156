import React from "react"

/**
 * `AgendaCurrentTimeIndicatorLine` is a horizontal line across a day indicating
 * the current time in the user's timezone in agenda view.
 */
export const AgendaCurrentTimeIndicatorLine: React.FC = React.memo(() => {
  return (
    <div
      className={`
        h-[2px]
        relative -left-1 -right-1
        !mb-2 -mr-0.5 -mt-[1px]
        bg-primary
        pointer-events-none
      `}
    >
      <div
        className="
          absolute left-0 -top-[5px]
          w-0.5 h-3
          bg-primary
          rounded-sm
        "
      />
    </div>
  )
})
AgendaCurrentTimeIndicatorLine.displayName = "AgendaCurrentTimeIndicatorLine"
