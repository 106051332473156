import { DateTime } from "luxon"
import React, { useCallback, useRef } from "react"
import scrollIntoView from "scroll-into-view-if-needed"
import { useViewportPosition } from "../../../../timeline/hooks/rendering/useViewportPosition"
import { useTimelineHeaderHeight } from "../../../../timeline/state/timelineHeaderHeight"
import { BlockGrabHandles } from "./BlockGrabHandles"
import { BlockJumpToDateButton } from "./BlockJumpToDateButton"

// BaseBlockItems must have an implementation through children
type BaseBlockItemProps = BlockItemProps & {
  children: unknown
}

/**
 * A block item is any item on the timeline that occupies a range of space.
 * Block items should implement the following props.
 */

export interface BlockItemProps {
  isAgenda?: boolean

  // `isDayLevel` is true if the card is being rendered at the
  // day level (at the top of the timeline).
  isDayLevel?: boolean

  // `hideJumpButtons` hides the jump buttons on day-level entries
  hideJumpButtons?: boolean

  // If `continues` is true, the item represented by this card
  // started on another day (before the day this card sits on).
  alreadyStarted?: boolean

  // If `continues` is true, the item represented by this card
  // continues onto another day.
  continues?: boolean

  // `start` is the start time of the item this card represents.
  start: DateTime

  // `end` is the end time of the item this card represents.
  end: DateTime

  // The height can be specified either as a percentage (for hour-level items)
  // or as a fixed number (for day-level or agenda items).
  percentageHeight?: number
  fixedHeight?: number

  // `width` is the width of the item in pixels.
  width: number

  // The Y offset can be specified either as a percentage (for hour-level items)
  // or as a fixed number (for day-level or agenda items).
  percentageYOffset?: number
  fixedYOffset?: number

  // `xOffset` is the number of pixels from the left that this
  // item should be offset.
  xOffset?: number
  percentageXOffset?: string
  percentageXOffsetEnd?: string

  // `overflow` is set if the item overflows the top or bottom of the viewport.
  overflow?: "top" | "bottom"

  // `zIndex` is the z-index of the item used to stack
  // items on top of each other in the correct order.
  zIndex?: number

  // If `fadedOut` is true, the item will be shown at
  // half opacity and will become inert.
  fadedOut?: boolean

  // If `inert` is true then pointer events will pass
  // through this item
  inert?: boolean

  // `onDrag` is a callback to be invoked when the block is dragged.
  onDrag?: (e: React.MouseEvent, direction: "start" | "end" | "move") => void
}

/**
 * BaseBlockItem is a base component for all block items.
 * It injects common props into the block item. It's responsible for making sure
 * the block item appears in the correct location, and has the correct Z-index.
 */
export const BaseBlockItem: React.FC<
  BaseBlockItemProps & { classNameForOverflow?: string }
> = React.memo((props) => {
  // If the user mouse-downs on this block item, we want to
  // start moving it around.
  const mouseDownHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      // Left clicks only
      if (e.button !== 0) {
        return
      }

      // We have to stop propagation in order to make sure
      // that the timeline doesn't capture the event thinking
      // that the user has clicked in an empty space.
      e.stopPropagation()

      props.onDrag && props.onDrag(e, "move")
    },
    [props],
  )

  const { viewportTop, viewportBottom } = useViewportPosition()
  const [timelineHeaderHeight] = useTimelineHeaderHeight()

  const currentTimeScrollRef = useRef<HTMLDivElement>(null)
  const onOverflowItemClick = useCallback(() => {
    if (!currentTimeScrollRef.current) return

    scrollIntoView(currentTimeScrollRef.current, {
      scrollMode: "always",
      behavior: "smooth",
      block: "center",
      inline: "start",
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeScrollRef.current])

  // TODO: Fix "presentation" role
  return (
    <>
      {props.overflow && (
        <div
          onClick={onOverflowItemClick}
          onKeyDown={onOverflowItemClick}
          onMouseDown={(e) => {
            // Stop propagation to drag-creating a new item.
            e.stopPropagation()
          }}
          role="button"
          tabIndex={0}
          className={`
            fixed bg-surface z-50 
            cursor-pointer overflow-hidden
            transition-[width,left,right] duration-300 ease-in-out
            focus:outline-none focus-visible:ring-2 focus:ring-focus-ring
            ring-offset-2 ring-offset-surface
            ${props.overflow === "top" ? "rounded-b-xl" : "rounded-t-xl"}
            ${props.fadedOut ? "opacity-50" : ""}
          `}
          style={{
            height: props.overflow === "top" ? 20 : 10,
            width: props.width,
            top:
              props.overflow === "top"
                ? (timelineHeaderHeight || 0) + (viewportTop || 0) - 10
                : "auto",
            bottom: props.overflow === "bottom" ? viewportBottom : "auto",
            marginLeft: props.xOffset || props.percentageXOffset,
          }}
        >
          <div className={props.classNameForOverflow} />
        </div>
      )}
      <div
        ref={currentTimeScrollRef}
        className={`
          transition-[width,left,right] duration-300
          ${props.isAgenda ? "" : "absolute"}
          ${
            props.overflow
              ? "opacity-0 pointer-events-none"
              : props.fadedOut
              ? "opacity-50"
              : ""
          }
          ${props.inert ? "pointer-events-none" : ""}
        `}
        role="presentation"
        onMouseDown={mouseDownHandler}
        style={{
          height: props.fixedHeight || `${props.percentageHeight || 0}%`,
          right:
            props.isDayLevel && !props.isAgenda
              ? props.percentageXOffsetEnd
              : 0,
          minHeight: 22,
          top: props.fixedYOffset || `${props.percentageYOffset || 0}%`,
          left:
            props.xOffset !== undefined
              ? props.xOffset
              : props.percentageXOffset,
          zIndex: props.zIndex,
        }}
      >
        <>
          {/* Back button for day-level items that have already started */}
          {props.isDayLevel &&
            props.alreadyStarted &&
            !props.hideJumpButtons &&
            !props.isAgenda && (
              <div
                className="
              absolute -left-4 top-[3px] z-30 
            "
              >
                <BlockJumpToDateButton
                  direction="backwards"
                  date={props.start}
                />
              </div>
            )}

          {!props.isAgenda && (
            <BlockGrabHandles
              isDayLevel={props.isDayLevel}
              onDrag={props.onDrag}
            />
          )}

          {props.children}

          {/* Forward button for day-level items that continue */}
          {props.isDayLevel &&
            props.continues &&
            !props.hideJumpButtons &&
            !props.isAgenda && (
              <div
                className="
              absolute right-[7px] top-[3px] z-30 
            "
              >
                <BlockJumpToDateButton
                  direction="forwards"
                  // End date is exclusive, so have to minus one day to
                  // get the actual date to jump to.
                  date={props.end.minus({ days: 1 })}
                />
              </div>
            )}
        </>
      </div>
    </>
  )
})
BaseBlockItem.displayName = "BaseBlockItem"
