import { useAuth } from "@daybridge/auth"
import {
  IconId,
  useGetPushNotificationAccessTokenMutation,
} from "@daybridge/client-api"
import { useCallback, useContext, useEffect, useState } from "react"
import { AlertDialog, ButtonVariant } from "@daybridge/components-core"
import { captureException } from "@sentry/nextjs"
import { beamsContext } from "./BeamsClientProvider"

const NOTIFICATION_PROMPT_ANSWERED_STORAGE_KEY = "notification-prompt-response"
const NOTIFICATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE = "false"

const ServerPushNotifications: React.FC = () => {
  const { user } = useAuth()
  const { mutateAsync } = useGetPushNotificationAccessTokenMutation()

  // Configure Pusher Beams client for push notifications
  const beamsClient = useContext(beamsContext)

  // Manage permissions prompt
  const notification = window.Notification
  const [showPermissionPrompt, setShowPermissionPrompt] = useState(false)
  const alreadyDismissed =
    window.localStorage.getItem(NOTIFICATION_PROMPT_ANSWERED_STORAGE_KEY) ===
    NOTIFICATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE
  const onDismiss = useCallback(() => {
    window.localStorage.setItem(
      NOTIFICATION_PROMPT_ANSWERED_STORAGE_KEY,
      NOTIFICATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE,
    )
    setShowPermissionPrompt(false)
  }, [])

  // This cannot be wrapped in useCallback because Safari insists it isn't *sigh*
  const start = () => {
    if (!user) return
    void beamsClient
      ?.start()
      .then(async () => {
        console.log("🔔 Notifications started")
        await beamsClient?.setUserId(user?.uid, {
          fetchToken: async () => {
            const response = await mutateAsync({})
            if (!response.getPushNotificationsAccessToken) {
              throw new Error("No access token returned")
            }
            console.log("🔔 Refreshed Noticications Token")
            return {
              token: response.getPushNotificationsAccessToken.accessToken,
            }
          },
        })
      })
      .catch((err) => {
        console.error("🔔 Notifications failed to start", err)
        captureException(err)
      })
  }

  useEffect(() => {
    switch (notification.permission) {
      case "granted":
        // Permission already granted - start now
        setShowPermissionPrompt(false)
        break
      case "denied":
        // Dont' need to ask for permission
        setShowPermissionPrompt(false)
        break
      default:
        if (alreadyDismissed) {
          // The user has already said no to the popup dialog
          setShowPermissionPrompt(false)
          return
        } else {
          setShowPermissionPrompt(true)
        }
        break
    }
  }, [alreadyDismissed, notification.permission])

  useEffect(() => {
    // Notifications set up already on page load
    // Only run once on page load
    if (notification.permission === "granted") {
      start()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AlertDialog
      open={showPermissionPrompt}
      icon={IconId.Bell}
      title="Should we send notifications to this device?"
      description="We use notifications to let you know when events are about to start, when you get invitations from others, and more. You can always change your settings later."
      actionButtonText="Get notifications"
      actionButtonVariant={ButtonVariant.Success}
      onActionPress={start}
      cancelButtonText="No thanks"
      onCancelPress={onDismiss}
    />
  )
}

export default ServerPushNotifications
