import { IconId } from "@daybridge/client-api"
import {
  Avatar,
  Button,
  ButtonSize,
  ButtonVariant,
  Menu,
  MenuItem,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { memo } from "react"
import { useRemoveFromContacts } from "../hooks/useRemoveFromContacts"

interface ContactListItemProps {
  username: string
  name: string
  avatar?: string | null
}

export const ContactListItem: React.FC<ContactListItemProps> = memo(
  (props: ContactListItemProps) => {
    const remove = useRemoveFromContacts()
    return (
      <div className="h-12 pl-6 pr-4 py-2 flex border-b border-tint items-center">
        <div className="w-7 h-7 mr-3">
          <Avatar
            defaultInitials={props.name[0] || undefined}
            url={props.avatar || undefined}
          />
        </div>
        <div className="text-base flex-1 truncate text-high-contrast">
          {props.name}
        </div>
        <Menu
          side="right"
          align="start"
          content={
            <MenuItem
              icon={IconId.TrashEmpty}
              onSelect={() =>
                void remove({ input: { username: props.username } })
              }
            >
              Remove from contacts
            </MenuItem>
          }
        >
          <Button
            size={ButtonSize.Custom}
            variant={ButtonVariant.Headless}
            className={`px-2 flex-shrink-0 self-stretch ring-focus-ring ring-inset rounded-full hover:bg-background text-high-contrast`}
          >
            <I type={IconId.EllipsisVertical} className="w-3 h-3" />
          </Button>
        </Menu>
      </div>
    )
  },
)

ContactListItem.displayName = "ContactListItem"
