import React from "react"

interface BlockTitleProps {
  className?: string
  children: string
}

export const BlockTitle: React.FC<BlockTitleProps> = React.memo(
  (props: BlockTitleProps) => {
    return (
      <h2
        className={`
          text-sm
          flex-1 truncate
          font-semibold
          text-left
          ${props.className || ""}
        `}
      >
        {props.children}
      </h2>
    )
  },
)
BlockTitle.displayName = "BlockTitle"
