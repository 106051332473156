import { ResponseInfo, ResponseType } from "@daybridge/client-api"

// Group responses by response type.
export function groupResponses(responses: ResponseInfo[]) {
  return {
    [ResponseType.Yes]:
      responses.filter((person) => person.response === ResponseType.Yes) || [],
    [ResponseType.Maybe]:
      responses.filter((person) => person.response === ResponseType.Maybe) ||
      [],
    [ResponseType.No]:
      responses.filter((person) => person.response === ResponseType.No) || [],
  }
}
