import { IconId, ResponseType } from "@daybridge/client-api"
import { I } from "@daybridge/icons"
import React from "react"

export interface ResponseBadgeProps {
  type: ResponseType
}

export const ResponseBadge: React.FC<ResponseBadgeProps> = React.memo(
  ({ type }: ResponseBadgeProps) => {
    const className =
      type === ResponseType.Yes
        ? "bg-success"
        : type === ResponseType.Maybe
        ? "bg-warning"
        : type === ResponseType.No
        ? "bg-error"
        : "bg-background"
    return (
      <div
        className={`
          w-4 h-4 rounded-full 
          absolute -bottom-0.5 -right-1 
          flex items-center justify-center
          border-2 border-surface
          ${className}
        `}
      >
        {type === ResponseType.Yes && (
          <I type={IconId.Tick} className="w-1.5 h-1.5 text-white" />
        )}
        {type === ResponseType.Maybe && (
          <I type={IconId.QuestionMark} className="w-1.5 h-1.5 text-white" />
        )}
        {type === ResponseType.No && (
          <I type={IconId.Cross} className="w-1 h-1 text-white" />
        )}
      </div>
    )
  },
)

ResponseBadge.displayName = "ResponseBadge"
