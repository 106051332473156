import React from "react"

interface CurrentTimeIndicatorLineProps {
  // `topPercent` is the percentage from the top of the timeline
  // at which the current time indicator should be placed.
  topPercent?: number

  // A `className` is required to set the correct left and right values based on
  // the view we're using the indicator in.
  className: string
}

/**
 * `CurrentTimeIndicatorLine` is a horizontal line across a day indicating the
 * current time in the user's timezone.
 */
export const CurrentTimeIndicatorLine: React.FC<CurrentTimeIndicatorLineProps> =
  React.memo((props: CurrentTimeIndicatorLineProps) => {
    return (
      <>
        <div
          className={`
          h-[2px] -mt-[1px]
          bg-primary
          pointer-events-none
          ${props.className}
        `}
          style={props.topPercent ? { top: `${props.topPercent}%` } : undefined}
        />
        <div
          className={`
            absolute left-0
            -ml-1 -mt-1.5
            w-0.5 h-3
            bg-primary
            rounded-sm
            ${props.className}
          `}
          style={
            props.topPercent
              ? {
                  top: `clamp(6px, ${props.topPercent}%, 100% - 6px)`,
                }
              : undefined
          }
        />
      </>
    )
  })
CurrentTimeIndicatorLine.displayName = "CurrentTimeIndicatorLine"
