import { IconId } from "@daybridge/client-api"
import { I } from "@daybridge/icons"
import { groupBy } from "lodash-es"
import { DateTime } from "luxon"
import React, { useCallback, useMemo } from "react"
import { useDayLevelItems } from "../../../items/hooks/useDayLevelItems"
import { useHourLevelItems } from "../../../items/hooks/useHourLevelItems"
import { useSetCreationStateByDate } from "../../../items/hooks/useSetCreationStateByDate"
import { useSkeletonItems } from "../../../items/hooks/useSkeletonItems"
import { ItemWithResolvedTimes } from "../../../items/types/itemWithResolvedTimes"
import { DayPart, DAY_PARTS } from "../../types/dayParts"
import { DayProps } from "../planning/PlanningDay"
import { AgendaDayPart } from "./AgendaDayPart"

type AgendaDayProps = DayProps & { width: number | undefined }

export const AgendaDay: React.FC<AgendaDayProps> = React.memo(
  (props: AgendaDayProps) => {
    // Grab the items we need to render for the day.
    const startLocal = useMemo(
      () => props.dayLocal.startOf("day"),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.dayLocal.toISO()],
    )
    const endLocal = useMemo(() => startLocal.plus({ days: 1 }), [startLocal])

    const filter: [DateTime, DateTime] = useMemo(
      () => [startLocal, endLocal],
      [startLocal, endLocal],
    )

    const { items: itemsUnfiltered, isLoading: isLoadingHourLevelItems } =
      useHourLevelItems(props.cacheStartLocal, props.cacheEndLocal, filter)
    const items = useMemo(() => {
      return itemsUnfiltered.filter((item) => item.__typename === "Item")
    }, [itemsUnfiltered])

    const { items: todayItemsUnfiltered, isLoading: isLoadingDayLevelItems } =
      useDayLevelItems(props.cacheStartLocal, props.cacheEndLocal)

    const todayItems = useMemo(() => {
      return todayItemsUnfiltered.filter(
        (item) =>
          item.__typename !== "VirtualItem" &&
          item.startLocal <= props.dayLocal &&
          item.endLocal >= props.dayLocal.plus({ days: 1 }),
      )
    }, [props.dayLocal, todayItemsUnfiltered])

    // If the items are still loading (initially, re-renders don't set
    // isLoading), we should render a skeleton loader.
    const skeletonItems = useSkeletonItems(startLocal, endLocal)

    // Group the items to the right daypart by their start time.
    const showInSection: (item: ItemWithResolvedTimes) => DayPart = useCallback(
      (item: ItemWithResolvedTimes) => {
        // Section logic
        // =================
        // We are currently displaying multi-day items only in the daypart that
        // they start, unless they overlap to another day, in which case we will
        // add it to the morning section of each consecutive day (because the
        // user might not see the day before). Example: if an item starts at 7am
        // until 2pm the next day, we show it in the morning of day 1 + the
        // morning of day 2.
        if (!item.startLocal) return "All day"
        if (item.startLocal.hour < 12 || item.startLocal < startLocal)
          return "Morning"
        if (item.startLocal.hour < 18) return "Afternoon"
        return "Evening"
      },
      [startLocal],
    )

    const isLoading = isLoadingHourLevelItems || isLoadingDayLevelItems

    const groups = useMemo(() => {
      // Display skeleton items until the actual items are loaded.
      return groupBy(isLoading ? skeletonItems : items, showInSection)
    }, [isLoading, items, showInSection, skeletonItems])

    // Dim past items in the current view (i.e. the current week in week view,
    // the current day in day view, or the current weekpart in 3/5-day view).
    const viewContainsToday = !(
      props.nowLocal < props.cacheStartLocal ||
      props.nowLocal > props.cacheEndLocal
    )

    const setCreationState = useSetCreationStateByDate()

    const isEmpty = useMemo(() => {
      return (
        !isLoading &&
        (!items || items.length === 0) &&
        (!todayItems || todayItems.length === 0)
      )
    }, [isLoading, items, todayItems])

    return (
      <div
        className="
        relative z-0
        flex-1 overflow-hidden
        px-2 flex flex-col pb-1
        text-high-contrast
      "
      >
        {props.width &&
          (isEmpty ? (
            <div className="flex flex-col items-center my-3 py-9">
              <div className="w-5 text-icon-decorative">
                <I type={IconId.CalendarOutline} />
              </div>
              <div className="mt-3 text-sm font-medium text-center text-low-contrast">
                Nothing planned
              </div>
            </div>
          ) : (
            <>
              {DAY_PARTS.map((dayPart) => (
                <AgendaDayPart
                  key={dayPart.title}
                  title={dayPart.title}
                  createAtHour={dayPart.createAtHour || 0}
                  icon={dayPart.icon}
                  viewContainsToday={viewContainsToday}
                  startLocal={startLocal}
                  endLocal={endLocal}
                  nowLocal={props.nowLocal}
                  items={
                    (dayPart.title === "All day"
                      ? todayItems
                      : groups?.[dayPart.title]) as ItemWithResolvedTimes[]
                  }
                  width={props.width as number}
                  onCreate={(createAtHour) =>
                    void setCreationState(
                      startLocal.set({ hour: createAtHour }),
                    )
                  }
                />
              ))}
            </>
          ))}
      </div>
    )
  },
)
AgendaDay.displayName = "AgendaDay"
