import { Density } from "@daybridge/client-api"
import usePreference from "../../../settings/hooks/preferences/usePreference"

const DENSITY_GRID_CELL_HEIGHTS = {
  [Density.Compact]: 60,
  [Density.Cosy]: 75,
  [Density.Relaxed]: 90,
}

/**
 * `useHourLevelScrollHeight` is a hook that returns the full scroll height of
 * the timeline, based on the density preference.
 */
export const useHourLevelScrollHeight = () => {
  const [density] = usePreference("density")
  return DENSITY_GRID_CELL_HEIGHTS[density || Density.Cosy] * 24
}
