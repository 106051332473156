import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  usePatchItemMutation,
  PatchItemMutation,
  PatchItemMutationVariables,
  useItemsQuery,
  PatchItemArgs,
  PatchItemInput,
  useItemQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { useCallback } from "react"
import { fromPromise } from "@daybridge/toast"
import { useResetTimeline } from "../../timeline/hooks/useResetTimeline"
import { EditMode } from "../types/editMode"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"

export const usePatchItem = (
  options?: UseMutationOptions<
    PatchItemMutation,
    string | GraphQLError,
    PatchItemMutationVariables,
    unknown
  >,
) => {
  const reset = useResetTimeline()
  const queryClient = useQueryClient()

  const { mutateAsync } = usePatchItemMutation({
    onSettled: async (data) => {
      await queryClient
        .invalidateQueries(useItemsQuery.getKey({ args: {} }))
        .then(() => reset())
      if (data?.patchItem?.id) {
        await queryClient.invalidateQueries(
          useItemQuery.getKey({ args: { id: data.patchItem.id } }),
        )
      }
    },
    ...options,
  })

  return useCallback(
    (args: PatchItemArgs, item: ItemWithResolvedTimes, mode?: EditMode) => {
      let input: PatchItemInput
      switch (mode) {
        case EditMode.Instance:
          if (!item.series) throw new Error("Item is not part of a series")
          input = {
            id: item.id,
            patch: args,
          }
          break
        case EditMode.Future:
          if (!item.series) throw new Error("Item is not part of a series")
          input = { id: item.id, from: item.id, patch: args }
          break
        case EditMode.Series:
          if (!item.series) throw new Error("Item is not part of a series")
          input = { id: item.id, series: item.series.id, patch: args }
          break
        default:
          if (mode) {
            throw new Error("Mode should not be set when editing a single item")
          }
          input = { id: item.id, patch: args }
          break
      }

      const resultPromise = mutateAsync({
        input,
      })
      void fromPromise(resultPromise, {
        successTitle: `${args.title || "Item"} saved`,
      })
    },
    [mutateAsync],
  )
}
