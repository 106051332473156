import { useRecoilState } from "recoil"
import { useEffect } from "react"
import debounce from "lodash-es/debounce"
import { viewportPositionAtom } from "../../state/viewportPosition"

export const useObserveViewportPosition = () => {
  const [viewportPosition, setViewportPosition] =
    useRecoilState(viewportPositionAtom)
  const viewportRef = viewportPosition.viewportRef

  // Observe the viewport's dimensions.
  useEffect(() => {
    if (!viewportRef?.current) return

    // Debounce handler so it only propagates after resizing, not during, to
    // prevent lots of re-renders that hurt performance.
    const handler = debounce(() => {
      if (!viewportRef?.current) return
      const el = viewportRef.current
      const rect = el.getBoundingClientRect()

      setViewportPosition({
        viewportHeight: el.clientHeight,
        viewportWidth: el.clientWidth,
        viewportTop: rect.top,
        viewportBottom: rect.bottom - rect.height,
        viewportRef,
      })
    }, 15)

    handler()

    const ro = new ResizeObserver(handler)
    ro.observe(viewportRef.current)
    return () => ro.disconnect()
  }, [setViewportPosition, viewportRef])
}
