import { IconId } from "@daybridge/client-api"
import {
  ListMenuItemProps,
  Popover,
  StandaloneListMenu,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React, { useCallback, useMemo } from "react"
import { useDragItem } from "../../items/hooks/useDragItem"
import { useItemDragStateFor } from "../../items/state/dragging"
import { EditMode } from "../../items/types/editMode"
import { ItemWithResolvedTimes } from "../../items/types/itemWithResolvedTimes"
import { useResetTimeline } from "../hooks/useResetTimeline"

interface RecurringMoveModeSelectorProps {
  item: ItemWithResolvedTimes
  isFutureEnabled?: boolean
  allDay?: boolean
  width: number
  percentageHeight?: number
  xOffset?: number
  percentageYOffset?: number
}

export const RecurringMoveModeSelector: React.FC<RecurringMoveModeSelectorProps> =
  React.memo((props: RecurringMoveModeSelectorProps) => {
    const reset = useResetTimeline()
    const [dragState] = useItemDragStateFor(props.item.id) || []
    const { commit } = useDragItem()

    const onModeSelect = useCallback(
      (mode: EditMode | "cancel") => {
        switch (mode) {
          case EditMode.Instance:
          case EditMode.Future:
          case EditMode.Series:
            commit(mode)
            break
          case "cancel":
            reset()
            break
        }
      },
      [reset, commit],
    )

    const seriesMenuItems = useMemo((): ListMenuItemProps<
      EditMode | "cancel"
    >[] => {
      return [
        {
          id: EditMode.Instance,
          title: "Just this one",
          contents: (
            <div className="flex flex-row items-center space-x-3">
              <I type={IconId.Copy} className="w-3 h-3" />
              <span>Just this one</span>
            </div>
          ),
        },
        props.isFutureEnabled && {
          id: EditMode.Future,
          title: "This and everything after",
          contents: (
            <div className="flex flex-row items-center space-x-3">
              <I type={IconId.ArrowRight} className="w-3 h-3" />
              <span>This and everything after</span>
            </div>
          ),
        },
        {
          id: EditMode.Series,
          title: "Everything",
          contents: (
            <div className="flex flex-row items-center space-x-3">
              <I type={IconId.Infinity} className="w-3 h-3" />
              <span>Everything</span>
            </div>
          ),
        },
        {
          id: "cancel",
          title: "Cancel",
          contents: (
            <div className="flex flex-row items-center space-x-3">
              <I type={IconId.Cross} className="w-3 h-3" />
              <span>Cancel</span>
            </div>
          ),
        },
      ].filter((x) => x) as ListMenuItemProps<EditMode | "cancel">[]
    }, [props.isFutureEnabled])

    return (
      <Popover
        open={
          !!dragState?.item.series &&
          dragState?.item.id === props.item.id &&
          dragState?.committed === true
        }
        className="w-60"
        content={
          <StandaloneListMenu<EditMode | "cancel">
            compact={true}
            onItemSelect={onModeSelect}
            items={seriesMenuItems}
          />
        }
        onOpenChange={(open) => {
          if (!open) reset()
        }}
        side="right"
        align="start"
      >
        <div
          className="absolute pointer-events-none"
          style={{
            width: props.width,
            height: `${props.percentageHeight || 0}%`,
            left: props.xOffset,
            top: `${props.percentageYOffset || 0}%`,
          }}
        />
      </Popover>
    )
  })
RecurringMoveModeSelector.displayName = "RecurringMoveModeSelector"
