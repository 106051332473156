import { useTime } from "@daybridge/server-time"
import { DateTime } from "luxon"
import { useNavigationDateState } from "../../state/navigationDate"

/**
 * `useNavigationDate` returns the current navigation date,
 * filling in the value with today's date if it is not set.
 */
export const useNavigationDate = (
  refreshInterval?: number,
  timeZone?: string,
): DateTime => {
  // Get the current server time
  const now = useTime(refreshInterval, timeZone)

  // Check if a navigation date has been set in the store
  const [navigationDate] = useNavigationDateState()

  if (navigationDate) {
    // The navigation date is stored in UTC, so needs to be converted
    // to whatever timezone the user is currently viewing their timeline in.
    return navigationDate.setZone(timeZone)
  } else {
    // `now` will already be in the correct timezone because the `useTime`
    // hook handles this for us.
    return now
  }
}
