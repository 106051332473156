import { useTime } from "@daybridge/server-time"
import { DateTime } from "luxon"
import { useRecoilCallback } from "recoil"
import {
  selectedRegionAtom,
  selectedRegion_transformedForCreateForm,
  SelectedRegionState,
} from "../../state/selectedRegion"
import { useTimeNavigation } from "../navigation/useTimeNavigation"

interface ChangeSelectedRegionFunctions {
  // `setDefault` sets the selected region to a default value.
  // This is useful when entering the creation flow without explicitly
  // selecting a region using the mouse.
  setDefault: (timeZone: string) => SelectedRegionState

  // `toggleDayLevel` switches the selected region between hour-level and
  // day level after a selection has been made.
  toggleAllDay: () => Promise<SelectedRegionState>

  // `changeStart` sets a new start time for the selected region, preserving
  // the duration of the region.
  changeStart: (newStart: DateTime) => Promise<SelectedRegionState>

  // `changeEnd` sets a new end time for the selected region,
  // applying a limit if needed.
  changeEnd: (newEnd: DateTime) => Promise<SelectedRegionState>
}

// `useChangeSelectedRegion` is a hook that provides functions for changing
// the selected region. It's used mainly in the creation flow.
export const useChangeSelectedRegion = (): ChangeSelectedRegionFunctions => {
  // Get the current server time
  const nowUTC = useTime(60000)

  // Connect to the navigation time so we can keep the selected region visible.
  const { jumpToDate } = useTimeNavigation()

  // `setDefault` sets the selected time range to a sensible default value
  const setDefault = useRecoilCallback(({ set }) => {
    return (timeZone: string): SelectedRegionState => {
      // Set a sensible default value: the start of the next hour, for 1 hour duration
      const defaultStart = nowUTC
        .plus({ hours: 1 })
        .startOf("hour")
        .setZone(timeZone)
      const defaultEnd = defaultStart.plus({ hours: 1 })

      const newSelection = {
        firstClickAt: defaultStart,
        firstClickWasAllDay: false,
        lastMousePositionAt: defaultEnd,
        lastMousePositionWasAllDay: false,
      }
      // Set the user input
      set(selectedRegionAtom, newSelection)
      return newSelection
    }
  })

  // `toggleAllDay` switches the selected region between hour-level and
  // day level after a selection has been made.
  const toggleAllDay = useRecoilCallback(({ snapshot, set }) => {
    return async (): Promise<SelectedRegionState> => {
      const selection = await snapshot.getPromise(
        selectedRegion_transformedForCreateForm,
      )
      if (!selection) {
        return
      }

      if (selection.renderAsAllDay) {
        // The selection is currently rendered as an all-day item, so we
        // need to move it down onto the timeline.
        const val = await snapshot.getPromise(selectedRegionAtom)

        if (
          val?.firstClickAt.equals(val.firstClickAt.startOf("day")) &&
          val.lastMousePositionAt.equals(val.lastMousePositionAt.startOf("day"))
        ) {
          // The selection runs midnight -> midnight, so we have no useful time info here.
          // The best thing to do is probably just to reset.
          return setDefault(selection.startLocal.zoneName)
        } else {
          // We have some useful time information - probably because the user originally
          // started out by selecting a region on the timeline and then toggling on all day.
          // Since the user is toggling again, we will revert to their original selection.
          if (!val) return

          const newSelection: SelectedRegionState = {
            ...val,
            firstClickWasAllDay: false,
            lastMousePositionWasAllDay: false,
          }

          set(selectedRegionAtom, newSelection)
          return newSelection
        }
      } else {
        // The selection is currently an hour-level selection, so we want
        // to make it an all-day item instead. This is actually quite straight-forward.
        // All we need to do is re-write their selection pretending that they dragged
        // in the all-day region to begin with.
        const val = await snapshot.getPromise(selectedRegionAtom)
        if (!val) return
        const newSelection: SelectedRegionState = {
          ...val,
          firstClickWasAllDay: true,
          lastMousePositionWasAllDay: true,
        }
        set(selectedRegionAtom, newSelection)
        return newSelection
      }
    }
  })

  const changeStart = useRecoilCallback(({ snapshot, set }) => {
    return async (newStart: DateTime): Promise<SelectedRegionState> => {
      const selection = await snapshot.getPromise(
        selectedRegion_transformedForCreateForm,
      )
      if (!selection) {
        return
      }

      const duration = selection.endLocal.diff(selection.startLocal)

      const val = await snapshot.getPromise(selectedRegionAtom)
      if (!val) return
      const newSelection: SelectedRegionState = {
        ...val,
        firstClickAt: newStart,
        lastMousePositionAt: newStart.plus(duration),
      }
      set(selectedRegionAtom, newSelection)

      // Make sure the new start date is visible on screen
      jumpToDate(newStart)

      return newSelection
    }
  })

  const changeEnd = useRecoilCallback(({ snapshot, set }) => {
    return async (newEnd: DateTime): Promise<SelectedRegionState> => {
      const selection = await snapshot.getPromise(
        selectedRegion_transformedForCreateForm,
      )
      if (!selection) {
        return
      }
      const val = await snapshot.getPromise(selectedRegionAtom)
      if (!val) return

      if (newEnd <= selection.startLocal) {
        // If the new end date is _before_ the start date,
        // apply a limit.
        const newSelection: SelectedRegionState = {
          ...val,
          firstClickAt: selection.startLocal,
          lastMousePositionAt: selection.startLocal.plus({
            // Zero duration is fine for all-day items.
            // For hour-level items, we need to add a few minutes.
            minutes: selection.isAllDayItem ? 0 : 5,
          }),
        }

        set(selectedRegionAtom, newSelection)
        return newSelection
      } else {
        const newSelection: SelectedRegionState = {
          ...val,
          firstClickAt: selection.startLocal,
          lastMousePositionAt: newEnd,
        }
        // Straightforward - just set the end date
        set(selectedRegionAtom, newSelection)
        return newSelection
      }
    }
  })

  return {
    setDefault,
    toggleAllDay,
    changeStart,
    changeEnd,
  }
}
