import { IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  TooltipWithKeyboardShortcut,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"

export interface SidebarPresetButtonProps {
  onPress: (e: React.MouseEvent | React.KeyboardEvent) => void
  isActive: boolean
  icon: IconId
  text: string
  shortcut: string
}

export const SidebarPresetButton: React.FC<SidebarPresetButtonProps> =
  React.memo(
    ({ onPress, isActive, icon, text, shortcut }: SidebarPresetButtonProps) => {
      return (
        <TooltipWithKeyboardShortcut
          content={text}
          shortcut={shortcut.toUpperCase()}
        >
          <Button
            size={ButtonSize.Custom}
            variant={ButtonVariant.Outline}
            onPress={onPress}
            className={`text-sm flex-col pt-4 pb-2 rounded-lg ${
              isActive
                ? "border-primary text-primary-body"
                : "text-low-contrast"
            }`}
          >
            <I
              type={icon}
              className={`${
                isActive ? "text-primary-body" : "text-low-contrast"
              } w-5 h-5 mb-3`}
            />
            {text}
          </Button>
        </TooltipWithKeyboardShortcut>
      )
    },
  )

SidebarPresetButton.displayName = "SidebarPresetButton"
