import { Modal } from "@daybridge/components-core"
import React from "react"
import { useEditCalendarId } from "../../state/editing"
import { EditCalendarForm } from "./EditCalendarForm"

export const EditCalendarFormCoordinator: React.FC = React.memo(() => {
  const [editCalendarId, setEditCalenderId] = useEditCalendarId()

  return (
    <>
      <Modal
        open={editCalendarId !== undefined}
        content={<EditCalendarForm />}
        onOpenChange={(open) => {
          if (!open) {
            setEditCalenderId(undefined)
          }
        }}
        className="w-full max-w-md"
      />
    </>
  )
})
EditCalendarFormCoordinator.displayName = "EditCalendarFormCoordinator"
