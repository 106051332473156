import {
  Color,
  DateTimeArgs,
  DateTimeInfo,
  IconId,
  InputMaybe,
} from "@daybridge/client-api"
import { ForwardedRef, forwardRef, memo, useMemo } from "react"
import {
  dateTimeToDateTimeInfo,
  resolveDateTimeInfo,
} from "@daybridge/datetime"
import { DateTime, Duration } from "luxon"
import { useTime } from "@daybridge/server-time"
import {
  Popover,
  Datepicker,
  TimePicker,
  SelectTarget,
} from "@daybridge/components-core"
import { Block } from "../common/Block"
import { isAllDayItem } from "../../../utils/shouldRenderAsAllDayItem"
import { ItemWithResolvedTimes } from "../../../types/itemWithResolvedTimes"
import { useChangeSelectedRegion } from "../../../../timeline/hooks/selected-region/useChangeSelectedRegion"
import { useChangeExistingItem } from "../../../hooks/useChangeExistingItem"
import { AllDayToggle } from "./AllDayToggle"

interface DateBlockProps {
  item: ItemWithResolvedTimes | undefined
  color: Color | null | undefined
  startValue: InputMaybe<DateTimeArgs> | undefined
  endValue: InputMaybe<DateTimeArgs> | undefined
  itemTimes: {
    startLocal: DateTime
    endLocal: DateTime
    start: DateTimeInfo
    end: DateTimeInfo
    isAllDayItem: boolean
    renderAsAllDay: boolean
  }
  onChange: (newStart: DateTimeInfo, newEnd: DateTimeInfo) => void
}

const DateBlockFn = forwardRef(
  (
    { item, itemTimes, color, startValue, endValue, onChange }: DateBlockProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { toggleAllDay, changeStart, changeEnd } = useChangeSelectedRegion()
    const { changeExistingItemStart, changeExistingItemEnd } =
      useChangeExistingItem()
    const now = useTime(undefined, itemTimes?.startLocal?.zoneName)

    // Calculate if the item should be rendered as all day, based on the
    // values of the start and end fields of the form.
    const displayAllDay = useMemo(() => {
      return item
        ? isAllDayItem({
            ...item,
            start: startValue,
            end: endValue,
            startLocal: resolveDateTimeInfo(
              startValue || itemTimes.start,
              item.startLocal.zoneName,
            ),
            endLocal: resolveDateTimeInfo(
              endValue || itemTimes.end,
              item.endLocal.zoneName,
            ),
          })
        : itemTimes.isAllDayItem
    }, [
      endValue,
      item,
      itemTimes.end,
      itemTimes.isAllDayItem,
      itemTimes.start,
      startValue,
    ])

    // This is a bit convoluted when we have access to `startLocal` but the form
    // values are the source of truth, so that's what we should be rendering.
    const startLocal = useMemo(() => {
      return startValue
        ? resolveDateTimeInfo(startValue, itemTimes.startLocal.zoneName)
        : itemTimes.startLocal
    }, [itemTimes.startLocal, startValue])

    // This is a bit convoluted when we have access to `endLocal` but the form
    // values are the source of truth, so that's what we should be rendering. If
    // `forceDayLevel` is enabled then we need to show the day before the end
    // time, in order for it to be intuitive.
    const endLocal = useMemo(() => {
      return endValue
        ? resolveDateTimeInfo(endValue, itemTimes.endLocal.zoneName)
        : itemTimes.endLocal
    }, [endValue, itemTimes.endLocal])

    const showEndDate = displayAllDay || !endLocal.hasSame(startLocal, "day")

    // TODO: Tidy this up and move it to somewhere more sensible
    // This is really duplicated logic from `useChangeSelectedRegion`, maybe
    // it could be consolidated into one place.
    const toggleExistingItemAllDay = (
      start: DateTimeInfo,
      end: DateTimeInfo,
      originalStart: DateTimeInfo,
      originalEnd: DateTimeInfo,
      now: DateTime,
    ): [DateTimeInfo, DateTimeInfo] => {
      const startLocal = resolveDateTimeInfo(start, now.zoneName)
      const endLocal = resolveDateTimeInfo(end, now.zoneName)
      if (start.date || end.date) {
        // Switch from all day -> hour
        const originalStartLocal = resolveDateTimeInfo(
          originalStart,
          now.zoneName,
        )
        const originalEndLocal = resolveDateTimeInfo(originalEnd, now.zoneName)
        if (
          !originalStartLocal.equals(originalStartLocal.startOf("day")) ||
          !originalEndLocal.equals(originalEndLocal.startOf("day"))
        ) {
          return [
            dateTimeToDateTimeInfo(originalStartLocal),
            dateTimeToDateTimeInfo(originalEndLocal),
          ]
        } else {
          return [
            dateTimeToDateTimeInfo(
              startLocal
                .set({ hour: now.hour, minute: now.minute })
                .plus({ hours: 1 })
                .startOf("hour"),
            ),
            dateTimeToDateTimeInfo(
              startLocal
                .set({ hour: now.hour, minute: now.minute })
                .plus({ hours: 2 })
                .startOf("hour"),
            ),
          ]
        }
      } else {
        // Switch from hour -> all day
        return [
          dateTimeToDateTimeInfo(startLocal, {
            isDate: true,
            includeZone: false,
          }),
          dateTimeToDateTimeInfo(endLocal, {
            isDate: true,
            includeZone: false,
          }),
        ]
      }
    }

    return (
      <Block
        ref={ref}
        icon={IconId.ClockOutline}
        title="Date and time"
        headerWidgets={
          <AllDayToggle
            itemDescription="event"
            value={displayAllDay}
            color={color || undefined}
            onToggle={() => {
              if (item) {
                if (!startValue || !endValue) return
                const [newStart, newEnd] = toggleExistingItemAllDay(
                  startValue,
                  endValue,
                  item.start || startValue,
                  item.end || endValue,
                  now,
                )
                onChange(newStart, newEnd)
              } else {
                void toggleAllDay()
              }
            }}
          />
        }
      >
        <div className="grid grid-cols-2 gap-2">
          <Popover
            content={({ onClose }) => (
              <Datepicker
                today={DateTime.now()}
                selectedDate={startLocal}
                onDateSelect={(newStart, shouldClose) => {
                  const [newStartVal, newEndVal] = changeExistingItemStart(
                    startValue as DateTimeInfo,
                    endValue as DateTimeInfo,
                    newStart,
                    displayAllDay,
                  )
                  onChange(newStartVal, newEndVal)
                  void changeStart(
                    resolveDateTimeInfo(
                      newStartVal,
                      itemTimes.startLocal.zoneName,
                    ),
                  )
                  if (shouldClose) onClose()
                }}
              />
            )}
            className="p-4"
            side="bottom"
            align="start"
          >
            {({ open }) => (
              <SelectTarget
                open={open}
                className={showEndDate ? "" : "col-span-2"}
              >
                <span className="text-low-contrast mr-2">on</span>
                {startLocal.toLocaleString({
                  day: "numeric",
                  month: showEndDate ? "short" : "long",
                })}
              </SelectTarget>
            )}
          </Popover>
          {showEndDate && (
            <Popover
              content={({ onClose }) => (
                <Datepicker
                  today={DateTime.now()}
                  selectedDate={endLocal}
                  onDateSelect={(newEnd, shouldClose) => {
                    const newEndVal = changeExistingItemEnd(
                      startValue as DateTimeInfo,
                      endValue as DateTimeInfo,
                      newEnd,
                      displayAllDay,
                    )
                    onChange(startValue as DateTimeInfo, newEndVal)
                    void changeEnd(
                      resolveDateTimeInfo(
                        newEndVal,
                        itemTimes.endLocal.zoneName,
                      ),
                    )
                    if (shouldClose) onClose()
                  }}
                />
              )}
              className="p-4"
              side="bottom"
              align="end"
            >
              {({ open }) => (
                <SelectTarget open={open}>
                  <span className="text-low-contrast mr-2">until</span>
                  {endLocal.toLocaleString({
                    day: "numeric",
                    month: "short",
                  })}
                </SelectTarget>
              )}
            </Popover>
          )}
          {!displayAllDay && (
            <>
              <TimePicker
                offsetTime={startLocal.startOf("day")}
                defaultTime={startLocal}
                onDateSelect={(newStart) => {
                  const [newStartVal, newEndVal] = changeExistingItemStart(
                    startValue as DateTimeInfo,
                    endValue as DateTimeInfo,
                    newStart,
                    displayAllDay,
                  )
                  onChange(newStartVal, newEndVal)
                  void changeStart(
                    resolveDateTimeInfo(
                      newStartVal,
                      itemTimes.startLocal.zoneName,
                    ),
                  )
                }}
                allowZeroDuration
                align="start"
              >
                {({ open }) => (
                  <SelectTarget open={open}>
                    <span className="text-low-contrast mr-2">from</span>
                    {startLocal.toLocaleString(DateTime.TIME_SIMPLE)}
                  </SelectTarget>
                )}
              </TimePicker>
              <TimePicker
                offsetTime={
                  endLocal.hasSame(startLocal, "day")
                    ? startLocal
                    : endLocal.startOf("day")
                }
                durationDelta={
                  endLocal.hasSame(startLocal, "day")
                    ? Duration.fromObject({ minutes: 0 })
                    : endLocal.startOf("day").diff(startLocal)
                }
                showOffsets
                onDateSelect={(newEnd) => {
                  const newEndVal = changeExistingItemEnd(
                    startValue as DateTimeInfo,
                    endValue as DateTimeInfo,
                    newEnd,
                    displayAllDay,
                  )
                  onChange(startValue as DateTimeInfo, newEndVal)
                  void changeEnd(
                    resolveDateTimeInfo(newEndVal, itemTimes.endLocal.zoneName),
                  )
                }}
                allowZeroDuration={!endLocal.hasSame(startLocal, "day")}
                defaultTime={endLocal}
                align="end"
              >
                {({ open }) => (
                  <SelectTarget open={open}>
                    <span className="text-low-contrast mr-2">to</span>
                    {endLocal.toLocaleString(DateTime.TIME_SIMPLE)}
                  </SelectTarget>
                )}
              </TimePicker>
            </>
          )}
        </div>
      </Block>
    )
  },
)
DateBlockFn.displayName = "DateBlock"

export const DateBlock = memo(DateBlockFn) as typeof DateBlockFn
