import { useRecoilCallback } from "recoil"
import {
  itemCreationStateAtom,
  itemTitleAtom,
} from "../../items/state/creation"
import { itemDragStateAtom } from "../../items/state/dragging"
import { selectedRegionAtom } from "../state/selectedRegion"
import { editingItemAtom } from "../../items/state/editing"

type ResetFunction = () => void

/**
 * `useReset` is a hook that returns a function that can be used to reset
 * the timeline in the middle of an interaction.
 */
export const useResetTimeline = (): ResetFunction =>
  useRecoilCallback(({ set }) => {
    return () => {
      // Reset the creation flow
      set(itemCreationStateAtom, undefined)

      // Remove any selected region
      set(selectedRegionAtom, undefined)

      // Stop dragging any items
      set(itemDragStateAtom, undefined)

      // Item title
      set(itemTitleAtom, "")

      // Stop editing any items
      set(editingItemAtom, undefined)
    }
  }, [])
