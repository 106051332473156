import { Color } from "@daybridge/client-api"
import { Label, Switch } from "@daybridge/components-core"
import React from "react"

interface AllDayToggleProps {
  itemDescription: string
  value?: boolean
  color?: Color
  onToggle: (value: boolean) => void
}

export const AllDayToggle: React.FC<AllDayToggleProps> = React.memo((props) => {
  return (
    <Label className="flex flex-row items-center space-x-2">
      <span className="text-low-contrast text-sm">All Day</span>
      <Switch
        checked={props.value || false}
        onCheckedChange={props.onToggle}
        contentColors
      />
    </Label>
  )
})
AllDayToggle.displayName = "AllDayToggle"
