import { fallsInTimeRange, updateDateTimeInfo } from "@daybridge/datetime"
import { DateTime } from "luxon"
import {
  atom,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
} from "recoil"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"

export enum ItemDragMode {
  // Moving a whole item
  Move = "move",

  // Dragging the start of an item
  ResizeStart = "resize_start",

  // Dragging the end of an item
  ResizeEnd = "resize_end",
}

export type ItemDragState =
  | {
      // The drag mode
      mode: ItemDragMode

      // `item` is the item being moved
      item: ItemWithResolvedTimes

      // `firstMousePositionAt` was the time the user started dragging from.
      // Note this is only available when the mouse starts to move, so it's
      // initially undefined.
      firstMousePositionAt?: DateTime

      // `firstClickWasAllDay` is true if the user started selecting in
      // the all-day region at the top of the page.
      firstMousePositionWasAllDay: boolean

      // `lastMousePositionAt` was the last time the user moved the mouse.
      // If they moved the mouse in the the all day region, this will be
      // midnight (the start of) the day they moved the mouse in.
      // Note that this time can be before or after the `firstClickAt` time.
      lastMousePositionAt: DateTime

      // `lastMousePositionWasAllDay` is true if the user moved the mouse in
      // the all-day region at the top of the page.
      lastMousePositionWasAllDay: boolean

      // `committed` is set to `true` once the mouse has been lifted up
      committed?: boolean
    }
  | undefined

// `itemDragState` stores the raw drag state
export const itemDragStateAtom = atom<ItemDragState>({
  key: "itemDragState",
  default: undefined,

  // Required when storing Luxon DateTimes in recoil state
  dangerouslyAllowMutability: true,
})
export const useItemDragState = () => useRecoilState(itemDragStateAtom)

// `itemDragDraftSelector` takes the item being dragged, the new time, and computes
// a new item with ammended times. Useful for rendering a preview of the item in its
// new position while it's being dragged around. This selector also accounts for the
// fact that items can be dragged in unusual ways like dragging an end time before its
// start time.
export const itemDragDraftSelector = selector<
  ItemWithResolvedTimes | undefined
>({
  key: "itemDragDraft",
  get: ({ get }) => {
    const state = get(itemDragStateAtom)
    if (!state) {
      return undefined
    }

    if (!state.item.start || !state.item.end) {
      return undefined
    }

    if (state.mode === ItemDragMode.Move) {
      /* --------------
       * Move case
       * --------------
       */

      const offset = state.firstMousePositionAt
        ? state.firstMousePositionAt.diff(state.item.startLocal)
        : undefined

      const duration = state.item.endLocal.diff(state.item.startLocal)
      const updatedStart = updateDateTimeInfo(
        state.item.start,
        offset
          ? state.lastMousePositionAt.minus(offset)
          : state.lastMousePositionAt,
        state.item.renderAsAllDay,
      )

      const updatedEndTime = updatedStart.dateTime.plus(duration)
      const updatedEnd = {
        dateTime: updatedEndTime,
        dateTimeInfo: updateDateTimeInfo(
          state.item.end,
          updatedEndTime,
          state.item.renderAsAllDay,
        ).dateTimeInfo,
      }

      return {
        ...state.item,
        start: updatedStart.dateTimeInfo,
        end: updatedEnd.dateTimeInfo,
        startLocal: updatedStart.dateTime,
        endLocal: updatedEnd.dateTime,
      }
    } else if (state.mode === ItemDragMode.ResizeStart) {
      /* --------------
       * Resize Start Case
       * --------------
       */

      let newStart: DateTime
      let newEnd: DateTime

      if (state.item.isAllDayItem) {
        const newTime = state.lastMousePositionAt.startOf("day")
        // Item being dragged is an all-day item. The endLocal time will be
        // exclusive, so we need to subtract one day in order for the calculations
        // to be accurate.
        if (newTime >= state.item.endLocal.minus({ days: 1 })) {
          // Start date and end date have swapped over.
          newStart = state.item.endLocal.minus({ days: 1 }).startOf("day")
          newEnd = newTime.plus({ days: 1 }).startOf("day")
        } else {
          newStart = newTime.startOf("day")
          newEnd = state.item.endLocal
        }

        const updatedStart = updateDateTimeInfo(
          state.item.start,
          newStart,
          true,
        )
        const updatedEnd = updateDateTimeInfo(state.item.end, newEnd, true)

        return {
          ...state.item,
          start: updatedStart.dateTimeInfo,
          end: updatedEnd.dateTimeInfo,
          startLocal: updatedStart.dateTime,
          endLocal: updatedEnd.dateTime,
        }
      } else {
        // Item being dragged is an hour level item. It might be
        // being rendered as all day if it's too long.
        const newTime = state.lastMousePositionAt
        if (newTime > state.item.endLocal) {
          newStart = state.item.endLocal
          newEnd = newTime
        } else if (newTime.equals(state.item.endLocal)) {
          newStart = state.item.endLocal
          newEnd = state.item.endLocal.plus({ minutes: 5 })
        } else {
          newStart = newTime
          newEnd = state.item.endLocal
        }

        const updatedStart = updateDateTimeInfo(
          state.item.start,
          newStart,
          state.lastMousePositionWasAllDay,
        )
        const updatedEnd = updateDateTimeInfo(
          state.item.end,
          newEnd,
          state.lastMousePositionWasAllDay,
        )

        return {
          ...state.item,
          start: updatedStart.dateTimeInfo,
          end: updatedEnd.dateTimeInfo,
          startLocal: updatedStart.dateTime,
          endLocal: updatedEnd.dateTime,
        }
      }
    } else {
      /* --------------
       * Resize End Case
       * --------------
       */

      let newStart: DateTime
      let newEnd: DateTime

      if (state.item.isAllDayItem) {
        const newTime = state.lastMousePositionAt.startOf("day")
        // Item being dragged is an all-day item.
        if (newTime <= state.item.startLocal) {
          // Start date and end date have swapped over.
          newStart = newTime
          newEnd = state.item.startLocal.plus({ days: 1 })
        } else {
          newStart = state.item.startLocal
          newEnd = newTime.plus({ days: 1 }).startOf("day")
        }

        const updatedStart = updateDateTimeInfo(
          state.item.start,
          newStart,
          true,
        )
        const updatedEnd = updateDateTimeInfo(state.item.end, newEnd, true)

        return {
          ...state.item,
          start: updatedStart.dateTimeInfo,
          end: updatedEnd.dateTimeInfo,
          startLocal: updatedStart.dateTime,
          endLocal: updatedEnd.dateTime,
        }
      } else {
        // Item being dragged is an hour level item. It might be
        // being rendered as all day if it's too long.
        const newTime = state.lastMousePositionAt
        if (newTime < state.item.startLocal) {
          // Start and end have swapped over
          newStart = newTime
          newEnd = state.item.startLocal
        } else if (newTime.equals(state.item.startLocal)) {
          newStart = state.item.startLocal
          newEnd = state.item.startLocal.plus({ minutes: 5 })
        } else {
          newStart = state.item.startLocal
          newEnd = newTime
        }

        const updatedStart = updateDateTimeInfo(
          state.item.start,
          newStart,
          state.lastMousePositionWasAllDay,
        )
        const updatedEnd = updateDateTimeInfo(
          state.item.end,
          newEnd,
          state.lastMousePositionWasAllDay,
        )

        return {
          ...state.item,
          start: updatedStart.dateTimeInfo,
          end: updatedEnd.dateTimeInfo,
          startLocal: updatedStart.dateTime,
          endLocal: updatedEnd.dateTime,
        }
      }
    }
  },
})

// `itemDragDraftInRangeSelectorFamily` provides an efficient way to fetch
// draft items that fall within a given time range. It means that the subscribing
// component will only be notified of new draft items that fall within the time range
// it is interested in, rather than any changes globally.
export const itemDragDraftInRangeSelectorFamily = selectorFamily<
  [ItemDragState, ItemWithResolvedTimes] | undefined,
  [DateTime, DateTime]
>({
  key: "itemDragDraftInRange",
  get:
    ([regionStart, regionEnd]) =>
    ({ get }) => {
      const dragState = get(itemDragStateAtom)
      const draft = get(itemDragDraftSelector)
      if (!dragState || !draft) {
        return undefined
      }

      if (
        fallsInTimeRange(
          draft.startLocal,
          draft.endLocal,
          regionStart,
          regionEnd,
        )
      ) {
        return [dragState, draft]
      }

      return undefined
    },
})
export const useItemDragDraftInRange = (range: [DateTime, DateTime]) =>
  useRecoilValue(itemDragDraftInRangeSelectorFamily(range))

// `itemDraggingInProgressModeSelector` is a selector that returns the current
// dragging mode if there is an item being dragged.
// Useful for changing the cursor without necessarily re-rendering everything
// for every pixel moved.
export const itemDraggingInProgressModeSelector = selector<
  ItemDragMode | undefined
>({
  key: "itemDraggingInProgressMode",
  get: ({ get }) => {
    const state = get(itemDragStateAtom)
    if (!state || state.committed) {
      return undefined
    }

    return state.mode
  },
})
export const useItemDraggingInProgressMode = () =>
  useRecoilValue(itemDraggingInProgressModeSelector)

// `itemDraggingInProgressAllDaySelector` is a selector that returns whether
// or not the item being dragged is all-day or not.
// Useful for changing the cursor without necessarily re-rendering everything
// for every pixel moved.
export const itemDraggingInProgressAllDaySelector = selector<
  boolean | undefined
>({
  key: "itemDraggingInProgressAllDay",
  get: ({ get }) => {
    const draft = get(itemDragDraftSelector)
    if (!draft) {
      return undefined
    }

    return draft.renderAsAllDay
  },
})
export const useItemDraggingInProgressAllDay = () =>
  useRecoilValue(itemDraggingInProgressAllDaySelector)

// This selector is similar to the one above but returns just a single boolean
export const itemDraggingInProgressSelector = selector<boolean>({
  key: "itemDraggingInProgress",
  get: ({ get }) => {
    const state = get(itemDraggingInProgressModeSelector)
    return !!state
  },
})
export const useItemDraggingInProgress = () =>
  useRecoilValue(itemDraggingInProgressSelector)

// The `itemDragStateForItemSelectorFamily` provides an efficient way for
// items to access their own drag state without being notified when drag states
// on other items change.
export const itemDragStateForItemSelectorFamily = selectorFamily<
  [ItemDragState, ItemWithResolvedTimes] | undefined,
  string
>({
  key: "itemDragStateForItem",
  get:
    (itemId: string) =>
    ({ get }) => {
      const itemDragState = get(itemDragStateAtom)
      const itemDragDraft = get(itemDragDraftSelector)
      if (itemDragState && itemDragDraft && itemDragState.item.id === itemId) {
        return [itemDragState, itemDragDraft]
      }
      return undefined
    },
})
export const useItemDragStateFor = (itemId: string) =>
  useRecoilValue(itemDragStateForItemSelectorFamily(itemId))

export const itemDraggingRecurringSelectionModeInProgressSelector =
  selector<boolean>({
    key: "itemDraggingRecurringSelectionModeInProgress",
    get: ({ get }) => {
      const state = get(itemDragStateAtom)
      if (!state) {
        return false
      }

      return !!state.item.series && !!state.committed
    },
  })
export const useItemDraggingSelectingRecurringMode = () =>
  useRecoilValue(itemDraggingRecurringSelectionModeInProgressSelector)
