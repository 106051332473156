import { CategoryId } from "@daybridge/client-api"
import { DateTime } from "luxon"
import React, { useMemo } from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { ItemOrVirtualItem } from "../../types/itemOrVirtualItem"
import { ItemWithResolvedTimes } from "../../types/itemWithResolvedTimes"
import { VirtualItemType } from "../../types/virtualItem"
import { getAgendaItemHeight } from "../../utils/getAgendaItemHeight"
import { RenderItemOrVirtualItem } from "./RenderItemOrVirtualItem"

interface RenderAgendaItemProps {
  item: ItemOrVirtualItem
  continues: boolean
  alreadyStarted: boolean
  width: number
  isBeforeToday: boolean

  // `viewContainsToday` should be true if the rendered timeline contains
  // today (e.g. the current week in week mode).
  viewContainsToday: boolean
}

// `RenderAgendaItem` is a wrapper for `RenderItem` in agenda mode, to
// provide it with the right props and to dim past items.
export const RenderAgendaItem: React.FC<RenderAgendaItemProps> = React.memo(
  (props) => {
    // Dim past items that are in the current view.
    const isPast =
      props.viewContainsToday &&
      (props.isBeforeToday || DateTime.now() > props.item.endLocal)

    const [isWorkHidden] = usePreference("hideWorkItems")
    const isHiddenWorkItem = useMemo(() => {
      return (
        (props.item as ItemWithResolvedTimes).category?.node.id ===
          CategoryId.Work && isWorkHidden
      )
    }, [isWorkHidden, props.item])

    // Set a fixed height. Make sure we check for location coordinates and not
    // just the presence of a location, since it might have a location but not a
    // map.
    const height = useMemo(
      () =>
        getAgendaItemHeight(
          !!(props.item as ItemWithResolvedTimes).location?.place?.node
            .coordinates?.latitude,
          !!isHiddenWorkItem,
          props.item.isAllDayItem,
        ),
      [isHiddenWorkItem, props.item],
    )

    return props.item.type === VirtualItemType.SelectedRegion ? null : (
      <div className={isPast ? "opacity-50" : ""}>
        <RenderItemOrVirtualItem
          item={props.item}
          dayLevel={props.item.isAllDayItem}
          isAgenda
          renderProps={{
            fixedHeight: height,
            width: props.width,
            xOffset: 0,
            continues: false,
            alreadyStarted: false,
          }}
        />
      </div>
    )
  },
)
RenderAgendaItem.displayName = "RenderAgendaItem"
