import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"

type ItemDateTimeProps = Pick<
  ItemWithResolvedTimes,
  "start" | "end" | "startLocal" | "endLocal"
>

/**
 * `shouldRenderAtDayLevel` determines whether or not a given item should be
 * rendered as an "all day" item.
 */
export const shouldRenderAsAllDayItem = (item: ItemDateTimeProps): boolean => {
  // All day items should be rendered as such
  if (isAllDayItem(item)) {
    return true
  }

  // Items that are too long to be rendered at the hour level should be rendered
  // as all day items too.
  if (tooLongForHourLevel(item)) {
    return true
  }

  // Otherwise they can be rendered at the hour level
  return false
}

// `isAllDayItem` determines whether or not this item is an all-day item
export const isAllDayItem = (item: ItemDateTimeProps): boolean => {
  return !!item.start?.date || !!item.end?.date
}

// `tooLongForHourLevel` determines whether or not this item is too long to be
// rendered at the hour level.
export const tooLongForHourLevel = (item: ItemDateTimeProps): boolean => {
  if (!item.endLocal || !item.startLocal) {
    return false
  }

  return Math.abs(item.endLocal.diff(item.startLocal).as("days")) > 1
}
