import { DaysVisible, IconId } from "@daybridge/client-api"
import { I } from "@daybridge/icons"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  KeyboardShortcuts,
  Popover,
  Tooltip,
  TooltipWithKeyboardShortcut,
  FadeAndScaleAnimation,
  TooltipWithDescription,
} from "@daybridge/components-core"
import React, { useCallback } from "react"
import { DateTime, Duration, DurationLike } from "luxon"
import { durationIgnoringZoneDifferences } from "@daybridge/datetime"
import { useAnalytics } from "@daybridge/analytics"
import { AnimatePresence } from "framer-motion"
import { fromPromise } from "@daybridge/toast"
import { useRouter } from "next/router"
import { useTimeNavigation } from "../../hooks/navigation/useTimeNavigation"
import {
  ItemCreationStateStage,
  useItemCreationState,
} from "../../../items/state/creation"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { daysVisibleToTimeframe } from "../../utils/daysVisible"
import { useChangeSelectedRegion } from "../../hooks/selected-region/useChangeSelectedRegion"
import { TimelineDatePicker } from "./TimelineDatePicker"

interface TimelineHeaderProps {
  startLocal: DateTime
  endLocal: DateTime
  nowLocal: DateTime
  homeTimeZone: string
}

/**
 * TimelineNavigation is a bar at the top of the timeline allowing users to
 * navigate around. It also manages keyboard shortcuts for navigation.
 */
export const TimelineNavigation: React.FC<TimelineHeaderProps> = React.memo(
  (props: TimelineHeaderProps) => {
    const { setDefault } = useChangeSelectedRegion()
    const [creationState, setCreationState] = useItemCreationState()
    const {
      moveBackward: _moveBackward,
      moveForward: _moveForward,
      reset: _reset,
    } = useTimeNavigation()
    const { track } = useAnalytics()

    const [, setTimeZone] = usePreference("timeZone")

    const newCallback = useCallback(() => {
      setDefault(props.startLocal.zoneName)
      setCreationState({ stage: ItemCreationStateStage.ItemSearch })
    }, [setDefault, setCreationState, props.startLocal])

    // Override the duration in work week view - the duration is actually 5
    // days, but since we are hiding the weekend we want to navigate 7 days.
    const [daysVisible] = usePreference("daysVisible") // Note that this may be nullish
    const duration =
      daysVisible === DaysVisible.Five
        ? Duration.fromObject({ days: 7 })
        : durationIgnoringZoneDifferences(props.startLocal, props.endLocal)
    const timeframe = daysVisibleToTimeframe(daysVisible)

    const setTravelTimezoneAsDefault = useCallback(() => {
      void fromPromise(setTimeZone(props.nowLocal.zoneName), {
        successTitle: `${props.nowLocal.zoneName} set as default`,
        errorTitle: `Could not set new timezone`,
      })
    }, [props.nowLocal.zoneName, setTimeZone])

    // Wrap navigation functions to add analytics.
    // TODO: move these to the useTimeNavigation hook (we can't do that now
    // since we are not passing the shortcut).
    const moveBackward = useCallback(
      (duration: DurationLike, isShortcut?: boolean) => {
        track("calendar.navigate", {
          method: "paging",
          duration: Duration.fromDurationLike(duration).toISO(),
          keyboardShortcut: isShortcut ? "true" : undefined,
        })
        _moveBackward(duration)
      },
      [_moveBackward, track],
    )
    const moveForward = useCallback(
      (duration: DurationLike, isShortcut?: boolean) => {
        track("calendar.navigate", {
          method: "paging",
          keyboardShortcut: isShortcut ? "true" : undefined,
        })
        _moveForward(duration)
      },
      [_moveForward, track],
    )
    const reset = useCallback(
      (isShortcut?: boolean) => {
        track("calendar.navigate", {
          method: "jump_to_today",
          keyboardShortcut: isShortcut ? "true" : undefined,
        })
        _reset()
      },
      [_reset, track],
    )

    const router = useRouter()
    const isSettingsOpen = router.asPath.startsWith("/settings")
    const [hideWorkItems, setIsWorkItemsHidden] = usePreference("hideWorkItems")

    return (
      <KeyboardShortcuts
        shortcuts={
          creationState === undefined && !isSettingsOpen
            ? [
                // t: Jump to today
                {
                  key: "t",
                  onPress: () => reset(true),
                },
                // m: Mute work
                {
                  key: "m",
                  onPress: () => void setIsWorkItemsHidden(!hideWorkItems),
                },
                // n: New calendar entry
                {
                  key: "n",
                  onPress: newCallback,
                },
                {
                  // Arrow key: regular jump
                  key: "ArrowLeft",
                  onPress: () =>
                    moveBackward({ days: duration.as("days") }, true),
                },
                {
                  // Shift + Arrow Key: bigger jump
                  key: "ArrowLeft",
                  shiftKey: true,
                  onPress: () => moveBackward({ months: 1 }, true),
                },
                {
                  // Cmd + Arrow Key: huge jump
                  key: "ArrowLeft",
                  controlCommandKey: true,
                  shiftKey: true,
                  onPress: () => moveBackward({ months: 3 }, true),
                },
                {
                  // Arrow key: regular jump
                  key: "ArrowRight",
                  onPress: () =>
                    moveForward({ days: duration.as("days") }, true),
                },
                {
                  // Shift + Arrow Key: bigger jump
                  key: "ArrowRight",
                  shiftKey: true,
                  onPress: () => moveForward({ months: 1 }, true),
                },
                {
                  // Cmd + Arrow Key: huge jump
                  key: "ArrowRight",
                  controlCommandKey: true,
                  shiftKey: true,
                  onPress: () => moveForward({ months: 3 }, true),
                },
              ]
            : []
        }
      >
        <div
          className="
            flex-shrink-0
            w-full h-16 z-10
            bg-gradient-to-b from-elevated to-surface
            pl-3 pr-5
            flex items-center justify-between
          "
        >
          <div className="flex-1 flex flex-row items-center text-lg whitespace-nowrap w-[11rem]">
            <Popover
              className="p-4"
              content={<TimelineDatePicker />}
              align="start"
            >
              {({ open }) => (
                <Tooltip content="Jump to date">
                  <Button
                    variant={ButtonVariant.Clear}
                    size={ButtonSize.Custom}
                    className="px-4 text-md h-8 rounded-full capitalize"
                  >
                    {props.startLocal.hasSame(props.endLocal, "month") ? (
                      <span>
                        <span className="text-high-contrast font-semibold truncate">
                          {props.startLocal.toLocaleString({
                            month: "long",
                          })}
                        </span>{" "}
                        <span className="text-low-contrast truncate">
                          {props.startLocal.toLocaleString({
                            year: "numeric",
                          })}
                        </span>
                      </span>
                    ) : (
                      <span>
                        <span className="text-high-contrast font-semibold truncate capitalize">
                          {props.startLocal.toLocaleString({
                            month: "short",
                          })}{" "}
                          &mdash;{" "}
                          {props.endLocal.toLocaleString({
                            month: "short",
                          })}
                        </span>{" "}
                        <span className="text-low-contrast truncate capitalize">
                          {props.endLocal.toLocaleString({
                            year: "numeric",
                          })}
                        </span>
                      </span>
                    )}
                    <I
                      type={IconId.ChevronDown}
                      className={`
                        text-low-contrast w-3 h-3 ml-3
                        ${open ? "transform rotate-180" : ""}
                        transition duration-300 ease-in-out
                      `}
                    />
                  </Button>
                </Tooltip>
              )}
            </Popover>
          </div>
          <div className="mr-6 h-full flex items-center justify-center">
            {props.homeTimeZone !== props.nowLocal.zoneName && (
              <TooltipWithDescription
                title="It looks like you're travelling"
                description={
                  <>
                    Events are shown in the same time zone as your device.
                    <Button
                      variant={ButtonVariant.Headless}
                      size={ButtonSize.Custom}
                      onPress={setTravelTimezoneAsDefault}
                      className="pointer-events-auto mt-1.5 cursor-pointer text-primary"
                    >
                      Set {props.nowLocal.zoneName} as default
                    </Button>
                  </>
                }
                side="bottom"
                align="center"
              >
                <div className="flex items-center text-warning cursor-help">
                  <I type={IconId.World} className="w-4 h-4 flex-shrink-0" />
                  <div className="text-sm whitespace-nowrap ml-2">
                    Travelling
                  </div>
                </div>
              </TooltipWithDescription>
            )}
          </div>
          <div className="flex items-center space-x-3">
            <AnimatePresence>
              {props.nowLocal < props.startLocal ||
              props.nowLocal > props.endLocal ||
              // Show the Today button if we're hiding the weekends on a weekend
              // to make the weekends reappear (might be confusing otherwise).
              (daysVisible === DaysVisible.Five &&
                props.nowLocal.weekday > 5) ? (
                <FadeAndScaleAnimation>
                  <TooltipWithKeyboardShortcut
                    content={props.nowLocal.toLocaleString({
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                    shortcut="T"
                  >
                    <Button
                      variant={ButtonVariant.Outline}
                      size={ButtonSize.Base}
                      onPress={() => reset()}
                      className="font-semibold"
                    >
                      Today
                    </Button>
                  </TooltipWithKeyboardShortcut>
                </FadeAndScaleAnimation>
              ) : null}
            </AnimatePresence>
            <TooltipWithKeyboardShortcut
              content={`Previous ${timeframe}`}
              shortcut="←"
            >
              <Button
                variant={ButtonVariant.Outline}
                size={ButtonSize.Custom}
                className="h-8 px-5 rounded-full"
                onPress={() => moveBackward({ days: duration.as("days") })}
              >
                <I
                  type={IconId.ChevronLeft}
                  className="w-3 h-3 flex-shrink-0"
                />
              </Button>
            </TooltipWithKeyboardShortcut>

            <TooltipWithKeyboardShortcut
              content={`Next ${timeframe}`}
              shortcut="→"
            >
              <Button
                variant={ButtonVariant.Outline}
                size={ButtonSize.Custom}
                className="h-8 px-5 rounded-full"
                onPress={() => moveForward({ days: duration.as("days") })}
              >
                <I
                  type={IconId.ChevronRight}
                  className="w-3 h-3 flex-shrink-0"
                />
              </Button>
            </TooltipWithKeyboardShortcut>
            <TooltipWithKeyboardShortcut content="New" shortcut="N">
              <Button
                variant={ButtonVariant.Primary}
                size={ButtonSize.Custom}
                onPress={newCallback}
                className="h-8 px-5 rounded-full"
              >
                <I type={IconId.Plus} className="w-2.5 h-2.5 flex-shrink-0" />
              </Button>
            </TooltipWithKeyboardShortcut>
          </div>
        </div>
      </KeyboardShortcuts>
    )
  },
)
TimelineNavigation.displayName = "TimelineNavigation"
