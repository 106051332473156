import { DateTime } from "luxon"
import { useCallback } from "react"
import { useChangeSelectedRegion } from "../../timeline/hooks/selected-region/useChangeSelectedRegion"
import { useItemCreationState, ItemCreationStateStage } from "../state/creation"

export const useSetCreationStateByDate = () => {
  const { setDefault, changeStart, toggleAllDay } = useChangeSelectedRegion()
  const [, setCreationState] = useItemCreationState()

  return useCallback(
    async (date: DateTime) => {
      setDefault(date.zoneName)
      await changeStart(date)
      if (date.hour === 0) await toggleAllDay()
      setCreationState({ stage: ItemCreationStateStage.ItemSearch })
    },
    [setDefault, changeStart, toggleAllDay, setCreationState],
  )
}
