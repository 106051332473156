import { useAnalytics } from "@daybridge/analytics"
import { CategoryId, ItemTypeId } from "@daybridge/client-api"
import React, { useMemo } from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { useSelectedRegionSelectionInProgress } from "../../../timeline/state/selectedRegion"
import { useDeleteItem } from "../../hooks/useDeleteItem"
import { useStartDraggingItem } from "../../hooks/useStartDraggingItem"
import {
  useItemDraggingInProgress,
  useItemDragStateFor,
} from "../../state/dragging"
import { useEditingItem } from "../../state/editing"
import { useSharingItemId } from "../../state/sharing"
import { ItemWithResolvedTimes } from "../../types/itemWithResolvedTimes"
import { Card } from "../block-items/Card"
import { BlockItemProps } from "../block-items/common/BaseBlockItem"
import { HiddenItem } from "../block-items/HiddenItem"

type BlockRenderItemProps = Omit<BlockItemProps, "start" | "end" | "isDayLevel">

type RenderItemProps = {
  dayLevel: boolean
  isAgenda: boolean
  item: ItemWithResolvedTimes
  renderProps: BlockRenderItemProps
}

// `RenderItem` is used to render any type of item in any mode.
// It figures out what to render based on the item and the mode,
// and then delegates the rendering to the appropriate component.
export const RenderItem: React.FC<RenderItemProps> = React.memo((props) => {
  const drag = useStartDraggingItem(props.item)
  const selectingSomething = useSelectedRegionSelectionInProgress()
  const draggingSomething = useItemDraggingInProgress()
  const dragState = useItemDragStateFor(props.item.id)
  const [, setEditState] = useEditingItem()
  const [, setSharingItemId] = useSharingItemId()
  const [colorByCategory] = usePreference("colorByCategory")
  const deleteItem = useDeleteItem()
  const { track } = useAnalytics()

  const hasMoved = useMemo(
    () =>
      dragState &&
      !(
        dragState[0]?.item.startLocal.equals(dragState[1].startLocal) &&
        dragState[0]?.item.endLocal.equals(dragState[1].endLocal)
      ),
    [dragState],
  )

  // This logic is needed to make sure that it's still possible to click on
  // items to open up the details pane.
  const inert = useMemo(
    () =>
      props.renderProps.inert ||
      !!selectingSomething ||
      (draggingSomething && (!dragState || hasMoved)),
    [
      dragState,
      draggingSomething,
      hasMoved,
      props.renderProps.inert,
      selectingSomething,
    ],
  )

  const shouldFadeOut = useMemo(() => {
    return (
      dragState &&
      !(
        props.item.startLocal.equals(dragState[1].startLocal) &&
        props.item.endLocal.equals(dragState[1].endLocal)
      )
    )
  }, [props, dragState])

  // Hide work items if that option is enabled.
  const [hideWorkItems] = usePreference("hideWorkItems")
  if (hideWorkItems && props.item.category?.node?.id === CategoryId.Work) {
    return (
      <HiddenItem
        isAgenda={props.isAgenda}
        dayLevel={props.dayLevel}
        startLocal={props.item.startLocal}
        endLocal={props.item.endLocal}
        renderProps={props.renderProps}
      />
    )
  }

  // If the item is not hidden, display the correct item type.
  switch (props.item.type.id) {
    case ItemTypeId.Event:
      return props.item.calendar ? (
        <Card
          isAgenda={props.isAgenda}
          key={props.item.id}
          type={props.item.type.id}
          {...props.renderProps}
          isDayLevel={props.dayLevel}
          calendar={props.item.calendar.node}
          title={props.item.title}
          people={props.item.people}
          icon={props.item.icon?.id}
          id={props.item.id}
          color={
            colorByCategory
              ? props.item.category?.node.color
              : props.item.calendar.node.color
          }
          category={props.item.category?.node}
          start={props.item.startLocal}
          end={props.item.endLocal}
          location={
            props.item.location?.place?.node || props.item.location?.name
          }
          series={props.item.series?.node}
          sharing={props.item.sharing}
          onDrag={
            props.item.canEdit
              ? (e, mode) => {
                  switch (mode) {
                    case "start":
                      drag.beginResizeStart()
                      break
                    case "end":
                      drag.beginResizeEnd()
                      break
                    case "move":
                      drag.beginMove()
                      break
                  }
                }
              : undefined
          }
          onShare={
            props.item.canShare
              ? () => {
                  if (props.item.sharing?.isPublic) {
                    void track("event-link.view", {
                      itemId: props.item.id,
                    })
                  } else {
                    void track("event-link-create.start", {
                      itemId: props.item.id,
                    })
                  }
                  setSharingItemId(props.item.id)
                }
              : undefined
          }
          onEdit={
            props.item.canEdit ? () => setEditState(props.item) : undefined
          }
          onDelete={
            props.item.canDelete
              ? (mode) => deleteItem(props.item, mode)
              : undefined
          }
          fadedOut={shouldFadeOut}
          inert={inert}
        />
      ) : null
    default:
      return null
  }
})
RenderItem.displayName = "RenderItem"
