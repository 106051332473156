import { UseQueryOptions } from "@tanstack/react-query"
import { useMemo } from "react"
import { DateTime } from "luxon"
import { useItemQuery } from "@daybridge/client-api"
import { resolveDateTimeInfo } from "@daybridge/datetime"
import { GraphQLError } from "@daybridge/graphql"
import { ItemQuery } from "@daybridge/client-api/src/gen/operations"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"

export const useItem = (
  id: string | undefined,
  startLocal: DateTime,
  options?: UseQueryOptions<ItemQuery, string | GraphQLError, ItemQuery>,
) => {
  const { data, isLoading } = useItemQuery(
    { args: { id: id as string } },
    {
      enabled: !!id,
      ...(options || {}),
    },
  )
  const item = useMemo(() => {
    if (data?.item) {
      const item = data?.item?.node
      const start =
        item?.start && resolveDateTimeInfo(item.start, startLocal.zoneName)
      const end =
        item?.start &&
        resolveDateTimeInfo(
          item.end ? item.end : item.start,
          startLocal.zoneName,
        )
      if (start && end) {
        return {
          ...item,
          startLocal: start,
          endLocal: end,
        } as ItemWithResolvedTimes
      }
    }
    return
  }, [data?.item, startLocal.zoneName])
  return { item, isLoading }
}
