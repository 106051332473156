import { DateTime } from "luxon"
import React, { useEffect, useMemo, useRef } from "react"
import { expandDaysInRange } from "@daybridge/datetime"
import { ScrollArea } from "@daybridge/components-core"
import { TimelineHeader } from "../TimelineHeader"
import { useSetViewportRef } from "../../hooks/rendering/useViewportPosition"
import { PlanningDay } from "./PlanningDay"
import { TimeLabels } from "./TimeLabels"
import { DayLevelItems } from "./DayLevelItems"

interface PlanningViewProps {
  startLocal: DateTime
  endLocal: DateTime
  nowLocal: DateTime
  homeTimeZone: string
}

/**
 * `PlanningView` renders an interactive calendar with a variable number of days
 */
export const PlanningView: React.FC<PlanningViewProps> = React.memo(
  (props: PlanningViewProps) => {
    // Calculate the number of days we need to render
    // Create a DateTime object for each one corresponding to the
    // start of the day that needs to be rendered.
    const days: DateTime[] = useMemo(
      () => expandDaysInRange(props.startLocal, props.endLocal),
      [props.startLocal, props.endLocal],
    )

    const ref = useRef<HTMLDivElement>(null)
    const setViewportRef = useSetViewportRef()

    useEffect(() => {
      setViewportRef(ref)
    }, [ref, setViewportRef])

    return (
      <ScrollArea
        ref={ref}
        className="
          relative z-0
          flex-1 
          overflow-hidden
          bg-surface
          2xl:border border-tint-alpha bg-origin-border
          2xl:rounded-2xl
        "
      >
        <TimelineHeader {...props}>
          <DayLevelItems {...props} />
        </TimelineHeader>
        <div
          className={`
            min-h-full
            flex-1
            flex flex-row
          `}
        >
          <TimeLabels
            startLocal={props.startLocal}
            endLocal={props.endLocal}
            nowLocal={props.nowLocal}
          />

          {days.map((day) => (
            <PlanningDay
              key={day.toISODate()}
              dayLocal={day}
              nowLocal={props.nowLocal}
              cacheStartLocal={props.startLocal}
              cacheEndLocal={props.endLocal}
            />
          ))}
        </div>
      </ScrollArea>
    )
  },
)
PlanningView.displayName = "PlanningView"
