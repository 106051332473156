import { useCallback, useMemo } from "react"
import { DateTime } from "luxon"
import { useRecoilCallback } from "recoil"
import { expandDaysInRange } from "@daybridge/datetime"
import {
  selectedRegionAtom,
  selectedRegion_transformedForRenderer,
} from "../../state/selectedRegion"
import {
  useItemCreationState,
  ItemCreationStateStage,
} from "../../../items/state/creation"
import { useDragItem } from "../../../items/hooks/useDragItem"
import { useItemDraggingSelectingRecurringMode } from "../../../items/state/dragging"
import { computeXPositionFromMouseEvent } from "../../placement/positionFromMouseEvent"
import { TimelineMouseInteractions } from "./useHourLevelMouseInteractions"

export const useDayLevelMouseInteractions = (
  startLocal: DateTime,
  endLocal: DateTime,

  // `initialGutterWidth` applies a fixed left-shift to the X position of
  // all items.
  initialGutterWidth: number,

  // `container` is the DOM element used for rendering day-level entries.
  // Mouse event positions are computed relative to this container's bounds.
  container?: HTMLDivElement | null,
): TimelineMouseInteractions => {
  const [itemCreationState, setItemCreationState] = useItemCreationState()
  const { dragTo, commit } = useDragItem()
  const draggingInProgress = useItemDraggingSelectingRecurringMode()

  const days = useMemo(
    () => expandDaysInRange(startLocal, endLocal),
    [startLocal, endLocal],
  )

  // `DetermineDay` takes a mouse event and calculates which day
  // the mouse event occurred in.
  const determineDay = useCallback(
    (e: React.MouseEvent<HTMLDivElement>): DateTime | undefined => {
      if (!container) {
        return undefined
      }

      // Calculate the position the mouse was in on this mouse down event
      const position = computeXPositionFromMouseEvent(e, container)

      // Determine the width of each day
      const dayWidth =
        (container.clientWidth - initialGutterWidth) / days.length

      // Based on this width, calculate the day that this position
      // corresponds to.
      const dayIndex = Math.max(
        0,
        Math.floor((position - initialGutterWidth) / dayWidth),
      )

      return days[dayIndex]
    },
    [days, initialGutterWidth, container],
  )

  const onMouseDown = useRecoilCallback(
    ({ set }) => {
      return (e: React.MouseEvent<HTMLDivElement>): void => {
        if (e.button !== 0) {
          // Left clicks only - ignore right/middle clicks
          return
        }

        const day = determineDay(e)
        if (!day) {
          return
        }

        // The user may be choosing the edit mode (just this one, everything going forward, everything)
        // In this case don't start dragging a selected region.
        if (draggingInProgress) {
          return
        }

        // Set the selected time range to this day. Note that we put the time
        // in twice because the cursor hasn't moved yet, and therefore the
        // "start time" and "end time" of the placeholder are in fact the same time.
        // Since the user started clicking in the day-level region, we also force
        // this selected region to stay in the day level.
        set(selectedRegionAtom, {
          firstClickAt: day,
          firstClickWasAllDay: true,
          lastMousePositionAt: day,
          lastMousePositionWasAllDay: true,
        })
      }
    },
    [determineDay, draggingInProgress],
  )

  const onMouseMove = useRecoilCallback(
    ({ set }) => {
      return (e: React.MouseEvent<HTMLDivElement>) => {
        if (!container) {
          return
        }

        const day = determineDay(e)
        if (
          !day ||
          itemCreationState?.stage === ItemCreationStateStage.ItemDetails
        ) {
          return
        }

        // If something is being dragged, update the drag state with the new date
        dragTo(day, true)

        set(selectedRegionAtom, (val) => {
          return val !== undefined
            ? {
                ...val,
                lastMousePositionAt: day,
                lastMousePositionWasAllDay: true,
              }
            : undefined
        })
      }
    },
    [container, determineDay, itemCreationState?.stage, dragTo],
  )

  const onMouseUp = useRecoilCallback(({ snapshot, set }) => {
    return async (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()

      const selectedRegion = await snapshot.getPromise(
        selectedRegion_transformedForRenderer,
      )

      if (selectedRegion?.renderAsAllDay && !selectedRegion.isAllDayItem) {
        // If the user has selected a region that spans multiple days,
        // then assume they wanted to create a multi-day item. Snap the times
        // to day boundaries and set the `forceDayLevel` flag to true.
        // The next step of the creation flow will then make a lot more sense.
        set(selectedRegionAtom, (val) =>
          val
            ? {
                firstClickAt: val.firstClickAt.startOf("day"),
                firstClickWasAllDay: true,
                lastMousePositionAt: val.lastMousePositionAt.startOf("day"),
                lastMousePositionWasAllDay: true,
              }
            : undefined,
        )
      }

      // If we've been dragging something, commit the changes
      commit()

      // Open the creation flow
      if (selectedRegion) {
        setItemCreationState({ stage: ItemCreationStateStage.ItemSearch })
      }
    }
  })

  return {
    onMouseDown,
    onMouseMove,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onMouseUp,
  }
}
