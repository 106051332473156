import { atom, useRecoilState } from "recoil"
import { Activity, ItemType } from "@daybridge/client-api"

export enum ItemCreationStateStage {
  ItemSearch = "item_search",
  ItemDetails = "item_details",
}

export type ItemCreationState =
  | {
      stage: ItemCreationStateStage.ItemSearch
    }
  | {
      stage: ItemCreationStateStage.ItemDetails
      // One of either activity or itemType will be selected
      activity?: Activity
      itemType?: ItemType
    }
  | undefined

// State machine to keep track of the current state of the creation process
export const itemCreationStateAtom = atom<ItemCreationState>({
  key: "itemCreationState",
  default: undefined,
})
export const useItemCreationState = () => useRecoilState(itemCreationStateAtom)

// This atom keeps track of the text that the user inputted into the
// new item search box.
export const itemTitleAtom = atom<string>({
  key: "itemTitle",
  default: "",
})
export const useItemTitle = () => useRecoilState(itemTitleAtom)
