import { useCallback } from "react"

// Hosts that are considered safe to link to (excluding subdomains). For these
// hosts, we won't show the warning page.
export const SAFE_HOSTS = [
  "google.com",
  "daybridge.com",
  "microsoft.com",
  "zoom.us",
  "webex.com",
]

// Helper function that validates and parses a string as a link, even if the
// protocol (e.g. https://) is missing.
function parseLink(maybeLink?: string): URL | false {
  if (!maybeLink || typeof maybeLink !== "string") return false
  try {
    const url = new URL(maybeLink)
    if (!["http:", "https:"].includes(url.protocol)) return false
    return url
  } catch (e) {
    // If it fails, check if it's a valid link with a missing protocol. We need
    // to validate this with some regex since `new URL()` will succeed with
    // every string that starts with a protocol.
    try {
      const linkRegex =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      if (linkRegex.test(maybeLink)) {
        // Link is valid, so we can manually add a protocol.
        return new URL("https://" + maybeLink)
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
}

// Hook that checks if an external link is safe to open, and if not, returns a
// link to `/out` that will show a warning page before redirecting the user. Can
// also return `false` if the provided string is not a link at all.
export const useGetSafeExternalLink = () => {
  return useCallback((link: unknown): string | false => {
    if (!link || typeof link !== "string") return false
    try {
      const url = parseLink(link)
      if (!url) return false
      // Check if the link is safe based on its host. We only want to check
      // the host without any subdomains (so we can just add "google.com"
      // instead of "www.google.com", "meet.google.com" etc).
      const uriParts = url.hostname.split(".")
      const domainWithoutSubdomain = uriParts
        .slice(0)
        .slice(-(uriParts.length === 4 ? 3 : 2))
        .join(".")
      if (SAFE_HOSTS.includes(domainWithoutSubdomain)) {
        // Link is safe! Return the original link (possibly with https:// added
        // if the protocol was missing).
        return url.toString()
      } else {
        // Link is not safe. Return a link to `/out` that will show a warning.
        return `/out?redirectTo=${encodeURI(url.toString())}`
      }
    } catch (e) {
      return false
    }
  }, [])
}
