import { IconId } from "@daybridge/client-api"
import { Button, ButtonSize, ButtonVariant } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { DateTime } from "luxon"
import React, { useMemo } from "react"
import { RenderAgendaItem } from "../../../items/components/render/RenderAgendaItem"
import { ItemWithResolvedTimes } from "../../../items/types/itemWithResolvedTimes"
import { useAgendaViewTimeIndicatorPosition } from "../../hooks/rendering/useAgendaViewTimeIndicatorPosition"
import { DayPart } from "../../types/dayParts"
import { AgendaCurrentTimeIndicatorLine } from "./AgendaCurrentTimeIndicatorLine"

export interface AgendaDayPartProps {
  items: ItemWithResolvedTimes[] | undefined
  viewContainsToday: boolean
  title: DayPart
  icon: IconId
  nowLocal: DateTime
  startLocal: DateTime
  endLocal: DateTime
  createAtHour: number
  width: number
  onCreate: (createAtHour: number | undefined) => void
}

export const AgendaDayPart: React.FC<AgendaDayPartProps> = React.memo(
  (props: AgendaDayPartProps) => {
    const isBeforeToday = useMemo(() => {
      return props.startLocal < props.nowLocal.startOf("day")
    }, [props.nowLocal, props.startLocal])

    const timeIndicatorPosition = useAgendaViewTimeIndicatorPosition(
      props.startLocal,
      props.nowLocal,
      props.title,
      props.items,
    )

    const timeIndicator = useMemo(() => <AgendaCurrentTimeIndicatorLine />, [])

    return props.items && props.items.length > 0 ? (
      <div className="group">
        <div
          className="
            w-full pt-6 pb-2 px-2
            text-low-contrast
            text-sm font-medium
            flex space-x-2
          "
        >
          <I
            type={props.icon}
            className="w-3 h-3 text-icon-decorative mt-px flex-none"
          />{" "}
          <span>{props.title}</span>
          <Button
            className="opacity-0 group-hover:opacity-100 transition-opacity duration-150"
            variant={ButtonVariant.Clear}
            size={ButtonSize.Small}
            onPress={() => props.onCreate(props.createAtHour)}
          >
            <I
              type={IconId.Plus}
              className={`
                w-2 h-2
                text-low-contrast
              `}
            />
          </Button>
        </div>
        <div className="space-y-1 relative">
          {props.items.map((item: ItemWithResolvedTimes, i) => (
            <>
              {timeIndicatorPosition === i && timeIndicator}
              <RenderAgendaItem
                key={item.id}
                item={item}
                viewContainsToday={props.viewContainsToday}
                isBeforeToday={isBeforeToday}
                continues={item.endLocal > props.endLocal}
                alreadyStarted={item.startLocal < props.startLocal}
                width={props.width}
              />
            </>
          ))}
          {props.items.length === timeIndicatorPosition && timeIndicator}
        </div>
      </div>
    ) : null
  },
)

AgendaDayPart.displayName = "AgendaDayPart"
