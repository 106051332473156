import { IconId } from "@daybridge/client-api"
import { Button, ButtonSize, ButtonVariant } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"

interface ItemIconButtonProps {
  icon: IconId
  open?: boolean
  className?: string
}

const ItemIconButtonFn = React.forwardRef(
  (props: ItemIconButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { icon, open, className, ...rest } = props
    return (
      <Button
        variant={ButtonVariant.Headless}
        size={ButtonSize.Custom}
        className={`
          w-10 h-10
          rounded-xl 
          bg-content-primary
          text-icon-on-color
          ${props.className || ""}
        `}
        ref={ref}
        {...rest}
      >
        <I type={icon} className="w-4 h-4" />
        <div className="absolute z-10 cursor-pointer -right-1 -bottom-1 flex items-center justify-center bg-elevated shadow-sm w-3.5 h-3.5 rounded-full">
          <I
            type={open ? IconId.ChevronUp : IconId.ChevronDown}
            className="w-1.5 h-1.5 flex-shrink-0 text-low-contrast"
          />
        </div>
      </Button>
    )
  },
)
ItemIconButtonFn.displayName = "ItemIconButton"

export const ItemIconButton = React.memo(
  ItemIconButtonFn,
) as typeof ItemIconButtonFn
