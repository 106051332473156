import { DaysVisible } from "@daybridge/client-api"

export const timeframes = {
  [DaysVisible.One]: "Day",
  [DaysVisible.Three]: "3 Days",
  [DaysVisible.Five]: "Weekdays",
  [DaysVisible.Seven]: "Week",
}

export function daysVisibleToTimeframe(
  daysVisible: DaysVisible | null | undefined,
) {
  return timeframes[daysVisible || DaysVisible.Seven]
}

export function daysVisibleToNumber(
  daysVisible: DaysVisible | null | undefined,
) {
  switch (daysVisible) {
    case DaysVisible.One:
      return 1
    case DaysVisible.Three:
      return 3
    case DaysVisible.Five:
      return 5
    default:
      return 7
  }
}
