import { DateTime } from "luxon"
import React, { useEffect, useMemo, useRef } from "react"
import { expandDaysInRange } from "@daybridge/datetime"
import { useTimelineHeaderHeight } from "../state/timelineHeaderHeight"
import { DayHeader } from "./DayHeader"
import { TimelineNavigation } from "./navigation/TimelineNavigation"
import { TIME_LABELS_GUTTER_WIDTH } from "./planning/TimeLabels"

interface TimelineHeaderProps {
  startLocal: DateTime
  endLocal: DateTime
  nowLocal: DateTime
  homeTimeZone: string
  columnWidth?: number
  isAgenda?: boolean
  children?: React.ReactNode
}

/**
 * `PlanningViewHeader` is responsible for rendering a header and all-day items
 * for the `PlanningView`.
 */
export const TimelineHeader: React.FC<TimelineHeaderProps> = React.memo(
  (props) => {
    // Calculate the number of days we need to render
    // Create a DateTime object for each one corresponding to the
    // start of the day that needs to be rendered.
    const days: DateTime[] = useMemo(
      () => expandDaysInRange(props.startLocal, props.endLocal),
      [props.startLocal, props.endLocal],
    )

    // Store the header height in Recoil state - we need it to
    // properly display overflowing items.
    const [, setTimelineHeaderHeight] = useTimelineHeaderHeight()
    const headerRef = useRef<HTMLDivElement>(null)

    // Set up a resize observer to update the header height
    useEffect(() => {
      if (!headerRef.current) {
        return
      }

      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0]
        setTimelineHeaderHeight(entry.contentRect.height)
      })
      resizeObserver.observe(headerRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }, [headerRef, setTimelineHeaderHeight])

    // Tailwind note: semantic colors don't work together with
    // opacity, so we need to use the non-semantic color below.
    return (
      <div
        ref={headerRef}
        className="
          sticky top-0 w-full
          z-10
          flex flex-col
          transition-all duration-300 ease-in-out
        "
      >
        <TimelineNavigation {...props} />
        <div
          style={{
            paddingLeft: props.isAgenda ? 16 : TIME_LABELS_GUTTER_WIDTH,
          }}
          className={`
            w-full h-9
            flex flex-row items-center relative z-0
            bg-surface
            border-b border-tint-alpha
          `}
        >
          {days.map((day) => (
            <DayHeader
              columnWidth={props.columnWidth}
              key={day.toISODate()}
              date={day}
              isToday={day.hasSame(props.nowLocal, "day")}
            />
          ))}
        </div>
        {props.children}
      </div>
    )
  },
)
TimelineHeader.displayName = "TimelineHeader"
