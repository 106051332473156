import { Button, ButtonVariant, ButtonSize } from "@daybridge/components-core"
import React from "react"
import { ShareItemAppearanceBox } from "./components/ShareItemAppearanceBox"
import { ShareItemHeader } from "./components/ShareItemHeader"

interface ShareItemFormProps {
  onGenerateLink: () => void
  isRecurring: boolean
  // `onClose` is a callback to be invoked when the modal is closed
  // by clicking the cross button.
  onClose: (value: boolean) => void
}

export const ShareItemForm: React.FC<ShareItemFormProps> = React.memo(
  (props: ShareItemFormProps) => {
    return (
      <>
        <ShareItemHeader onClose={props.onClose} />
        <div className="p-6 space-y-6">
          <div className="space-y-3">
            <p className="text-base text-low-contrast">
              Create a unique link to share this event with others, even if they
              don&apos;t have Daybridge.
            </p>
          </div>
          <div className="space-y-3">
            <div className="font-semibold text-base text-high-contrast">
              How you&apos;ll appear
            </div>
            <ShareItemAppearanceBox onClose={props.onClose} />
            <div className="text-sm text-low-contrast">
              You can remove this event&apos;s link at any time.
            </div>
          </div>
          <Button
            block
            variant={ButtonVariant.Primary}
            size={ButtonSize.Medium}
            onPress={() => props.onGenerateLink()}
          >
            Create Link
          </Button>
        </div>
      </>
    )
  },
)

ShareItemForm.displayName = "ShareItemForm"
