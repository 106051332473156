/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTime, DurationLike } from "luxon"
import { useRecoilCallback } from "recoil"
import { useCallback } from "react"
import { useTime } from "@daybridge/server-time"
import { DaysVisible } from "@daybridge/client-api"
import { navigationDateAtom } from "../../state/navigationDate"
import usePreference from "../../../settings/hooks/preferences/usePreference"

interface TimeNavigation {
  jumpToDate: (date: DateTime) => void
  moveBackward: (amount: DurationLike) => void
  moveForward: (amount: DurationLike) => void
  reset: () => void
}

/*
 * useTimeNavigation allows components to manipulate the current navigation date.
 *
 * ⚠️ Note that we store the navigation date in UTC, not local time.
 * All local times will be converted to UTC before being used here.
 * For this reason it's important to convert this to local time if local time
 * is what is required for the use case. This can be achieved using the
 * `useTimeNavigation` hook.
 */
export const useTimeNavigation = (): TimeNavigation => {
  // Some functions need to know the current time to
  // operate correctly. To avoid callers having to provide
  // this themselves, we inject it for them here.
  const nowUTC = useTime().toUTC()
  const [daysVisible, setDaysVisible] = usePreference("daysVisible")

  const injectCurrentTime = useCallback(
    (fn: (t: DateTime, ...params: any) => any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
      return (...params: any) => fn(nowUTC, ...params)
    },
    [nowUTC],
  )

  /*
   * `jumpToDate` sets the navigation  date to the provided date
   */
  const jumpToDate = useRecoilCallback(({ set }) => {
    return (date: DateTime) => {
      set(navigationDateAtom, date.startOf("day").toUTC())
    }
  }, [])

  /*
   * `moveBackward` moves the navigation  date backward by the provided
   * duration.
   */
  const moveBackward = useRecoilCallback(({ snapshot, set }) => {
    return async (now: DateTime, amount: DurationLike) => {
      const navigationDate = await snapshot.getPromise(navigationDateAtom)

      let newDate: DateTime | undefined
      if (navigationDate) {
        newDate = navigationDate.minus(amount)
      } else {
        newDate = now.minus(amount)
      }

      if (newDate.hasSame(now, "day")) {
        newDate = undefined
      }

      set(navigationDateAtom, newDate)
    }
  }, [])

  /*
   * `moveForward` moves the navigation  date forward by the provided
   * duration.
   */
  const moveForward = useRecoilCallback(({ snapshot, set }) => {
    return async (now: DateTime, amount: DurationLike) => {
      const navigationDate = await snapshot.getPromise(navigationDateAtom)

      let newDate: DateTime | undefined
      if (navigationDate) {
        newDate = navigationDate.plus(amount)
      } else {
        newDate = now.plus(amount)
      }

      if (newDate.hasSame(now, "day")) {
        newDate = undefined
      }

      set(navigationDateAtom, newDate)
    }
  }, [])

  /*
   * `reset` sets the navigation  date to `undefined` which has
   * the effect of the client falling back to the current day.
   */
  const reset = useRecoilCallback(
    ({ set }) => {
      return () => {
        // If we're in work week view and it's a weekend, show the weekend
        // by moving to week view (might be confusing otherwise).
        if (daysVisible === DaysVisible.Five && nowUTC.weekday > 5) {
          void setDaysVisible(DaysVisible.Seven)
        }
        set(navigationDateAtom, undefined)
      }
    },
    [daysVisible, nowUTC.weekday, setDaysVisible],
  )

  return {
    jumpToDate,
    moveBackward: injectCurrentTime(moveBackward),
    moveForward: injectCurrentTime(moveForward),
    reset,
  }
}
