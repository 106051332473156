import { DateTime } from "luxon"
import { isEqual } from "lodash-es"
import { useCallback } from "react"
import {
  DateTimeArgs,
  DateTimeInfo,
  Item,
  PatchItemArgs,
} from "@daybridge/client-api"
import {
  SeriesArgs,
  RuleArgs,
  RuleInfo,
} from "@daybridge/client-api/src/gen/types"

// Filters any patch fields that are not changed.
export const usePatchItemDiff = () => {
  return useCallback((patch: PatchItemArgs, item: Item): PatchItemArgs => {
    const newPatch = { ...patch }
    for (const [k, value] of Object.entries(newPatch)) {
      const itemKey = k as keyof Item
      const patchKey = k as keyof PatchItemArgs

      // Strip any equal values.
      if (value === item[itemKey]) {
        delete newPatch[patchKey]
        continue
      }

      if (
        k === "series" &&
        isEqual(patch.series?.rules, item.series?.node.rules)
      ) {
        delete newPatch[patchKey]
        continue
      }

      // If we're submitting a new series, filter out all the unused rules
      // (currently null).
      if (k === "series" && patch.series?.rules?.length) {
        ;(newPatch.series as SeriesArgs).rules = patch.series.rules.map(
          (rules) => {
            const newRules = { ...rules } as RuleInfo
            Object.entries(rules).forEach(([ruleKey, ruleValue]) => {
              if ((ruleValue as DateTimeInfo)?.date === null) {
                ;(newRules[ruleKey as keyof RuleArgs] as DateTimeInfo) = {
                  dateTime: (ruleValue as DateTimeInfo).dateTime,
                  timeZone: (ruleValue as DateTimeInfo).timeZone,
                } as DateTimeInfo
              }
              if (ruleValue === null) {
                delete newRules[ruleKey as keyof RuleArgs]
              }
            })
            return newRules
          },
        )
      }

      // In the case of dates, the value is the same if the date (or dateTime)
      // and timezone are the same.
      if (k === "start" || k === "end") {
        const itemDateObj = item[itemKey] as DateTimeArgs
        const patchDateObj = newPatch[patchKey] as DateTimeArgs
        const itemDate =
          (itemDateObj.dateTime || itemDateObj.date) &&
          DateTime.fromISO(
            (itemDateObj.dateTime || itemDateObj.date) as string,
            {
              zone: itemDateObj.timeZone || undefined,
            },
          )
        const patchDate =
          (patchDateObj.dateTime || patchDateObj.date) &&
          DateTime.fromISO(
            (patchDateObj.dateTime || patchDateObj.date) as string,
            {
              zone: patchDateObj.timeZone || undefined,
            },
          )
        if (
          itemDate &&
          patchDate &&
          itemDate.toMillis() === patchDate.toMillis()
        ) {
          delete newPatch[patchKey]
          continue
        }
      }

      if (typeof item[itemKey] === "object") {
        // In some cases, the value of the form is a string, but the corresponding
        // value in the Item is an object with an ID that equals that string (e.g.
        // patch.icon could be "Beach" when item.icon is { id: "Beach", ... }).
        if (value === (item[itemKey] as { id: string })?.id) {
          delete newPatch[patchKey]
          continue
        }

        // In other cases, e.g. for `start` and `end`, the objects are the same but
        // don't have the same reference so they are wrongly included.
        if (isEqual(item[itemKey], newPatch[patchKey])) {
          delete newPatch[patchKey]
          continue
        }
      }
    }
    return newPatch
  }, [])
}
