import {
  ItemsDocument,
  ItemsArgs,
  ItemsQuery,
  ItemsQueryVariables,
  useItemsQuery,
} from "@daybridge/client-api"
import { GraphQLError, useFetcher } from "@daybridge/graphql"
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query"

export const useInfiniteItemsQuery = <
  TData = ItemsQuery,
  TError = string | GraphQLError,
>(
  variables: ItemsQueryVariables,
  options?: UseInfiniteQueryOptions<ItemsQuery, TError, TData>,
) => {
  const fetcher = useFetcher<ItemsQuery, ItemsQueryVariables>(ItemsDocument)

  return useInfiniteQuery<ItemsQuery, TError, TData>(
    useItemsQuery.getKey(variables),
    (metaData: { pageParam?: ItemsArgs }) =>
      fetcher.bind(null, {
        args: { ...variables.args, ...(metaData.pageParam ?? {}) },
      })(),
    {
      getNextPageParam: (lastPage: ItemsQuery): ItemsArgs | undefined => {
        return lastPage.items?.pageInfo?.hasNextPage
          ? {
              first: lastPage.items.edges.length,
              after: lastPage.items.pageInfo.endCursor,
            }
          : undefined
      },
      getPreviousPageParam: (firstPage: ItemsQuery): ItemsArgs | undefined => {
        return firstPage.items?.pageInfo?.hasPreviousPage
          ? {
              last: firstPage.items.edges.length,
              before: firstPage.items.pageInfo.startCursor,
            }
          : undefined
      },
      ...options,
    },
  )
}
