import { v4 } from "uuid"

const LOCALSTORAGE_KEY_ID = "daybridge-identifier"
const LOCALSTORAGE_KEY_DISPLAY_NAME = "daybridge-name"

// To allow for anonymous responses, we can store an identifier in the user's
// local storage. The identifier contains a user id and a secret key, seperated
// by a colon. Both are a version 4 UUID.

type LocalIdentifier = {
  uid: string | undefined
  key: string | undefined
  displayName: string | undefined
}

// Get the locally stored information from the browser.
export function getLocalIdentifier(): LocalIdentifier {
  if (typeof window === "undefined")
    return { uid: undefined, key: undefined, displayName: undefined }

  const localValue = window.localStorage
    .getItem(LOCALSTORAGE_KEY_ID)
    ?.split(":")

  return {
    uid: localValue?.length === 2 ? localValue[0] : undefined,
    key: localValue?.length === 2 ? localValue[1] : undefined,
    displayName:
      window.localStorage.getItem(LOCALSTORAGE_KEY_DISPLAY_NAME) || undefined,
  }
}

// Set a new identifier in the browser. If one is already set, we should not
// overwrite it but should still be able to update the display name.
export function setLocalIdentifier(displayName: string): LocalIdentifier {
  if (typeof window === "undefined")
    return { uid: undefined, key: undefined, displayName: undefined }

  let { uid, key } = getLocalIdentifier()
  uid = uid || v4()
  key = key || v4()

  window.localStorage.setItem(LOCALSTORAGE_KEY_ID, `${uid}:${key}`)
  window.localStorage.setItem(LOCALSTORAGE_KEY_DISPLAY_NAME, displayName)

  return {
    uid,
    key,
    displayName:
      window.localStorage.getItem(LOCALSTORAGE_KEY_DISPLAY_NAME) || undefined,
  }
}
