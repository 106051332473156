import { atom, SetterOrUpdater, useRecoilState } from "recoil"

// Pass the calendar ID to edit, or undefined to close the modal.
export const editCalendarIdAtom = atom<string | undefined>({
  key: "edit_calendar_id",
  default: undefined,
})
export const useEditCalendarId = (): [
  string | undefined,
  SetterOrUpdater<string | undefined>,
] => useRecoilState(editCalendarIdAtom)
