import { useEffect, useMemo } from "react"
import { ContactNode } from "@daybridge/client-api/src/gen/types"
import { useInfiniteContactsQuery } from "./useInfiniteContactsQuery"

export const useContacts = (): {
  contacts: ContactNode[]
  isLoading: boolean
} => {
  const {
    data: contacts,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    isLoading,
  } = useInfiniteContactsQuery()

  useEffect(() => {
    if (hasNextPage && !isRefetching) {
      void fetchNextPage()
    }
  }, [contacts?.pageParams.length, hasNextPage, fetchNextPage, isRefetching])

  const allContacts = useMemo(() => {
    return (
      contacts?.pages?.flatMap((page) => {
        if (!page.contacts || !page.contacts.edges) {
          return []
        }

        return page.contacts.edges.map(
          (edge): ContactNode => ({
            ...edge.node,
            id: edge.id,
          }),
        )
      }) || []
    )
  }, [contacts?.pages])

  return { contacts: allContacts, isLoading }
}
