import { useAuth } from "@daybridge/auth"
import { UserNode, useUserQuery } from "@daybridge/client-api"
import debounce from "lodash-es/debounce"
import { useEffect, useRef, useState } from "react"

export const useFindByUsername = (
  username: string,
): { user: UserNode | undefined; status: "error" | "success" | "loading" } => {
  // We need this state, ref and effect to debounce the query and avoid the
  // query being called every time the user types something in the search field.
  const [usernameToValidate, setUsernameToValidate] = useState("")

  const validateUsername = useRef(
    debounce((username: string) => {
      setUsernameToValidate(username)
    }, 200),
  ).current

  useEffect(() => {
    validateUsername(username)
  }, [username, validateUsername])

  // We need to get the current user to avoid querying for the current user.
  const { user: auth } = useAuth()
  const { data: me } = useUserQuery({ args: { id: auth?.uid } })

  const { status, data } = useUserQuery(
    { args: { username: usernameToValidate } },
    {
      enabled: !!usernameToValidate,
      useErrorBoundary: false,
      // Only refetch if the username changes
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  )

  return {
    user:
      status === "success" &&
      me?.user?.node.username !== data.user?.node.username &&
      data?.user?.node.displayName
        ? data.user.node
        : undefined,
    status,
  }
}
