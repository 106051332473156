import { DaysVisible, IconId, useMeQuery } from "@daybridge/client-api"
import { KeyboardShortcuts } from "@daybridge/components-core"
import { useTime } from "@daybridge/server-time"
import React, { useCallback, useEffect, useMemo } from "react"
import { DateTime } from "luxon"
import { durationIgnoringZoneDifferences } from "@daybridge/datetime"
import { useAnalytics } from "@daybridge/analytics"
import Head from "next/head"
import { plain } from "@daybridge/toast"
import { renderToStaticMarkup } from "react-dom/server"
import { useRouter } from "next/router"
import { useItemCreationState } from "../../items/state/creation"
import { useEditingItem } from "../../items/state/editing"
import { useNavigationDate } from "../hooks/navigation/useNavigationDate"
import { useResetTimeline } from "../hooks/useResetTimeline"
import { EditCalendarFormCoordinator } from "../../calendars/components/editing/EditCalendarFormCoordinator"
import { AppsPopupCoordinator } from "../../settings/components/AppsPopupCoordinator"
import usePreference from "../../settings/hooks/preferences/usePreference"
import { daysVisibleToNumber } from "../utils/daysVisible"
import { ShareItemFormCoordinator } from "../../sharing/form/ShareItemFormCoordinator"
import usePreset from "../hooks/presets/usePreset"
import { LocalNotifications } from "../../notifications/components/LocalNotifications"
import { useOpenItem } from "../../items/state/opening"
import { TourBoxCoordinator } from "../../tour/components/TourBoxCoordinator"
import { useObserveViewportPosition } from "../hooks/rendering/useObserveViewportPosition"
import { CreateItemSearch } from "../../items/components/creation/CreateItemSearch"
import { PageWithMetadata } from "../../../types/PageWithMetadata"
import ServerPushNotifications from "../../notifications/components/ServerPushNotifications"
import { ShareCalendarModalCoordinator } from "../../calendars/components/sharing/ShareCalendarModalCoordinator"
import { PlanningView } from "./planning/PlanningView"
import { Sidebar } from "./sidebar/Sidebar"
import { AgendaView } from "./agenda/AgendaView"
import { Favicon } from "./Favicon"
import { DetailsPaneCoordinator } from "./details-pane/DetailsPaneCoordinator"

// `TICK_INTERVAL` is how ofren the current time should be updated.
export const TICK_INTERVAL = 10000

/**
 * `Timeline` is the main component of the timeline. It's responsible for choosing
 * which view to render, and rendering the header at the top of the timeline.
 */
export const Timeline: PageWithMetadata = React.memo(() => {
  // To render the timeline correctly, we need to know the timezone the user
  // is viewing the timeline in.
  const { data: account } = useMeQuery()
  const homeTimeZone = account?.me?.account.preferences?.timeZone
  const timeZone = DateTime.local().zoneName

  // Fetch the current time.
  const now = useTime(TICK_INTERVAL, timeZone)

  // The navigation date is the date that the user is currently viewing.
  const navigationDate = useNavigationDate(TICK_INTERVAL, timeZone)

  // Reset function
  const reset = useResetTimeline()

  const [, setOpenItem] = useOpenItem()

  let [daysVisible] = usePreference("daysVisible")
  if (!daysVisible) daysVisible = DaysVisible.Seven

  const [hideFreeTime] = usePreference("hideFreeTime")

  // Register an effect that observes and stores the viewport position, so that
  // we can display overflowing items.
  useObserveViewportPosition()

  // We currently differentiate between "relative mode" (the first day that's
  // rendered is the current day) vs. "absolute mode" (the first day is Monday)
  // based on the number of days. It might make sense to not link the two in
  // the future and turn both into options the user can change.
  const startLocal = useMemo(
    () => {
      if (
        daysVisible === DaysVisible.Five ||
        daysVisible === DaysVisible.Seven
      ) {
        return navigationDate.startOf("week")
      } else if (daysVisible === DaysVisible.Three) {
        // Use `durationIgnoringZoneDifferences` as we may have to deal with
        // summer/winter time transitions.
        const diffInDays = Math.abs(
          durationIgnoringZoneDifferences(
            navigationDate.startOf("day"),
            DateTime.now().startOf("day"),
          ).as("days"),
        )

        // If you're in 3-day mode, you expect today to always be on the first
        // day that is rendered. That is why we set the navigation date to the
        // closest multiple of 3 when you switch to this view.
        if (diffInDays % 3 > 0) {
          return navigationDate.minus({ days: diffInDays % 3 }).startOf("day")
        } else {
          return navigationDate.startOf("day")
        }
      } else {
        return navigationDate.startOf("day")
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [daysVisible, navigationDate.startOf("day").toISO()],
  )

  const endLocal = useMemo(() => {
    return startLocal.plus({
      days: daysVisibleToNumber(daysVisible),
    })
  }, [startLocal, daysVisible])

  // Analytics: send event whenever the app renders a new window of time
  const { track } = useAnalytics()
  const trackCalendarView = useCallback(() => {
    track("calendar.view", {
      timeResolution: "week",
      dateRange: `${startLocal.toISODate()}-${endLocal.toISODate()}`,
    })
  }, [track, startLocal, endLocal])

  useEffect(() => {
    window.addEventListener("focus", trackCalendarView)
    return () => window.removeEventListener("focus", trackCalendarView)
  }, [trackCalendarView])

  const [creationState] = useItemCreationState()
  const [editingState] = useEditingItem()
  const [, setPreset] = usePreset()

  // Render a favicon to show the current date
  const favicon = useMemo(
    () =>
      encodeURIComponent(renderToStaticMarkup(<Favicon>{now.day}</Favicon>)),
    [now],
  )

  const router = useRouter()
  const isSettingsOpen = router.asPath.startsWith("/settings")

  return (
    <>
      <ServerPushNotifications />
      <Head>
        <title>Calendars | Daybridge</title>
        <link
          key="favicon"
          rel="shortcut icon"
          href={`data:image/svg+xml,${favicon}`}
          type="image/svg+xml"
        />
      </Head>
      <KeyboardShortcuts
        shortcuts={
          isSettingsOpen
            ? []
            : [
                {
                  key: "Escape",
                  onPress: () => {
                    reset()
                    setOpenItem(undefined)
                  },
                },
                {
                  key: "f",
                  onPress: () => {
                    void setPreset("focus", true)
                    plain({
                      title: "Focus",
                      icon: IconId.Clock,
                      id: "set-preset",
                    })
                  },
                },
                {
                  key: "p",
                  onPress: () => {
                    void setPreset("plan", true)
                    plain({
                      title: "Plan",
                      icon: IconId.Calendar,
                      id: "set-preset",
                    })
                  },
                },
                {
                  key: "r",
                  onPress: () => {
                    void setPreset("relax", true)
                    plain({
                      title: "Relax",
                      icon: IconId.Beach,
                      id: "set-preset",
                    })
                  },
                },
              ]
        }
      >
        <div
          role="none"
          // Capture stray mouse-up events when dragging
          onMouseUp={() => {
            if (creationState || editingState) return
            reset()
          }}
          className="h-full flex flex-col bg-background"
        >
          <div className="h-full 2xl:p-2 2xl:pl-0 flex flex-row flex-1">
            <Sidebar />
            {hideFreeTime ? (
              <AgendaView
                startLocal={startLocal}
                endLocal={endLocal}
                nowLocal={now}
                homeTimeZone={homeTimeZone || timeZone}
              />
            ) : (
              <PlanningView
                startLocal={startLocal}
                endLocal={endLocal}
                nowLocal={now}
                homeTimeZone={homeTimeZone || timeZone}
              />
            )}
            <DetailsPaneCoordinator startLocal={startLocal} />
          </div>
          <EditCalendarFormCoordinator />
          <ShareCalendarModalCoordinator />
          <ShareItemFormCoordinator />
          <AppsPopupCoordinator />
          <TourBoxCoordinator />

          <CreateItemSearch />
          <LocalNotifications />
        </div>
      </KeyboardShortcuts>
    </>
  )
})
Timeline.displayName = "Timeline"
