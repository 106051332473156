import { UseMutateAsyncFunction, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import {
  AddToContactsMutation,
  useAddToContactsMutation,
  useContactsQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { fromPromise } from "@daybridge/toast"
import { AddToContactsMutationVariables } from "@daybridge/client-api/src/gen/operations"

export const useAddToContacts = (): UseMutateAsyncFunction<
  AddToContactsMutation,
  string | GraphQLError,
  AddToContactsMutationVariables
> => {
  const queryClient = useQueryClient()
  const { mutateAsync } = useAddToContactsMutation({
    useErrorBoundary: false,
  })

  return useCallback(
    (values) => {
      const username = values.input.username
      if (!username) throw new Error("No username provided.")

      const result = mutateAsync(values, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(
            useContactsQuery.getKey({ args: {} }),
          )
        },
      })
      void fromPromise(result, {
        loadingMessage: `Adding @${username}...`,
        successTitle: `@${username} added`,
      })
      return result
    },
    [queryClient, mutateAsync],
  )
}
