import {
  Activity,
  ActivityId,
  Color,
  IconId,
  ItemTypeId,
} from "@daybridge/client-api"
import { ListMenuItemProps, Tag } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { colorToClass } from "@daybridge/colors"
import React, { useMemo } from "react"

/*
Compiles a set of renderable results from matching activities
 */
export const useActivitySearchResults = (
  searchValue: string,
  matches: ActivityId[] | undefined,
  activities: { [activity in ActivityId]: Activity } | undefined,
): ListMenuItemProps<ActivityId | ItemTypeId>[] => {
  return useMemo(() => {
    if (!matches || !activities) {
      return []
    }

    return matches
      .map((match): ListMenuItemProps<ActivityId | ItemTypeId> => {
        const activity = activities[match]
        return {
          id: activity.id,
          title: activity.name,
          contents: (
            <div
              className={`w-full flex flex-row space-x-2 items-center content-${colorToClass(
                activity.defaultCategory.node.color || Color.Stone,
              )}`}
            >
              <div
                className={`
                  w-6 h-6 p-[7px]
                  rounded-full 
                  text-white bg-content-primary
                  flex items-center justify-center
                  -ml-2
                  mr-1
                `}
              >
                <I type={activity.defaultIcon.id} className="w-full h-full" />
              </div>
              <span>{activity.name}</span>
              <div className="flex-1" />
              <Tag className="bg-surface">
                {activity.defaultCategory.node.name}
              </Tag>
            </div>
          ),
        }
      })
      .concat(
        searchValue.length > 0
          ? [
              {
                id: ItemTypeId.Event,
                title: `Add "${searchValue}" as an Event`,
                contents: (
                  <div className="w-full flex flex-row space-x-2 items-center">
                    <div
                      className={`
                        w-6 h-6 p-[7px]
                        rounded-full 
                        text-low-contrast bg-background
                        flex items-center justify-center
                        -ml-2
                        mr-1
                      `}
                    >
                      <I type={IconId.Calendar} className="w-full h-full" />
                    </div>
                    <span>Add &quot;{searchValue}&quot;</span>
                    <div className="flex-1" />
                  </div>
                ),
              },
            ]
          : [],
      )
  }, [searchValue, matches, activities])
}
