import { DateTimeInfo } from "@daybridge/client-api"
import {
  resolveDateTimeInfo,
  dateTimeToDateTimeInfo,
} from "@daybridge/datetime"
import { DateTime } from "luxon"
import { useCallback } from "react"

export const useChangeExistingItem = () => {
  const changeExistingItemStart = useCallback(
    (
      start: DateTimeInfo,
      end: DateTimeInfo,
      newStartLocal: DateTime,
      isAllDayItem: boolean,
    ): [DateTimeInfo, DateTimeInfo] => {
      // Preserve duration
      const startLocal = resolveDateTimeInfo(start, newStartLocal.zoneName)
      const endLocal = resolveDateTimeInfo(end, newStartLocal.zoneName)
      const duration = endLocal.diff(startLocal)
      const newEndLocal = newStartLocal.plus(duration)
      const newStart = dateTimeToDateTimeInfo(newStartLocal, {
        isDate: isAllDayItem,
      })
      const newEnd = dateTimeToDateTimeInfo(newEndLocal, {
        isDate: isAllDayItem,
      })
      return [newStart, newEnd]
    },
    [],
  )

  const changeExistingItemEnd = useCallback(
    (
      start: DateTimeInfo,
      end: DateTimeInfo,
      newEndLocal: DateTime,
      isAllDayItem: boolean,
    ): DateTimeInfo => {
      const startLocal = resolveDateTimeInfo(start, newEndLocal.zoneName)
      if (newEndLocal <= startLocal) {
        return dateTimeToDateTimeInfo(
          startLocal.plus(isAllDayItem ? { minutes: 0 } : { minutes: 5 }),
          {
            isDate: isAllDayItem,
          },
        )
      } else {
        return dateTimeToDateTimeInfo(newEndLocal, {
          isDate: isAllDayItem,
        })
      }
    },
    [],
  )

  return { changeExistingItemStart, changeExistingItemEnd }
}
