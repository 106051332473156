import { useAnalytics } from "@daybridge/analytics"
import { IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Tooltip,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { DateTime } from "luxon"
import React from "react"
import { useTimeNavigation } from "../../../../timeline/hooks/navigation/useTimeNavigation"

interface BlockJumpToDateButtonProps {
  direction: "backwards" | "forwards"
  date: DateTime
}

export const BlockJumpToDateButton: React.FC<BlockJumpToDateButtonProps> =
  React.memo((props: BlockJumpToDateButtonProps) => {
    const { jumpToDate } = useTimeNavigation()
    const { track } = useAnalytics()

    const helpText =
      props.direction === "backwards"
        ? `Jump to start (${props.date.toLocaleString({
            day: "numeric",
            month: "short",
            year: "numeric",
          })})`
        : `Jump to end (${props.date.toLocaleString({
            day: "numeric",
            month: "short",
            year: "numeric",
          })})`

    return (
      <Tooltip content={helpText}>
        <Button
          variant={ButtonVariant.Headless}
          size={ButtonSize.Custom}
          className="
          w-5 h-5 
          bg-surface hover:bg-tint
          text-low-contrast
          rounded 
          shadow-sm 
          border border-tint
          cursor-pointer
        "
          onMouseDown={(e) => e.stopPropagation()}
          onPress={() => {
            track("calendar.navigate", { method: "jump_to_date" })
            jumpToDate(props.date)
          }}
        >
          <I
            type={
              props.direction === "backwards"
                ? IconId.ArrowLeft
                : IconId.ArrowRight
            }
            className="w-[10px] h-[10px]"
          />
        </Button>
      </Tooltip>
    )
  })
BlockJumpToDateButton.displayName = "BlockJumpToDateButton"
