import {
  CategoryId,
  CreateItemArgs,
  IconId,
  ItemTypeId,
  PatchItemArgs,
} from "@daybridge/client-api"
import { SidebarModal } from "@daybridge/components-core"
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { v4 } from "uuid"
import { Formik } from "formik"
import { useGroupedCalendars } from "../../../calendars/hooks/useGroupedCalendars"
import { EditMode } from "../../types/editMode"
import {
  ItemCreationStateStage,
  useItemCreationState,
  useItemTitle,
} from "../../state/creation"
import {
  useSelectedRegionForCreateForm,
  SelectedRegion,
} from "../../../timeline/state/selectedRegion"
import { useResetTimeline } from "../../../timeline/hooks/useResetTimeline"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { useEditingItem } from "../../state/editing"
import { useCreateItem } from "../../hooks/useCreateItem"
import { useEditItem } from "../../hooks/useEditItem"
import { ItemEditPaneForm, ItemTimes } from "./ItemEditPaneForm"

const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    if (!value) return
    ref.current = value
  })
  return ref.current
}

export const ItemEditPane = memo(<
  T extends CreateItemArgs | PatchItemArgs,
>() => {
  const [editingItem] = useEditingItem()
  const [defaultCalendarId] = usePreference("defaultCalendar")

  // ------------------------------
  // Idempotency Key
  // ------------------------------
  const [idempotencyKey] = useState(v4())

  // ------------------------------
  // External Global State
  // ------------------------------
  const [creationState] = useItemCreationState()

  // ------------------------------
  // Title
  // ------------------------------
  const [title] = useItemTitle()

  // ------------------------------
  // Calendars
  // ------------------------------
  const { groups: calendarGroups } = useGroupedCalendars()
  const selectableCalendars = calendarGroups
    .map((group) =>
      group.filter(
        (calendar) =>
          calendar.canCreate &&
          (!editingItem ||
            calendar.provider.id === editingItem.calendar?.node.provider.id),
      ),
    )
    .filter((group) => group.length !== 0)

  const selectedRegion = useSelectedRegionForCreateForm()
  const previousSelectedRegion = usePrevious(
    selectedRegion,
  ) as NonNullable<SelectedRegion>
  const previousItem = usePrevious(editingItem)
  const itemTimes: NonNullable<SelectedRegion> = useMemo((): ItemTimes => {
    const itemWithPrevious = editingItem || previousItem
    const sr = selectedRegion || previousSelectedRegion
    return itemWithPrevious
      ? ({
          start: itemWithPrevious.start,
          startLocal: itemWithPrevious.startLocal,
          end: itemWithPrevious.isAllDayItem
            ? {
                date: itemWithPrevious.endLocal.minus({ days: 1 }).toISODate(),
              }
            : itemWithPrevious.end,
          endLocal: itemWithPrevious.isAllDayItem
            ? itemWithPrevious.endLocal.minus({ days: 1 })
            : itemWithPrevious.endLocal,
          isAllDayItem: itemWithPrevious.isAllDayItem,
          renderAsAllDay: itemWithPrevious.renderAsAllDay,
        } as NonNullable<SelectedRegion>)
      : sr
  }, [editingItem, previousItem, selectedRegion, previousSelectedRegion])

  // ------------------------------
  // Location
  // ------------------------------

  const reset = useResetTimeline()

  // ------------------------------
  // Repeat Settings
  // ------------------------------
  const [mode, setMode] = useState<EditMode | undefined>()

  const create = useCreateItem()
  const edit = useEditItem()
  const handleSubmit = useCallback(
    (input: T) => {
      if (editingItem) {
        void edit(
          input as PatchItemArgs,
          idempotencyKey,
          itemTimes.startLocal.zoneName,
          editingItem,
          mode,
        )
      } else {
        void create(
          input as CreateItemArgs,
          idempotencyKey,
          itemTimes.startLocal.zoneName,
        )
      }
    },
    [
      create,
      edit,
      editingItem,
      idempotencyKey,
      itemTimes.startLocal.zoneName,
      mode,
    ],
  )

  return (
    <div className="h-full overflow-hidden">
      <SidebarModal
        closeConfirmationOptions={{
          title: editingItem ? "Discard your changes?" : "Discard new event?",
          description: (
            <>
              Are you sure you want to discard your changes to{" "}
              {editingItem?.title ? (
                <span className="font-semibold">{editingItem.title}</span>
              ) : (
                "this item"
              )}
              ?
            </>
          ),
          onActionPress: () => reset(),
        }}
      >
        {(Trigger) => (
          <>
            <Formik<T>
              onSubmit={handleSubmit}
              initialValues={
                {
                  calendar:
                    editingItem?.calendar?.id ||
                    defaultCalendarId ||
                    selectableCalendars[0][0]?.id ||
                    "",
                  title: editingItem?.title || title,
                  icon:
                    editingItem?.icon?.id ||
                    (creationState?.stage === ItemCreationStateStage.ItemDetails
                      ? creationState.activity?.defaultIcon.id ||
                        creationState.itemType?.defaultIcon.id ||
                        IconId.Calendar
                      : IconId.Calendar),
                  type:
                    editingItem?.type.id ||
                    (creationState?.stage === ItemCreationStateStage.ItemDetails
                      ? creationState.activity?.defaultItemType.id ||
                        creationState.itemType?.id ||
                        ItemTypeId.Event
                      : ItemTypeId.Event),
                  category:
                    editingItem?.category?.id ||
                    (creationState?.stage === ItemCreationStateStage.ItemDetails
                      ? creationState.activity?.defaultCategory.id ||
                        creationState.itemType?.defaultCategory.id ||
                        CategoryId.General
                      : CategoryId.General),
                  start: itemTimes.start,
                  end: itemTimes.end,
                  series: editingItem?.series
                    ? { rules: editingItem.series.node.rules }
                    : null,
                } as T
              }
            >
              {(formProps) => {
                return (
                  <ItemEditPaneForm
                    {...formProps}
                    setMode={setMode}
                    trigger={Trigger}
                    itemTimes={itemTimes}
                    selectableCalendars={selectableCalendars}
                  />
                )
              }}
            </Formik>
          </>
        )}
      </SidebarModal>
    </div>
  )
})
ItemEditPane.displayName = "ItemEditPane"
