import { IconId } from "@daybridge/client-api"
import { Menu, MenuItem } from "@daybridge/components-core"
import React from "react"
import { EditMode } from "../../items/types/editMode"

export interface SeriesMenuProps {
  align?: "end" | "start" | "center"
  // `children` is the button that triggers the menu
  children: React.ReactElement
  // `enabled` should be set if the item has a series and we want to show the
  // menu on the button that's provided as a child.
  enabled: boolean
  // `respond` is a callback function that invokes if the user has selected an
  // option from the menu (with the exception of "cancel").
  respond: (mode: EditMode) => void
}

export const SeriesMenu: React.FC<SeriesMenuProps> = React.memo(
  ({ children, enabled, respond, align }: SeriesMenuProps) => {
    return (
      <Menu
        side="bottom"
        align={align}
        content={
          enabled ? (
            <>
              <MenuItem
                onSelect={() => respond(EditMode.Instance)}
                icon={IconId.Copy}
              >
                Just this one
              </MenuItem>
              <MenuItem
                onSelect={() => respond(EditMode.Series)}
                icon={IconId.Infinity}
              >
                Everything
              </MenuItem>
              <MenuItem icon={IconId.Cross}>Cancel</MenuItem>
            </>
          ) : undefined
        }
      >
        {children}
      </Menu>
    )
  },
)

SeriesMenu.displayName = "SeriesMenu"
