import { DateTime } from "luxon"
import { atom, useRecoilState } from "recoil"

// The Navigation Date is used to keep track of where the
// user is on the timeline. As they move around the navigation
// date changes, and this causes the window of time visible on the timeline
// to change. The navigation date is common across views so as people
// change between (e.g.) week view, day view, and month view - they should stay
// in approximately the same place.
// Note that it can be "undefined" and in this case today's date should be used.
export const navigationDateAtom = atom<DateTime | undefined>({
  key: "navigationDate",
  default: undefined,

  // Required when storing Luxon DateTime objects in Recoil state.
  dangerouslyAllowMutability: true,
})
export const useNavigationDateState = () => useRecoilState(navigationDateAtom)
