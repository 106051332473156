import {
  ContactsArgs,
  ContactsDocument,
  ContactsQuery,
  ContactsQueryVariables,
  useContactsQuery,
} from "@daybridge/client-api"
import { GraphQLError, useFetcher } from "@daybridge/graphql"
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query"

export const useInfiniteContactsQuery = <
  TData = ContactsQuery,
  TError = string | GraphQLError,
>(
  variables: ContactsQueryVariables = { args: {} },
  options?: UseInfiniteQueryOptions<ContactsQuery, TError, TData>,
) => {
  const fetcher = useFetcher<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
  )

  return useInfiniteQuery<ContactsQuery, TError, TData>(
    useContactsQuery.getKey(variables),
    (metaData: { pageParam?: ContactsArgs }) =>
      fetcher.bind(null, {
        args: { ...variables.args, ...(metaData.pageParam ?? {}) },
      })(),
    {
      getNextPageParam: (lastPage: ContactsQuery): ContactsArgs | undefined => {
        return lastPage.contacts?.pageInfo?.hasNextPage
          ? {
              first: lastPage.contacts.edges.length,
              after: lastPage.contacts.pageInfo.endCursor,
            }
          : undefined
      },
      getPreviousPageParam: (
        firstPage: ContactsQuery,
      ): ContactsArgs | undefined => {
        return firstPage.contacts?.pageInfo?.hasPreviousPage
          ? {
              last: firstPage.contacts.edges.length,
              before: firstPage.contacts.pageInfo.startCursor,
            }
          : undefined
      },
      ...options,
    },
  )
}
