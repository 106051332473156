import React from "react"

interface FaviconProps {
  children: React.ReactNode
}

const FaviconFn = React.forwardRef(
  (props: FaviconProps, ref: React.ForwardedRef<SVGSVGElement>) => {
    const { children, ...rest } = props
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        style={{
          fontWeight: "semibold",
        }}
        ref={ref}
        {...rest}
      >
        <rect width={16} height={16} rx={2} fill="#793AAF" />
        <rect width={12} x={2} y={2} height={1} rx={1} fill="#BF7AF0" />
        <text
          fill="white"
          x="50%"
          y="10"
          dominantBaseline="middle"
          textAnchor="middle"
          fontFamily="Inter, sans-serif"
          fontSize="10"
        >
          {children}
        </text>
      </svg>
    )
  },
)
FaviconFn.displayName = "Favicon"

export const Favicon = React.memo(FaviconFn) as typeof FaviconFn
