import { ActivityId, ItemTypeId } from "@daybridge/client-api"
import { Modal, Search } from "@daybridge/components-core"
import { sample } from "lodash-es"
import React, { useState, useEffect, useCallback } from "react"
import { useItemSchema } from "../../hooks/useItemSchema"
import {
  ItemCreationStateStage,
  useItemCreationState,
  useItemTitle,
} from "../../state/creation"
import { useQueryStringMapping } from "../../hooks/useQueryStringMapping"
import { useActivitySearch } from "../../hooks/useActivitySearch"
import { useActivitySearchResults } from "../../hooks/useActivitySearchResults"
import { useResetTimeline } from "../../../timeline/hooks/useResetTimeline"

export const CreateItemSearch: React.FC = React.memo(() => {
  const [creationState, setItemCreationState] = useItemCreationState()

  // Reset the timeline if the creation flow is closed
  const reset = useResetTimeline()

  // Keep track of the title that the user has typed in.
  const [searchValue, setSearchValue] = useItemTitle()

  const { data: ontology, isLoading: ontologyLoading } = useItemSchema()
  const { activities, itemTypes } = ontology || {}

  /*
  -----------------------------
  Random input placeholder
  -----------------------------
  */
  const [inputPlaceholder, setInputPlaceholder] = useState<string | undefined>()
  useEffect(() => {
    if (!activities || Object.values(activities).length === 0) return

    setInputPlaceholder(
      sample(
        Object.values(activities)
          .filter(
            (a) =>
              // Don't include sensitive activities in the suggestions
              !a.sensitive,
          )
          .map((a) => a.name),
      ),
    )
  }, [activities, creationState?.stage])

  // Search logic
  const mapping = useQueryStringMapping(activities)
  const matches = useActivitySearch(searchValue, mapping, activities, 2, 5)

  // Item select callback
  const onItemSelect = useCallback(
    (activityOrItemTypeId: ActivityId | ItemTypeId) => {
      if (!activities || !itemTypes) return
      setItemCreationState({
        stage: ItemCreationStateStage.ItemDetails,
        activity: activities[activityOrItemTypeId as keyof typeof activities],
        itemType: itemTypes[activityOrItemTypeId as keyof typeof itemTypes],
      })
    },
    [setItemCreationState, activities, itemTypes],
  )

  // Compile final results
  const results = useActivitySearchResults(searchValue, matches, activities)

  return (
    <Modal
      open={creationState?.stage === ItemCreationStateStage.ItemSearch}
      content={
        <Search<ActivityId | ItemTypeId>
          onItemSelect={onItemSelect}
          placeholder={`Add something new, like "${inputPlaceholder || ""}"`}
          inputLabel="Start typing to add something new"
          resultsLabel="Search results"
          value={searchValue}
          onChange={(e, value) => {
            setSearchValue(value)
          }}
          items={results}
          loading={searchValue.length > 0 && ontologyLoading}
        />
      }
      onOpenChange={(open) => {
        if (!open) {
          reset()
        }
      }}
      className="w-full sm:max-w-lg"
    />
  )
})
CreateItemSearch.displayName = "NewItemSearch"
