import { useCallback } from "react"

interface DayLevelPositionFuncs {
  // `xOffsetFor` returns how far from the left of the renderable area
  // an item should start.
  percentageXOffsetFor: (startColumn: number) => string
  percentageXOffsetEndFor: (endColumn: number) => string

  // `yOffsetFor` returns how far from the top of the renderable
  // area an item should start.
  yOffsetFor: (row: number) => number

  // `widthFor` calcualtes how wide an item should be.
  widthFor: (
    // The first column the item occupies, 0-indexed
    startColumn: number,

    // The last column the item occupies, 0-indexed
    endColumn: number,
  ) => number
}

/**
 * `useDayLevelPositions` returns functions that can be used to
 * calculate the x-offset, y-offset, and width of day-level items.
 */
export const useDayLevelPositions = (
  // `initialGutterWidth` applies a fixed left-shift to the X position of
  // all items.
  initialGutterWidth: number,

  // `leftDayGutterWidth` is the width of each day's left gutter in pixels.
  leftDayGutterWidth: number,

  // `rightDayGutterWidth` is the width of each day's right gutter in pixels.
  rightDayGutterWidth: number,

  // `renderableAreaWidth` is the total amount of horizontal space available.
  renderableAreaWidth: number,

  // `rowHeight` is the height of each row in pixels.
  rowHeight: number,

  // `rowPadding` is the amount of padding between rows in pixels.
  rowPadding: number,

  // `numberOfDays` is the number of days in the viewport.
  numberOfDays: number,
): DayLevelPositionFuncs => {
  // Calculate the pixel width of all gutters in the timeline, based on the
  // number of days that are visible.
  const allGutters =
    initialGutterWidth +
    numberOfDays * (leftDayGutterWidth + rightDayGutterWidth)

  const percentageXOffsetFor = useCallback(
    (startColumn: number): string => {
      if (startColumn >= numberOfDays || startColumn < 0) {
        throw new Error(
          `Start column (got ${startColumn}) must be >= 0 and < ${numberOfDays}`,
        )
      }

      // Calculate the pixel width of the gutters we have jumped over, which
      // is everything to the left of this item.
      const jumpedGutters =
        initialGutterWidth +
        leftDayGutterWidth +
        startColumn * (leftDayGutterWidth + rightDayGutterWidth)

      // We first calculate the total area of the timeline minus the gutters. We
      // then divide by the number of days available to get the width per column
      // (excluding gutters). We then multiply by the start column index to get
      // the right percentage value. From there, we just need to add the pixel
      // width of the gutters we've jumped over.
      return `calc((100% - ${allGutters}px) / ${numberOfDays} * ${startColumn} + ${jumpedGutters}px)`
    },
    [
      numberOfDays,
      initialGutterWidth,
      leftDayGutterWidth,
      rightDayGutterWidth,
      allGutters,
    ],
  )

  const percentageXOffsetEndFor = useCallback(
    (endColumn: number): string => {
      if (endColumn >= numberOfDays || endColumn < 0) {
        throw new Error(
          `End column (got ${endColumn}) must be >= 0 and < ${numberOfDays}`,
        )
      }

      // Calculate the number of gutters we need to jump over, counting from the
      // right of the timeline.
      const numberOfColumnsToJumpOver = numberOfDays - endColumn - 1

      // Calculate the pixel width of the gutters we have jumped over, which
      // is everything to the right of this item.
      const jumpedGutters =
        (numberOfColumnsToJumpOver + 1) *
        (leftDayGutterWidth + rightDayGutterWidth)

      // We first calculate the total area of the timeline minus the gutters. We
      // then divide by the number of days available to get the width per column
      // (excluding gutters). We then multiply by the number of columns to jump
      // over (counting from the right) to get the right percentage value. Then,
      // we just need to add the pixel width of the gutters we've jumped over.
      return `calc((100% - ${allGutters}px) / ${numberOfDays} * ${numberOfColumnsToJumpOver} + ${jumpedGutters}px)`
    },
    [allGutters, leftDayGutterWidth, numberOfDays, rightDayGutterWidth],
  )

  const yOffsetFor = useCallback(
    (row: number) => {
      return (
        // Initial top padding
        rowPadding +
        // For each row, the height of the row plus padding above it
        row * (rowHeight + rowPadding)
      )
    },
    [rowHeight, rowPadding],
  )

  const widthFor = useCallback(
    (startColumn: number, endColumn: number): number => {
      if (startColumn >= numberOfDays || startColumn < 0) {
        throw new Error(
          `Start column (got ${startColumn}) must be >= 0 and < ${numberOfDays}`,
        )
      }

      if (endColumn >= numberOfDays || endColumn < 0) {
        throw new Error(
          `End column (got ${endColumn}) must be >= 0 and < ${numberOfDays}`,
        )
      }

      const colSpan = endColumn - startColumn + 1

      // The width of each day is the available width minus the total gutter,
      // divided by the number of days.
      const widthPerDay =
        (renderableAreaWidth ? renderableAreaWidth - allGutters : 0) /
        numberOfDays

      return (
        // Base width per day
        widthPerDay * colSpan +
        // Bridge over the gutters if spanning multiple days
        (endColumn - startColumn) * (leftDayGutterWidth + rightDayGutterWidth)
      )
    },
    [
      numberOfDays,
      renderableAreaWidth,
      allGutters,
      leftDayGutterWidth,
      rightDayGutterWidth,
    ],
  )

  return {
    percentageXOffsetFor,
    percentageXOffsetEndFor,
    yOffsetFor,
    widthFor,
  }
}
