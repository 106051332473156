import { useCallback } from "react"
import {
  ColumnAllocation,
  ColumnAllocations,
} from "./useDayLevelColumnAllocator"

// `RowAllocations` is a map of Item ID -> number, where this number represents
// the row number on which the item with the given ID should be placed.
export type RowAllocations = {
  [id: string]: number
}

// A `DayLevelRowAllocatorFunc` takes a set of item column allocations and assigns each item
// a row number, placing multiple items on the same row where those items don't clash
// with each other.
type DayLevelRowAllocatorFunc = (
  items: ColumnAllocations,
) => DayLevelRowAllocatorResult
type DayLevelRowAllocatorResult = {
  allocations: RowAllocations
  numRows: number
}

/**
 * `useDayLevelRowAllocator` returns a `DayLevelRowAllocatorFunc` that can be used to
 * efficiently stack day-level items vertically. It's essentially a box-packing algorithm
 * that takes a set of items and allocates them to rows.
 */
export const useDayLevelRowAllocator = (): DayLevelRowAllocatorFunc =>
  useCallback(
    (columnAllocations: ColumnAllocations): DayLevelRowAllocatorResult => {
      const items = Object.values(columnAllocations)
      const allocations: RowAllocations = {}

      // We want to pack the largest items first, so we sort the items by their
      // width in descending order. Packing largest first makes the algorithm
      // slightly more efficient.
      const itemsSortedByColSpanDescending = items.sort(
        (a, b) => b.endColumn - b.startColumn - (a.endColumn - a.startColumn),
      )

      // Initialise the rows with a single row with no items.
      const rows: ColumnAllocation[][] = []

      itemLoop: for (const item of itemsSortedByColSpanDescending) {
        if (item.excludeFromConflictResolution) {
          allocations[item.id] = 0
          continue
        }

        // Find the first row that can fit the item
        for (const row of rows) {
          // If the item overlaps any of the items in the row, skip it
          // Two items are said to overlap if they have a common column.
          if (
            row.some(
              (i) =>
                !(i.startColumn > item.endColumn) &&
                !(item.startColumn > i.endColumn),
            )
          ) {
            continue
          }

          // If the item doesn't overlap any of the items in the row, add it
          row.push(item)
          continue itemLoop
        }

        // If we couldn't find a row, create a new one
        const newRow: ColumnAllocation[] = [item]
        rows.push(newRow)
      }

      // Construct the final result.
      rows.forEach((row, rowIndex) => {
        row.forEach((item) => {
          allocations[item.id] = rowIndex
        })
      })

      return {
        allocations,
        numRows: Math.max(rows.length, 1),
      }
    },
    [],
  )
