import { DateTime } from "luxon"
import { useCallback } from "react"
import { RenderItemOrVirtualItem } from "../../../items/components/render/RenderItemOrVirtualItem"
import { ItemOrVirtualItem } from "../../../items/types/itemOrVirtualItem"
import {
  HourLevelItemPositions,
  useHourLevelConflictResolver,
} from "./useHourLevelConflictResolver"
import { useHourLevelPositions } from "./useHourLevelPositions"

export interface HourLevelRendererOptions {
  // `rangeStartLocal` is the start of the period we're rendering for
  rangeStartLocal: DateTime

  // `rangeEndLocal` is the end of the period we're rendering for
  rangeEndLocal: DateTime

  // `renderableAreaWidth` is the total width we have available to render across.
  renderableAreaWidth: number

  // Hour-level items should not be rendered any smaller than their MIN_WIDTH
  minWidth: number
}

// An `HourLevelRenderFunc` takes a list of Items and returns a set
// of React elements that should be rendered on the canvas.
export type HourLevelRenderFunc = (
  items: ItemOrVirtualItem[],
) => React.ReactElement[]

export const useHourLevelRenderer = (
  options: HourLevelRendererOptions,
): HourLevelRenderFunc => {
  const {
    percentageYOffsetFor,
    percentageHeightFor,
    alreadyStarted,
    continues,
  } = useHourLevelPositions(options.rangeStartLocal, options.rangeEndLocal)

  // Conflict resolver
  const resolveConflicts = useHourLevelConflictResolver(
    options.renderableAreaWidth,
    options.minWidth,
  )

  return useCallback(
    (items: ItemOrVirtualItem[]): React.ReactElement[] => {
      // Compute vertical positions
      const positions: HourLevelItemPositions = {}
      items.forEach((item) => {
        positions[item.id] = {
          id: item.id,
          percentageYOffset: percentageYOffsetFor(item.startLocal),
          percentageHeight: percentageHeightFor(item.startLocal, item.endLocal),
          alreadyStarted: alreadyStarted(item.startLocal),
          continues: continues(item.endLocal),
          excludeFromConflictResolution:
            item.__typename === "VirtualItem"
              ? item.excludeFromConflictResolution
              : false,
        }
      })

      // Resolve conflicts
      const conflictResolutions = resolveConflicts(positions)

      return items.map((item) => (
        <RenderItemOrVirtualItem
          key={item.id}
          isAgenda={false}
          dayLevel={false}
          item={item}
          renderProps={{
            ...positions[item.id],
            ...conflictResolutions[item.id],
          }}
        />
      ))
    },
    [
      resolveConflicts,
      percentageYOffsetFor,
      percentageHeightFor,
      alreadyStarted,
      continues,
    ],
  )
}
