import { resolveDateTimeInfo } from "@daybridge/datetime"
import { Color, IconId } from "@daybridge/client-api"
import { ItemWithResolvedTimes } from "../../items/types/itemWithResolvedTimes"
import env from "../../../env.config"

export function generateEventImageUrl(item: ItemWithResolvedTimes) {
  const domain =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : `https://${env.NEXT_PUBLIC_VERCEL_URL || ""}`

  const start =
    item.start && item.start.timeZone
      ? resolveDateTimeInfo(item.start, item.start.timeZone)
      : undefined

  const end =
    item.end && item.end?.timeZone
      ? resolveDateTimeInfo(item.end, item.end.timeZone)
      : undefined

  const queryParams: {
    title: string
    color: Color | ""
    icon: IconId | ""
    date: string | ""
    start: string | ""
    end: string | ""
  } = {
    title: item.title,
    color: item.category?.node.color || "",
    icon: item.icon?.id || "",
    date: start ? start.toISODate({ format: "basic" }) : "",
    start: start ? start.toISOTime({ suppressSeconds: true }) : "",
    end: end ? end.toISOTime({ suppressSeconds: true }) : "",
  }

  const searchParams = new URLSearchParams(queryParams)

  return `${domain}/api/og/shared-event/?${searchParams.toString()}`
}
