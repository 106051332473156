import {
  PatchCalendarMutation,
  PatchCalendarMutationVariables,
  usePatchCalendarMutation,
  useCalendarsQuery,
  useItemsQuery,
  useItemQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { useCallback } from "react"
import {
  InfiniteData,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query"
import { CalendarsQuery } from "@daybridge/client-api/src/gen/operations"
import { produce } from "immer"
import { fromPromise, PromiseToastOptions } from "@daybridge/toast"
import { useEditCalendarId } from "../state/editing"
import { useOpenItem } from "../../items/state/opening"

export const useEditCalendar = (
  options?: UseMutationOptions<
    PatchCalendarMutation,
    string | GraphQLError,
    PatchCalendarMutationVariables,
    unknown
  >,
) => {
  const queryClient = useQueryClient()
  const [, setEditCalendarId] = useEditCalendarId()
  const [openItem] = useOpenItem()

  const onMutate = useCallback(
    async (variables: PatchCalendarMutationVariables) => {
      const queryKey = useCalendarsQuery.getKey({
        args: { filterBy: { deleted: false } },
      })

      // Cancel any in-flight queries
      await queryClient.cancelQueries(queryKey)

      // Fetch previous state
      const previousState =
        queryClient.getQueryData<InfiniteData<CalendarsQuery>>(queryKey)

      // Produce new state
      const newState = produce(previousState, (draft) => {
        if (!draft) return draft // Data not loaded yet.

        const pages = draft?.pages.map((page) => {
          const calendar = page?.calendars?.edges.find(
            (calendar) => calendar.id === variables.input.id,
          )
          if (calendar?.node) {
            if (variables.input.patch.color) {
              calendar.node.color = variables.input.patch.color
            }
            if (variables.input.patch.name) {
              calendar.node.name = variables.input.patch.name
            }
            // Note: the `people` block is not optimistically updated.
          }
          return page
        })
        draft.pages = pages
        return draft
      })

      queryClient.setQueryData(queryKey, newState)
    },
    [queryClient],
  )

  const { mutateAsync } = usePatchCalendarMutation({
    onMutate,
    onSettled: async () => {
      await queryClient.invalidateQueries(useItemsQuery.getKey({ args: {} }))
      await queryClient.invalidateQueries(
        useCalendarsQuery.getKey({ args: {} }),
      )
      if (openItem) {
        await queryClient.invalidateQueries(
          useItemQuery.getKey({ args: { id: openItem.id } }),
        )
      }
    },
    onSuccess: () => {
      setEditCalendarId(undefined)
    },
    ...options,
  })

  return useCallback(
    (
      input: PatchCalendarMutationVariables,
      toast?: PromiseToastOptions<PatchCalendarMutation, Error>,
    ) => {
      const promise = mutateAsync(input)
      if (toast) {
        void fromPromise(promise, toast)
      }
    },
    [mutateAsync],
  )
}
