import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  useShareItemMutation,
  ShareItemMutation,
  ShareItemMutationVariables,
  useItemsQuery,
  useItemQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { useCallback } from "react"
import { error, success, loading } from "@daybridge/toast"
import { getSharableLink } from "../../sharing/utils/getSharableLink"
import { generateEventImageUrl } from "../../sharing/utils/generateEventImageUrl"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"

export const useShareItem = (
  options?: UseMutationOptions<
    ShareItemMutation,
    string | GraphQLError,
    ShareItemMutationVariables,
    unknown
  >,
) => {
  const queryClient = useQueryClient()
  const { mutateAsync } = useShareItemMutation({
    onSettled: (data) => {
      // Invalidate both the `item` and `items` queries.
      const id = data?.shareItem?.id
      if (id) {
        void queryClient.invalidateQueries(
          useItemQuery.getKey({ args: { id } }),
        )
      }
      void queryClient.invalidateQueries(useItemsQuery.getKey({ args: {} }))
    },
    ...options,
  })

  return useCallback(
    async (itemId: string) => {
      loading({
        id: "create-link",
        title: "Creating link...",
      })
      const resultPromise = mutateAsync({
        input: {
          id: itemId,
        },
      })
        .then((result) => {
          // If the mutation was successful, we'll copy the share link to the
          // clipboard automatically. However, if that fails (e.g. because the browser
          // does not allow it, like Safari) we should still show a success toast.
          const shareLink = getSharableLink(
            result.shareItem?.node.sharing?.path,
          )
          if (shareLink) {
            // Request the openGraph image in the background, so that it gets
            // generated now and speed up loading time when the link is first
            // shared.
            const ogImageUrl = generateEventImageUrl(
              result.shareItem?.node as ItemWithResolvedTimes,
            )
            void fetch(ogImageUrl).catch(() => null)
            // Copy the link to clipboard.
            navigator.clipboard
              .writeText(shareLink)
              .then(() => {
                success({
                  id: "create-link",
                  title: "Event link copied",
                })
              })
              .catch(() => {
                success({
                  id: "create-link",
                  title: "Event link created",
                })
              })
          }
        })
        .catch(() => {
          error({
            id: "create-link",
            title: "Could not create link",
          })
        })
      return resultPromise
    },
    [mutateAsync],
  )
}
