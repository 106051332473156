import { useAnalytics } from "@daybridge/analytics"
import { DateTime } from "luxon"
import { useRecoilCallback } from "recoil"
import {
  itemDragDraftSelector,
  ItemDragState,
  itemDragStateAtom,
} from "../state/dragging"
import { useResetTimeline } from "../../timeline/hooks/useResetTimeline"
import { EditMode } from "../types/editMode"
import { usePatchItem } from "./usePatchItem"

interface UseDragItemFunctions {
  dragTo: (date: DateTime, allDay: boolean) => void
  commit: (mode?: EditMode) => void
  cancel: () => void
}

export const useDragItem = (): UseDragItemFunctions => {
  const reset = useResetTimeline()
  const patchItem = usePatchItem()
  const { track } = useAnalytics()

  const dragTo = useRecoilCallback(({ set }) => {
    return (date: DateTime, allDay: boolean) => {
      set(itemDragStateAtom, (val): ItemDragState => {
        if (val === undefined) return undefined
        if (val.committed) return val

        return {
          ...val,
          // Set first mouse position if it hasn't already been set
          firstMousePositionAt: val.firstMousePositionAt || date,
          lastMousePositionAt: date,
          lastMousePositionWasAllDay: allDay,
        }
      })
    }
  }, [])

  const commit = useRecoilCallback(
    ({ snapshot, set }) => {
      return async (mode?: EditMode) => {
        const originalItem = await snapshot.getPromise(itemDragStateAtom)
        if (!originalItem) {
          return
        }

        const newItem = await snapshot.getPromise(itemDragDraftSelector)
        if (!newItem) {
          return
        }

        // If nothing has changed, don't do anything.
        if (
          originalItem.item.startLocal.equals(newItem.startLocal) &&
          originalItem.item.endLocal.equals(newItem.endLocal)
        ) {
          reset()
          return
        }

        // Mark committed so no further dragging is allowed.
        set(itemDragStateAtom, { ...originalItem, committed: true })

        // If the item is a recurrng item and a mode hasn't been
        // selected, then we need to return now. The user will need
        // to select a mode before this can be committed.
        if (originalItem.item.series && !mode) {
          return
        }

        // Analytics: track drag event
        track("item.drag", {
          itemId: originalItem.item.id,
          recurring: originalItem.item.series ? "true" : undefined,
          itemsUpdated: originalItem.item.series ? mode : undefined,
        })

        patchItem(
          {
            start: newItem.start,
            end: newItem.end,
          },
          originalItem.item,
          mode,
        )
      }
    },
    [reset, track, patchItem],
  )

  const cancel = useRecoilCallback(({ set }) => {
    return () => {
      set(itemDragStateAtom, undefined)
    }
  }, [])

  return {
    dragTo,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    commit,
    cancel,
  }
}
