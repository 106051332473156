import { IconId } from "@daybridge/client-api"
import { Button, ButtonSize, ButtonVariant } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { DateTime } from "luxon"
import React, { useRef, useState } from "react"
import { useDayLevelItems } from "../../../items/hooks/useDayLevelItems"
import { useDayLevelRenderer } from "../../hooks/rendering/useDayLevelRenderer"
import { useDayLevelMouseInteractions } from "../../hooks/mouse-interactions/useDayLevelMouseInteractions"
import { useSelectedRegionSelectionInProgress } from "../../state/selectedRegion"
import {
  ItemDragMode,
  useItemDraggingInProgressAllDay,
  useItemDraggingInProgressMode,
} from "../../../items/state/dragging"
import { useViewportPosition } from "../../hooks/rendering/useViewportPosition"
import { TIME_LABELS_GUTTER_WIDTH } from "./TimeLabels"

interface DayLevelItemsProps {
  // `startLocal` is the first date of the period to render over (inclusive)
  startLocal: DateTime
  // `endLocal` is the end of the period to render over (exclusive)
  endLocal: DateTime
  // `nowLocal` is the current time
  nowLocal: DateTime
}

// `NUM_ROWS_COLLAPSED` is the number of rows to show when the day-level items
// section is collapsed.
const NUM_ROWS_COLLAPSED = 2

// `DAY_LEVEL_ITEM_HEIGHT` is the height of each item in the day-level items.
// This is fixed for all items.
const DAY_LEVEL_ITEM_HEIGHT = 32

// `DAY_LEVEL_ROW_PADDING` is the amount of space between each row.
const DAY_LEVEL_ROW_PADDING = 8

/**
 * `DayLevelItems` is the section at the top of the timeline that renders
 * items at the "day level" - this means they are associated with that day
 * but don't have a specific start/end time. Items at the day level can
 * span across multiple days too.
 */
export const DayLevelItems: React.FC<DayLevelItemsProps> = React.memo(
  (props) => {
    // Keep track of whether the day level items are expanded or collapsed.
    // When there are more than NUM_ROWS_COLLAPSED items to show, we display
    // a "show more" button to stop the day-level items from taking over the entire screen.
    const [expanded, setExpanded] = useState(false)

    // Figure out how much width we have to work with. This will vary
    // as the window is resized.
    const containerRef = useRef<HTMLDivElement>(null)
    const { viewportWidth } = useViewportPosition()

    // Keep track of selection state, so we can adjust the cursor accordingly
    const selectionInProgress = useSelectedRegionSelectionInProgress()
    const mode = useItemDraggingInProgressMode()
    const modeAllDay = useItemDraggingInProgressAllDay()

    let cursor: string | undefined
    if (mode === ItemDragMode.Move) {
      cursor = "cursor-move"
    } else if (modeAllDay || selectionInProgress === "allDay") {
      cursor = "cursor-col-resize"
    } else if (mode || selectionInProgress === "hourLevel") {
      cursor = "cursor-row-resize"
    }

    // Initialise mouse handlers
    const { onMouseDown, onMouseMove, onMouseUp } =
      useDayLevelMouseInteractions(
        props.startLocal,
        props.endLocal,
        TIME_LABELS_GUTTER_WIDTH,
        containerRef.current,
      )

    // Fetch items that need to be rendered here
    const { items } = useDayLevelItems(props.startLocal, props.endLocal)

    // Spin up a renderer to convert items into renderable react elements
    const render = useDayLevelRenderer(
      props.startLocal,
      props.endLocal,
      viewportWidth || 0,
      TIME_LABELS_GUTTER_WIDTH,
      DAY_LEVEL_ITEM_HEIGHT,
      DAY_LEVEL_ROW_PADDING,
      expanded ? undefined : NUM_ROWS_COLLAPSED,
    )

    // Invoke the renderer with the items we need
    const { renderables, numRowsRendered, totalNumRows } = render(items)

    return (
      <div
        className="
          flex items-center relative
          border-b border-tint-alpha
        "
      >
        <div className="absolute bg-surface inset-0" />
        {/* Renderable area */}
        <div
          ref={containerRef}
          className={`
            w-full
            inset-0
            max-h-[40vh] overflow-auto
            relative
            ${cursor || ""}
            ${
              mode === ItemDragMode.Move && modeAllDay === false
                ? "pointer-events-none"
                : ""
            }
          `}
        >
          {/* 
          Collapsible area whose height may be greater than the height of the parent above
          In this case, the parent will scroll vertically.
          */}
          <div
            role="none"
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            className="
              transition-[height] duration-250 ease-in-out 
              overflow-hidden
            "
            style={{
              height:
                numRowsRendered > 0
                  ? `${
                      DAY_LEVEL_ROW_PADDING * 1.25 +
                      numRowsRendered *
                        (DAY_LEVEL_ITEM_HEIGHT + DAY_LEVEL_ROW_PADDING)
                    }px`
                  : 0,
            }}
          >
            {/* Actual items to render */}
            {renderables}
          </div>

          {/* Show/Hide Button */}
          {totalNumRows > NUM_ROWS_COLLAPSED && (
            <div className="sticky left-0 right-0 bottom-0">
              <Button
                block
                variant={ButtonVariant.Clear}
                size={ButtonSize.Custom}
                className={`
                  w-full
                  text-sm px-2 py-1
                  text-high-contrast
                  ${expanded ? "bg-surface" : ""}
                  ring-inset
                  cursor-pointer
                `}
                onPress={() => {
                  setExpanded((e) => !e)
                }}
              >
                {expanded
                  ? "Hide"
                  : `Show ${totalNumRows - numRowsRendered} more`}
                <I
                  type={expanded ? IconId.ChevronUp : IconId.ChevronDown}
                  className="ml-2 w-2 h-2"
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  },
)
DayLevelItems.displayName = "DayLevelItems"
