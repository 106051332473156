import { useMeQuery } from "@daybridge/client-api"
import {
  Avatar,
  Tooltip,
  Button,
  ButtonSize,
  ButtonVariant,
} from "@daybridge/components-core"
import Link from "next/link"
import React from "react"

interface ShareItemAppearanceBoxProps {
  onClose: (value: boolean) => void
}

export const ShareItemAppearanceBox: React.FC<ShareItemAppearanceBoxProps> =
  React.memo((props: ShareItemAppearanceBoxProps) => {
    const { data: account } = useMeQuery()
    return (
      <div className="flex space-x-3 p-2 bg-background rounded-lg text-base text-high-contrast">
        <div className="p-1 flex items-center flex-1 space-x-3">
          <div className="w-5 h-5">
            <Avatar
              url={account?.me?.account.avatar || undefined}
              defaultInitials={account?.me?.account.preferredName.charAt(0)}
            />
          </div>
          <div className="flex-1 leading-5 overflow-hidden">
            <div className="truncate font-semibold text-high-contrast">
              {account?.me?.account.preferredName}
            </div>
          </div>
        </div>

        <Tooltip content="Open account settings">
          <Link href="/settings/account" passHref>
            <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.Outline}
              className="h-full"
              onPress={() => props.onClose(false)}
            >
              Change Name
            </Button>
          </Link>
        </Tooltip>
      </div>
    )
  })

ShareItemAppearanceBox.displayName = "ShareItemAppearanceBox"
