/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Activity, ActivityId } from "@daybridge/client-api"
import { useMemo } from "react"

/*
`useActivitySearch` implements logic to find an activity based on
a search term that the user has inputted.
 */
export const useActivitySearch = (
  // `searchValue` is the value the user has typed into the search box
  searchValue: string,

  // `mapping` is a lookup table mapping query strings to activities
  // It could be undefined if we are still constructing said mapping
  // (e.g. if we are waiting for activities etc from the network)
  mapping:
    | {
        [queryString: string]: ActivityId[]
      }
    | undefined,

  // All activities
  activities: { [activity in ActivityId]: Activity } | undefined,

  // minCharCount is the number of characters that needs to be
  // typed before the search kicks in.
  minCharCount?: number,

  // numResults is the maximum number of results to return
  numResults?: number,
): ActivityId[] | undefined =>
  useMemo(() => {
    if (!mapping || !activities) {
      // We can't proceed without a mapping
      return undefined
    }

    const queryValueWords = searchValue.toLowerCase().split(" ")

    // For each possible query string
    const matchStrengths: { [activity in ActivityId]?: number } = {}

    if (!minCharCount || searchValue.length > minCharCount) {
      if (queryValueWords.length > 0) {
        // Iterate over each word in the query
        const activityToWeightForWords = queryValueWords.map((word, index) => {
          // For each word, iterate over the query strings
          // to measure the extent to which this word matches
          // the query string.
          // Create a map of query string -> match weight
          const queryStringKeys = Object.keys(mapping)
          const weights = queryStringKeys.map((queryString) => {
            if (index === queryValueWords.length - 1) {
              // This is the last word in the query. Allow partial matches.
              if (queryString.indexOf(word) === 0) {
                return word.length
              } else {
                return 0
              }
            } else {
              // This is not the last word in the query. Typing of this
              // word is assumed to be finished. Require an exact match.
              if (queryString === word) {
                return word.length
              } else {
                return 0
              }
            }
          })

          // Create a mapping of query string -> match weight for this word
          // where the match weight is not 0
          const queryStringWeights = Object.fromEntries(
            queryStringKeys
              .map((q, i) => [q, weights[i]])
              .filter((q): q is [string, number] => q[1] !== 0),
          )

          // Next create a mapping of Activity -> Weight
          const activityToWeightForWord: {
            [activity in ActivityId]?: number
          } = {}
          Object.keys(queryStringWeights)
            .flatMap((queryString) => {
              const activities = mapping[queryString]
              const weight = queryStringWeights[queryString]
              return activities.map((activity): [ActivityId, number] => [
                activity,
                weight,
              ])
            })
            .forEach(([activity, weight]) => {
              if (activityToWeightForWord[activity] === undefined) {
                activityToWeightForWord[activity] = weight
              } else {
                activityToWeightForWord[activity] = Math.max(
                  activityToWeightForWord[activity]!,
                  weight,
                )
              }
            })

          return activityToWeightForWord
        })

        // Finally, merge all of the words in the query into
        // one match set by adding together the weights for each word.
        activityToWeightForWords.forEach((wordStrength) => {
          Object.keys(wordStrength).forEach((activity) => {
            if (matchStrengths[activity as ActivityId] === undefined) {
              matchStrengths[activity as ActivityId] =
                wordStrength[activity as ActivityId]
            } else {
              matchStrengths[activity as ActivityId]! +=
                wordStrength[activity as ActivityId]!
            }
          })
        })
      }

      // If the inputted search term matches or partially matches an
      // activity title, increase the weighting of it
      Object.values(activities).forEach((activity) => {
        if (
          activity.name.toLowerCase().indexOf(searchValue.toLowerCase()) === 0
        ) {
          if (matchStrengths[activity.id] === undefined) {
            matchStrengths[activity.id] = searchValue.length
          } else {
            matchStrengths[activity.id]! += searchValue.length
          }
        }
      })
    }

    const results = Object.entries(matchStrengths)
      .sort((a, b) => b[1]! - a[1]!)
      .map((a) => a[0] as ActivityId)

    return numResults ? results.slice(0, numResults) : results
  }, [searchValue, mapping, activities, minCharCount, numResults])
