import { IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonProps,
  ButtonSize,
  ButtonVariant,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"

export interface ResponseButtonProps {
  icon: IconId
  variant: ButtonVariant
  children: string
}

function ResponseButtonFn(
  props: ResponseButtonProps & Partial<ButtonProps>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { variant, icon, onPress, children, ...rest } = props

  return (
    <Button
      ref={ref}
      aria-describedby="label-respond-buttons"
      size={ButtonSize.Base}
      variant={variant}
      onPress={onPress}
      {...rest}
    >
      <I
        type={icon}
        className={`w-2.5 h-2.5 mr-2 transition ease-in-out duration-300 flex-shrink-0`}
      />
      <span className="transition duration-300 ease-in-out">{children}</span>
    </Button>
  )
}

export const ResponseButton = React.memo(React.forwardRef(ResponseButtonFn))
