import { DateTime } from "luxon"
import { durationIgnoringZoneDifferences } from "@daybridge/datetime"
import { findTransitionTime } from "./placementFromTime"

export const timeFromPosition = (
  position: number,
  height: number,
  windowStart: DateTime,
  windowEnd: DateTime,
  snapInterval = 5,
): DateTime => {
  if (position <= 0) {
    // If the position is 0 then the time is the window start.
    return windowStart
  }
  if (position >= height) {
    // If the position is greater than the height, then the position
    // is the end of the window.
    return windowEnd
  }

  const durationMinutes = durationIgnoringZoneDifferences(
    windowStart,
    windowEnd,
  ).as("minutes")

  let timeBeforeRounding: DateTime
  if (windowStart.offset > windowEnd.offset) {
    // Clocks have gone back in this period
    const offsetDurationMinutes = windowStart.offset - windowEnd.offset
    const offsetHeight = (offsetDurationMinutes / durationMinutes) * height

    const transitionTime = findTransitionTime(windowStart, windowEnd)
    if (!transitionTime) {
      throw new Error("Could not find transition time")
    }

    const positionTransitionStarts =
      (transitionTime.diff(windowStart).as("minutes") / durationMinutes) *
      height

    const positionTransitionEnds = positionTransitionStarts + offsetHeight

    if (position <= positionTransitionStarts) {
      // The position is before the transition
      timeBeforeRounding = windowStart.plus({
        minutes: (position / height) * durationMinutes,
      })
    } else if (position >= positionTransitionEnds) {
      // The position is after the transition
      timeBeforeRounding = windowStart.plus({
        minutes: ((position + offsetHeight) / height) * durationMinutes,
      })
    } else {
      // The position is during the transition
      const positionIntoTransitionWindow =
        (position - positionTransitionStarts) * 2
      timeBeforeRounding = windowStart.plus({
        minutes:
          ((positionTransitionStarts + positionIntoTransitionWindow) / height) *
          durationMinutes,
      })
    }
  } else if (windowStart.offset < windowEnd.offset) {
    const offsetDurationMinutes = windowEnd.offset - windowStart.offset
    const offsetHeight = (offsetDurationMinutes / durationMinutes) * height

    // Clocks have gone forward in this period
    const transitionTime = findTransitionTime(windowStart, windowEnd)
    if (!transitionTime) {
      throw new Error("Could not find transition time")
    }

    const positionTransitionStarts =
      (transitionTime.diff(windowStart).as("minutes") / durationMinutes) *
      height

    // Check if cursor is in the offset period
    if (
      position >= positionTransitionStarts &&
      position < positionTransitionStarts + offsetHeight
    ) {
      timeBeforeRounding = transitionTime
    } else if (position < positionTransitionStarts) {
      // The position is before the transition
      timeBeforeRounding = windowStart.plus({
        minutes: (position / height) * durationMinutes,
      })
    } else {
      // The position is after the transition
      timeBeforeRounding = windowStart.plus({
        minutes: ((position - offsetHeight) / height) * durationMinutes,
      })
    }
  } else {
    // Clocks have not changed in this period
    // Compute the duration of the window in minutes
    const windowDuration = windowEnd.diff(windowStart).as("minutes")
    const ratio = position / height
    // Figure out how many minutes we are into the window
    const positionMinutes = Math.floor(ratio * windowDuration)
    timeBeforeRounding = windowStart.plus({ minutes: positionMinutes })
  }

  // Compute how far into the current interval we are
  const remainderMinutes = timeBeforeRounding.minute % snapInterval

  // Figure out how many minutes we need to add to the window
  // start, rounded to the nearest `snapInterval` minutes
  const minutesToAdd =
    remainderMinutes > snapInterval - remainderMinutes
      ? // We're closer to the end of the interval
        snapInterval - remainderMinutes
      : // We're closer to the start of the interval
        -remainderMinutes

  return timeBeforeRounding.plus({ minutes: minutesToAdd })
}
