import { IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  FadeAndScaleAnimation,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { ForwardedRef, forwardRef, memo, ReactNode } from "react"

interface BlockProps {
  icon: IconId
  headerWidgets?: ReactNode
  children: ReactNode
  title: string
  className?: string
  onClose?: () => void
}

const BlockFn = forwardRef(
  (props: BlockProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      icon,
      title,
      children,
      className,
      headerWidgets,
      onClose,
      ...rest
    } = props
    return (
      <div ref={ref} {...rest}>
        <FadeAndScaleAnimation>
          <div className="bg-elevated shadow-sm rounded-2xl">
            <div
              className={`flex items-center px-4 h-14 pb-0 space-x-3 ${
                onClose ? "pr-3" : ""
              }`}
            >
              <I type={icon} className="w-4 h-4 text-icon-decorative" />
              <div className="text-base flex-1 text-high-contrast">{title}</div>
              {headerWidgets}
              {onClose && (
                <Button
                  variant={ButtonVariant.Clear}
                  size={ButtonSize.IconAction}
                  onPress={onClose}
                >
                  <I type={IconId.Cross} />
                </Button>
              )}
            </div>
            <div className={`p-3 pt-0 ${className || ""}`}>{children}</div>
          </div>
        </FadeAndScaleAnimation>
      </div>
    )
  },
)
BlockFn.displayName = "Block"

export const Block = memo(BlockFn) as typeof BlockFn
