import { RefObject } from "react"
import { atom } from "recoil"

export type ViewportPosition = {
  // The width and height of the viewport.
  viewportHeight: number | undefined
  viewportWidth: number | undefined
  // The top and bottom position of the viewport.
  viewportTop: number | undefined
  viewportBottom: number | undefined
  // The ref to the viewport.
  viewportRef: RefObject<HTMLDivElement> | undefined
}

export const viewportPositionAtom = atom<ViewportPosition>({
  key: "viewportPosition",
  default: {
    viewportHeight: undefined,
    viewportWidth: undefined,
    viewportTop: undefined,
    viewportBottom: undefined,
    viewportRef: undefined,
  },
  dangerouslyAllowMutability: true,
})
