import { IconId, Place, usePlacesMutation } from "@daybridge/client-api"
import {
  ComboboxMenu,
  ComboboxMenuItem,
  ComboboxMenuProps,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React, { useCallback, useState } from "react"
import { v4 } from "uuid"

type LocationMenuProps = Pick<ComboboxMenuProps, "children" | "className"> & {
  // A callback to be invoked when a location gets selected
  onLocationSelect: (location: string | Place, sessionId: string) => void
}

const LocationMenuFn = React.forwardRef(
  (props: LocationMenuProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { children, onLocationSelect, ...rest } = props

    // Manage the Session ID
    const [sessionId, setSessionId] = useState<string>(v4())
    const onLocationSelectWithReset = useCallback(
      (location: string | Place) => {
        // Invoke callback
        onLocationSelect(location, sessionId)

        // Regenerate the Session ID
        setSessionId(v4())
      },
      [sessionId, onLocationSelect],
    )

    // Seach function
    const { mutateAsync } = usePlacesMutation()
    const search: (query: string) => Promise<Place[]> = useCallback(
      async (query: string) => {
        if (query === "") return []
        const result = await mutateAsync({ input: { query, sessionId } })
        if (!result.places) return []
        return result.places.edges.map((p) => p.node)
      },
      [mutateAsync, sessionId],
    )

    const results = useCallback(
      async (queryString: string) =>
        (await search(queryString))
          .map((place) => (
            <ComboboxMenuItem
              onSelect={() => onLocationSelectWithReset(place)}
              key={place.id}
              className="py-2"
            >
              <div className="flex flex-row items-center space-x-3 w-full">
                <div
                  className="
                    w-6 h-6
                    flex items-center justify-center flex-shrink-0
                    bg-background
                    rounded-full
                  "
                >
                  <I
                    type={IconId.MapPin}
                    className="text-low-contrast w-3 h-3"
                  />
                </div>
                <div className="flex flex-col justify-center truncate">
                  <div>{place.name}</div>
                  <div className="text-sm text-low-contrast truncate">
                    {place.description}
                  </div>
                </div>
              </div>
            </ComboboxMenuItem>
          ))
          .concat(
            queryString.length > 0
              ? [
                  <ComboboxMenuItem
                    onSelect={() => onLocationSelectWithReset(queryString)}
                    key="custom"
                    className="py-2"
                  >
                    <div className="flex flex-row items-center space-x-3">
                      <div
                        className="
                          w-6 h-6
                          flex items-center justify-center flex-shrink-0
                          bg-background text-low-contrast
                          rounded-full
                        "
                      >
                        <I type={IconId.Plus} className="w-3 h-3" />
                      </div>
                      <div>Add &quot;{queryString}&quot;</div>
                    </div>
                  </ComboboxMenuItem>,
                ]
              : [],
          ),
      [search, onLocationSelectWithReset],
    )

    return (
      <ComboboxMenu
        align="center"
        className="!w-[325px]"
        placeholder="Search for a location..."
        defaultText="Start typing to find places"
        noResultsText="No places found"
        items={results}
        ref={ref}
        {...rest}
      >
        {children}
      </ComboboxMenu>
    )
  },
)
LocationMenuFn.displayName = "LocationMenu"

export const LocationMenu = React.memo(LocationMenuFn) as typeof LocationMenuFn
