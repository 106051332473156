import { useCallback } from "react"
import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  useItemsQuery,
  DeleteCalendarMutationVariables,
  DeleteCalendarMutation,
  useDeleteCalendarMutation,
  DeleteCalendarInput,
  useCalendarsQuery,
  useItemQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { fromPromise } from "@daybridge/toast"
import { useResetTimeline } from "../../timeline/hooks/useResetTimeline"
import { useOpenItem } from "../../items/state/opening"
import { useCalendars } from "./useCalendars"

export const useDeleteCalendar = (
  options?: UseMutationOptions<
    DeleteCalendarMutation,
    string | GraphQLError,
    DeleteCalendarMutationVariables,
    unknown
  >,
) => {
  const reset = useResetTimeline()
  const queryClient = useQueryClient()
  const { calendars } = useCalendars()
  const [openItem] = useOpenItem()

  const { mutateAsync } = useDeleteCalendarMutation({
    onSuccess: async () => {
      reset()
      await queryClient.invalidateQueries(
        useCalendarsQuery.getKey({ args: {} }),
      )
      await queryClient.invalidateQueries(useItemsQuery.getKey({ args: {} }))
      if (openItem) {
        await queryClient.invalidateQueries(
          useItemQuery.getKey({ args: { id: openItem.id } }),
        )
      }
    },
    ...options,
  })

  return useCallback(
    (id: string) => {
      const calendar = calendars?.find((calendar) => calendar.id === id)
      const input: DeleteCalendarInput = {
        id,
      }
      const resultPromise = mutateAsync({
        input,
      })
      void fromPromise(resultPromise, {
        loadingMessage: `Deleting ${calendar?.name || "calendar"}...`,
        successTitle: `${calendar?.name || "Calendar"} deleted`,
      })
    },
    [mutateAsync, calendars],
  )
}
