import { ResponseType } from "@daybridge/client-api"

export function responseTypeToString(
  response: ResponseType | null | undefined,
  fullSentence?: boolean,
): string {
  switch (response) {
    case ResponseType.Yes:
      return fullSentence ? "You're going" : "Going"
    case ResponseType.Maybe:
      return fullSentence ? "You might be going" : "Maybe"
    case ResponseType.No:
      return fullSentence ? "You can't go" : "Can't go"
    default:
      return fullSentence ? "You haven't responded" : "Hasn't responded"
  }
}
