import { Color, CategoryId, IconId } from "@daybridge/client-api"
import {
  ComboboxMenu,
  ComboboxMenuItem,
  CategoryBar,
  ComboboxMenuProps,
  Button,
  ButtonSize,
  ButtonVariant,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"
import { useItemSchema } from "../../../hooks/useItemSchema"

interface CategoryPickerProps {
  selectedCategory?: CategoryId
  onCategorySelect?: (category: CategoryId) => void
  children?: ComboboxMenuProps["children"]
}

const CategoryPickerFn = React.forwardRef(
  (
    props: CategoryPickerProps & Omit<ComboboxMenuProps, "children" | "items">,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { children, onCategorySelect, selectedCategory, ...rest } = props
    const { data: schema, isLoading: schemaLoading } = useItemSchema()
    return (
      <ComboboxMenu
        ref={ref}
        loading={schemaLoading}
        defaultSelectedKey={selectedCategory || CategoryId.General}
        side="bottom"
        align="start"
        noResultsText="No matches"
        {...rest}
        items={(searchValue: string) => {
          return schema?.categories
            ? Object.values(schema?.categories)
                .filter(
                  (category) =>
                    category.name
                      ?.toLowerCase()
                      .indexOf(searchValue.toLowerCase()) !== -1,
                )
                .sort((a, b) =>
                  a.name && b.name ? a.name.localeCompare(b.name) : -1,
                )
                .map((category) => (
                  <ComboboxMenuItem
                    onSelect={() =>
                      onCategorySelect && onCategorySelect(category.id)
                    }
                    key={category.id}
                  >
                    <div className="flex flex-row items-center">
                      <CategoryBar
                        color={category.color || Color.Stone}
                        className={"h-4 mr-3"}
                      />
                      <span className="truncate">{category.name}</span>
                    </div>
                  </ComboboxMenuItem>
                ))
            : []
        }}
      >
        {children
          ? children
          : ({ open }) => (
              <Button
                variant={ButtonVariant.Headless}
                size={ButtonSize.Custom}
                className="rounded-sm relative self-start z-10 flex-0 text-sm text-content-body"
              >
                <div>
                  {
                    schema?.categories[selectedCategory || CategoryId.General]
                      .name
                  }
                </div>
                <I
                  type={open ? IconId.ChevronUp : IconId.ChevronDown}
                  className="w-1.5 h-1.5 ml-1.5 flex-shrink-0"
                />
              </Button>
            )}
      </ComboboxMenu>
    )
  },
)
CategoryPickerFn.displayName = "CategoryPicker"

export const CategoryPicker = React.memo(
  CategoryPickerFn,
) as typeof CategoryPickerFn
