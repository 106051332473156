import {
  PatchPreferencesMutationVariables,
  useMeQuery,
  MeQuery,
  usePatchPreferencesMutation,
  PatchPreferencesInput,
} from "@daybridge/client-api"
import { produce } from "immer"
import { useCallback } from "react"
import { useQueryClient } from "@tanstack/react-query"
import {
  AccessiblePreferences,
  PreferenceMutationOptions,
} from "../../types/preferences"

// Patches multiple preferences at the same time.
const usePatchMultiplePreferences = () => {
  // We need access to a query client to invalidate the account and preferences
  // queries when the preference is set.
  const queryClient = useQueryClient()

  const onMutate = useCallback(
    async (variables: PatchPreferencesMutationVariables) => {
      const queryKey = useMeQuery.getKey()

      // Cancel any in-flight queries
      await queryClient.cancelQueries(queryKey)

      // Fetch previous state
      const previousState = queryClient.getQueryData<MeQuery>(queryKey)

      // Produce new state with updated theme
      const newState = produce(previousState, (draft) => {
        if (!draft?.me?.account.preferences) {
          // Account unavailable for some reason - can't optimistically update
          return
        }
        draft.me.account.preferences = {
          ...draft.me.account.preferences,
          ...(variables.input as AccessiblePreferences),
        }
        return draft
      })

      queryClient.setQueryData(queryKey, newState)
    },
    [queryClient],
  )

  const { mutateAsync } = usePatchPreferencesMutation({
    // `onMutate` implements optimistic updating for the preference value.
    onMutate,
    // We define this here instead of inline in `mutateAsync` so that
    // the caller can pass their own `onSuccess` function and it won't
    // overwrite this one (they will both run).
    onSettled: async () => {
      // Reset the account and preferences queries on success
      await queryClient.invalidateQueries(useMeQuery.getKey())
    },
  })

  return useCallback(
    (
      input: PatchPreferencesInput,
      mutationOptions?: PreferenceMutationOptions,
    ) => {
      return mutateAsync(
        {
          input,
        },
        mutationOptions,
      )
    },
    [mutateAsync],
  )
}

export default usePatchMultiplePreferences
