import { IconId } from "@daybridge/client-api"
import { I } from "@daybridge/icons"
import React from "react"

interface BlockIconProps {
  icon: IconId
  className?: string
}

/**
 * BlockIcon is a circlar icon rendered on the right of some block items
 */
export const BlockIcon: React.FC<BlockIconProps> = React.memo(
  (props: BlockIconProps) => {
    return (
      <div
        className={`
          flex-shrink-0
          w-5 h-5
          flex items-center justify-center 
          rounded-full
          bg-content-primary
          ${props.className || ""}
        `}
      >
        <I
          type={props.icon}
          className={`
            flex-shrink-0
            w-[10px] h-[10px]
            text-icon-on-color
          `}
        />
      </div>
    )
  },
)
BlockIcon.displayName = "BlockIcon"
