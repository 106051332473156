import React from "react"
import { ItemOrVirtualItem } from "../../types/itemOrVirtualItem"
import { BlockItemProps } from "../block-items/common/BaseBlockItem"
import { RenderItem } from "./RenderItem"
import { RenderVirtualItem } from "./RenderVirtualItem"

type BlockRenderItemProps = Omit<BlockItemProps, "start" | "end" | "isDayLevel">

type RenderItemOrVirtualItemProps = {
  dayLevel: boolean
  isAgenda: boolean
  item: ItemOrVirtualItem
  renderProps: BlockRenderItemProps
}

export const RenderItemOrVirtualItem: React.FC<RenderItemOrVirtualItemProps> =
  React.memo((props) => {
    switch (props.item.__typename) {
      case "Item":
        return (
          <RenderItem
            isAgenda={props.isAgenda}
            dayLevel={props.dayLevel}
            item={props.item}
            renderProps={props.renderProps}
          />
        )
      case "VirtualItem":
        return (
          <RenderVirtualItem
            isAgenda={props.isAgenda}
            dayLevel={props.dayLevel}
            item={props.item}
            renderProps={props.renderProps}
          />
        )
    }

    throw new Error(
      `Unknown item type ${props.item.__typename || "(undefined)"}`,
    )
  })
RenderItemOrVirtualItem.displayName = "RenderItem"
