import { durationIgnoringZoneDifferences } from "@daybridge/datetime"
import { DateTime } from "luxon"

/**
 * `placementFromTime` figures out where on the timeline a time should be placed
 * based on where it sits between two times - the start of the day and the end of
 * the day. Note that the start and end times will not necessarily be midnight
 * if part of the day has been hidden.
 *
 * The algorithm made more complex because it takes into account the possibility that:
 * - Hours may be missing if the clocks go forward. In this case we have to render
 *   a block where that hour would be, but everything in that block is non-interactive.
 * - Hours may be "doubled up" if the clocks go back - where we need to squeeze two hours
 *   into what would usually be only one hour.
 *
 * It returns a single floating value between 0 and 1, where 0 is the top
 * of the window, and 1 is the bottom.
 */
export const placementFromTime = (
  time: DateTime,
  windowStart: DateTime,
  windowEnd: DateTime,
) => {
  if (time <= windowStart) {
    // Shortcut - if the time we are trying to place is
    // before the start time of the window, then the position is 0.
    return 0
  }

  if (time >= windowEnd) {
    // Likewise if the time is after the end of the window, then
    // the position is at the very end of the window (its height).
    return 1
  }

  // Compute the duration of the window in minutes
  const windowDuration = durationIgnoringZoneDifferences(
    windowStart,
    windowEnd,
  ).as("minutes")
  // Compute the number of minutes into the window the time is
  const positionAsMinutes = Math.floor(time.diff(windowStart).as("minutes"))

  if (windowStart.offset > windowEnd.offset) {
    // Clocks go back - we need to squeeze two hours into one.

    const offsetDurationMinutes = windowStart.offset - windowEnd.offset

    const transitionTime = findTransitionTime(windowStart, windowEnd)

    if (!transitionTime) {
      throw new Error("Could not find transition time")
    }
    const doubledUpRegionEnds = transitionTime.plus({
      minutes: offsetDurationMinutes * 2,
    })

    const heightOfDoubledUpRegionAsFractionOfWindow =
      offsetDurationMinutes / windowDuration

    if (time <= transitionTime) {
      // The time is before the transition, so we can just
      // compute the position as normal.
      return positionAsMinutes / windowDuration
    } else if (time >= doubledUpRegionEnds) {
      return (
        positionAsMinutes / windowDuration -
        heightOfDoubledUpRegionAsFractionOfWindow
      )
    } else {
      const minutesIntoTransitionWindow = time
        .diff(transitionTime)
        .as("minutes")
      const ratioIntoTransitionWindow =
        minutesIntoTransitionWindow / (offsetDurationMinutes * 2)
      return (
        positionAsMinutes / windowDuration -
        heightOfDoubledUpRegionAsFractionOfWindow * ratioIntoTransitionWindow
      )
    }
  } else if (windowStart.offset < windowEnd.offset) {
    // Clocks go forward - we render a block where an hour would be.
    const transitionDurationMinutes = windowEnd.offset - windowStart.offset
    const deadRegionHeightAsFractionOfWindow =
      transitionDurationMinutes / windowDuration

    const transition = findTransitionTime(windowStart, windowEnd)
    if (!transition) {
      throw new Error("Could not find transition time")
    }

    if (time <= transition) {
      // If the time is before the transition, then we can just use the
      // normal calculation
      return positionAsMinutes / windowDuration
    } else {
      // If the time is after the transition, then we need to subtract
      // the height of the dead region from the position
      return (
        positionAsMinutes / windowDuration + deadRegionHeightAsFractionOfWindow
      )
    }
  } else {
    // Compute the ratio of start minutes to total minutes
    // This will give a number between 0 and 1
    const ratio = positionAsMinutes / windowDuration

    return ratio
  }
}

// Given two dates, find the first hour between them where the offset changes
// This is the hour where the clocks go forward or back
// We do this by iterating through the hours between the two dates
// and checking if the offset changes
export const findTransitionTime = (
  windowStart: DateTime,
  windowEnd: DateTime,
): DateTime | undefined => {
  if (windowStart.offset === windowEnd.offset) {
    return undefined
  }

  // In case the window isn't exactly on hour boundaries, snap it to the nearest hour
  windowStart = windowStart.startOf("hour")
  windowEnd = windowEnd.startOf("hour").plus({ hours: 1 })

  const hours = windowEnd.diff(windowStart).as("hours")
  for (let i = 0; i < hours; i++) {
    const hour = windowStart.plus({ hours: i })
    const nextHour = windowStart.plus({ hours: i + 1 })
    if (hour.offset !== nextHour.offset) {
      if (hour.offset > nextHour.offset) {
        // Clocks go back
        return hour
      } else {
        return nextHour
      }
    }
  }

  return undefined
}
