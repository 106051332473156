import { DateTime } from "luxon"
import { useMemo } from "react"
import { VirtualItemType, VirtualItem } from "../types/virtualItem"

function randomBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// `useSkeletonItems` is a hook that generates random skeleton items for a
// given period, that can be used as a loading state.
export const useSkeletonItems = (
  startLocal: DateTime,
  endLocal: DateTime,
): VirtualItem[] => {
  return useMemo(() => {
    const daysWithItems: VirtualItem[] = []
    const durationInDays = endLocal.diff(startLocal, "days").days
    for (let dayIndex = 0; dayIndex < durationInDays; dayIndex++) {
      const numberOfItemsThisDay = randomBetween(2, 4)
      let hourInDay = 8
      for (let itemIndex = 0; itemIndex < numberOfItemsThisDay; itemIndex++) {
        const duration = randomBetween(1, 4)
        hourInDay += randomBetween(duration + 1, 5)
        const itemStart = startLocal
          .plus({ days: dayIndex })
          .set({ hour: hourInDay })
          .startOf("hour")
        const itemEnd = itemStart.plus({ hours: duration })
        // Don't render items that overflow the day.
        if (itemStart.day === startLocal.day && itemStart.day === itemEnd.day) {
          daysWithItems.push({
            __typename: "VirtualItem",
            id: `skeleton-item-${dayIndex}-${itemIndex}`,
            type: VirtualItemType.Skeleton,
            startLocal: itemStart,
            endLocal: itemEnd,
            isAllDayItem: false,
            renderAsAllDay: false,
          })
        }
      }
    }
    return daysWithItems
  }, [endLocal, startLocal])
}
