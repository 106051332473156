import { ItemTypeId } from "@daybridge/client-api"
import React from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { RecurringMoveModeSelector } from "../../../timeline/components/RecurringMoveModeSelector"
import { VirtualItem, VirtualItemType } from "../../types/virtualItem"
import { Card } from "../block-items/Card"
import { BlockItemProps } from "../block-items/common/BaseBlockItem"
import { SelectedRegion } from "../block-items/SelectedRegion"
import { SkeletonRegion } from "../block-items/SkeletonRegion"

export type BlockRenderItemProps = Omit<
  BlockItemProps,
  "start" | "end" | "isDayLevel"
>

type RenderVirtualItemProps = {
  dayLevel: boolean
  isAgenda: boolean
  item: VirtualItem
  renderProps: BlockRenderItemProps
}

export const RenderVirtualItem: React.FC<RenderVirtualItemProps> = React.memo(
  (props) => {
    const [colorByCategory] = usePreference("colorByCategory")

    switch (props.item.type) {
      case VirtualItemType.Skeleton:
        return (
          <SkeletonRegion
            key={props.item.id}
            isAgenda={props.isAgenda}
            {...props.renderProps}
            isDayLevel={props.dayLevel}
            start={props.item.startLocal}
            end={props.item.endLocal}
          />
        )
      case VirtualItemType.SelectedRegion:
        return (
          <SelectedRegion
            key={props.item.id}
            {...props.renderProps}
            isDayLevel={props.dayLevel}
            start={props.item.startLocal}
            end={props.item.endLocal}
          />
        )
      case VirtualItemType.DraftItem:
        return props.item.item.calendar ? (
          <>
            <RecurringMoveModeSelector
              item={props.item.item}
              {...props.renderProps}
              isFutureEnabled={
                false
                // TODO Enable after we add support for editing this
                // and future instances of recurring Google Events.
                // props.item.item.calendar.node.provider.node.type === "google"
              }
              allDay={props.dayLevel}
            />
            <Card
              isAgenda={false}
              type={ItemTypeId.Event}
              key={props.item.id}
              id={props.item.id}
              {...props.renderProps}
              isDayLevel={props.dayLevel}
              calendar={props.item.item.calendar.node}
              title={props.item.item.title}
              icon={props.item.item.icon?.id}
              people={props.item.item.people}
              sharing={props.item.item.sharing}
              color={
                colorByCategory
                  ? props.item.item.category?.node.color
                  : props.item.item.calendar.node.color
              }
              category={props.item.item.category?.node}
              start={props.item.startLocal}
              end={props.item.endLocal}
              location={
                props.item.item.location?.place?.node ||
                props.item.item.location?.name
              }
              series={props.item.item.series?.node}
              inert
            />
          </>
        ) : null
    }
  },
)
RenderVirtualItem.displayName = "RenderVirtualItem"
