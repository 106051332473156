import { DateTimeInfo } from "@daybridge/client-api"
import { DateTime } from "luxon"
import { ItemWithResolvedTimes } from "./itemWithResolvedTimes"

export enum VirtualItemType {
  // `SelectedRegion` is a virtual item used to render
  // blue selected regions on the timeline.
  SelectedRegion = "selected_region",

  // `DraftItem` is a virtual item used to render
  // items that are being dragged.
  DraftItem = "draft_item",

  Skeleton = "skeleton",
}

export type VirtualItem = {
  // Used to discriminate between items and virtual items
  __typename: "VirtualItem"

  // Unique ID, used for rendering
  id: string

  // If `true` then this item shouldn't stack with other items, instead it should
  // be rendered on top of them.
  excludeFromConflictResolution?: boolean

  // Times at which to render the virtual item
  start?: DateTimeInfo
  end?: DateTimeInfo
  startLocal: DateTime
  endLocal: DateTime
  isAllDayItem: boolean
  renderAsAllDay: boolean
} & (DraftItemVirtualItem | SkeletonVirtualItem | SelectedRegionVirtualItem)

export type SelectedRegionVirtualItem = {
  // Type of virtual item
  type: VirtualItemType.SelectedRegion
}

export type SkeletonVirtualItem = {
  // Type of virtual item
  type: VirtualItemType.Skeleton
}

export type DraftItemVirtualItem = {
  // Type of virtual item
  type: VirtualItemType.DraftItem

  // The item being dragged
  item: ItemWithResolvedTimes
}
