import { useRecoilCallback } from "recoil"
import { DateTime } from "luxon"
import { timeFromPosition } from "../../placement/timeFromPosition"
import { computeYPositionFromMouseEvent } from "../../placement/positionFromMouseEvent"

import {
  selectedRegion_transformedForRenderer,
  selectedRegionAtom,
} from "../../state/selectedRegion"
import {
  ItemCreationStateStage,
  useItemCreationState,
} from "../../../items/state/creation"
import { useDragItem } from "../../../items/hooks/useDragItem"
import { useItemDraggingSelectingRecurringMode } from "../../../items/state/dragging"

export interface TimelineMouseInteractions {
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseMove: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseUp: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const useHourLevelMouseInteractions = (
  day: DateTime,
  container?: HTMLDivElement | null,
): TimelineMouseInteractions => {
  const [itemCreationState, setItemCreationState] = useItemCreationState()
  const { dragTo, commit } = useDragItem()
  const draggingInProgress = useItemDraggingSelectingRecurringMode()

  const onMouseDown = useRecoilCallback(
    ({ set }) => {
      return (e: React.MouseEvent<HTMLDivElement>): void => {
        if (!container) {
          // We need a container to compute the position
          return
        }

        if (e.button !== 0) {
          // Left clicks only - ignore right/middle clicks
          return
        }

        // The user may be choosing the edit mode (just this one, everything going forward, everything)
        // In this case don't start dragging a selected region.
        if (draggingInProgress) {
          return
        }

        // Calculate the position the mouse was in on this mouse down event
        const position = computeYPositionFromMouseEvent(e)

        // Calculate the time that this position corresponds to
        const time = timeFromPosition(
          position,
          container.clientHeight,
          day.startOf("day"),
          day.startOf("day").plus({ days: 1 }),
          15,
        )

        // Set the selected time range to this time. We default to 1 hour in duration.
        set(selectedRegionAtom, {
          firstClickAt: time,
          firstClickWasAllDay: false,
          lastMousePositionAt: time.plus({ hours: 1 }),
          lastMousePositionWasAllDay: false,
        })
      }
    },
    [container, day, draggingInProgress],
  )

  const onMouseMove = useRecoilCallback(
    ({ set }) => {
      return (e: React.MouseEvent<HTMLDivElement>) => {
        if (
          !container ||
          itemCreationState?.stage === ItemCreationStateStage.ItemDetails
        ) {
          return
        }

        const position = computeYPositionFromMouseEvent(e, container)
        const time = timeFromPosition(
          position,
          container.clientHeight,
          day.startOf("day"),
          day.startOf("day").plus({ days: 1 }),
        )

        // If something is being dragged, update the drag state with the new time
        dragTo(time, false)

        set(selectedRegionAtom, (val) => {
          if (val === undefined) return undefined

          return {
            ...val,
            lastMousePositionAt: time,
            lastMousePositionWasAllDay: false,
          }
        })
      }
    },
    [container, day, dragTo, itemCreationState?.stage],
  )

  const onMouseUp = useRecoilCallback(
    ({ snapshot, set }) => {
      return async (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()

        const selectedRegion = await snapshot.getPromise(
          selectedRegion_transformedForRenderer,
        )

        if (
          selectedRegion?.startLocal.equals(selectedRegion?.endLocal) &&
          !selectedRegion.isAllDayItem
        ) {
          // Default to 1 hour duration
          set(selectedRegionAtom, (val) =>
            val
              ? {
                  ...val,
                  firstClickAt: val.firstClickAt,
                  lastMousePositionAt: val.firstClickAt.plus({ hours: 1 }),
                }
              : undefined,
          )
        } else if (
          selectedRegion?.renderAsAllDay &&
          !selectedRegion.isAllDayItem
        ) {
          // If the user has selected a region that spans multiple days,
          // then assume they wanted to create a multi-day item. Snap the times
          // to day boundaries and set the `forceDayLevel` flag to true.
          // The next step of the creation flow will then make a lot more sense.
          set(selectedRegionAtom, (val) =>
            val
              ? {
                  firstClickAt: val.firstClickAt.startOf("day"),
                  firstClickWasAllDay: true,
                  lastMousePositionAt: val.lastMousePositionAt.startOf("day"),
                  lastMousePositionWasAllDay: true,
                }
              : undefined,
          )
        }

        // If we've been dragging something, commit the changes
        commit()

        if (selectedRegion) {
          setItemCreationState({ stage: ItemCreationStateStage.ItemSearch })
        }
      }
    },
    [setItemCreationState, commit],
  )

  return {
    onMouseDown,
    onMouseMove,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onMouseUp,
  }
}
