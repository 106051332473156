import { SelectedCalendarKeyValuePair } from "@daybridge/client-api"
import { useCallback, useMemo } from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { keyValuePairToCalendarMap } from "./useCalendarSelected"

const calendarMapToKeyValuePair = (selectedCalendars: {
  [calendarId: string]: boolean
}): SelectedCalendarKeyValuePair[] => {
  return Object.keys(selectedCalendars).map((calendarId) => {
    return {
      key: calendarId,
      value: selectedCalendars[calendarId],
    }
  })
}

export const useSetCalendarSelected = () => {
  const [selectedCalendars, setSelectedCalendars] =
    usePreference("selectedCalendars")
  const selectedCalendarMapping = useMemo(
    () => keyValuePairToCalendarMap(selectedCalendars),
    [selectedCalendars],
  )

  return useCallback(
    async (calendarId: string, selected: boolean) => {
      const newSelectedCalendars = {
        ...selectedCalendarMapping,
        [calendarId]: selected,
      }
      await setSelectedCalendars(
        calendarMapToKeyValuePair(newSelectedCalendars),
      )
    },
    [selectedCalendarMapping, setSelectedCalendars],
  )
}
