/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Color, IconId, ItemTypeId } from "@daybridge/client-api"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Map,
} from "@daybridge/components-core"
import React, { useCallback, useMemo, useState } from "react"
import { colorToClass } from "@daybridge/colors"
import { InView } from "react-intersection-observer"
import env from "../../../../env.config"
import { useTimelineHeaderHeight } from "../../../timeline/state/timelineHeaderHeight"
import { Overflow } from "../../types/overflow"
import { useOpenItem } from "../../state/opening"
import { useViewportPosition } from "../../../timeline/hooks/rendering/useViewportPosition"
import { useHourLevelScrollHeight } from "../../../timeline/hooks/rendering/useHourLevelScrollHeight"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { CardContextMenu } from "./CardContextMenu"
import { CardProps } from "./CardProps"
import { BaseBlockItem } from "./common/BaseBlockItem"
import { BlockIcon } from "./common/BlockIcon"
import { BlockTimeLabels } from "./common/BlockTimeLabels"
import { BlockTitle } from "./common/BlockTitle"

// Set a default icon
const DEFAULT_ICON = IconId.Calendar

// Define height breakpoints. Anything below each of these breakpoints
// renders that layout
export const CARD_TINY_BREAKPOINT = 46

// `MIN_ICON_WIDTH` is the minimum width at which the icon will be rendered.
const MIN_ICON_WIDTH = 120

export const Card: React.FC<CardProps> = React.memo((props) => {
  const [overflow, setOverflow] = useState<Overflow>(undefined)
  const [openItem, setOpenItem] = useOpenItem()
  const [colorByCategory] = usePreference("colorByCategory")

  const { viewportRef } = useViewportPosition()
  const scrollHeight = useHourLevelScrollHeight()
  const heightInPixels =
    props.fixedHeight || ((props.percentageHeight || 0) * scrollHeight) / 100

  const [timelineHeaderHeight] = useTimelineHeaderHeight()

  // Choose what sort of contents to show depending on
  // how tall the card is.
  let layout: React.ReactElement
  switch (true) {
    case props.isDayLevel:
      layout = <DayLevelCardLayout {...props} />
      break
    case heightInPixels < CARD_TINY_BREAKPOINT:
      layout = <TinyCardLayout {...props} />
      break
    default:
      layout = <MediumCardLayout {...props} />
      break
  }

  const colorClass = useMemo(
    () => colorToClass(props.color || Color.White),
    [props.color],
  )

  const overflowThreshold = useMemo(() => {
    // The InView component accepts a threshold between 0 and 1, indicating the
    // percentage that should be visible before triggering `overflow`. We
    // always want to display 10px of the card when it's overflowing, so this
    // will calculate what threshold we need to display 10px.
    return Math.min(10 / heightInPixels, 1)
  }, [heightInPixels])

  const roundedClasses = useMemo(() => {
    return props.isDayLevel
      ? "rounded-xl"
      : /* Cards on the timeline aren't rounded on the top/bottom if they have already started/continue */
        (!props.alreadyStarted || props.isAgenda ? "rounded-t-xl" : "") +
          (overflow !== "bottom" && (!props.continues || props.isAgenda)
            ? " rounded-b-xl"
            : "")
  }, [
    overflow,
    props.alreadyStarted,
    props.continues,
    props.isAgenda,
    props.isDayLevel,
  ])

  const className = useMemo(
    () => `
      w-full h-full
      content-${colorClass}
      flex items-center justify-center
      group
      overflow-hidden
      bg-content-background text-content-body 
      text-sm
      border-surface
      ${props.xOffset === 0 && !props.isDayLevel ? "border" : ""}
      ${
        props.xOffset !== undefined && props.xOffset > 0 && !props.isDayLevel
          ? "border-2"
          : ""
      }
      flex items-center justify-center
      ${roundedClasses}
    `,
    [props.xOffset, props.isDayLevel, colorClass, roundedClasses],
  )

  const inViewChangeHandler = useCallback(
    (inView: boolean, entry: IntersectionObserverEntry) => {
      // Day level items never overflow.
      if (props.isDayLevel || props.isAgenda) return
      // Set the overflow to top or bottom, based on the position of the item.
      // Note: do NOT check if `overflow` is defined before unsetting it.
      // Somehow this callback, in rare occasions, gets invoked twice at the
      // same time, resulting in missing events. See thread:
      // https://daybridgeapp.slack.com/archives/C01NYQ13BMH/p1657534751549699?thread_ts=1657525820.696499&cid=C01NYQ13BMH
      if (inView) {
        setOverflow(undefined)
      } else if (!inView) {
        // Do not apply any overflow logic when `entry.boundingClientRect`
        // is empty (i.e. all zeros). This can happen after dragging an item.
        if (
          entry.boundingClientRect.height === 0 &&
          entry.boundingClientRect.width === 0
        ) {
          return
        }
        // Determine where to overflow the item (top or bottom).
        if (
          entry.boundingClientRect.top < (timelineHeaderHeight || 200) + 10 &&
          overflow !== "top"
        ) {
          setOverflow("top")
        } else if (
          entry.boundingClientRect.top >= (timelineHeaderHeight || 200) + 10 &&
          overflow !== "bottom"
        ) {
          setOverflow("bottom")
        }
      }
    },
    [overflow, props.isAgenda, props.isDayLevel, timelineHeaderHeight],
  )

  return (
    <BaseBlockItem
      {...props}
      key={props.id}
      overflow={overflow}
      classNameForOverflow={className}
      zIndex={
        // Increment the z-index to open items with 1 so that the ring displays
        // over other items. We don't want to increment any higher to prevent
        // the item from overlapping with conflicting items.
        openItem?.id === props.id ? props.zIndex || 0 + 1 : props.zIndex
      }
    >
      <CardContextMenu {...props}>
        <Button
          variant={ButtonVariant.Headless}
          size={ButtonSize.Custom}
          onPress={() =>
            setOpenItem(
              openItem?.id === props.id
                ? undefined
                : {
                    id: props.id,
                    title: props.title,
                    color:
                      (colorByCategory
                        ? props.category?.color
                        : props.calendar?.color) || Color.Stone,
                    icon: props.icon || IconId.Calendar,
                  },
            )
          }
          tabIndex={props.inert ? -1 : 0}
          className={`
            w-full h-full 
            content-${colorClass} ring-content-primary !ring-offset-0
            ${openItem?.id === props.id ? `ring-2` : ""}
            ${roundedClasses}
          `}
        >
          <InView
            as="div"
            className={className}
            disabled={props.isDayLevel || props.isAgenda}
            root={viewportRef?.current}
            rootMargin={`-${timelineHeaderHeight || 0}px 0px 0px 0px`}
            threshold={overflowThreshold}
            onChange={inViewChangeHandler}
          >
            {/* Main layout */}
            {layout}
          </InView>
        </Button>
      </CardContextMenu>
    </BaseBlockItem>
  )
})
Card.displayName = "Card"

export const DayLevelCardLayout: React.FC<CardProps> = React.memo(
  (props: CardProps) => {
    return (
      <div
        className={`
          pl-2
          ${props.continues ? "pr-9" : "pr-2"}
          w-full h-full
          space-x-2
          flex flex-row items-center justify-between
          truncate
        `}
      >
        {props.width >= MIN_ICON_WIDTH && (
          <BlockIcon icon={props.icon || DEFAULT_ICON} />
        )}
        <BlockTitle>{props.title}</BlockTitle>
        {(!props.start.equals(props.start.startOf("day")) ||
          !props.end.equals(props.end.startOf("day"))) && (
          <BlockTimeLabels
            start={props.start}
            end={props.end}
            width={props.width}
            alreadyStarted={props.alreadyStarted}
            continues={props.continues}
            singleLabelMinWidth={0}
            doubleLabelMinWidth={500}
          />
        )}
      </div>
    )
  },
)
DayLevelCardLayout.displayName = "DayLevelCardLayout"

export const TinyCardLayout: React.FC<CardProps> = React.memo(
  (props: CardProps) => {
    return (
      <div
        className="
          pl-2 pr-3 py-2
          w-full h-full
          space-x-2
          flex flex-row items-center
          truncate
        "
      >
        {props.width >= MIN_ICON_WIDTH && (
          <BlockIcon icon={props.icon || DEFAULT_ICON} />
        )}
        <BlockTitle>{props.title}</BlockTitle>
        <BlockTimeLabels
          start={props.start}
          end={props.end}
          width={props.width}
          alreadyStarted={props.alreadyStarted}
          continues={props.continues}
          singleLabelMinWidth={150}
          doubleLabelMinWidth={false}
        />
      </div>
    )
  },
)
TinyCardLayout.displayName = "TinyCardLayout"

export const MediumCardLayout: React.FC<CardProps> = React.memo(
  (props: CardProps) => {
    const scrollHeight = useHourLevelScrollHeight()
    const height =
      props.fixedHeight || ((props.percentageHeight || 0) * scrollHeight) / 100

    return (
      <div
        className="
          flex flex-1 flex-col items-start
          h-full truncate
        "
      >
        <div
          className="
            pl-2 pr-3 pt-2
            w-full
            space-x-1.5
            flex flex-row items-start
          "
        >
          {props.width >= MIN_ICON_WIDTH && (
            <BlockIcon icon={props.icon || DEFAULT_ICON} />
          )}
          <div className="flex-1 flex flex-col truncate">
            <BlockTitle>{props.title}</BlockTitle>
            <BlockTimeLabels
              start={props.start}
              end={props.end}
              width={props.width}
              alreadyStarted={props.alreadyStarted}
              continues={props.continues}
              singleLabelMinWidth={0}
              doubleLabelMinWidth={160}
            />
          </div>
        </div>
        {props.type === ItemTypeId.Event &&
          height > 100 &&
          props.location &&
          env.NEXT_PUBLIC_MAPBOX_PUBLIC_ACCESS_TOKEN &&
          typeof props.location !== "string" &&
          props.location.viewportBottomLeft &&
          props.location.viewportTopRight &&
          props.location.coordinates?.latitude && (
            <div
              className="w-full flex-1 relative z-0 -mt-4"
              style={{
                maskImage:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 40%)",
                WebkitMaskImage:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 40%)",
              }}
            >
              <Map
                publicAccessToken={env.NEXT_PUBLIC_MAPBOX_PUBLIC_ACCESS_TOKEN}
                showAttribution={false}
                width={props.width}
                latitude={props.location.coordinates.latitude}
                longitude={props.location.coordinates.longitude}
                bottomLeft={[
                  props.location.viewportBottomLeft.longitude,
                  props.location.viewportBottomLeft.latitude,
                ]}
                topRight={[
                  props.location.viewportTopRight.longitude,
                  props.location.viewportTopRight.latitude,
                ]}
                markerSize={4}
                style={{ mixBlendMode: "multiply" }}
                className={`content-${colorToClass(
                  props.color || Color.White,
                )} text-content-body bg-content-background dark:bg-content-primary/60`}
              />
            </div>
          )}
      </div>
    )
  },
)
MediumCardLayout.displayName = "MediumCardLayout"
