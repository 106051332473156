import { useAuth } from "@daybridge/auth"
import { IconId, useUserQuery } from "@daybridge/client-api"
import {
  Avatar,
  Button,
  ButtonSize,
  ButtonVariant,
  ComboboxMenu,
  ComboboxMenuItem,
  ScrollArea,
  SidebarTabContent,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { ForwardedRef, forwardRef, memo, useCallback, useState } from "react"
import { ContactListItem } from "../../../contacts/components/ContactListItem"
import { useAddToContacts } from "../../../contacts/hooks/useAddToContacts"
import { useContacts } from "../../../contacts/hooks/useContacts"
import { useFindByUsername } from "../../../contacts/hooks/useFindByUsername"

const SidebarContactListFn = forwardRef(
  (_, ref: ForwardedRef<HTMLDivElement>) => {
    const { contacts } = useContacts()
    const [username, setUsername] = useState("")
    const { user: auth } = useAuth()
    const { data: me } = useUserQuery({ args: { id: auth?.uid } })
    const { user, status } = useFindByUsername(username)
    const add = useAddToContacts()

    const results = useCallback(
      (query: string) => {
        setUsername(query.replace("@", ""))
        return user
          ? [
              <ComboboxMenuItem
                onSelect={() =>
                  void add({ input: { username: user.username } })
                }
                key={user.id}
                className="py-2"
              >
                <div className="flex flex-row items-center space-x-3 w-full">
                  <div
                    className="
                    w-9 h-9
                    flex items-center justify-center flex-shrink-0
                    bg-background
                    rounded-full
                  "
                  >
                    <Avatar
                      url={user.avatar || undefined}
                      defaultInitials={user.displayName[0]}
                      className="text-lg"
                    />
                  </div>
                  <div className="flex flex-col justify-center truncate">
                    <div>{user.displayName}</div>
                    <div className="text-sm text-low-contrast truncate">
                      @{user.username}
                    </div>
                  </div>
                </div>
              </ComboboxMenuItem>,
            ]
          : []
      },
      [add, user],
    )

    return (
      <SidebarTabContent
        title="Contacts"
        ref={ref}
        value="contacts"
        buttons={
          <ComboboxMenu
            align="center"
            className="!w-[325px]"
            placeholder="Search by username..."
            defaultText="Start typing to find people"
            noResultsText={
              username === me?.user?.node.username
                ? "You can't add yourself."
                : "Couldn't find anyone with that username. You can only search for exact usernames for now."
            }
            loading={status === "loading" && username.length > 0}
            items={results}
          >
            <Button
              variant={ButtonVariant.Clear}
              size={ButtonSize.IconAction}
              className="flex-shrink-0"
            >
              <I type={IconId.Plus} />
            </Button>
          </ComboboxMenu>
        }
      >
        <div className="flex flex-col h-full">
          <ScrollArea className="w-full overflow-hidden flex-1 min-w-0">
            {contacts.length ? (
              contacts.map((contact) => {
                const profile = contact.profile?.node
                // The displayName and username are nullable but in reality
                // should always be set. In the unlikely case that they are
                // empty, we'll prevent the contact from rendering.
                return profile?.displayName && profile?.username ? (
                  <ContactListItem
                    key={profile.id}
                    username={profile.username}
                    avatar={profile.avatar}
                    name={profile.displayName}
                  />
                ) : null
              })
            ) : (
              <div className="py-8">
                <I
                  type={IconId.UsersOutline}
                  className="w-8 h-8 mx-auto text-icon"
                />
                <p className="text-low-contrast mt-3 text-base text-center">
                  You don&apos;t have any <br />
                  contacts yet.
                </p>
              </div>
            )}
          </ScrollArea>
        </div>
      </SidebarTabContent>
    )
  },
)
SidebarContactListFn.displayName = "SidebarContactList"

export const SidebarContactList = memo(
  SidebarContactListFn,
) as typeof SidebarContactListFn
