import { DateTimeInfo } from "@daybridge/client-api"
import { DateTime } from "luxon"
import { atom, selector, selectorFamily, useRecoilValue } from "recoil"
import { fallsInTimeRange } from "@daybridge/datetime"

// `SelectedRegionState` represents the region that the user has selected on the
// timeline. This is the blue area that appears when clicking and dragging.
export type SelectedRegionState =
  | {
      // `firstClickAt` was the time the user started selecting. If
      // they started selecting in the all day region, this will be
      // midnight (the start of) the day they clicked inside.
      firstClickAt: DateTime

      // `firstClickWasAllDay` is true if the user started selecting in
      // the all-day region at the top of the page.
      firstClickWasAllDay: boolean

      // `lastMousePositionAt` was the last time the user moved the mouse.
      // If they moved the mouse in the the all day region, this will be
      // midnight (the start of) the day they moved the mouse in.
      // Note that this time can be before or after the `firstClickAt` time.
      lastMousePositionAt: DateTime

      // `lastMousePositionWasAllDay` is true if the user moved the mouse in
      // the all-day region at the top of the page.
      lastMousePositionWasAllDay: boolean
    }
  | undefined

export type SelectedRegionLocalTimes =
  | {
      startLocal: DateTime
      endLocal: DateTime
    }
  | undefined

export type SelectedRegion =
  | (SelectedRegionLocalTimes & {
      start: DateTimeInfo
      end: DateTimeInfo
      isAllDayItem: boolean
      renderAsAllDay: boolean
    })
  | undefined

// `selectedRegionAtom` stores the raw selected region. It may be out-of-order
// (with the end time before the start time) if the user dragged backwards.
export const selectedRegionAtom = atom<SelectedRegionState>({
  key: "selectedRegion",
  default: undefined,

  // Required when storing Luxon DateTime objects in Recoil state.
  dangerouslyAllowMutability: true,
})

// `selectedRegion_isAllDay` is `true` if the selection should be treated as
// an all-day item.
export const selectedRegion_isAllDay = selector<boolean | undefined>({
  key: "selectedRegion_isAllDay",
  get: ({ get }) => {
    const userSelection = get(selectedRegionAtom)
    if (!userSelection) {
      return
    }

    return (
      // The user started the selection in the all-day region
      userSelection.firstClickWasAllDay ||
      // OR the user has moved the mouse to the all-day region
      userSelection.lastMousePositionWasAllDay
    )
  },
})

// `selectedRegion_transferredToAllDay` is true if the selection is hour-level
// (made on the timeline, not in the all-day section) but longer than a day.
// In this case, rather than rendering an absolutely massive series of rectangles,
// we shift ("transfer") the selection to the all-day section.
export const selectedRegion_transferredToAllDay = selector<boolean | undefined>(
  {
    key: "selectedRegion_transferredToAllDay",
    get: ({ get }) => {
      const userSelection = get(selectedRegionAtom)
      const selectionIsAllDay = get(selectedRegion_isAllDay)
      if (!userSelection || selectionIsAllDay === undefined) {
        return
      }

      return (
        // If the selection is already all-day, it cannot be "transferred" to all-day
        !selectionIsAllDay &&
        // The selection is longer than a day
        Math.abs(
          userSelection.firstClickAt
            .diff(userSelection.lastMousePositionAt)
            .as("days"),
        ) > 1
      )
    },
  },
)

// `selectedRegion_shouldRenderAsAllDay` determines whether or not the selection that the user made
// should be rendered in the all-day region at the top of the timeline, instead of in the
// hour-level region (main body of the timeline).
export const selectedRegion_shouldRenderAsAllDay = selector<
  boolean | undefined
>({
  key: "selectedRegion_shouldRenderAsAllDay",
  get: ({ get }) => {
    const selectionIsAllDay = get(selectedRegion_isAllDay)
    const selectionTransferredToAllDay = get(selectedRegion_transferredToAllDay)

    if (
      selectionIsAllDay === undefined ||
      selectionTransferredToAllDay === undefined
    ) {
      return
    }

    return (
      // The user started the selection in the all-day region
      selectionIsAllDay ||
      // The selection is long enough that it should be transferred to the all-day region
      selectionTransferredToAllDay
    )
  },
})

// `selectedRegion_sorted` returns the times that the user dragged between
// in sorted order. Since the user may have dragged backwards, we need to sort the
// selection so that the start time is always before the end time.
export const selectedRegion_sorted = selector<SelectedRegionLocalTimes>({
  key: "selectedRegion_sorted",
  get: ({ get }) => {
    const userSelection = get(selectedRegionAtom)
    if (!userSelection) {
      return
    }

    const sorted = [
      userSelection.firstClickAt,
      userSelection.lastMousePositionAt,
    ].sort((a, b) => (a.equals(b) ? 0 : a < b ? -1 : 1))

    return {
      startLocal: sorted[0],
      endLocal: sorted[1],
    }
  },
})

// `selectedRegion_transformedForRenderer` returns the times that should be used to
// render the selected region on the timeline. Crucially these are not necessarily the times
// that the user dragged between, since a transformation might have been applied.
export const selectedRegion_transformedForRenderer = selector<SelectedRegion>({
  key: "selectedRegion_transformedForRenderer",
  get: ({ get }) => {
    const sortedUserSelection = get(selectedRegion_sorted)
    const isAllDayItem = get(selectedRegion_isAllDay)
    const renderAsAllDay = get(selectedRegion_shouldRenderAsAllDay)
    if (
      sortedUserSelection === undefined ||
      isAllDayItem === undefined ||
      renderAsAllDay === undefined
    ) {
      return
    }

    if (renderAsAllDay) {
      // If this selection should render as all-day, we need to apply a
      // transformation so that:
      // - The start time is midnight (the start of) the selection region
      // - The end time is midnight (the start of) the day after the selection
      //   region ends.
      // This is because the end time of all-day items is exclusive. In other words
      // a selection that spans two columns from 1st Jan - 2nd Jan would have a start
      // time of 1st Jan @ 00:00 and an end time of 3rd Jan @ 00:00.

      const startDate = sortedUserSelection.startLocal.startOf("day")
      const endDate = sortedUserSelection.endLocal
        .startOf("day")
        .plus({ days: 1 })
      return {
        start: {
          date: startDate.toISODate(),
        },
        startLocal: startDate,
        end: {
          date: endDate.toISODate(),
        },
        endLocal: endDate,
        isAllDayItem,
        renderAsAllDay,
      }
    } else {
      return {
        start: {
          dateTime: sortedUserSelection.startLocal.toISO(),
        },
        startLocal: sortedUserSelection.startLocal,
        end: {
          dateTime: sortedUserSelection.endLocal.toISO(),
        },
        endLocal: sortedUserSelection.endLocal,
        isAllDayItem,
        renderAsAllDay,
      }
    }
  },
})
export const useSelectedRegionForRenderer = () =>
  useRecoilValue(selectedRegion_transformedForRenderer)

// `selectedRegion_transformedForCreateForm` returns the times that should be used to
// populate the start and end times on the creation form when creating a new item.
export const selectedRegion_transformedForCreateForm = selector<SelectedRegion>(
  {
    key: "selectedRegion_transformedForCreateForm",
    get: ({ get }) => {
      const sortedUserSelection = get(selectedRegion_sorted)
      const isAllDayItem = get(selectedRegion_isAllDay)
      const renderAsAllDay = get(selectedRegion_shouldRenderAsAllDay)

      if (
        sortedUserSelection === undefined ||
        isAllDayItem === undefined ||
        renderAsAllDay === undefined
      ) {
        return
      }

      if (isAllDayItem) {
        // If this selection is an all-day item, we need to apply a
        // slightly different transformation for the purposes of rendering
        // the item creation form. Specifically, since the end date is exclusive,
        // we need to not add that extra day. This makes the end date more intuitive
        // in the form. If we want the item to run from 1st Jan to 3rd Jan inclusive, we want
        // the end date on the form to say 3rd Jan, not 4th Jan which would be the actual
        // technical end date.
        const startDate = sortedUserSelection.startLocal.startOf("day")
        const endDate = sortedUserSelection.endLocal.startOf("day")

        return {
          start: {
            date: startDate.toISODate(),
          },
          startLocal: startDate,
          end: {
            date: endDate.toISODate(),
          },
          endLocal: endDate,
          isAllDayItem,
          renderAsAllDay,
        }
      } else {
        return {
          start: {
            dateTime: sortedUserSelection.startLocal.toISO(),
          },
          startLocal: sortedUserSelection.startLocal,
          end: {
            dateTime: sortedUserSelection.endLocal.toISO(),
          },
          endLocal: sortedUserSelection.endLocal,
          isAllDayItem,
          renderAsAllDay,
        }
      }
    },
  },
)
export const useSelectedRegionForCreateForm = () =>
  useRecoilValue(selectedRegion_transformedForCreateForm)

// `selectedRegion_transformedForRenderer_inRange` is a selector family that provides an efficient
// way to observe changes to the selected region that fall within a given range.
// For example, a day on the timeline only needs to listen to changes to the
// selected region that fall on that day. It shouldn't re-render if the selected
// region changes on other days.
export const selectedRegion_transformedForRenderer_inRange = selectorFamily<
  SelectedRegion,
  [DateTime, DateTime]
>({
  key: "selectedRegion_transformedForRenderer_inRange",
  get:
    ([start, end]) =>
    ({ get }) => {
      const selectedRegion = get(selectedRegion_transformedForRenderer)
      if (!selectedRegion) {
        return
      }

      if (
        fallsInTimeRange(
          selectedRegion.startLocal,
          selectedRegion.endLocal,
          start,
          end,
        )
      ) {
        return selectedRegion
      }

      return undefined
    },

  // Required when storing Luxon DateTime objects in Recoil state.
  dangerouslyAllowMutability: true,
})
export const useSelectedRegionForRendererInRange = (
  range: [DateTime, DateTime],
): SelectedRegion =>
  useRecoilValue(selectedRegion_transformedForRenderer_inRange(range))

// `selectedRegion_selectionInProgress` is a selector that returns true if the
// user is currently selecting a region. This provides an efficient way to subscribe
// to the binary "selecting"/"not selecting" state without subscribing to granular changes
// in the selected range itself.
export const selectedRegion_selectionInProgress = selector<
  false | "hourLevel" | "allDay"
>({
  key: "selectedRegion_selectionInProgress",
  get: ({ get }) => {
    const renderAsAllDay = get(selectedRegion_shouldRenderAsAllDay)
    if (renderAsAllDay === undefined) {
      return false
    } else if (renderAsAllDay) {
      return "allDay"
    } else {
      return "hourLevel"
    }
  },

  // Required when storing Luxon DateTime objects in Recoil state.
  dangerouslyAllowMutability: true,
})
export const useSelectedRegionSelectionInProgress = ():
  | false
  | "hourLevel"
  | "allDay" => useRecoilValue(selectedRegion_selectionInProgress)
