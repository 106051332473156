import { useAnalytics } from "@daybridge/analytics"
import { IconId } from "@daybridge/client-api"
import { Tooltip, ButtonVariant, ButtonSize } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"
import { CopyLinkButton } from "../../../components/CopyLinkButton"

export interface CopyShareLinkButtonProps {
  itemId: string
  shareLink: string
  size: ButtonSize
  variant: ButtonVariant
  className?: string
}

export const CopyShareLinkButton: React.FC<CopyShareLinkButtonProps> =
  React.memo(
    ({
      itemId,
      shareLink,
      size,
      variant,
      className,
    }: CopyShareLinkButtonProps) => {
      const { track } = useAnalytics()

      return (
        <Tooltip content="Copy link" className="rounded-full">
          <CopyLinkButton
            link={shareLink}
            onPress={() => {
              track("event-link.copy", {
                itemId,
              })
            }}
            className={className}
            variant={variant}
            size={size}
          >
            {(copied) => <I type={copied ? IconId.Tick : IconId.Link} />}
          </CopyLinkButton>
        </Tooltip>
      )
    },
  )

CopyShareLinkButton.displayName = "CopyShareLinkButton"
