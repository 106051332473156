import { ResponseInfo } from "@daybridge/client-api"

// Filter out any responses that don't have a response or display name. This is
// to prevent weird UI states when a microservice is unavailable. Also sort them
// by response type so we can display them in a logical order.
export function filterResponses(responses: ResponseInfo[] | null | undefined) {
  return responses
    ? responses.filter(
        (response) => response.response && response.profile?.node.displayName,
      )
    : []
}
