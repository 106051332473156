/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from "react"
import Link from "next/link"
import {
  Calendar,
  Category,
  Color,
  IconId,
  Maybe,
  Place,
  ResponseInfo,
  ResponseType,
  Series,
  SharingInfo,
  CallInfo,
} from "@daybridge/client-api"
import {
  Tooltip,
  ButtonVariant,
  Button,
  ButtonSize,
  Map,
  Menu,
  MenuItem,
  Avatar,
  AlertDialog,
  TooltipWithKeyboardShortcut,
  ScrollArea,
  AvatarGroup,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { colorToClass } from "@daybridge/colors"
import { friendlyDuration } from "@daybridge/datetime"
import { DateTime } from "luxon"
import { useAnalytics } from "@daybridge/analytics"
import { useAuth } from "@daybridge/auth"
import sanitizeHtml from "sanitize-html"
import env from "../../../../env.config"
import { EditMode } from "../../types/editMode"
import { formatNumber } from "../../../sharing/utils/formatNumber"
import { getAvatarColor } from "../../../sharing/utils/getAvatarColor"
import { ResponseBadge } from "../../../sharing/shared/ResponseBadge"
import { responseTypeToString } from "../../../sharing/utils/responseTypeToString"
import { filterResponses } from "../../../sharing/utils/filterResponses"
import { groupResponses } from "../../../sharing/utils/groupResponses"
import { CopyShareLinkButton } from "../../../sharing/shared/CopyShareLinkButton"
import { getSharableLink } from "../../../sharing/utils/getSharableLink"
import { ResponseButton } from "../../../sharing/shared/ResponseButton"
import { useRespondToInvite } from "../../../sharing/page/hooks/useRespondToInvite"
import { SeriesMenu } from "../../../sharing/shared/SeriesMenu"
import { CopyLinkButton } from "../../../../components/CopyLinkButton"
import { useGetSafeExternalLink } from "../../../timeline/hooks/external-links/useGetSafeExternalLink"
import { CalendarCombobox } from "../edit-pane/header/CalendarCombobox"
import { useGroupedCalendars } from "../../../calendars/hooks/useGroupedCalendars"
import { ContextCard } from "./ContextCard"

export type BasicContextProps = {
  // `calendar` is the calendar this item belongs to
  calendar: Calendar

  // `id` is the ID of the item
  id: string

  // `start` is the start time of the item this card represents.
  start: DateTime

  // `end` is the end time of the item this card represents.
  end: DateTime

  // `isDayLevel` is whether the item this card represents is rendered
  // at the top of the timeline.
  isDayLevel?: boolean

  // `icon` is an icon to render next to the title
  icon?: IconId

  // `title` is the title of the card
  title: string

  // `description` is the description of the card
  description?: Maybe<string>

  // `color` is the color of the icon/card sidebar
  color?: Color

  // `category` is a category for this item
  category?: Category

  // `call` includes call link data, if applicable
  call?: Maybe<CallInfo> | null

  // `place` is a location tied to this block item, if applicable
  location?: Place | string

  // `series` is a series definition for recurring items.
  series?: Series

  // `sharing` includes if the item is shared and its sharing link
  sharing?: Maybe<SharingInfo> | undefined

  // `people` contains the response details if the item is shared
  people?: Maybe<ResponseInfo[]>

  // `onEdit` is a callback to be invoked when an item is shared
  onShare?: () => void

  // `onEdit` is a callback to be invoked when an item is edited
  onEdit?: () => void

  // `onDelete` is a callback to be invoked when an item is deleted
  onDelete?: (mode?: EditMode) => void

  // `onClose` is a callback to be invoked when the item is closed
  onClose: () => void
}

export const BasicContext: React.FC<BasicContextProps> = React.memo((props) => {
  const { user } = useAuth()
  const getSafeExternalLink = useGetSafeExternalLink()

  const safeLocationLink = useMemo(() => {
    return getSafeExternalLink(props.location)
  }, [getSafeExternalLink, props.location])

  const sanitizedDescription = useMemo(() => {
    return (
      props.description &&
      sanitizeHtml(props.description, {
        transformTags: {
          a: (tagName, attribs) => {
            return {
              tagName: "a",
              attribs: {
                // Always open links in a new tab, and rewrite links that are
                // not safelisted to pass through our `/out` page that displays
                // a redirection warning.
                target: "_blank",
                href: getSafeExternalLink(attribs.href) || "",
                rel: "noopener noreferrer",
              },
            }
          },
        },
        allowedTags: [
          "b",
          "i",
          "u",
          "em",
          "strong",
          "a",
          "br",
          "li",
          "ul",
          "ol",
        ],
        allowedAttributes: {
          a: ["href"],
        },
      })
    )
  }, [getSafeExternalLink, props.description])

  const { groups: calendarGroups } = useGroupedCalendars()
  const selectableCalendars = useMemo(() => {
    return calendarGroups
      .map((group) =>
        group.filter(
          (calendar) =>
            calendar.canCreate &&
            calendar.provider.id === props.calendar?.provider.id,
        ),
      )
      .filter((group) => group.length !== 0)
  }, [calendarGroups, props.calendar])

  const deleteButton = (
    <Button
      size={ButtonSize.IconAction}
      variant={ButtonVariant.ClearAlpha}
      onClick={
        props.series ? undefined : () => setAlertDialogMode("not-recurring")
      }
      disabled={!props.onDelete}
    >
      <I type={IconId.TrashEmpty} className="w-[14px] h-[14px]" />
    </Button>
  )

  // Wait for the animation to finish before rendering heavy parts of the
  // content, like the map, to prevent the sidebar from lagging.
  const [isAnimationDone, setIsAnimationDone] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimationDone(true)
    }, 350)
    return () => clearTimeout(timeout)
  }, [])

  // Analytics: track when an item modal gets opened
  const { track } = useAnalytics()
  useEffect(() => {
    track("item.view", {
      itemId: props.id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Prepare sharing details (if the item is shared).
  const MAX_PEOPLE = 6
  const allResponses = useMemo(
    () => filterResponses(props.people),
    [props.people],
  )
  const groupedResponses = useMemo(
    () => groupResponses(allResponses),
    [allResponses],
  )
  const myResponse = useMemo(
    () => allResponses.find((person) => person.profile?.id === user?.uid),
    [allResponses, user?.uid],
  )
  const sharingTitle = useMemo(
    () =>
      groupedResponses[ResponseType.Yes].length === 0
        ? "No one going yet"
        : `${formatNumber(
            groupedResponses[ResponseType.Yes].length,
            "person",
            "people",
          )} going`,
    [groupedResponses],
  )

  const [alertDialogMode, setAlertDialogMode] = useState<
    EditMode | "not-recurring" | false
  >(false)
  const alertContent = useMemo(() => {
    const maybeSharedText = props.sharing?.isPublic
      ? " The event link will be disabled, but people won't be notified."
      : ""
    const maybeSharedCalendarText = props.calendar.people
      ? ` It will be deleted for all ${props.calendar.people.length} people in this calendar.`
      : ""

    switch (alertDialogMode) {
      case EditMode.Future:
        return {
          title: `Delete "${props.title}"?`,
          description: `You're deleting everything after ${props.start.toLocaleString(
            { month: "long", day: "numeric" },
          )}.${maybeSharedText}`,
        }
      case EditMode.Series:
        return {
          title: `Delete "${props.title}"?`,
          description: `You're deleting the whole series.${maybeSharedText}`,
        }
      default:
        // Not recurring
        return {
          title: `Delete "${props.title}"?`,
          description: `You won't be able to restore it.${maybeSharedText}${maybeSharedCalendarText}`,
        }
    }
  }, [
    alertDialogMode,
    props.calendar.people,
    props.sharing?.isPublic,
    props.start,
    props.title,
  ])

  const shareLink = useMemo(() => {
    return getSharableLink(props.sharing?.path)
  }, [props.sharing?.path])
  const sharedBySomeoneElse = !props.onShare && props.sharing?.isPublic
  const isGoogleItem = props.calendar.provider.node.type === "google"
  const respond = useRespondToInvite(props.id)
  const hasMaybeOrNoResponses = useMemo(
    () =>
      groupedResponses[ResponseType.Maybe].length +
        groupedResponses[ResponseType.No].length >
      0,
    [groupedResponses],
  )

  const calendarSubtitle = useMemo(() => {
    if (props.calendar.people && props.calendar.people.length < 4) {
      const names = props.calendar.people.map(
        (p) => p.profile?.node.displayName || "User",
      )
      // Join names with "and" before the last one.
      const joinedNames = names
        .slice(0, -1)
        .join(", ")
        .concat(` and ${names[names.length - 1]}`)
      return `Shared with ${joinedNames}`
    } else if (props.calendar.people) {
      return `Shared with ${props.calendar.people.length} people`
    }
    return undefined
  }, [props.calendar.people])

  return (
    <div
      key={props.id}
      onMouseDown={(e) =>
        // Stop mouse clicks inside the context from
        // propagating to the parent so that the card this context
        // is attached to doesn't start moving around.
        e.stopPropagation()
      }
      className={`
        relative w-[384px]
        flex flex-col h-full
        text-high-contrast
        overflow-hidden
        focus:outline-none
        select-none
        ${props.color ? `content-${colorToClass(props.color)}` : ""}
      `}
    >
      <div className="absolute w-full h-[40rem] bg-gradient-to-b from-content-background via-surface"></div>
      {/* Top Controls */}
      <div
        className="
          absolute w-full z-20
          flex flex-row items-center
          rounded-t-2xl
          backdrop-filter backdrop-blur-lg
          space-x-2 py-3 px-4
        "
      >
        {!sharedBySomeoneElse && !isGoogleItem && !props.calendar.people && (
          <Tooltip
            content={
              !props.onShare
                ? "This item can't be shared"
                : props.sharing?.isPublic
                ? "Manage sharing"
                : ""
            }
          >
            {/* This outer span is necessary to allow tooltips to work on disabled buttons */}
            <span className={!props.onShare ? "cursor-not-allowed" : ""}>
              <Button
                size={
                  props.sharing?.isPublic
                    ? ButtonSize.IconAction
                    : ButtonSize.Medium
                }
                variant={ButtonVariant.ClearAlpha}
                disabled={!props.onShare}
                onPress={() => props.onShare && props.onShare()}
              >
                <I
                  type={IconId.Users}
                  className={!props.sharing?.isPublic ? "w-3 h-3 mr-2" : ""}
                />
                {!props.sharing?.isPublic && <span>Share</span>}
              </Button>
            </span>
          </Tooltip>
        )}
        {props.sharing?.isPublic && (
          <CopyShareLinkButton
            itemId={props.id}
            shareLink={shareLink}
            size={ButtonSize.IconAction}
            variant={ButtonVariant.ClearAlpha}
          />
        )}
        {sharedBySomeoneElse && myResponse?.response && (
          <Tooltip
            content={
              props.calendar.people
                ? "Events in shared calendars cannot be moved"
                : "Move to another calendar"
            }
          >
            <CalendarCombobox
              defaultSelectedKey={props.calendar.id}
              selectableCalendars={selectableCalendars}
              onSelect={(calendarId) => {
                void respond(
                  myResponse.response as ResponseType,
                  undefined,
                  undefined,
                  calendarId,
                )
              }}
            >
              <Button
                size={ButtonSize.IconAction}
                variant={ButtonVariant.ClearAlpha}
                disabled={!!props.calendar.people}
              >
                <I type={IconId.Calendar} className="w-4 h-4" />
                <span>{!props.sharing?.isPublic && "Share"}</span>
              </Button>
            </CalendarCombobox>
          </Tooltip>
        )}
        {!sharedBySomeoneElse && (
          <>
            <Tooltip
              content={!props.onEdit ? "This item can't be edited" : "Edit"}
            >
              {/* This outer span is necessary to allow tooltips to work on disabled buttons */}
              <span className={!props.onEdit ? "cursor-not-allowed" : ""}>
                <Button
                  size={ButtonSize.IconAction}
                  variant={ButtonVariant.ClearAlpha}
                  disabled={!props.onEdit}
                  onPress={() => props.onEdit && props.onEdit()}
                >
                  <I type={IconId.Edit} />
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              content={
                !props.onDelete ? "This item can't be deleted" : "Delete"
              }
            >
              {/* This outer span is necessary to allow tooltips to work on disabled buttons */}
              <span className={!props.onDelete ? "cursor-not-allowed" : ""}>
                {props.series ? (
                  <Menu
                    side="bottom"
                    align="end"
                    content={
                      props.onDelete ? (
                        <>
                          <MenuItem
                            onSelect={() =>
                              setAlertDialogMode(EditMode.Instance)
                            }
                            icon={IconId.Copy}
                          >
                            Just this one
                          </MenuItem>
                          <MenuItem
                            onSelect={() => setAlertDialogMode(EditMode.Future)}
                            icon={IconId.ArrowRight}
                          >
                            This and everything after
                          </MenuItem>
                          <MenuItem
                            onSelect={() => setAlertDialogMode(EditMode.Series)}
                            icon={IconId.Infinity}
                          >
                            Everything
                          </MenuItem>
                          <MenuItem icon={IconId.Cross}>Cancel</MenuItem>
                        </>
                      ) : undefined
                    }
                  >
                    {deleteButton}
                  </Menu>
                ) : (
                  deleteButton
                )}
              </span>
            </Tooltip>
          </>
        )}
        <div className="flex-1" />
        <TooltipWithKeyboardShortcut content="Close" shortcut="Esc">
          <Button
            variant={ButtonVariant.ClearAlpha}
            size={ButtonSize.IconAction}
            onPress={() => props.onClose()}
          >
            <I type={IconId.Cross} />
          </Button>
        </TooltipWithKeyboardShortcut>
      </div>
      {/* Padded Area */}
      <div className="flex-1 overflow-hidden">
        <ScrollArea className="relative h-full pb-4">
          <div className="h-full flex flex-col">
            {/* Icon */}
            <div className="w-full mt-24 flex items-center justify-center z-10">
              <div
                className={`
                  w-12 h-12 -mb-6 p-4
                  rounded-full
                  bg-content-primary
                  text-icon-on-color
                `}
              >
                <I type={props.icon || IconId.CalendarOutline} />
              </div>
            </div>
            <ContextCard className="pt-10 flex flex-col items-center justify-center space-y-5">
              <div className="flex flex-col items-center justify-center">
                <h3 className="block text-lg text-center font-semibold mb-1">
                  {props.title}
                </h3>
                <div className="text-base font-semibold text-low-contrast">
                  {props.start.hasSame(props.end, "day") ||
                  (props.isDayLevel &&
                    props.start.equals(props.start.startOf("day")) &&
                    props.start.equals(props.end.minus({ days: 1 }))) ? (
                    <div className="flex flex-wrap items-center flex-row">
                      <span className="flex-shrink-0 mr-1">
                        {props.start.toLocaleString({
                          weekday: "short",
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>

                      {!props.isDayLevel && (
                        <>
                          <span className="flex-shrink-0 mx-2">&#8226;</span>
                          <span className="flex-shrink-0">
                            {props.start.toLocaleString(DateTime.TIME_SIMPLE)}
                          </span>
                          <I
                            type={IconId.ChevronRight}
                            className="flex-shrink-0 w-2 h-2 mx-1.5"
                          />
                          <span className="flex-shrink-0 mr-1">
                            {props.end.toLocaleString(DateTime.TIME_SIMPLE)}
                          </span>
                          <span className="flex-shrink-0 text-low-contrast font-medium">
                            ({friendlyDuration(props.start, props.end)})
                          </span>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-wrap items-center flex-row">
                      <span className="flex-shrink-0 mr-1">
                        {props.start.toLocaleString({
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                      {!(
                        props.isDayLevel &&
                        props.start.equals(props.start.startOf("day"))
                      ) && (
                        <>
                          <span className="flex-shrink-0 mx-2">&#8226;</span>
                          <span className="flex-shrink-0">
                            {props.start.toLocaleString(DateTime.TIME_SIMPLE)}
                          </span>
                        </>
                      )}
                      <I
                        type={IconId.ChevronRight}
                        className="block flex-shrink-0 w-2 h-2 mx-1.5"
                      />
                      {!(
                        props.isDayLevel &&
                        props.end.equals(props.end.startOf("day"))
                      ) && (
                        <>
                          <span className="flex-shrink-0 mr-1">
                            {props.end.toLocaleString(DateTime.TIME_SIMPLE)}
                          </span>
                          <span className="flex-shrink-0 mr-2">&#8226;</span>
                        </>
                      )}
                      <span className="flex-shrink-0">
                        {(props.isDayLevel &&
                        props.end.equals(props.end.startOf("day"))
                          ? props.end.minus({ days: 1 })
                          : props.end
                        ).toLocaleString({
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`w-full flex flex-row items-center space-x-2 px-3 pb-3 content-${colorToClass(
                  props.category?.color || Color.Stone,
                )}`}
              >
                {props.category && (
                  <div className="truncate text-center flex-shrink-0 px-5 py-2 bg-content-background rounded-full text-content-body text-base">
                    {props.category.name}
                  </div>
                )}
                {props.calendar && (
                  <Tooltip
                    content={
                      calendarSubtitle && (
                        <div>
                          <div className="text-sm font-semibold text-high-contrast">
                            {props.calendar.name}
                          </div>
                          <div className="text-sm text-low-contrast">
                            {calendarSubtitle}
                          </div>
                        </div>
                      )
                    }
                  >
                    <div
                      className={`
                      overflow-hidden text-center flex items-center justify-center flex-1 px-5 py-2 bg-tint rounded-full text-high-contrast text-base
                      ${props.calendar.people ? "pl-3" : ""}
                      `}
                    >
                      {props.calendar.people && (
                        <AvatarGroup
                          className="mr-2"
                          profiles={props.calendar.people.map(
                            (p) => p.profile?.node,
                          )}
                        />
                      )}
                      <div className="truncate">{props.calendar.name}</div>
                    </div>
                  </Tooltip>
                )}
              </div>
              {props.series?.description && (
                <div className="w-full flex flex-row items-center">
                  <div className="flex-shrink-0 rounded-full p-3.5 mr-3 w-10 h-10 text-content-body bg-content-background">
                    <I type={IconId.Repeat} />
                  </div>
                  <div className="text-base text-high-contrast flex-1 whitespace-pre-line">
                    {props.series?.description}
                  </div>
                </div>
              )}
            </ContextCard>

            {props.call && (
              <ContextCard
                icon={props.call.provider?.node.icon || IconId.Camcorder}
                className="flex flex-row flex-shrink-0 space-x-2 overflow-hidden"
              >
                <div className="text-base font-semibold truncate">
                  {props.call.provider?.node.name || "Call"}
                </div>
                <div className="text-sm text-low-contrast truncate">
                  {props.call.uri}
                </div>
                <div className="grid grid-cols-2 gap-2 mt-3 pb-1">
                  <CopyLinkButton
                    link={props.call.uri}
                    variant={ButtonVariant.Outline}
                    size={ButtonSize.Base}
                  >
                    Copy
                  </CopyLinkButton>
                  <a
                    href={props.call.uri}
                    target="_blank"
                    rel="noreferrer"
                    className="
                        block rounded-full px-px
                        transition duration-300 ease-in-out
                        focus:outline-none
                        focus-visible:ring-2
                        ring-offset-2 ring-focus-ring
                        ring-offset-surface
                      "
                  >
                    <Button
                      block
                      variant={ButtonVariant.Outline}
                      size={ButtonSize.Base}
                    >
                      Join Call
                    </Button>
                  </a>
                </div>
              </ContextCard>
            )}
            {props.people && props.people.length > 0 && (
              <ContextCard icon={IconId.Users}>
                <div className="text-base font-semibold truncate">
                  {sharingTitle}
                </div>
                {hasMaybeOrNoResponses && (
                  <div className="text-sm text-low-contrast truncate mb-3">
                    {groupedResponses[ResponseType.Maybe].length} maybe,{" "}
                    {groupedResponses[ResponseType.No].length} can&apos;t go
                  </div>
                )}
                <div className="flex space-x-2 mt-2 pb-1">
                  {allResponses.slice(0, MAX_PEOPLE).map((person, index) => (
                    <Tooltip
                      key={person.profile?.id}
                      content={
                        <div className="text-center">
                          <div className="text-high-contrast font-semibold">
                            {person.profile?.node.displayName || ""}
                          </div>
                          <div className="text-low-contrast">
                            {responseTypeToString(person.response)}
                          </div>
                        </div>
                      }
                    >
                      <div className="w-8 h-8 relative">
                        <Avatar
                          url={person.profile?.node.avatar || undefined}
                          overrideBg
                          defaultInitials={
                            person.profile?.node.displayName?.[0] || undefined
                          }
                          className={`text-base font-semibold ${getAvatarColor(
                            index,
                          )}`}
                        />
                        <ResponseBadge type={person.response as ResponseType} />
                      </div>
                    </Tooltip>
                  ))}
                  {allResponses.length > MAX_PEOPLE && (
                    <Tooltip
                      content={
                        <div className="divide-y divide-tint">
                          {allResponses.slice(MAX_PEOPLE).map((person) => (
                            <div className="py-2 pr-2" key={person.profile?.id}>
                              <span className="text-high-contrast">
                                {person.profile?.node.displayName}
                              </span>
                              <span className="text-low-contrast">
                                {" "}
                                &middot; {responseTypeToString(person.response)}
                              </span>
                            </div>
                          ))}
                        </div>
                      }
                    >
                      <div
                        className="
                            w-8 h-8 
                            bg-background rounded-full
                            flex items-center justify-center
                            text-semibold text-sm text-low-contrast
                          "
                      >
                        +{allResponses.length - MAX_PEOPLE}
                      </div>
                    </Tooltip>
                  )}
                </div>
              </ContextCard>
            )}
            {(props.sharing?.isPublic || props.calendar.people) && (
              <ContextCard
                icon={IconId.EnvelopeOpen}
                className="flex flex-row space-x-2"
              >
                <div className="text-base font-semibold">Are you going?</div>
                <div className="grid grid-cols-3 gap-2 mt-2">
                  <SeriesMenu
                    align="start"
                    enabled={!!props.series}
                    respond={(mode) =>
                      void respond(
                        ResponseType.Yes,
                        mode === EditMode.Series ? props.series?.id : undefined,
                      )
                    }
                  >
                    <ResponseButton
                      variant={
                        myResponse?.response === ResponseType.Yes
                          ? ButtonVariant.Success
                          : ButtonVariant.SuccessInverted
                      }
                      onPress={
                        props.series
                          ? undefined
                          : () => void respond(ResponseType.Yes)
                      }
                      icon={IconId.Tick}
                    >
                      Yes
                    </ResponseButton>
                  </SeriesMenu>
                  <SeriesMenu
                    enabled={!!props.series}
                    respond={(mode) =>
                      void respond(
                        ResponseType.Maybe,
                        mode === EditMode.Series ? props.series?.id : undefined,
                      )
                    }
                  >
                    <ResponseButton
                      variant={
                        myResponse?.response === ResponseType.Maybe
                          ? ButtonVariant.Warning
                          : ButtonVariant.WarningInverted
                      }
                      onPress={
                        props.series
                          ? undefined
                          : () => void respond(ResponseType.Maybe)
                      }
                      icon={IconId.QuestionMark}
                    >
                      Maybe
                    </ResponseButton>
                  </SeriesMenu>
                  <SeriesMenu
                    align="end"
                    enabled={!!props.series}
                    respond={(mode) =>
                      void respond(
                        ResponseType.No,
                        mode === EditMode.Series ? props.series?.id : undefined,
                      )
                    }
                  >
                    <ResponseButton
                      variant={
                        myResponse?.response === ResponseType.No
                          ? ButtonVariant.Error
                          : ButtonVariant.ErrorInverted
                      }
                      onPress={
                        props.series
                          ? undefined
                          : () => void respond(ResponseType.No)
                      }
                      icon={IconId.Cross}
                    >
                      No
                    </ResponseButton>
                  </SeriesMenu>
                </div>
              </ContextCard>
            )}
            {sanitizedDescription && (
              <ContextCard
                icon={IconId.Information}
                className="flex flex-row space-x-2"
              >
                <div
                  className="text-sm pt-0.5 leading-normal break-words select-text whitespace-pre-wrap format-inner-html"
                  dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                />
              </ContextCard>
            )}
            {props.location && typeof props.location === "string" && (
              <ContextCard
                icon={IconId.MapPin}
                className="flex flex-row space-x-2"
              >
                {safeLocationLink ? (
                  <Link
                    href={safeLocationLink}
                    target="_blank"
                    className="truncate"
                    rel="noopener noreferrer"
                  >
                    {props.location}
                  </Link>
                ) : (
                  <div className="text-base font-semibold truncate">
                    {props.location}
                  </div>
                )}
              </ContextCard>
            )}
            {props.location &&
              typeof props.location !== "string" &&
              props.location.viewportBottomLeft &&
              props.location.viewportTopRight && (
                <>
                  <ContextCard className="-mb-6 z-20" icon={IconId.MapPin}>
                    <Link
                      target="_blank"
                      className="flex flex-col"
                      href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${props.location.id}`}
                    >
                      <div className="text-base font-semibold truncate">
                        {props.location.name}
                      </div>

                      <div className="text-sm text-low-contrast truncate">
                        {props.location.description}
                      </div>
                    </Link>
                  </ContextCard>

                  {isAnimationDone &&
                    props.location.coordinates &&
                    env.NEXT_PUBLIC_MAPBOX_PUBLIC_ACCESS_TOKEN && (
                      <div className="mx-4 flex-1 flex-shrink-0 relative min-h-[10rem]">
                        <Map
                          width="100%"
                          publicAccessToken={
                            env.NEXT_PUBLIC_MAPBOX_PUBLIC_ACCESS_TOKEN
                          }
                          latitude={props.location.coordinates.latitude}
                          longitude={props.location.coordinates.longitude}
                          bottomLeft={[
                            props.location.viewportBottomLeft?.longitude,
                            props.location.viewportBottomLeft?.latitude,
                          ]}
                          topRight={[
                            props.location.viewportTopRight?.longitude,
                            props.location.viewportTopRight?.latitude,
                          ]}
                          pitch={60}
                          markerSize={5}
                          className="rounded-xl overflow-hidden"
                        />
                      </div>
                    )}
                </>
              )}
          </div>
        </ScrollArea>
      </div>

      <AlertDialog
        open={!!alertDialogMode}
        onOpenChange={() => setAlertDialogMode(false)}
        onActionPress={() =>
          props.onDelete?.(
            alertDialogMode === "not-recurring"
              ? undefined
              : (alertDialogMode as EditMode),
          )
        }
        icon={IconId.TrashFull}
        title={alertContent.title}
        description={alertContent.description}
        actionButtonVariant={ButtonVariant.Error}
        actionButtonText={"Delete item"}
      />
    </div>
  )
})
BasicContext.displayName = "BasicContext"
