import { SelectedCalendarKeyValuePair } from "@daybridge/client-api"
import { useCallback, useMemo } from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"

// `MapCalendars` generates a map that is easier to work with from the
// key/value pairs returned by the backend.
export const keyValuePairToCalendarMap = (
  selectedCalendars?: SelectedCalendarKeyValuePair[] | null,
): {
  [calendarId: string]: boolean
} => {
  if (!selectedCalendars) return {}
  return selectedCalendars.reduce(
    (acc, calendar) => {
      acc[calendar.key] = calendar.value
      return acc
    },
    {} as {
      [calendarId: string]: boolean
    },
  )
}

export const useCalendarSelected = () => {
  const [selectedCalendars] = usePreference("selectedCalendars")
  const selectedCalendarMapping = useMemo(
    () => keyValuePairToCalendarMap(selectedCalendars),
    [selectedCalendars],
  )

  return useCallback(
    (calendarId: string) => {
      // By default, calendars should be selected.
      // So if there is no entry in this mapping for a calendar, return true.
      const calendarSelected = selectedCalendarMapping[calendarId]
      return calendarSelected === undefined ? true : calendarSelected
    },
    [selectedCalendarMapping],
  )
}
