import { DateTime } from "luxon"
import React, { useMemo } from "react"
import { AgendaDayAddButtons } from "./agenda/AgendaDayAddButtons"

interface DayHeaderProps {
  date: DateTime
  columnWidth?: number
  isToday?: boolean
}

/**
 * `dayHeader` renders at the top of each day on the timeline.
 * It displays the day of the week and the date. If the day is
 * the current day, the date will be highlighted with a purple circle.
 */
export const DayHeader: React.FC<DayHeaderProps> = React.memo(
  (props: DayHeaderProps) => {
    const displayAddButtonsSubmenu: boolean = useMemo(() => {
      // Calculate whether the "add event" button in the day header should
      // expand to a submenu (more space) or go straight to the create form
      // (less space).
      if (props.columnWidth) {
        // We need about 48 pixels to display the date - the add buttons are
        // about 160 pixels, so we can display them if the remaining space is
        // bigger than the space the add buttons take up + 16px margin.
        return props.columnWidth - 48 > 160 + 16
      }
      return false
    }, [props.columnWidth])

    return (
      <div
        className={`
          h-full relative
          flex-1
          flex flex-col lg:flex-row justify-center
          pl-4 pr-2 lg:justify-start
          ${props.isToday ? "text-primary-body" : "text-low-contrast"}
          truncate
          group
        `}
      >
        <div
          className={`
            lg:mr-2
            flex justify-center
            truncate
            text-sm lg:text-base font-semibold capitalize
          `}
        >
          {props.date.toLocaleString({ weekday: "short" })}
        </div>
        <div className="text-base font-bold">
          {props.date.toLocaleString({ day: "2-digit" })}
        </div>
        {(props.columnWidth || 0) > 110 && (
          <AgendaDayAddButtons
            day={props.date}
            displaySubmenu={displayAddButtonsSubmenu}
            className="ml-auto hidden lg:block -mt-0.5"
          />
        )}
        {props.isToday && (
          <div className="absolute bottom-0 w-5 h-1.5 rounded-t-xl bg-primary" />
        )}
      </div>
    )
  },
)
DayHeader.displayName = "DayHeader"
