import {
  CreateCalendarMutation,
  CreateCalendarMutationVariables,
  useCreateCalendarMutation,
  useCalendarsQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { fromPromise } from "@daybridge/toast"
import { useCallback } from "react"
import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"

export const useCreateCalendar = (
  options?: UseMutationOptions<
    CreateCalendarMutation,
    string | GraphQLError,
    CreateCalendarMutationVariables,
    unknown
  >,
) => {
  const queryClient = useQueryClient()

  const { mutateAsync } = useCreateCalendarMutation({
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(
        useCalendarsQuery.getKey({ args: {} }),
      )
      options?.onSuccess && (await options.onSuccess(data, variables, context))
    },
  })

  return useCallback(
    (input: CreateCalendarMutationVariables) => {
      const resultPromise = mutateAsync(input)

      void fromPromise(resultPromise, {
        loadingMessage: "Creating calendar...",
        successTitle: `${input.input.create.name || "Calendar"} saved`,
      })
    },
    [mutateAsync],
  )
}
