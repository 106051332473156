import { useAnalytics } from "@daybridge/analytics"
import { IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  FormInput,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React, { useState } from "react"
import { CopyShareLinkButton } from "../shared/CopyShareLinkButton"
import { ShareItemHeader } from "./components/ShareItemHeader"

interface ShareItemFormWithLinkProps {
  shareLink: string
  itemId: string
  onRemove: () => void
  onClose: (value: boolean) => void
}

export const ShareItemFormWithLink: React.FC<ShareItemFormWithLinkProps> =
  React.memo((props: ShareItemFormWithLinkProps) => {
    const { track } = useAnalytics()

    // `confirmTo` will likely include "reset" later, hence the enum.
    const [confirmTo, setConfirmTo] = useState<"remove" | undefined>()

    return (
      <>
        <ShareItemHeader onClose={props.onClose} />
        <div className="p-6 space-y-6">
          <div className="space-y-4">
            <p className="text-base text-low-contrast">
              Use this link to share this event with others, even if they
              don&apos;t have Daybridge.
            </p>
            <div className="flex items-center h-11 space-x-2">
              <FormInput className="w-full" readOnly value={props.shareLink} />
              <CopyShareLinkButton
                itemId={props.itemId}
                shareLink={props.shareLink}
                variant={ButtonVariant.Outline}
                size={ButtonSize.IconAction}
                className="flex-shrink-0"
              />
            </div>
          </div>
          {!confirmTo && (
            <Button
              block
              size={ButtonSize.Medium}
              variant={ButtonVariant.ErrorInverted}
              onPress={() => {
                track("event-link-disable.start", {
                  itemId: props.itemId,
                })
                setConfirmTo("remove")
              }}
            >
              <span className="w-3.5 h-3.5 mr-2">
                <I type={IconId.PadlockLocked} />
              </span>
              Disable Sharing
            </Button>
          )}

          {confirmTo === "remove" && (
            <div className="space-y-5 p-4 bg-error-background rounded-xl">
              <div>
                <div className="text-base leading-normal text-error-body font-semibold">
                  Are you sure you want to disable sharing?
                </div>
                <div className="text-base leading-normal text-low-contrast">
                  The sharable link will no longer work.
                </div>
              </div>
              <div className="space-x-3 flex justify-end">
                <Button
                  size={ButtonSize.Base}
                  variant={ButtonVariant.Clear}
                  onPress={() => setConfirmTo(undefined)}
                >
                  Cancel
                </Button>
                <Button
                  size={ButtonSize.Base}
                  variant={ButtonVariant.Error}
                  onPress={() => props.onRemove()}
                >
                  Disable
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    )
  })

ShareItemFormWithLink.displayName = "ShareItemFormWithLink"
