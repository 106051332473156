import { useCallback } from "react"
import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  useItemsQuery,
  DeleteItemMutationVariables,
  DeleteItemMutation,
  useDeleteItemMutation,
  DeleteItemInput,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { fromPromise } from "@daybridge/toast"
import { useAnalytics } from "@daybridge/analytics"
import { useResetTimeline } from "../../timeline/hooks/useResetTimeline"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"
import { EditMode } from "../types/editMode"
import { useOpenItem } from "../state/opening"

export const useDeleteItem = (
  options?: UseMutationOptions<
    DeleteItemMutation,
    string | GraphQLError,
    DeleteItemMutationVariables,
    unknown
  >,
) => {
  const reset = useResetTimeline()
  const queryClient = useQueryClient()
  const { track } = useAnalytics()
  const [openItem, setOpenItem] = useOpenItem()
  const { mutateAsync } = useDeleteItemMutation({
    onSuccess: async () => {
      reset()
      // Close the sidebar if it's currently open. Note that we shouldn't use
      // the mutation data to compare the deleted item with the item that's open
      // in the sidebar - we currently just always close the sidebar.
      if (openItem) setOpenItem(undefined)
      await queryClient.invalidateQueries(useItemsQuery.getKey({ args: {} }))
    },
    ...options,
  })

  return useCallback(
    (item: ItemWithResolvedTimes, editMode?: EditMode) => {
      let input: DeleteItemInput
      switch (editMode) {
        case EditMode.Instance:
          if (!item.series) throw new Error("Item is not part of a series")
          input = { id: item.id }
          break
        case EditMode.Future:
          if (!item.series) throw new Error("Item is not part of a series")
          input = { id: item.id, from: item.id }
          break
        case EditMode.Series:
          if (!item.series) throw new Error("Item is not part of a series")
          input = { id: item.id, series: item.series.id }
          break
        default:
          // Not deleting a recurring item
          if (editMode) {
            throw new Error("Mode should not be set when editing a single item")
          }
          input = { id: item.id }
          break
      }
      const resultPromise = mutateAsync({
        input,
      })
      void fromPromise(resultPromise, {
        loadingMessage: `Deleting ${item.title || "Item"}...`,
        successTitle: `${item.title || "Item"} deleted`,
      })
      // Analytics: the user confirms the action to delete an item
      track("item-delete.confirm", {
        itemId: item.id,
        recurring: item.series ? "true" : undefined,
        itemsUpdated: item.series ? editMode : undefined,
      })
    },
    [track, mutateAsync],
  )
}
