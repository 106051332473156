import { expandDaysInRange } from "@daybridge/datetime"
import { DateTime } from "luxon"
import { useCallback, useMemo } from "react"
import {
  RENDERABLE_AREA_LEFT_GUTTER,
  RENDERABLE_AREA_RIGHT_GUTTER,
} from "../../components/planning/PlanningDay"
import { ItemOrVirtualItem } from "../../../items/types/itemOrVirtualItem"
import { RenderItemOrVirtualItem } from "../../../items/components/render/RenderItemOrVirtualItem"
import { useDayLevelPositions } from "./useDayLevelPositions"
import {
  ColumnAllocations,
  useDayLevelColumnAllocator,
} from "./useDayLevelColumnAllocator"
import { useDayLevelRowAllocator } from "./useDayLevelRowAllocator"

interface RenderResult {
  renderables: React.ReactElement[]
  numRowsRendered: number
  totalNumRows: number
}

// A `DayLevelRenderFunction` takes a list of Items and returns a set
// of React elements that should be rendered on the canvas.
export type DayLevelRenderFunction = (
  items: ItemOrVirtualItem[],
) => RenderResult

/**
 * `useDayLevelRenderer` returns a function that can be used to render
 * items on a day level view.
 * @param options The options for the renderer.
 */
export const useDayLevelRenderer = (
  // `startLocal` is the first date of the period to render over
  startLocal: DateTime,

  // `endLocal` is the last date of the period to render over
  endLocal: DateTime,

  // `renderableAreaWidth` is the total width we have available to render across.
  renderableAreaWidth: number,

  // `initialGutterWidth` is the width of the gutter on the left.
  initialGutterWidth: number,

  // `rowHeight` is the height of each row.
  rowHeight: number,

  // `rowPadding` is the amount of space to leave between rows.
  rowPadding: number,

  // `showOnlyFirst` limits the number of rows that are shown, allowing
  // the user to collapse the day-level items section to keep it out of the way.
  showOnlyFirst?: number,
): DayLevelRenderFunction => {
  // Calculate the number of days we need to render
  // Create a DateTime object for each one corresponding to the
  // start of the day that needs to be rendered.
  const days: DateTime[] = useMemo(
    () => expandDaysInRange(startLocal, endLocal),
    [startLocal, endLocal],
  )

  // Function to compute which columns an item should occupy
  const allocateColumnsFor = useDayLevelColumnAllocator(startLocal, endLocal)

  // Function to stack things vertically efficiently
  const allocateRows = useDayLevelRowAllocator()

  // Function to convert columns into actual pixel locations
  const {
    percentageXOffsetFor,
    percentageXOffsetEndFor,
    yOffsetFor,
    widthFor,
  } = useDayLevelPositions(
    initialGutterWidth,
    RENDERABLE_AREA_LEFT_GUTTER,
    RENDERABLE_AREA_RIGHT_GUTTER,
    renderableAreaWidth,
    rowHeight,
    rowPadding,
    days.length,
  )

  return useCallback(
    (items: ItemOrVirtualItem[]): RenderResult => {
      // Allocate columns to each item
      const columnAllocations: ColumnAllocations = {}
      items.forEach((item) => {
        const allocation = allocateColumnsFor(item)
        if (allocation) {
          columnAllocations[item.id] = allocation
        }
      })

      const { allocations: rowAllocations, numRows } =
        allocateRows(columnAllocations)

      const renderables = items
        .map((item): React.ReactElement | undefined => {
          const columnAllocation = columnAllocations[item.id]
          const rowAllocation = rowAllocations[item.id]

          if (columnAllocation === undefined || rowAllocation === undefined) {
            // This item hasn't been assigned columns or a row,
            // so it can't be rendered.
            return undefined
          }

          // Hide rows if the user has collapsed the day-level items section
          if (showOnlyFirst !== undefined && rowAllocation >= showOnlyFirst) {
            return undefined
          }

          // Compute positions and widths
          const percentageXOffset = percentageXOffsetFor(
            columnAllocation.startColumn,
          )
          const percentageXOffsetEnd = percentageXOffsetEndFor(
            columnAllocation.endColumn,
          )
          const yOffset = yOffsetFor(rowAllocation)
          const width = widthFor(
            columnAllocation.startColumn,
            columnAllocation.endColumn,
          )

          // Render the item
          return (
            <RenderItemOrVirtualItem
              key={item.id}
              isAgenda={false}
              dayLevel={true}
              item={item}
              renderProps={{
                fixedHeight: 32,
                width,
                percentageXOffset,
                percentageXOffsetEnd,
                fixedYOffset: yOffset,
                zIndex:
                  item.__typename === "VirtualItem" &&
                  item.excludeFromConflictResolution
                    ? 10000
                    : 0,
                alreadyStarted: columnAllocation.alreadyStarted,
                continues: columnAllocation.continues,
              }}
            />
          )
        })
        // Remove any `undefined` results
        .filter((item): item is React.ReactElement => !!item)

      return {
        renderables,
        numRowsRendered: showOnlyFirst
          ? Math.min(numRows, showOnlyFirst)
          : numRows,
        totalNumRows: numRows,
      }
    },
    [
      allocateRows,
      showOnlyFirst,
      allocateColumnsFor,
      percentageXOffsetFor,
      percentageXOffsetEndFor,
      yOffsetFor,
      widthFor,
    ],
  )
}
