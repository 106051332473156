import { Color, IconId, useIntegrationsQuery } from "@daybridge/client-api"
import {
  CollapsibleSection,
  AlertDialogImperativeHandle,
  Menu,
  MenuItem,
  Button,
  ButtonSize,
  ButtonVariant,
  AlertDialog,
  Modal,
  SidebarTabContent,
  ScrollArea,
  ColorDot,
  MenuSeparator,
  ColorPicker,
  SubMenu,
  IconPicker,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { colorToClass } from "@daybridge/colors"
import { useRouter } from "next/router"
import React, { createRef, useCallback, useMemo } from "react"
import { useIconSets } from "../../../../hooks/useIconSets"
import { CreateCalendarForm } from "../../../calendars/components/creation/CreateCalendarForm"
import { useDeleteCalendar } from "../../../calendars/hooks/useDeleteCalendar"
import { useEditCalendar } from "../../../calendars/hooks/useEditCalendar"
import { useGroupedCalendars } from "../../../calendars/hooks/useGroupedCalendars"
import { useEditCalendarId } from "../../../calendars/state/editing"
import { useShareCalendarId } from "../../../calendars/state/sharing"
import { usePatchCalendarIntegration } from "../../../integrations/hooks/calendar-integrations/usePatchCalendarFromIntegration"
import { useItemSchema } from "../../../items/hooks/useItemSchema"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { useCalendarSelected } from "../../hooks/selected-calendars/useCalendarSelected"
import { useSetCalendarSelected } from "../../hooks/selected-calendars/useSetCalendarSelected"

const SidebarCalendarListFn = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { groups } = useGroupedCalendars()
    const calendars = groups.flatMap((group) => group)
    const router = useRouter()
    const iconSets = useIconSets()
    const { data: schema } = useItemSchema()

    const calendarSelected = useCalendarSelected()
    const setCalendarSelected = useSetCalendarSelected()
    const [, setCalendarSelectedBatch] = usePreference("selectedCalendars")
    const [defaultCalendarId, setDefaultCalendar] =
      usePreference("defaultCalendar")
    const [, setEditCalendarId] = useEditCalendarId()
    const [, setShareCalendarId] = useShareCalendarId()
    const editCalendar = useEditCalendar()
    const deleteCalendar = useDeleteCalendar()
    const patch = usePatchCalendarIntegration()
    const integrations = useIntegrationsQuery()

    const getIntegration = useCallback(
      (calendarId: string) => {
        return integrations.data?.integrations?.edges.find((integration) =>
          integration.node.calendars?.map((x) => x.id).includes(calendarId),
        )
      },
      [integrations.data?.integrations?.edges],
    )

    const getIntegrationCalendar = useCallback(
      (calendarId: string) => {
        const integration = getIntegration(calendarId)
        return integration?.node.calendars?.find((x) => x.id === calendarId)
      },
      [getIntegration],
    )

    const calendarSelectedValues = useMemo(
      () =>
        Object.fromEntries(
          calendars?.map((calendar) => [
            calendar.id,
            calendarSelected(calendar.id),
          ]) || [],
        ),
      [calendars, calendarSelected],
    )

    return (
      <SidebarTabContent
        title="Calendars"
        ref={ref}
        value="calendars"
        buttons={
          <Modal
            content={({ onClose }) => <CreateCalendarForm onClose={onClose} />}
            className="w-full max-w-md"
          >
            <Button
              variant={ButtonVariant.Clear}
              size={ButtonSize.IconAction}
              className="flex-shrink-0"
            >
              <I type={IconId.Plus} />
            </Button>
          </Modal>
        }
      >
        <div className="flex flex-col h-full">
          <ScrollArea className="w-full overflow-hidden flex-1 truncate min-w-0">
            {groups.map((group) => (
              <CollapsibleSection
                key={group[0].provider.node.id}
                title={
                  <span className="px-3">
                    {group[0].provider.node.displayName}
                  </span>
                }
                subtitle={
                  <span className="px-3">
                    {group[0].provider.node.username}
                  </span>
                }
                className="px-3 py-4 w-64 border-b border-tint"
                defaultOpen={true}
              >
                {group.map((calendar) => {
                  const deleteDialogRef = createRef<
                    HTMLButtonElement & AlertDialogImperativeHandle
                  >()

                  return (
                    <div
                      className="flex flex-row space-x-0.5 mb-0.5"
                      key={calendar.id}
                    >
                      <Button
                        block
                        variant={ButtonVariant.Headless}
                        size={ButtonSize.Custom}
                        className="peer flex flex-row overflow-hidden justify-start p-1.5 rounded-full ring-inset hover:bg-background"
                        onPress={() =>
                          void setCalendarSelected(
                            calendar.id,
                            !calendarSelectedValues[calendar.id],
                          )
                        }
                      >
                        <ColorDot
                          isDouble={!!calendar.people}
                          className={`
                            ${calendar.people ? "w-4 h-4 m-0.5" : "w-3 h-3 m-1"}
                            flex-shrink-0
                            transition-none
                            ${
                              calendarSelectedValues[calendar.id]
                                ? ""
                                : "opacity-50"
                            }
                            content-${colorToClass(
                              calendar.color || Color.Stone,
                            )}
                          `}
                        />
                        <div
                          className={`
                          text-left ml-2 text-base flex-1 truncate text-high-contrast
                          ${
                            calendarSelectedValues[calendar.id]
                              ? ""
                              : "opacity-50"
                          }
                        `}
                        >
                          {calendar.name || "Unnamed calendar"}
                        </div>
                      </Button>
                      {(calendar.canEdit || calendar.canDelete) && (
                        <Menu
                          side="right"
                          align="start"
                          content={({ onClose }) => (
                            <>
                              <MenuItem
                                icon={IconId.Eye}
                                onSelect={() => {
                                  void setCalendarSelectedBatch(
                                    Object.keys(calendarSelectedValues).map(
                                      (id) => ({
                                        key: id,
                                        value: id === calendar.id,
                                      }),
                                    ),
                                  )
                                }}
                              >
                                Focus
                              </MenuItem>
                              {calendar.canCreate &&
                                defaultCalendarId !== calendar.id && (
                                  <MenuItem
                                    icon={IconId.Home}
                                    onSelect={() => {
                                      void setDefaultCalendar(calendar.id)
                                    }}
                                  >
                                    Set as default calendar
                                  </MenuItem>
                                )}
                              {calendar.canEdit && (
                                <MenuItem
                                  icon={IconId.Edit}
                                  onSelect={() =>
                                    setEditCalendarId(calendar.id)
                                  }
                                >
                                  Rename
                                </MenuItem>
                              )}
                              {calendar.canEdit &&
                                calendar.provider.node.type === "daybridge" && (
                                  <MenuItem
                                    icon={IconId.Users}
                                    onSelect={() =>
                                      setShareCalendarId(calendar.id)
                                    }
                                  >
                                    {calendar.people
                                      ? "Manage sharing"
                                      : "Share calendar"}
                                  </MenuItem>
                                )}
                              {calendar.canEdit &&
                                calendar.provider.node.type === "google" && (
                                  <>
                                    <MenuSeparator />
                                    <SubMenu
                                      title="Default icon"
                                      icon={
                                        getIntegrationCalendar(calendar.id)
                                          ?.defaultIcon?.id || IconId.Calendar
                                      }
                                    >
                                      <IconPicker
                                        iconSets={iconSets}
                                        selectedIcon={
                                          getIntegrationCalendar(calendar.id)
                                            ?.defaultIcon?.id || IconId.Calendar
                                        }
                                        onIconSelect={(icon, wasClick) => {
                                          const integration = getIntegration(
                                            calendar.id,
                                          )?.id
                                          if (!integration) {
                                            return
                                          }
                                          void patch(integration, {
                                            id: calendar.id,
                                            defaultIcon: icon,
                                          })
                                          if (wasClick) {
                                            onClose()
                                          }
                                        }}
                                      />
                                    </SubMenu>
                                    {schema?.categories && (
                                      <SubMenu
                                        title="Default category"
                                        icon={IconId.General}
                                      >
                                        {Object.values(schema?.categories).map(
                                          (category) => (
                                            <MenuItem
                                              key={category.id}
                                              icon={category.icon?.id}
                                              onSelect={() => {
                                                const integrationId =
                                                  getIntegration(
                                                    calendar.id,
                                                  )?.id
                                                if (!integrationId) {
                                                  return
                                                }
                                                void patch(integrationId, {
                                                  id: calendar.id,
                                                  defaultCategory: category.id,
                                                })
                                              }}
                                            >
                                              {category.name}
                                            </MenuItem>
                                          ),
                                        )}
                                      </SubMenu>
                                    )}
                                  </>
                                )}
                              <MenuSeparator />
                              <div className="group/color">
                                <div className="pt-2.5 pb-1 px-3 space-x-3 flex items-center">
                                  <div className="flex-shrink-0">
                                    <I
                                      type={IconId.Art}
                                      className="w-3 h-3 text-icon-decorative group-hover/color:text-high-contrast"
                                    />
                                  </div>
                                  <div className="text-base">Color</div>
                                </div>
                                <ColorPicker
                                  value={calendar.color}
                                  onSelect={(value: Color) => {
                                    void editCalendar({
                                      input: {
                                        id: calendar.id,
                                        patch: { color: value },
                                      },
                                    })
                                  }}
                                />
                              </div>
                              <MenuSeparator />
                              <MenuItem
                                icon={IconId.Settings}
                                onSelect={() =>
                                  void router.push(
                                    "/settings/calendars" +
                                      (calendar.provider.node.type ===
                                      "daybridge"
                                        ? ""
                                        : `?provider=${calendar.provider.id}`),
                                  )
                                }
                              >
                                Calendar settings
                              </MenuItem>
                              {calendar.canDelete && (
                                <MenuItem
                                  icon={IconId.TrashEmpty}
                                  onSelect={() =>
                                    deleteDialogRef.current?.open()
                                  }
                                >
                                  Delete
                                </MenuItem>
                              )}
                            </>
                          )}
                        >
                          {({ open }) => (
                            <Button
                              size={ButtonSize.Custom}
                              variant={ButtonVariant.Headless}
                              className={`px-2 flex-shrink-0 ring-focus-ring ring-inset rounded-full ${
                                open
                                  ? "bg-background text-high-contrast"
                                  : "opacity-0 peer-hover:opacity-100 peer-focus:opacity-100 hover:opacity-100 focus:opacity-100 hover:bg-background focus:bg-background text-low-contrast"
                              }`}
                            >
                              <I
                                type={IconId.EllipsisVertical}
                                className="w-3 h-3"
                              />
                            </Button>
                          )}
                        </Menu>
                      )}

                      <AlertDialog
                        ref={deleteDialogRef}
                        actionButtonText="Delete"
                        actionButtonVariant={ButtonVariant.Error}
                        icon={IconId.TrashEmpty}
                        title="Delete calendar"
                        description={
                          <>
                            Are you sure you want to delete{" "}
                            <strong>{calendar.name}</strong>? You won&apos;t be
                            able to restore it.
                          </>
                        }
                        onActionPress={() => deleteCalendar(calendar.id)}
                      />
                    </div>
                  )
                })}
              </CollapsibleSection>
            ))}
          </ScrollArea>
        </div>
      </SidebarTabContent>
    )
  },
)
SidebarCalendarListFn.displayName = "SidebarCalendarList"

export const SidebarCalendarList = React.memo(
  SidebarCalendarListFn,
) as typeof SidebarCalendarListFn
