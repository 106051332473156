import { IconId } from "@daybridge/client-api"
import React, { useLayoutEffect, useRef } from "react"
import scrollIntoView from "scroll-into-view-if-needed"
import { useHourLevelScrollHeight } from "../../../timeline/hooks/rendering/useHourLevelScrollHeight"
import { BaseBlockItem, BlockItemProps } from "./common/BaseBlockItem"
import { BlockIcon } from "./common/BlockIcon"
import { BlockTimeLabels } from "./common/BlockTimeLabels"
import { BlockTitle } from "./common/BlockTitle"

export type SelectedRegionProps = BlockItemProps

// Define height breakpoints. Anything below each of these breakpoints
// renders that layout
export const SelectedRegion_TINY_BREAKPOINT = 46

export const SelectedRegion: React.FC<SelectedRegionProps> = React.memo(
  (props) => {
    const scrollHeight = useHourLevelScrollHeight()
    const height =
      props.fixedHeight || ((props.percentageHeight || 0) * scrollHeight) / 100

    // Choose what sort of contents to show depending on
    // how tall the SelectedRegion is.
    let layout: React.ReactElement
    switch (true) {
      case props.isDayLevel:
        layout = <DayLevelSelectedRegionLayout {...props} />
        break
      case height < SelectedRegion_TINY_BREAKPOINT:
        layout = <TinySelectedRegionLayout {...props} />
        break
      default:
        layout = <MediumSelectedRegionLayout {...props} />
        break
    }

    const selectedRegionRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
      if (!selectedRegionRef.current) return
      if (props.alreadyStarted) return

      scrollIntoView(selectedRegionRef.current, {
        scrollMode: "if-needed",
        behavior: "smooth",
        block: "center",
        inline: "start",
      })
      // This is an anti-pattern but it does work for now. Will fix later.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegionRef.current, props.start])

    return (
      <BaseBlockItem {...props} hideJumpButtons>
        <div
          ref={selectedRegionRef}
          className={`
              w-full h-full
              overflow-hidden
              bg-primary-background/75
              border-primary-tint/40
              text-primary-body
              content-violet
              backdrop-blur-sm
              text-sm
              flex items-center justify-center
              ${
                /* Corner Rounding Logic */
                props.isDayLevel
                  ? /* Day-level cards aren't rounded on the right side if they overflow */
                    "rounded-l-xl border-t border-b border-l " +
                    (!props.continues ? " rounded-r-xl border-r" : "")
                  : /* Cards on the timeline aren't rounded on the top/bottom if they have already started/continue */
                    "border-l border-r " +
                    (!props.alreadyStarted ? "rounded-t-xl border-t" : "") +
                    (!props.continues ? " rounded-b-xl border-b" : "")
              }
            `}
        >
          {/* Main layout */}
          {layout}
        </div>
      </BaseBlockItem>
    )
  },
)
SelectedRegion.displayName = "SelectedRegion"

export const DayLevelSelectedRegionLayout: React.FC<SelectedRegionProps> =
  React.memo((props: SelectedRegionProps) => {
    return (
      <div
        className={`
          pl-2
          ${props.continues ? "pr-9" : "pr-2"}
          w-full h-full
          space-x-1.5
          flex flex-row items-center justify-between
          truncate
        `}
      >
        <BlockIcon icon={IconId.Plus} />
        <BlockTitle>New</BlockTitle>
      </div>
    )
  })
DayLevelSelectedRegionLayout.displayName = "DayLevelSelectedRegionLayout"

export const TinySelectedRegionLayout: React.FC<SelectedRegionProps> =
  React.memo((props: SelectedRegionProps) => {
    return (
      <div
        className="
          p-2
          w-full h-full
          space-x-1.5
          flex flex-row items-center
          truncate
        "
      >
        <BlockIcon icon={IconId.Plus} />
        <BlockTitle>New</BlockTitle>
        <BlockTimeLabels
          start={props.start}
          end={props.end}
          width={props.width}
          alreadyStarted={props.alreadyStarted}
          continues={props.continues}
          singleLabelMinWidth={200}
          doubleLabelMinWidth={300}
        />
      </div>
    )
  })
TinySelectedRegionLayout.displayName = "TinySelectedRegionLayout"

export const MediumSelectedRegionLayout: React.FC<SelectedRegionProps> =
  React.memo((props: SelectedRegionProps) => {
    return (
      <div
        className="
          p-2
          w-full h-full
          space-x-1.5
          flex flex-row items-start
          truncate
        "
      >
        <BlockIcon icon={IconId.Plus} />
        <div className="flex-1 flex flex-col truncate">
          <BlockTitle>New</BlockTitle>
          <BlockTimeLabels
            start={props.start}
            end={props.end}
            width={props.width}
            alreadyStarted={props.alreadyStarted}
            continues={props.continues}
            singleLabelMinWidth={0}
            doubleLabelMinWidth={160}
          />
        </div>
      </div>
    )
  })
MediumSelectedRegionLayout.displayName = "MediumSelectedRegionLayout"
