import React from "react"
import { Formik } from "formik"
import { PatchCalendarMutationVariables } from "@daybridge/client-api"
import {
  Button,
  ButtonVariant,
  ButtonSize,
  InlineInput,
} from "@daybridge/components-core"
import { object, string } from "yup"
import { useEditCalendarId } from "../../state/editing"
import { useEditCalendar } from "../../hooks/useEditCalendar"
import { useCalendars } from "../../hooks/useCalendars"

export const EditCalendarForm: React.FC = React.memo(() => {
  const [editCalendarId, setEditCalendarId] = useEditCalendarId()
  const editCalendar = useEditCalendar()
  const { calendars } = useCalendars()

  return (
    <Formik<PatchCalendarMutationVariables>
      initialValues={{
        input: {
          id: editCalendarId as string,
          patch: {
            name: "",
          },
        },
      }}
      validationSchema={object().shape({
        input: object().shape({
          patch: object().shape({
            name: string()
              .max(64, "Please choose a shorter name")
              .required("Please choose a name for your calendar"),
          }),
        }),
      })}
      onSubmit={(values) => editCalendar(values)}
    >
      {({ getFieldProps, getFieldMeta, handleSubmit }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <InlineInput
            {...getFieldProps("input.patch.name")}
            {...getFieldMeta("input.patch.name")}
            required
            maxLength={64}
            placeholder={`Choose a new name for ${
              calendars?.find((calendar) => calendar.id === editCalendarId)
                ?.name || "this calendar"
            }...`}
            className="border-b border-tint"
          />
          <div className="px-6 py-4 flex items-center justify-end space-x-2">
            <Button
              variant={ButtonVariant.Clear}
              size={ButtonSize.Base}
              onPress={() => setEditCalendarId(undefined)}
            >
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              size={ButtonSize.Base}
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
})
EditCalendarForm.displayName = "EditCalendarForm"
