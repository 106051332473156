import React, { useEffect } from "react"
import { useSelectedRegionForCreateForm } from "../../../timeline/state/selectedRegion"

interface ItemEditPaneFormUpdaterProps {
  setFieldValue: (field: string, value: unknown) => void
}

/**
 * This component is responsible for updating the form values with the computed
 * selected region when the global state changes, keeping us in sync.
 */
export const ItemEditPaneFormUpdater: React.FC<ItemEditPaneFormUpdaterProps> =
  React.memo((props: ItemEditPaneFormUpdaterProps) => {
    const selectedRegion = useSelectedRegionForCreateForm()
    useEffect(() => {
      if (!selectedRegion) return
      props.setFieldValue("start", selectedRegion?.start)
      props.setFieldValue("end", selectedRegion?.end)
    }, [selectedRegion, props])

    return <div />
  })
ItemEditPaneFormUpdater.displayName = "ItemEditPaneFormUpdater"
