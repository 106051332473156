import React, { useLayoutEffect, useRef } from "react"
import { range } from "lodash-es"
import { DateTime } from "luxon"
import scrollIntoView from "scroll-into-view-if-needed"
import { hourFriendlyName } from "@daybridge/datetime"
import { placementFromTime } from "../../placement/placementFromTime"

interface TimeLabelsProps {
  // If `now` is between `startLocal` and `endLocal` then the time indicator will
  // show up.
  startLocal: DateTime
  endLocal: DateTime

  // `now` is the current local time.
  nowLocal: DateTime
}

export const TIME_LABELS_GUTTER_WIDTH = 54

/**
 * `TimeLabels` are the time ticks shown on the left of the timeline. This component
 * is also responsible for making sure the time label is visible on initial page load
 * by scrolling to the correct position automatically.
 */
export const TimeLabels: React.FC<TimeLabelsProps> = React.memo(
  (props: TimeLabelsProps) => {
    const timeLabelsRef = useRef<HTMLDivElement>(null)

    // On initial load, scroll the current time into view. This element is positioned
    // a bit below the active time label, so that we show more of the future rather
    // showing future and past time equally as much.
    const currentTimeScrollRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
      if (!currentTimeScrollRef.current) return

      scrollIntoView(currentTimeScrollRef.current, {
        scrollMode: "always",
        behavior: "auto",
        block: "center",
        inline: "start",
      })
      // This is an anti-pattern but it does work for now. Will fix later.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTimeScrollRef.current])

    // Compute the current time indicator position, only if the current time
    // falls on this day.

    const timeIndicatorPositionPercent =
      props.nowLocal >= props.startLocal && props.nowLocal < props.endLocal
        ? placementFromTime(
            props.startLocal.set({
              hour: props.nowLocal.hour,
              minute: props.nowLocal.minute,
            }),
            props.startLocal,
            props.endLocal.hour === 0
              ? props.startLocal.plus({ days: 1 })
              : props.startLocal.set({ hour: props.endLocal.hour }),
          ) * 100
        : undefined

    return (
      <div
        role="none"
        className="
          flex flex-col
        "
        style={{ width: TIME_LABELS_GUTTER_WIDTH }}
      >
        <div
          ref={timeLabelsRef}
          className="w-full flex-1 flex flex-col relative overflow-hidden"
        >
          {timeIndicatorPositionPercent !== undefined && (
            <>
              <div
                className="
                  absolute left-2 right-1
                  h-5
                  mx-1 -mt-[10px]
                  font-semibold
                  flex items-center justify-end
                  text-tiny text-primary-body
                  bg-surface
                "
                style={{
                  top: `clamp(10px, ${timeIndicatorPositionPercent}%, calc(100% - 10px))`,
                }}
              >
                {props.nowLocal.toLocaleString(DateTime.TIME_SIMPLE)}
              </div>
              <div
                ref={currentTimeScrollRef}
                className="absolute left-0 right-0 mt-24"
                style={{ top: `${timeIndicatorPositionPercent.toString()}%` }}
              />
            </>
          )}

          {/* Render 24 ticks. This is the case even for weeks with days lasting 23 or 35 days. */}
          {range(0, 24).map((n) => (
            <div
              key={n}
              className={`
                flex-1
              `}
            >
              {/* Render a time label on every hour except midnight */}
              {n > 0 && (
                <div
                  className="
                    -mt-2 pr-2
                    text-right text-tiny uppercase
                    text-low-contrast
                    
                  "
                >
                  {hourFriendlyName(n)}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  },
)

TimeLabels.displayName = "TimeLabels"
