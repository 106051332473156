import { DateTime } from "luxon"
import { ForwardedRef, forwardRef, memo, useMemo } from "react"
import { ItemContextPane } from "../../../items/components/context-pane/ItemContextPane"
import { ItemEditPane } from "../../../items/components/edit-pane/ItemEditPane"
import {
  ItemCreationStateStage,
  useItemCreationState,
} from "../../../items/state/creation"
import { useEditingItem } from "../../../items/state/editing"
import { useOpenItem } from "../../../items/state/opening"
import { DetailsPane } from "./DetailsPane"

interface DetailsPaneCoordinatorProps {
  startLocal: DateTime
}

const DetailsPaneCoordinatorFn = forwardRef(
  (props: DetailsPaneCoordinatorProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { startLocal, ...rest } = props

    const [creationState] = useItemCreationState()
    const [editingState] = useEditingItem()
    const [openItem] = useOpenItem()

    const isDetailsPaneOpen = useMemo(() => {
      return (
        !!openItem ||
        creationState?.stage === ItemCreationStateStage.ItemDetails ||
        !!editingState
      )
    }, [creationState?.stage, editingState, openItem])

    const isEditPaneOpen =
      creationState?.stage === ItemCreationStateStage.ItemDetails ||
      editingState

    return (
      <DetailsPane ref={ref} open={isDetailsPaneOpen} {...rest}>
        {isEditPaneOpen && <ItemEditPane />}
        <ItemContextPane startLocal={startLocal} />
      </DetailsPane>
    )
  },
)
DetailsPaneCoordinatorFn.displayName = "DetailsPane"

export const DetailsPaneCoordinator = memo(
  DetailsPaneCoordinatorFn,
) as typeof DetailsPaneCoordinatorFn
