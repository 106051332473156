import { IconId } from "@daybridge/client-api"
import {
  FadeAndScaleAnimation,
  Button,
  ButtonVariant,
  ButtonSize,
  Tooltip,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { colorToClass } from "@daybridge/colors"
import { AnimatePresence } from "framer-motion"
import { DateTime } from "luxon"
import { memo, useState } from "react"
import { useSetCreationStateByDate } from "../../../items/hooks/useSetCreationStateByDate"
import { DAY_PARTS } from "../../types/dayParts"

interface AgendaDayAddButtonsProps {
  day: DateTime
  displaySubmenu: boolean
  className?: string
}

export const AgendaDayAddButtons: React.FC<AgendaDayAddButtonsProps> = memo(
  (props: AgendaDayAddButtonsProps) => {
    // We need to separate these states to slightly time the two animations
    // differently (the 4 buttons need to be completely unmounted before the add
    // button can mount again to prevent flickering).
    const [isHover, setHover] = useState(false)
    const [isAddButtonVisible, setIsAddButtonVisible] = useState(true)
    const setCreationState = useSetCreationStateByDate()

    return (
      <div
        className={`${props.className || ""}`}
        onMouseEnter={() => {
          if (props.displaySubmenu) {
            setHover(true)
            setIsAddButtonVisible(false)
          }
        }}
        onMouseLeave={() => setHover(false)}
      >
        {isAddButtonVisible && (
          <FadeAndScaleAnimation side="right">
            <div className="p-1 items-center rounded-full">
              <Button
                variant={ButtonVariant.Outline}
                size={ButtonSize.Base}
                className="!px-2"
                onPress={() =>
                  !props.displaySubmenu &&
                  void setCreationState(props.day.set({ hour: 12 }))
                }
              >
                <I
                  type={IconId.Plus}
                  className={`
                    w-2 h-2
                    text-low-contrast
                  `}
                />
              </Button>
            </div>
          </FadeAndScaleAnimation>
        )}
        <AnimatePresence
          onExitComplete={() =>
            props.displaySubmenu && setIsAddButtonVisible(true)
          }
        >
          {isHover && (
            <FadeAndScaleAnimation side="right">
              <div className="flex p-1 border border-tint shadow-tiny items-center rounded-full">
                {DAY_PARTS.map((dayPart) => (
                  <Tooltip
                    key={dayPart.title}
                    content={
                      dayPart.createAtHour
                        ? `Add something in the ${dayPart.title.toLowerCase()}`
                        : "Add something today"
                    }
                  >
                    <Button
                      variant={ButtonVariant.Clear}
                      size={ButtonSize.Base}
                      onPress={() =>
                        void setCreationState(
                          props.day.set({ hour: dayPart.createAtHour }),
                        )
                      }
                      className={`!px-3 content-${colorToClass(
                        dayPart.iconColor,
                      )}`}
                    >
                      <I
                        type={dayPart.icon}
                        className={`
                          w-3 h-3
                          text-content-primary
                        `}
                      />
                    </Button>
                  </Tooltip>
                ))}
              </div>
            </FadeAndScaleAnimation>
          )}
        </AnimatePresence>
      </div>
    )
  },
)

AgendaDayAddButtons.displayName = "AgendaDayAddButtons"
