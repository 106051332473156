import { RefObject, useCallback } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { viewportPositionAtom } from "../../state/viewportPosition"

export function useViewportPosition() {
  return useRecoilValue(viewportPositionAtom)
}

export function useSetViewportRef() {
  const setter = useSetRecoilState(viewportPositionAtom)
  return useCallback(
    (ref: RefObject<HTMLDivElement>) => {
      setter((x) => ({ ...x, viewportRef: ref }))
    },
    [setter],
  )
}
