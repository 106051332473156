import { useCallback } from "react"
import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  useItemsQuery,
  DateTimeArgs,
  PatchItemMutationVariables,
  PatchItemMutation,
  usePatchItemMutation,
  PatchItemArgs,
  PatchItemInput,
  useItemQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { fromPromise } from "@daybridge/toast"
import { DateTime } from "luxon"
import {
  resolveDateTimeInfo,
  dateTimeToDateTimeInfo,
} from "@daybridge/datetime"
import { useResetTimeline } from "../../timeline/hooks/useResetTimeline"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"
import { EditMode } from "../types/editMode"
import { usePatchItemDiff } from "./usePatchItemDiff"

export const useEditItem = (
  options?: UseMutationOptions<
    PatchItemMutation,
    string | GraphQLError,
    PatchItemMutationVariables,
    unknown
  >,
) => {
  const reset = useResetTimeline()
  const queryClient = useQueryClient()
  const { mutateAsync } = usePatchItemMutation(options)
  const filterEqualValues = usePatchItemDiff()

  return useCallback(
    (
      args: PatchItemArgs,
      idempotencyKey: string,
      timeZone: string,
      item?: ItemWithResolvedTimes,
      mode?: EditMode,
    ) => {
      let start: DateTimeArgs | null | undefined
      let end: DateTimeArgs | null | undefined
      if (!item) {
        throw new Error("An item must be provided during editing")
      }

      if (!args.start || !args.end) {
        return
      }

      if (args.end?.date) {
        // End date is inclusive in this form, but exclusive on the API.
        // Add 1 day to the end date if we are using date submission mode.
        start = args.start
        end = {
          date: DateTime.fromISO(args.end.date).plus({ days: 1 }).toISODate(),
        }
      } else if (args.series?.rules?.length) {
        // If this item is recurring then we need to submit as a floating time.
        const startLocal = resolveDateTimeInfo(args.start, timeZone)
        const endLocal = resolveDateTimeInfo(args.end, timeZone)
        start = dateTimeToDateTimeInfo(startLocal, {
          floating: true,
          includeZone: true,
        })
        end = dateTimeToDateTimeInfo(endLocal, {
          floating: true,
          includeZone: true,
        })
      } else {
        start = args.start
        end = args.end
      }

      let input: PatchItemInput
      switch (mode) {
        case EditMode.Instance:
          if (!item.series) throw new Error("Item is not part of a series")
          input = {
            id: item.id,
            patch: { ...args, start, end },
          }
          break
        case EditMode.Future:
          if (!item.series) throw new Error("Item is not part of a series")
          input = {
            id: item.id,
            from: item.id,
            patch: { ...args, start, end },
          }
          break
        case EditMode.Series:
          if (!item.series) throw new Error("Item is not part of a series")
          input = {
            id: item.id,
            series: item.series.id,
            patch: { ...args, start, end },
          }
          break
        default:
          // Not deleting a recurring item
          if (mode) {
            throw new Error("Mode should not be set when editing a single item")
          }
          input = { id: item.id, patch: { ...args, start, end } }
          break
      }

      // Filter out any values that are the same as the existing value.
      input.patch = filterEqualValues({ ...input.patch } as PatchItemArgs, item)

      // Don't submit anything if there are no changes.
      if (Object.keys(input.patch).length === 0) {
        return reset()
      }

      const resultPromise = mutateAsync(
        {
          input,
        },
        {
          onSuccess: async () => {
            reset()
            await queryClient.invalidateQueries(
              useItemQuery.getKey({ args: { id: item.id } }),
            )
            await queryClient.invalidateQueries(
              useItemsQuery.getKey({ args: {} }),
            )
          },
        },
      )
      void fromPromise(resultPromise, {
        successTitle: `${args.title || "Item"} saved`,
      })
    },
    [filterEqualValues, mutateAsync, queryClient, reset],
  )
}
