import { useCallback } from "react"
import { DateTime } from "luxon"
import { placementFromTime } from "../../placement/placementFromTime"

interface HourLevelPositionFunctions {
  // `percentageYOffsetFor` returns how far from the top of the renderable
  // area an item should start.
  percentageYOffsetFor: (startLocal: DateTime) => number

  // `percentageHeightFor` calcualtes how tall an item should be.
  percentageHeightFor: (startLocal: DateTime, endLocal: DateTime) => number

  // `alreadyStarted` is true if the item started before the day started
  alreadyStarted: (startLocal: DateTime) => boolean

  // `continues` is true if the item carries on after the end of the day
  continues: (endLocal: DateTime) => boolean
}

/**
 * `useHourLevelPositions` returns functions that can be used to
 * calculate the y-offset and height of hour-level items.
 */
export const useHourLevelPositions = (
  // `rangeStartLocal` is the start of the period we're rendering for
  rangeStartLocal: DateTime,

  // `rangeEndLocal` is the end of the period we're rendering for
  rangeEndLocal: DateTime,
): HourLevelPositionFunctions => {
  const percentageYOffsetFor = useCallback(
    (time: DateTime) =>
      placementFromTime(time, rangeStartLocal, rangeEndLocal) * 100,
    [rangeStartLocal, rangeEndLocal],
  )

  const percentageHeightFor = useCallback(
    (startLocal: DateTime, endLocal: DateTime) => {
      const startPositionPercent = percentageYOffsetFor(startLocal)
      const endPositionPercent = percentageYOffsetFor(endLocal)
      return endPositionPercent - startPositionPercent
    },
    [percentageYOffsetFor],
  )

  const alreadyStarted = useCallback(
    (startLocal: DateTime) => startLocal < rangeStartLocal,
    [rangeStartLocal],
  )

  const continues = useCallback(
    (endLocal: DateTime) => endLocal > rangeEndLocal,
    [rangeEndLocal],
  )

  return {
    percentageYOffsetFor,
    percentageHeightFor,
    alreadyStarted,
    continues,
  }
}
