import { useRecoilCallback } from "recoil"
import { ItemDragMode, itemDragStateAtom } from "../state/dragging"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"

interface useStartDraggingItemFunctions {
  beginMove: () => void
  beginResizeStart: () => void
  beginResizeEnd: () => void
}

export const useStartDraggingItem = (
  item: ItemWithResolvedTimes,
): useStartDraggingItemFunctions => {
  const beginMove = useRecoilCallback(
    ({ set }) => {
      return () => {
        set(itemDragStateAtom, {
          mode: ItemDragMode.Move,
          item,
          firstMousePositionWasAllDay: item.renderAsAllDay,
          lastMousePositionAt: item.startLocal,
          lastMousePositionWasAllDay: item.renderAsAllDay,
        })
      }
    },
    [item],
  )

  const beginResizeStart = useRecoilCallback(
    ({ set }) => {
      return () => {
        set(itemDragStateAtom, {
          mode: ItemDragMode.ResizeStart,
          item,
          firstMousePositionAt: item.startLocal,
          firstMousePositionWasAllDay: item.renderAsAllDay,
          lastMousePositionAt: item.startLocal,
          lastMousePositionWasAllDay: item.renderAsAllDay,
        })
      }
    },
    [item],
  )

  const beginResizeEnd = useRecoilCallback(
    ({ set }) => {
      return () => {
        set(itemDragStateAtom, {
          mode: ItemDragMode.ResizeEnd,
          item,
          firstMousePositionAt: item.isAllDayItem
            ? item.endLocal.minus({ days: 1 })
            : item.endLocal,
          firstMousePositionWasAllDay: item.renderAsAllDay,
          lastMousePositionAt: item.isAllDayItem
            ? item.endLocal.minus({ days: 1 })
            : item.endLocal,
          lastMousePositionWasAllDay: item.renderAsAllDay,
        })
      }
    },
    [item],
  )

  return {
    beginMove,
    beginResizeStart,
    beginResizeEnd,
  }
}
