import { DaysVisible } from "@daybridge/client-api"
import { ScrollArea } from "@daybridge/components-core"
import { expandDaysInRange } from "@daybridge/datetime"
import { DateTime } from "luxon"
import React, { useEffect, useMemo, useRef } from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import {
  useSetViewportRef,
  useViewportPosition,
} from "../../hooks/rendering/useViewportPosition"
import { daysVisibleToNumber } from "../../utils/daysVisible"
import { TimelineHeader } from "../TimelineHeader"
import { AgendaDay } from "./AgendaDay"

interface AgendaViewProps {
  startLocal: DateTime
  endLocal: DateTime
  nowLocal: DateTime
  homeTimeZone: string
}

const AGENDA_LEFT_PADDING = 16
const AGENDA_DAY_X_PADDING = 16

export const AgendaView: React.FC<AgendaViewProps> = React.memo((props) => {
  const [daysVisible] = usePreference("daysVisible")

  // Calculate the number of days we need to render
  // Create a DateTime object for each one corresponding to the
  // start of the day that needs to be rendered.
  const days: DateTime[] = useMemo(
    () => expandDaysInRange(props.startLocal, props.endLocal),
    [props.startLocal, props.endLocal],
  )

  // Calculate the width of the items.
  // ================================
  // Ideally we didn't have to do this manual calculation, but since the
  // `ScrollArea` applies "min-width: 100%" it doesn't seem like we can
  // bind the ref to the column itself.
  const { viewportWidth } = useViewportPosition()
  const columnWidth = useMemo(() => {
    // Returns the width of the column excluding padding (i.e. the width of the
    // items).
    return (
      viewportWidth &&
      Math.floor(
        (viewportWidth - AGENDA_LEFT_PADDING) /
          daysVisibleToNumber(daysVisible || DaysVisible.Seven) -
          AGENDA_DAY_X_PADDING,
      )
    )
  }, [daysVisible, viewportWidth])

  const ref = useRef<HTMLDivElement>(null)
  const setViewportRef = useSetViewportRef()

  useEffect(() => {
    setViewportRef(ref)
  }, [ref, setViewportRef])

  return (
    <ScrollArea
      ref={ref}
      className="
        relative z-0
        flex-1
        bg-surface
        2xl:border border-tint-alpha
        2xl:rounded-xl
        overflow-hidden"
    >
      <TimelineHeader {...props} isAgenda columnWidth={columnWidth} />
      <div
        className={`
          w-full
          pl-4 pb-10
          flex-1 flex flex-row
        `}
      >
        {days.map((day) => (
          <AgendaDay
            key={day.toISODate()}
            width={columnWidth}
            dayLocal={day}
            nowLocal={props.nowLocal}
            cacheStartLocal={props.startLocal}
            cacheEndLocal={props.endLocal}
          />
        ))}
      </div>
    </ScrollArea>
  )
})
AgendaView.displayName = "AgendaView"
