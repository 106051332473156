const avatarColorCombinations = [
  "content-blue bg-content-tint text-content-body",
  "content-orange bg-content-tint text-content-body",
  "content-green bg-content-tint text-content-body",
  "content-violet bg-content-tint text-content-body",
  "content-pink bg-content-tint text-content-body",
  "content-yellow bg-content-tint text-content-body",
]

export const getAvatarColor = (index: number) => {
  while (index > avatarColorCombinations.length - 1) {
    index -= avatarColorCombinations.length
  }
  return avatarColorCombinations[index]
}
