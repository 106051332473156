import { Modal } from "@daybridge/components-core"
import React from "react"
import { useShareCalendarId } from "../../state/sharing"
import { ShareCalendarModal } from "./ShareCalendarModal"

export const ShareCalendarModalCoordinator: React.FC = React.memo(() => {
  const [shareCalendarId, setShareCalenderId] = useShareCalendarId()

  return (
    <>
      <Modal
        open={shareCalendarId !== undefined}
        content={<ShareCalendarModal />}
        onOpenChange={(open) => {
          if (!open) {
            setShareCalenderId(undefined)
          }
        }}
        className="w-full max-w-md"
      />
    </>
  )
})
ShareCalendarModalCoordinator.displayName = "ShareCalendarModalCoordinator"
