import { IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonSize,
  ButtonVariant,
  ScrollArea,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { colorToClass } from "@daybridge/colors"
import { memo } from "react"
import { PartialItem } from "../../state/opening"
import { ContextCard } from "./ContextCard"

type BasicContextSkeletonProps = PartialItem & { onClose: () => void }

export const BasicContextSkeleton: React.FC<BasicContextSkeletonProps> = memo(
  (props: BasicContextSkeletonProps) => {
    return (
      <div
        key={props.id}
        className={`
          relative w-[384px]
          flex flex-col h-full
          text-high-contrast
          overflow-hidden
          focus:outline-none
          select-none
          ${props.color ? `content-${colorToClass(props.color)}` : ""}
        `}
      >
        <div className="absolute w-full h-[40rem] bg-gradient-to-b from-content-background via-surface"></div>
        {/* Top Controls */}
        <div
          className="
          absolute w-full z-20
          flex flex-row items-center
          rounded-t-2xl
          backdrop-filter backdrop-blur-lg
          space-x-2 py-3 px-4
        "
        >
          <Button
            size={ButtonSize.IconAction}
            variant={ButtonVariant.Clear}
            disabled
          >
            <I type={IconId.Edit} />
          </Button>
          <Button
            size={ButtonSize.IconAction}
            variant={ButtonVariant.Clear}
            disabled
          >
            <I type={IconId.TrashEmpty} className="w-[14px] h-[14px]" />
          </Button>
          <div className="flex-1" />
          <Button
            variant={ButtonVariant.Clear}
            size={ButtonSize.IconAction}
            onPress={() => props.onClose()}
          >
            <I type={IconId.Cross} />
          </Button>
        </div>
        {/* Padded Area */}
        <div className="flex-1 overflow-hidden">
          <ScrollArea className="relative h-full pb-4">
            <div className="h-full flex flex-col">
              {/* Icon */}
              <div className="w-full mt-24 flex items-center justify-center z-10">
                <div
                  className={`
                  w-12 h-12 -mb-6 p-4
                  rounded-full
                  bg-content-primary
                  text-icon-on-color
                `}
                >
                  <I type={props.icon || IconId.CalendarOutline} />
                </div>
              </div>
              <ContextCard className="pt-10 flex flex-col items-center justify-center space-y-5">
                <div className="flex flex-col items-center justify-center">
                  <h3 className="block text-lg text-center font-semibold mb-1">
                    {props.title}
                  </h3>
                  <div className="text-base font-semibold text-low-contrast w-64 rounded-lg h-5 animate-pulse bg-background" />
                </div>
                <div className="w-full flex flex-row items-center space-x-2 px-3 pb-3">
                  <div className="truncate text-center flex-[0.5] px-5 py-2 bg-content-background rounded-full h-9 text-content-body text-base animate-pulse" />
                  <div className="truncate text-center flex-1 px-5 py-2 bg-tint rounded-full h-9 text-high-contrast text-base animate-pulse" />
                </div>
              </ContextCard>
            </div>
          </ScrollArea>
        </div>
      </div>
    )
  },
)

BasicContextSkeleton.displayName = "BasicContextSkeleton"
