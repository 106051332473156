import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  useUnshareItemMutation,
  UnshareItemMutation,
  UnshareItemMutationVariables,
  useItemsQuery,
  useItemQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { useCallback } from "react"
import { fromPromise } from "@daybridge/toast"

export const useUnshareItem = (
  options?: UseMutationOptions<
    UnshareItemMutation,
    string | GraphQLError,
    UnshareItemMutationVariables,
    unknown
  >,
) => {
  const queryClient = useQueryClient()
  const { mutateAsync } = useUnshareItemMutation({
    onSettled: (data) => {
      // Invalidate both the `item` and `items` queries.
      const id = data?.unshareItem?.id
      if (id) {
        void queryClient.invalidateQueries(
          useItemQuery.getKey({ args: { id } }),
        )
      }
      void queryClient.invalidateQueries(useItemsQuery.getKey({ args: {} }))
    },
    ...options,
  })

  return useCallback(
    (itemId: string) => {
      const resultPromise = mutateAsync({
        input: {
          id: itemId,
        },
      })
      void fromPromise(resultPromise, {
        loadingMessage: "Removing link...",
        successTitle: "Link removed",
        errorTitle: "Could not remove link",
      })
      return resultPromise
    },
    [mutateAsync],
  )
}
