import { Activity, ActivityId } from "@daybridge/client-api"
import { useMemo } from "react"

/*
`useQueryStringMapping` takes a set of activities and constructs a
mapping of query string -> activities that query string may refer to.
This is useful because multiple activities can share query strings
(e.g. "flying" could refer to a flight, flying lesson ...etc)
 */
export const useQueryStringMapping: (
  activities: { [activity in ActivityId]: Activity } | undefined,
) =>
  | {
      [queryString: string]: ActivityId[]
    }
  | undefined = (
  activities: { [activity in ActivityId]: Activity } | undefined,
) =>
  useMemo(() => {
    if (!activities || Object.values(activities).length === 0) {
      return undefined
    }
    const result: { [queryString: string]: ActivityId[] } = {}

    Object.values(activities)
      .flatMap((activity) =>
        activity.queryStrings.map((queryString) => {
          return { [queryString.toLowerCase()]: activity.id }
        }),
      )
      .forEach((item) => {
        Object.keys(item).forEach((queryString) => {
          if (result[queryString] === undefined) {
            result[queryString] = [item[queryString]]
          } else {
            if (result[queryString].indexOf(item[queryString]) === -1) {
              result[queryString].push(item[queryString])
            }
          }
        })
      })
    return result
  }, [activities])
