import React from "react"
import { BaseBlockItem, BlockItemProps } from "./common/BaseBlockItem"

export type SkeletonRegionProps = BlockItemProps

export const SkeletonRegion: React.FC<SkeletonRegionProps> = React.memo(
  (props) => {
    return (
      <div className="dark:opacity-50">
        <BaseBlockItem {...props} hideJumpButtons>
          <div
            className={`
              animate-pulse
              w-full h-full
              overflow-hidden
              bg-background border border-tint
              text-primary
              backdrop-blur-sm
              text-sm
              p-2
              ${
                /* Corner Rounding Logic */
                props.isDayLevel
                  ? /* Day-level cards aren't rounded on the right side if they overflow */
                    "rounded-l-xl border-t border-b border-l " +
                    (!props.continues ? " rounded-r-xl border-r" : "")
                  : /* Cards on the timeline aren't rounded on the top/bottom if they have already started/continue */
                    "border-l border-r " +
                    (!props.alreadyStarted ? "rounded-t-xl border-t" : "") +
                    (!props.continues ? " rounded-b-xl border-b" : "")
              }
            `}
          >
            <div className="flex space-x-1.5">
              <div className="h-5 w-5 animate-pulse bg-icon-decorative rounded-full" />
              <div>
                <div className="h-2.5 mt-1 w-16 animate-pulse bg-icon-decorative rounded" />
                <div className="h-2.5 w-8 mt-1 animate-pulse bg-icon-decorative rounded" />
              </div>
            </div>
          </div>
        </BaseBlockItem>
      </div>
    )
  },
)
SkeletonRegion.displayName = "SkeletonRegion"
