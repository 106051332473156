import React, { useCallback, useEffect, useState } from "react"
import { Modal } from "@daybridge/components-core"
import { useItemQuery } from "@daybridge/client-api"
import { useAnalytics } from "@daybridge/analytics"
import { useShareItem } from "../../items/hooks/useShareItem"
import { useUnshareItem } from "../../items/hooks/useUnshareItem"
import { useSharingItemId } from "../../items/state/sharing"
import { getSharableLink } from "../utils/getSharableLink"
import { ShareItemForm } from "./ShareItemForm"
import { ShareItemFormWithLink } from "./ShareItemFormWithLink"

export const ShareItemFormCoordinator: React.FC = React.memo(() => {
  const { track } = useAnalytics()

  const [sharedItemId, setSharedItemId] = useSharingItemId()
  const { data } = useItemQuery(
    { args: { id: sharedItemId as string } },
    { enabled: !!sharedItemId },
  )
  const sharedItem = data?.item?.node

  const shareItem = useShareItem()
  const unshareItem = useUnshareItem()

  // Get the sharable link if the item is public.
  const shareLink = getSharableLink(sharedItem?.sharing?.path)

  // We need to separate the modal open state from the `sharedItem`, otherwise
  // the contents of the modal will change while the modal is transitioning out.
  const [isOpen, setIsOpen] = useState(false)

  // Function to close the modal without the content change during transition.
  const closeModal = useCallback(() => {
    setIsOpen(false)
    // TODO: ideally we would set this in a callback on the modal, rather than
    // hardcode the transition time here.
    setTimeout(() => {
      setSharedItemId(undefined)
    }, 200)
  }, [setSharedItemId])

  // Effect to open the modal if a shared item is set (and make sure it's closed
  // in case something unsets it and the function above was not called.
  useEffect(() => {
    if (sharedItem) {
      // Open modal if shared item is set.
      setIsOpen(true)
    } else if (isOpen) {
      // Close modal if shared item is unset. The modal shouldn't be closed this
      // way, but just in case.
      setIsOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedItem])

  return (
    <Modal
      open={isOpen}
      className="rounded-2xl w-96"
      content={
        shareLink && sharedItemId ? (
          <ShareItemFormWithLink
            itemId={sharedItemId}
            shareLink={shareLink}
            onClose={closeModal}
            onRemove={() => {
              if (sharedItem) {
                void track("event-link-disable.confirm", {
                  itemId: sharedItem.id,
                })
                void unshareItem(sharedItem.id).then(() => {
                  closeModal()
                })
              }
            }}
          />
        ) : (
          <ShareItemForm
            isRecurring={!!sharedItem?.series}
            onGenerateLink={() => {
              if (sharedItem) {
                void track("event-link-create.confirm", {
                  itemId: sharedItem.id,
                })
                void shareItem(sharedItem.id)
              }
            }}
            onClose={closeModal}
          />
        )
      }
      onOpenChange={(open) => {
        if (!open) closeModal()
      }}
    />
  )
})
ShareItemFormCoordinator.displayName = "ShareItemFormCoordinator"
