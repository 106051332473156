import { DaysVisible, Density, IconId } from "@daybridge/client-api"
import {
  Button,
  ButtonVariant,
  ButtonSize,
  TooltipWithDescription,
  FadeAndScaleAnimation,
  Label,
  ScrollArea,
  SegmentedControl,
  Select,
  SelectItem,
  SidebarTabContent,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import usePreset from "../../hooks/presets/usePreset"
import { timeframes } from "../../utils/daysVisible"
import { SidebarPresetButton } from "./SidebarPresetButton"

const SidebarViewSettingsFn = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDivElement>) => {
    // Theme switcher
    // In practice this should always be immediately available in the cache
    // because we've pre-fetched account data during bootstrapping.
    const [density, setDensity] = usePreference("density")

    // Visible days setting
    const [daysVisible, setDaysVisible] = usePreference("daysVisible")

    // View mode setting
    const [hideFreeTime, setHideFreeTime] = usePreference("hideFreeTime")

    // View mode setting
    const [hideWorkItems, setHideWorkItems] = usePreference("hideWorkItems")

    // Presets
    const [preset, setPreset] = usePreset()

    return (
      <SidebarTabContent title="Layout" ref={ref} value="layout">
        <div className="flex flex-col h-full">
          <ScrollArea className="w-full overflow-hidden flex-1 truncate min-w-0">
            <div className="pt-4 p-6">
              <Label className="block">
                <div className="mb-2.5 text-sm text-low-contrast">
                  Shortcuts
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <SidebarPresetButton
                    isActive={preset === "plan"}
                    text="Plan"
                    shortcut="p"
                    icon={IconId.Calendar}
                    onPress={() => void setPreset("plan")}
                  />
                  <SidebarPresetButton
                    isActive={preset === "focus"}
                    text="Focus"
                    shortcut="f"
                    icon={IconId.Clock}
                    onPress={() => void setPreset("focus")}
                  />
                  <SidebarPresetButton
                    isActive={preset === "relax"}
                    text="Relax"
                    shortcut="r"
                    icon={IconId.Beach}
                    onPress={() => void setPreset("relax")}
                  />
                </div>
              </Label>

              <Label className="mt-6 block">
                <div className="mb-2 text-sm text-low-contrast">View</div>
                <Select
                  icon={IconId.CalendarOutline}
                  value={daysVisible || DaysVisible.Seven}
                  onValueChange={(value) => void setDaysVisible(value)}
                  className="w-full"
                >
                  {Object.keys(timeframes).map((daysVisibleOption: string) => (
                    <SelectItem
                      key={daysVisibleOption}
                      value={daysVisibleOption}
                    >
                      {timeframes[daysVisibleOption as DaysVisible]}
                    </SelectItem>
                  ))}
                </Select>
              </Label>

              <Label
                htmlFor="sidebar-hide-free-time"
                className="block mt-6 mb-2 text-sm text-low-contrast"
              >
                Free time
              </Label>
              <SegmentedControl<boolean>
                id="sidebar-hide-free-time"
                value={!!hideFreeTime}
                onValueChange={(value) => void setHideFreeTime(!!value)}
                className="flex-none border border-tint"
                options={[
                  {
                    label: (
                      <div className="flex flex-row items-center space-x-2">
                        <I
                          type={IconId.CalendarOutline}
                          className="w-3 h-3 text-icon-decorative"
                        />
                        <span>Show</span>
                      </div>
                    ),
                    value: false,
                  },
                  {
                    label: (
                      <div className="flex flex-row items-center space-x-2">
                        <I
                          type={IconId.Menu}
                          className="w-3 h-3 text-icon-decorative"
                        />
                        <span>Hide</span>
                      </div>
                    ),
                    value: true,
                  },
                ]}
              />

              <Label
                htmlFor="sidebar-mute-work"
                className="mt-6 mb-2 flex items-center text-sm text-low-contrast"
              >
                Work
                <TooltipWithDescription
                  title="Mute work"
                  description="Hide items categorized as work, to take a break and relax."
                >
                  <Button
                    size={ButtonSize.Custom}
                    variant={ButtonVariant.Headless}
                    className="p-1 cursor-help rounded-full flex items-center justify-center bg-background flex-0 ml-1"
                  >
                    <I
                      type={IconId.QuestionMark}
                      className="w-2 h-2 text-low-contrast"
                    />
                  </Button>
                </TooltipWithDescription>
              </Label>
              <SegmentedControl<boolean>
                id="sidebar-mute-work"
                value={!!hideWorkItems}
                onValueChange={(value) => void setHideWorkItems(!!value)}
                className="flex-none border border-tint"
                options={[
                  {
                    label: (
                      <div className="flex flex-row items-center space-x-2">
                        <I
                          type={IconId.Eye}
                          className="w-3 h-3 text-icon-decorative"
                        />
                        <span>Show</span>
                      </div>
                    ),
                    value: false,
                  },
                  {
                    label: (
                      <div className="flex flex-row items-center space-x-2">
                        <I
                          type={IconId.EyeInactive}
                          className="w-3 h-3 text-icon-decorative"
                        />
                        <span>Mute</span>
                      </div>
                    ),
                    value: true,
                  },
                ]}
              />

              {!hideFreeTime && (
                <FadeAndScaleAnimation>
                  <Label className="block mt-6">
                    <div className="mb-2 text-sm text-low-contrast">
                      Density
                    </div>
                    <Select
                      value={density || Density.Cosy}
                      onValueChange={(value) => void setDensity(value)}
                      className="w-full"
                    >
                      <SelectItem value={Density.Compact}>Compact</SelectItem>
                      <SelectItem value={Density.Cosy}>Cosy</SelectItem>
                      <SelectItem value={Density.Relaxed}>Relaxed</SelectItem>
                    </Select>
                  </Label>
                </FadeAndScaleAnimation>
              )}
            </div>
          </ScrollArea>
        </div>
      </SidebarTabContent>
    )
  },
)
SidebarViewSettingsFn.displayName = "SidebarViewSettings"

export const SidebarViewSettings = React.memo(
  SidebarViewSettingsFn,
) as typeof SidebarViewSettingsFn
