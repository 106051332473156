/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Color,
  DateTimeArgs,
  DateTimeInfo,
  Day,
  Frequency,
  IconId,
  InputMaybe,
  RuleArgs,
  SeriesArgs,
} from "@daybridge/client-api"
import {
  Datepicker,
  DayMapping,
  FormInput,
  Label,
  Popover,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectItem,
  SelectTarget,
  Switch,
  Tooltip,
  WeekdayPicker,
} from "@daybridge/components-core"
import { resolveDateTimeInfo } from "@daybridge/datetime"
import { useTime } from "@daybridge/server-time"
import { produce } from "immer"
import { DateTime } from "luxon"
import React, {
  useState,
  useRef,
  useCallback,
  ForwardedRef,
  forwardRef,
  memo,
  useMemo,
  useEffect,
} from "react"
import { Block } from "../common/Block"

type EndMode = "count" | "until"

// Default end count
const DEFAULT_END_COUNT_VALUE = 5

interface RepeatBlockProps {
  onClose: () => void
  onChange: (rules: InputMaybe<RuleArgs> | undefined) => void
  startValue: InputMaybe<DateTimeArgs> | undefined
  seriesValue: InputMaybe<SeriesArgs> | undefined
  color: Color | null | undefined
  itemTimes: {
    startLocal: DateTime
    endLocal: DateTime
    start: DateTimeInfo
    end: DateTimeInfo
    isAllDayItem: boolean
    renderAsAllDay: boolean
  }
}

const RepeatBlockFn = forwardRef(
  (
    { startValue, onClose, onChange, itemTimes, seriesValue }: RepeatBlockProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const startLocal = useMemo(() => {
      return startValue
        ? resolveDateTimeInfo(startValue, itemTimes.startLocal.zoneName)
        : itemTimes.startLocal
    }, [itemTimes.startLocal, startValue])

    const rule = useMemo(() => {
      return seriesValue?.rules && seriesValue?.rules.length > 0
        ? seriesValue?.rules[0]
        : undefined
    }, [seriesValue?.rules])

    // Needed for date pickers
    const now = useTime(10000, startLocal.zoneName)

    // Control whether the end calendar is shown on the right
    // hand side or not.
    const [endCalendarOpen, setEndCalendarOpen] = useState<boolean>(false)

    // References to side calendar and open calendar button
    const endCalButtonRef = useRef<HTMLButtonElement>(null)

    const handleFrequencyChange = useCallback(
      (frequency: Frequency) => {
        const newRule = produce(rule, (draft: RuleArgs) => {
          if (!draft) return
          draft.frequency = frequency
          if (frequency === Frequency.Weekly) {
            draft.byDays = {
              [startLocal.setLocale("en").weekdayLong.toLowerCase()]: 0,
            }
          } else {
            draft.byDays = undefined
          }
          return draft
        })
        onChange(newRule)
      },
      [onChange, rule, startLocal],
    )

    // Convert the selected days into a format understood by
    // the day picker component
    const selectedDays = {
      [Day.Monday]: rule?.byDays?.monday === 0,
      [Day.Tuesday]: rule?.byDays?.tuesday === 0,
      [Day.Wednesday]: rule?.byDays?.wednesday === 0,
      [Day.Thursday]: rule?.byDays?.thursday === 0,
      [Day.Friday]: rule?.byDays?.friday === 0,
      [Day.Saturday]: rule?.byDays?.saturday === 0,
      [Day.Sunday]: rule?.byDays?.sunday === 0,
    }

    // Do the reverse when days are selected
    const setSelectedDays = useCallback(
      (selectedDays: DayMapping) => {
        const newRule = produce(rule, (draft: RuleArgs) => {
          if (!draft) return
          draft.byDays = {
            monday: selectedDays.Monday ? 0 : undefined,
            tuesday: selectedDays.Tuesday ? 0 : undefined,
            wednesday: selectedDays.Wednesday ? 0 : undefined,
            thursday: selectedDays.Thursday ? 0 : undefined,
            friday: selectedDays.Friday ? 0 : undefined,
            saturday: selectedDays.Saturday ? 0 : undefined,
            sunday: selectedDays.Sunday ? 0 : undefined,
          }
          // If no days are selected, byDays should be null instead of an empty object.
          if (
            Object.values(draft.byDays).filter((x) => x !== undefined)
              .length === 0
          ) {
            draft.byDays = null
          }
        })
        onChange(newRule)
      },
      [onChange, rule],
    )

    // When this dialog gets opened, if repeat isn't already enabled,
    // then switch it on. This just saves the user from having to
    // switch it on manually.
    useEffect(() => {
      if (!rule) {
        onChange({
          frequency: Frequency.Weekly,
          interval: 1,
          byDays: {
            [startLocal.setLocale("en").weekdayLong.toLowerCase()]: 0,
          },
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Handle switching between "count" and "until" end modes
    const handleEndModeChange = useCallback(
      (newMode: EndMode) => {
        if (
          (newMode === "until" && !!rule?.until) ||
          (newMode === "count" && !!rule?.count)
        ) {
          return
        }
        const newRule = produce(rule, (draft: RuleArgs) => {
          if (!draft) return
          if (newMode === "until") {
            draft.count = undefined
            draft.until = {
              date: startLocal.toISODate(),
            }
          } else {
            draft.count = 5
            draft.until = undefined
          }
          return draft
        })
        onChange(newRule)
      },
      [onChange, rule, startLocal],
    )

    const handleIntervalChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(e.target.value)
        if (e.target.value && isNaN(input)) return

        const newRule = produce(rule, (draft) => {
          if (!draft) return
          draft.interval = input
          return draft
        })
        onChange(newRule)
      },
      [onChange, rule],
    )

    // Handle end calendar open/close
    const handleEndCalendarPress = useCallback(() => {
      setEndCalendarOpen((val) => !val)
    }, [])

    const handleEndDateSelect = useCallback(
      (date: DateTime, shouldClose: boolean) => {
        const newRule = produce(rule, (draft) => {
          if (!draft) return
          draft.until = {
            date: date.toISODate(),
          }
          return draft
        })
        onChange(newRule)
        if (shouldClose) {
          setEndCalendarOpen(false)
        }
      },
      [onChange, rule],
    )

    // Handle end count change
    const handleCountChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(e.target.value)
        if (e.target.value && isNaN(input)) return

        const newRule = produce(rule, (draft) => {
          if (!draft) return
          draft.count = input
          return draft
        })
        onChange(newRule)
      },
      [onChange, rule],
    )

    // Handle End toggle on/off
    const handleEndChange = useCallback(
      (value: boolean) => {
        if (value) {
          handleEndModeChange("until")
        } else {
          const newRule = produce(rule, (draft) => {
            if (!draft) return
            draft.until = undefined
            draft.count = undefined
            return draft
          })
          onChange(newRule)
        }
      },
      [handleEndModeChange, rule, onChange],
    )

    return (
      <Block
        ref={ref}
        icon={IconId.Repeat}
        title="Repeat"
        onClose={onClose}
        className="p-0"
      >
        <div
          onKeyDown={(e) => {
            // This is necessary to stop things from tabbing twice
            if (e.key === "Tab") {
              e.stopPropagation()
            }
          }}
        >
          <div className="min-w-[320px]">
            <>
              <div className="px-4 py-1">
                <div className="flex flex-row">
                  <Label
                    id="label-repeat-frequency"
                    className="
                        block mr-8
                        self-center
                        text-base
                        text-low-contrast
                      "
                  >
                    Every
                  </Label>
                  <FormInput
                    aria-labelledby="label-repeat-frequency"
                    type="number"
                    min={1}
                    max={365}
                    className="w-28 mr-2"
                    value={rule?.interval?.toString() || "1"}
                    onChange={handleIntervalChange}
                  />
                  <Select<Frequency>
                    aria-labelledby="label-repeat-frequency"
                    defaultValue={Frequency.Daily}
                    value={rule?.frequency || Frequency.Weekly}
                    onValueChange={handleFrequencyChange}
                    className="w-full"
                  >
                    <SelectItem value={Frequency.Daily}>
                      {rule?.interval === 1 ? "Day" : "Days"}
                    </SelectItem>
                    <SelectItem value={Frequency.Weekly}>
                      {rule?.interval === 1 ? "Week" : "Weeks"}
                    </SelectItem>
                    <SelectItem value={Frequency.Monthly}>
                      {rule?.interval === 1 ? "Month" : "Months"}
                    </SelectItem>
                    <SelectItem value={Frequency.Yearly}>
                      {rule?.interval === 1 ? "Year" : "Years"}
                    </SelectItem>
                  </Select>
                </div>
              </div>
              {rule?.frequency === Frequency.Weekly && (
                <div
                  className="
                    px-4 py-2 flex items-center justify-between
                  "
                >
                  <Label
                    id="label-repeat-days"
                    className="block text-base text-low-contrast"
                  >
                    On
                  </Label>
                  <WeekdayPicker
                    labelledBy="label-repeat-days"
                    selectedDays={selectedDays}
                    contentColors
                    multiSelectable
                    onChange={setSelectedDays}
                  />
                </div>
              )}
              <Label
                className="
                  px-2 pt-2 pb-1 mt-2 mx-2 border-t border-tint
                  flex flex-row items-center justify-center
                "
              >
                <span
                  className="
                    flex-1
                    py-2
                    block
                    text-base
                    text-low-contrast
                  "
                >
                  Stops repeating
                </span>
                <Switch
                  checked={
                    (rule?.count !== undefined && rule.count !== null) ||
                    !!rule?.until
                  }
                  contentColors
                  onCheckedChange={handleEndChange}
                />
              </Label>
              {((rule?.count !== undefined && rule.count !== null) ||
                !!rule?.until) && (
                <div
                  className="
                    px-1 pb-3
                    flex flex-row items-center justify-center
                  "
                >
                  <RadioGroup<EndMode>
                    className="w-full"
                    value={rule?.until ? "until" : "count"}
                    onValueChange={handleEndModeChange}
                  >
                    <RadioGroupItem
                      id="label-repeat-until"
                      contentColors
                      value="until"
                      className="py-1"
                    >
                      <div
                        onClick={() => handleEndModeChange("until")}
                        className="w-full flex flex-row items-center space-x-3 p-1"
                      >
                        <span className="w-12 flex-shrink-0 text-base text-low-contrast">
                          On
                        </span>
                        <Popover
                          modal
                          onOpenChange={(open) => setEndCalendarOpen(open)}
                          open={endCalendarOpen}
                          content={
                            endCalendarOpen && !!rule?.until?.date ? (
                              <Datepicker
                                today={now}
                                minDate={startLocal}
                                selectedDate={DateTime.fromISO(
                                  rule.until.date,
                                  {
                                    zone: startLocal.zoneName,
                                  },
                                )}
                                onDateSelect={handleEndDateSelect}
                              />
                            ) : undefined
                          }
                          className="p-4"
                          side="bottom"
                          align="end"
                        >
                          {({ open }) => (
                            <Tooltip content={"Choose a date"}>
                              <SelectTarget
                                open={open}
                                ref={endCalButtonRef}
                                className="w-48"
                                onPress={handleEndCalendarPress}
                                disabled={rule?.until === undefined}
                              >
                                <span className="flex-1">
                                  {(rule.until?.date
                                    ? DateTime.fromISO(rule.until.date, {
                                        zone: startLocal.zoneName,
                                      })
                                    : startLocal
                                  ).toFormat("d MMM yyyy")}
                                </span>
                              </SelectTarget>
                            </Tooltip>
                          )}
                        </Popover>
                      </div>
                    </RadioGroupItem>
                    <RadioGroupItem
                      className="mt-px py-1"
                      contentColors
                      id="end-mode-count"
                      value="count"
                    >
                      <div
                        onClick={() => handleEndModeChange("count")}
                        className="flex flex-row items-center space-x-3 p-1"
                      >
                        <span className="w-12 flex-shrink-0 text-base text-low-contrast">
                          After
                        </span>
                        <FormInput
                          type="number"
                          value={
                            rule?.count?.toString() ||
                            DEFAULT_END_COUNT_VALUE.toString()
                          }
                          min={1}
                          className="w-16"
                          onChange={handleCountChange}
                          onFocus={() => handleEndModeChange("count")}
                          disabled={rule?.count === undefined}
                        />
                        <span className="text-base">occurrences</span>
                      </div>
                    </RadioGroupItem>
                  </RadioGroup>
                </div>
              )}
            </>
          </div>
        </div>
      </Block>
    )
  },
)
RepeatBlockFn.displayName = "RepeatBlock"

export const RepeatBlock = memo(RepeatBlockFn)
