import { atom, SetterOrUpdater, useRecoilState } from "recoil"

// Pass the calendar ID to edit, or undefined to close the modal.
export const shareCalendarIdAtom = atom<string | undefined>({
  key: "share_calendar_id",
  default: undefined,
})
export const useShareCalendarId = (): [
  string | undefined,
  SetterOrUpdater<string | undefined>,
] => useRecoilState(shareCalendarIdAtom)
