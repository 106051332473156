import { IconId } from "@daybridge/client-api"
import { Button, ButtonVariant, ButtonSize } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"

export interface ShareItemHeaderProps {
  onClose: (value: boolean) => void
}

export const ShareItemHeader: React.FC<ShareItemHeaderProps> = React.memo(
  (props: ShareItemHeaderProps) => {
    return (
      <div className="border-b border-tint flex items-center pl-6 pr-3 h-14">
        <h1 className="text-high-contrast font-bold truncate flex-1">
          Share Event
        </h1>
        <Button
          variant={ButtonVariant.Clear}
          size={ButtonSize.IconAction}
          onPress={() => props.onClose(false)}
        >
          <I type={IconId.Cross} />
        </Button>
      </div>
    )
  },
)

ShareItemHeader.displayName = "ShareItemHeader"
