import { useAnalytics } from "@daybridge/analytics"
import { useCallback, useMemo } from "react"
import { DaysVisible } from "@daybridge/client-api"
import usePatchMultiplePreferences from "../../../settings/hooks/preferences/usePatchMultiplePreferences"
import {
  PresetCombo,
  PresetValueAndSetter,
  Preset,
} from "../../../settings/types/presets"
import usePreference from "../../../settings/hooks/preferences/usePreference"

const PRESET_COMBOS: PresetCombo = {
  plan: {
    daysVisible: DaysVisible.Seven,
    hideWorkItems: false,
    hideFreeTime: false,
  },
  focus: {
    daysVisible: DaysVisible.One,
    hideWorkItems: false,
    hideFreeTime: false,
  },
  relax: {
    daysVisible: DaysVisible.Three,
    hideWorkItems: true,
    hideFreeTime: true,
  },
} as const

const usePreset: () => PresetValueAndSetter = () => {
  const patchMultiplePreferences = usePatchMultiplePreferences()
  const [daysVisible] = usePreference("daysVisible")
  const [hideFreeTime] = usePreference("hideFreeTime")
  const [hideWorkItems] = usePreference("hideWorkItems")
  const { track } = useAnalytics()

  // Get the active preset, only returned if the combo is an exact match of
  // the user's preferences.
  const activePreset: Preset | undefined = useMemo(() => {
    for (const [key, combo] of Object.entries(PRESET_COMBOS)) {
      if (
        daysVisible === combo.daysVisible &&
        hideWorkItems === combo.hideWorkItems &&
        hideFreeTime === combo.hideFreeTime
      ) {
        return key as Preset
      }
    }

    return undefined
  }, [daysVisible, hideFreeTime, hideWorkItems])

  // Callback to set a new preset (i.e. a combination of preferences).
  const setPreset = useCallback(
    (preset: Preset, keyboardShortcut = false) => {
      if (!PRESET_COMBOS[preset]) {
        throw new Error(`Preset not defined: ${preset}`)
      }
      track("preset.enable", {
        preset,
        keyboardShortcut: keyboardShortcut ? "true" : undefined,
      })
      return patchMultiplePreferences(PRESET_COMBOS[preset])
    },
    [patchMultiplePreferences, track],
  )

  return [activePreset, setPreset]
}

export default usePreset
