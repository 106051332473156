import { IconId, Place } from "@daybridge/client-api"
import { SelectTarget } from "@daybridge/components-core"
import { ForwardedRef, forwardRef, memo } from "react"
import { Block } from "../common/Block"
import { LocationMenu } from "./LocationMenu"

interface LocationBlockProps {
  location: string | Place | undefined
  onSelect: (location: string | Place, sessionId: string) => void
  onClose: () => void
}

const LocationBlockFn = forwardRef(
  (
    { location, onSelect, onClose }: LocationBlockProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <Block ref={ref} icon={IconId.MapPin} title="Location" onClose={onClose}>
        <LocationMenu onLocationSelect={onSelect}>
          {({ open }) => (
            <SelectTarget open={open} className="w-full">
              {location ? (
                <span className="truncate flex-1 text-left">
                  {typeof location === "string" ? location : location.name}
                </span>
              ) : (
                <span className="text-low-contrast flex-1 text-left">
                  Search...
                </span>
              )}
            </SelectTarget>
          )}
        </LocationMenu>
      </Block>
    )
  },
)
LocationBlockFn.displayName = "LocationBlock"

export const LocationBlock = memo(LocationBlockFn) as typeof LocationBlockFn

/*
Todo: add map (we'll need to make another PlacesDetails request). When you fetch the place details, provide the IdempotencyKey from the autocomplete request. For strange billing reasons, this actually makes it a lot cheaper.
{location &&
          typeof location !== "string" &&
          location.coordinates &&
          location.viewportBottomLeft &&
          location.viewportTopRight &&
          env.NEXT_PUBLIC_MAPBOX_PUBLIC_ACCESS_TOKEN && (
            <div className="flex-1 flex-shrink-0 relative min-h-[10rem]">
              <div
                className="
                  absolute inset-x-px z-10 top-0 h-36 
                  bg-gradient-to-b from-above-surface 
                  pointer-events-none
                "
                <div className="h-24">
                  <Map
                    color={color || Color.Gray400}
                    publicAccessToken={env.NEXT_PUBLIC_MAPBOX_PUBLIC_ACCESS_TOKEN}
                    latitude={location.coordinates.latitude}
                    longitude={location.coordinates.longitude}
                    bottomLeft={[
                      location.viewportBottomLeft.longitude,
                      location.viewportBottomLeft.latitude,
                    ]}
                    topRight={[
                      location.viewportTopRight.longitude,
                      location.viewportTopRight.latitude,
                    ]}
                    pitch={60}
                    markerSize={5}
                    className={`text-${colorToClass(color || Color.Gray400)}`}
                  />
                </div>
              </div>)}
*/
