/* eslint-disable jsx-a11y/media-has-caption */
import { WebNotificationsSound } from "@daybridge/client-api"
import { useTime } from "@daybridge/server-time"
import { DateTime } from "luxon"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useHourLevelItems } from "../../items/hooks/useHourLevelItems"
import { ItemWithResolvedTimes } from "../../items/types/itemWithResolvedTimes"
import usePreference from "../../settings/hooks/preferences/usePreference"

// Refresh scheduled notifications at least once every
// 5 minutes. In practice they will be refreshed more often
// than this as the user navigates around the app, but if the
// app is backgrounded then we need to force a refresh here.
const REFRESH_INTERVAL_MS = 5 * 60 * 1000

/**
 * -----------------
 * Notifications
 * -----------------
 *
 * A simple desktop notifications manager for sending alerts for upcoming events.
 * TODOs:
 * - Support all day items
 * - Send alerts selectively
 * - Support sending alerts at times other than just 10 mins before
 * - Support sending alerts longer than 24 hours in advance
 */
export const LocalNotifications = React.memo(() => {
  // Keep a reference to the audio chime so we can play it
  // when a notification is sent.
  const audioRef = useRef<HTMLAudioElement>(null)

  // Load notification settings.
  const [isNotificationsEnabled] = usePreference("webNotifications")
  const [notificationSound] = usePreference("webNotificationsSound")

  // This is a Safari workaround to make sure
  // the audio notification can play.
  useEffect(() => {
    function unlockAudio() {
      void unlockAudioAsync()
    }
    async function unlockAudioAsync() {
      if (!audioRef || !audioRef.current) return
      await audioRef.current.play().catch(() => null)
      audioRef.current.pause()
      audioRef.current.currentTime = 0

      window.removeEventListener("click", unlockAudio)
      window.removeEventListener("touchstart", unlockAudio)
    }

    window.addEventListener("click", unlockAudio)
    window.addEventListener("touchstart", unlockAudio)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef.current])

  // Get upcoming items
  const currentTime = useTime(10000)
  const windowStart = useMemo(
    () => currentTime.startOf("day").toUTC(),
    [currentTime],
  )
  const windowEnd = useMemo(() => windowStart.plus({ days: 2 }), [windowStart])
  const { items } = useHourLevelItems(windowStart, windowEnd, undefined, {
    // Only enable this if notifications are supported by this browser.
    enabled: typeof window !== "undefined" && "Notification" in window,
    // Refetch new items in the background so that if you make a change on
    // mobile, web notifications will automatically adjust.
    refetchInterval: REFRESH_INTERVAL_MS,
    refetchIntervalInBackground: true,
  })

  const sendNotification = useCallback(
    (item: ItemWithResolvedTimes) => {
      // Don't send notifications if they are not supported by this browser.
      if (!(typeof window !== "undefined" && "Notification" in window)) return

      // Don't send notifications if they are disabled in settings. Note that
      // this may be null and we default to `true` in that case, so we want to
      // check for `false` explicitly.
      if (isNotificationsEnabled === false) return

      if (Notification.permission !== "granted") {
        // The server-side notifications manager will request permission
        // when the user logs in. If it hasn't been granted, don't do it here.
        return
      }

      const notification = new Notification(item.title, {
        body:
          "Starting at " +
          item.startLocal.toLocal().toLocaleString(DateTime.TIME_SIMPLE),
        icon: "/apple-touch-icon.png",
        silent: true,
      })
      notification.onshow = async () => {
        // Play an audio tone when the notification appears, unless
        // notifications are muted in settings.
        if (notificationSound === WebNotificationsSound.Marimba) {
          await audioRef.current?.play().catch(() => null)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNotificationsEnabled, notificationSound, audioRef.current],
  )

  useEffect(() => {
    // Return early if no items
    if (!items || items.length === 0) return

    // Remove any virtual items (e.g. selected region)
    const filteredItems: ItemWithResolvedTimes[] = items.filter(
      (item) => item.__typename === "Item",
    ) as ItemWithResolvedTimes[]

    // Figure out at what time to schedule each alert
    const timeouts = filteredItems.map((item) => {
      const alertTime = item.startLocal.minus({ minute: 10 })
      if (alertTime < currentTime) return undefined

      const delay = alertTime.diff(currentTime).as("milliseconds")
      return setTimeout(() => sendNotification(item), delay)
    })

    return () => {
      // Cleanup function. Clear old scheduled notifications
      // when the data is refreshed and notifications are
      // re-scheduled.
      timeouts.forEach((timeout) => {
        if (timeout) {
          clearTimeout(timeout)
        }
      })
    }
  }, [items, currentTime, sendNotification])

  return (
    <>
      <audio ref={audioRef}>
        <source src="/assets/notifications/marimba.mp3" type="audio/mpeg" />
      </audio>
    </>
  )
})
LocalNotifications.displayName = "Notifications"
