import { useAnalytics } from "@daybridge/analytics"
import { DaysVisible, useMeQuery } from "@daybridge/client-api"
import { Datepicker } from "@daybridge/components-core"
import { useTime } from "@daybridge/server-time"
import React from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { useNavigationDate } from "../../hooks/navigation/useNavigationDate"
import { useTimeNavigation } from "../../hooks/navigation/useTimeNavigation"

const TICK_INTERVAL = 10000

/**
 * `TimelineDatePicker` allows users to jump to a specific date.
 */
export const TimelineDatePicker: React.FC = React.memo(() => {
  // To render the datepicker correctly, we need to know the timezone the user
  // is viewing the timeline in.
  const { data: account } = useMeQuery(undefined, { refetchOnMount: false })
  const timeZone = account?.me?.account.preferences?.timeZone
  const now = useTime(TICK_INTERVAL, timeZone)
  const { track } = useAnalytics()
  const navigationDate = useNavigationDate(TICK_INTERVAL, timeZone)
  const { jumpToDate } = useTimeNavigation()
  const [daysVisible] = usePreference("daysVisible")

  return (
    <Datepicker
      today={now}
      disableWeekends={daysVisible === DaysVisible.Five}
      selectedDate={navigationDate}
      onDateSelect={(date) => {
        track("calendar.navigate", { method: "mini_calendar" })
        jumpToDate(date)
      }}
    />
  )
})
TimelineDatePicker.displayName = "TimelineDatePicker"
