import { IconId } from "@daybridge/client-api"
import { Button, ButtonSize, ButtonVariant } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { DateTime } from "luxon"
import React from "react"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { useViewportPosition } from "../../../timeline/hooks/rendering/useViewportPosition"
import { BaseBlockItem, BlockItemProps } from "./common/BaseBlockItem"
import { BlockTimeLabels } from "./common/BlockTimeLabels"

type BlockRenderItemProps = Omit<BlockItemProps, "start" | "end" | "isDayLevel">

type HiddenItemProps = {
  isAgenda: boolean
  dayLevel: boolean
  startLocal: DateTime
  endLocal: DateTime
  renderProps: BlockRenderItemProps
}

export const HiddenItem: React.FC<HiddenItemProps> = React.memo((props) => {
  const [, setHideWorkItems] = usePreference("hideWorkItems")
  const { viewportHeight } = useViewportPosition()
  const height =
    props.renderProps.fixedHeight ||
    (props.renderProps.percentageHeight || 0) * (viewportHeight || 0)

  return (
    <BaseBlockItem
      start={props.startLocal}
      end={props.endLocal}
      isAgenda={props.isAgenda}
      isDayLevel={props.dayLevel}
      {...props.renderProps}
    >
      <Button
        size={ButtonSize.Custom}
        variant={ButtonVariant.Headless}
        onPress={() => void setHideWorkItems(false)}
        className={`
            cursor-default
            w-full h-full 
            rounded-md
          `}
      >
        <div
          className={`
              w-full h-full
              overflow-hidden
              bg-background text-low-contrast
              border-surface
              ${
                props.renderProps.xOffset === 0 && !props.dayLevel
                  ? "border"
                  : ""
              }
              ${
                props.renderProps.xOffset !== undefined &&
                props.renderProps.xOffset > 0 &&
                !props.dayLevel
                  ? "border-2"
                  : ""
              }
              text-sm
              flex items-center justify-center
              ${
                /* Corner Rounding Logic */
                props.dayLevel
                  ? /* Day-level cards aren't rounded on the right side if they overflow */
                    "rounded-l-lg " +
                    (!props.renderProps.continues ? " rounded-r-lg " : "")
                  : /* Cards on the timeline aren't rounded on the top/bottom if they have already started/continue */
                    (!props.renderProps.alreadyStarted ? "rounded-t-lg " : "") +
                    (!props.renderProps.continues ? " rounded-b-lg " : "")
              }
          `}
        >
          <div className="opacity-50 text-center flex items-center space-x-2 justify-center">
            <I
              type={IconId.EyeInactive}
              className="w-3.5 h-3.5 text-low-contrast"
            />
            {!props.dayLevel &&
              height > 46 &&
              (!props.startLocal.equals(props.startLocal.startOf("day")) ||
                !props.endLocal.equals(props.endLocal.startOf("day"))) && (
                <BlockTimeLabels
                  start={props.startLocal}
                  end={props.endLocal}
                  width={props.renderProps.width}
                  alreadyStarted={props.renderProps.alreadyStarted}
                  continues={props.renderProps.continues}
                  singleLabelMinWidth={0}
                  doubleLabelMinWidth={125}
                />
              )}
          </div>
        </div>
      </Button>
    </BaseBlockItem>
  )
})
HiddenItem.displayName = "HiddenItem"
