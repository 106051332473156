import { IconId } from "@daybridge/client-api"
import { I } from "@daybridge/icons"
import React from "react"

interface ContextCardProps {
  children: React.ReactNode
  className?: string
  icon?: IconId
}

const ContextCardFn = React.forwardRef(
  (props: ContextCardProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { icon, className, children, ...rest } = props
    return (
      <div
        className={`
          mx-4 mb-4
          rounded-2xl p-4 bg-elevated shadow-sm
          ${className || ""}
        `}
        ref={ref}
        {...rest}
      >
        {icon ? (
          <div className="flex flex-row w-full">
            <div className="flex-shrink-0 rounded-full p-3.5 mr-3 w-10 h-10 text-content-body bg-content-background">
              <I type={icon} />
            </div>
            <div className="flex-1 truncate">{children}</div>
          </div>
        ) : (
          children
        )}
      </div>
    )
  },
)
ContextCardFn.displayName = "ContextCard"

export const ContextCard = React.memo(ContextCardFn) as typeof ContextCardFn
