import { DateTime } from "luxon"
import { useMemo } from "react"
import { DayPart } from "../../types/dayParts"
import { ItemWithResolvedTimes } from "../../../items/types/itemWithResolvedTimes"

/**
 * `useAgendaViewTimeIndicatorPosition` determines the location we should place
 * the current time indicator, which is a horizontal line across the current day.
 * This allows the user to easily visualise the current time and progress through their day.
 */
export const useAgendaViewTimeIndicatorPosition = (
  dayLocal: DateTime,
  nowLocal: DateTime,
  dayPart: DayPart,
  items: ItemWithResolvedTimes[] | undefined,
): number | undefined => {
  const isInThisDayPart = useMemo(() => {
    // If the days don't match, we should never display the time indicator.
    if (!nowLocal.hasSame(dayLocal, "day")) return false
    switch (dayPart) {
      case "All day":
        return false // The time indicator should never be inside the "all day" section.
      case "Morning":
        return nowLocal.hour < 12
      case "Afternoon":
        return nowLocal.hour >= 12 && nowLocal.hour < 18
      case "Evening":
        return nowLocal.hour >= 18
    }
  }, [dayLocal, dayPart, nowLocal])

  return useMemo(() => {
    // Don't display the indicator if it's not inside this day part.
    if (!isInThisDayPart) return undefined
    // If it is inside this day part, we need to calculate the position. This is
    // returned as the number of items it needs to pass until displaying the indicator.
    const position = items?.reduce(
      (acc, item) => acc + (item.startLocal > nowLocal ? 0 : 1),
      0,
    )
    return position
  }, [isInThisDayPart, items, nowLocal])
}
