import { IconId } from "@daybridge/client-api"
import { ContextMenu, ContextMenuItem } from "@daybridge/components-core"
import React from "react"
import { useSetCalendarSelected } from "../../../timeline/hooks/selected-calendars/useSetCalendarSelected"
import { CardProps } from "./CardProps"

type CardContextMenuProps = CardProps & {
  children: React.ReactElement
}

const CardContextMenuFn = React.forwardRef(
  (
    props: CardContextMenuProps,
    ref?: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const setCalendarSelected = useSetCalendarSelected()

    return (
      <ContextMenu
        ref={ref}
        modal
        content={
          <>
            {(props.onEdit || props.onShare) && (
              <>
                {props.onEdit && (
                  <ContextMenuItem
                    icon={IconId.EditOutline}
                    onSelect={props.onEdit}
                  >
                    Edit
                  </ContextMenuItem>
                )}
                {props.onShare && (
                  <ContextMenuItem icon={IconId.Link} onSelect={props.onShare}>
                    {props.sharing?.isPublic ? "Get event link" : "Share event"}
                  </ContextMenuItem>
                )}
              </>
            )}
            <ContextMenuItem
              icon={IconId.EyeInactive}
              onSelect={() => {
                void setCalendarSelected(props.calendar.id, false)
              }}
            >
              Hide {props.calendar.name}
            </ContextMenuItem>
          </>
        }
      >
        {props.children}
      </ContextMenu>
    )
  },
)
CardContextMenuFn.displayName = "CardContextMenu"

export const CardContextMenu = React.memo(
  CardContextMenuFn,
) as typeof CardContextMenuFn
