import { Modal } from "@daybridge/components-core"
import React from "react"
import { useShowAppsPopup } from "../state/showAppsPopup"
import { DownloadMobileApps } from "./DownloadMobileApps"

export const AppsPopupCoordinator: React.FC = React.memo(() => {
  const [showAppsPopup, setShowAppsPopup] = useShowAppsPopup()
  return (
    <Modal
      open={showAppsPopup}
      className="rounded-3xl border-none"
      content={
        <div className="w-full bg-surface rounded-[32px] overflow-hidden flex min-h-[680px]">
          <DownloadMobileApps />
        </div>
      }
      onOpenChange={(open) => {
        if (!open) setShowAppsPopup(false)
      }}
    />
  )
})
AppsPopupCoordinator.displayName = "AppsPopupCoordinator"
