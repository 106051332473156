import { IconId } from "@daybridge/client-api"
import { Button, ButtonSize, ButtonVariant } from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { AnimatePresence, motion } from "framer-motion"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { TourStep, useTourStep } from "../state/useTourStep"

const TOUR_STEPS: {
  name: TourStep
  text: string
  className: string
  classNameOnArrow: string
}[] = [
  {
    name: "timeline",
    text: "Welcome to your new calendar! Here you'll find your events, colour-coded by category.",
    className: "bottom-24 inset-x-0 mx-auto",
    classNameOnArrow: "-top-2 right-24",
  },
  {
    name: "view",
    text: "Find your perfect layout. Hide free time, or mute work events – both with quick shortcuts.",
    className: "top-44 left-20",
    classNameOnArrow: "top-6 -left-2",
  },
  {
    name: "create",
    text: "Add a new event by clicking here, or drag anywhere on your timeline.",
    className: "top-20 right-6",
    classNameOnArrow: "-top-2 right-4",
  },
]

// TODO: Add an effect to open the sidebar when it's closed and the view options
// are demonstrated. We can't do that right now because the sidebar
// `onOpenChange` does not trigger when updating the `sidebarOpen` preference,
// likely related to this Radix bug:
// https://github.com/glennflanagan/react-collapsible/issues/219
export const TourBoxCoordinator: React.FC = React.memo(() => {
  const [activeStep, setActiveStep] = useTourStep()
  const [isLoaded, setIsLoaded] = useState(false)

  // Wait 1.2 seconds before showing the tour box when the user first loads the app.
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 1200)
  }, [])

  const activeStepIndex = TOUR_STEPS.findIndex(
    (step) => step.name === activeStep,
  )

  const isFinalStep = useMemo(() => {
    return activeStepIndex === TOUR_STEPS.length - 1
  }, [activeStepIndex])

  const handlePrevious = useCallback(() => {
    if (activeStepIndex === 0) return
    setActiveStep(TOUR_STEPS[activeStepIndex - 1].name)
  }, [activeStepIndex, setActiveStep])

  const handleContinue = useCallback(() => {
    setActiveStep(
      activeStepIndex === TOUR_STEPS.length - 1
        ? undefined
        : TOUR_STEPS[activeStepIndex + 1].name,
    )
  }, [activeStepIndex, setActiveStep])

  const { name, text, className, classNameOnArrow } =
    TOUR_STEPS[activeStepIndex] || {}

  return (
    <AnimatePresence>
      {isLoaded && name && (
        <motion.div
          key={name}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              bounce: 0.4,
              duration: 0.4,
              delay: 0.4,
            },
          }}
          exit={{
            scale: 0.8,
            opacity: 0,
            transition: {
              type: "spring",
              bounce: 0.4,
              duration: 0.4,
              delay: 0,
            },
          }}
          className={`fixed w-96 z-50 shadow-deep bg-primary rounded-xl ${className}`}
        >
          <div
            className={`absolute rotate-45 h-4 w-4 bg-primary ${classNameOnArrow}`}
          />
          <div className="relative py-5 px-6 text-white">
            <div className="font-medium mb-10">{text}</div>
            <div className="flex">
              <div className="text-sm text-white flex-1 self-center">
                Getting Started &middot; {activeStepIndex + 1} of{" "}
                {TOUR_STEPS.length}
              </div>
              {activeStepIndex > 0 && (
                <Button
                  variant={ButtonVariant.Clear}
                  size={ButtonSize.Base}
                  onPress={handlePrevious}
                  className="!px-3 mr-2 hover:bg-white/20"
                >
                  <I type={IconId.ChevronLeft} className="w-3 h-3 text-white" />
                </Button>
              )}
              <Button
                variant={ButtonVariant.OutlineAlpha}
                size={ButtonSize.Base}
                onPress={handleContinue}
              >
                {isFinalStep ? "Let's go!" : "Next"}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
})

TourBoxCoordinator.displayName = "TourBoxCoordinator"
