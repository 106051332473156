import { useAuth } from "@daybridge/auth"
import { CalendarPeopleInfo, IconId, ProfileEdge } from "@daybridge/client-api"
import {
  AlertDialog,
  EntityWithOptions,
  Avatar,
  Button,
  ButtonSize,
  ButtonVariant,
  ComboboxMenu,
  ComboboxMenuItem,
  Menu,
  MenuItem,
  Tooltip,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React, { useCallback, useState } from "react"
import { useContacts } from "../../../contacts/hooks/useContacts"
import { useCalendars } from "../../hooks/useCalendars"
import { useEditCalendar } from "../../hooks/useEditCalendar"
import { useShareCalendarId } from "../../state/sharing"

export const ShareCalendarModal: React.FC = React.memo(() => {
  const [shareCalendarId, setShareCalendarId] = useShareCalendarId()
  const { user } = useAuth()
  const { calendars } = useCalendars()
  const [alert, setAlert] = useState<
    { type: string; person: CalendarPeopleInfo } | undefined
  >(undefined)
  const { contacts, isLoading } = useContacts()
  const calendar = calendars.find((calendar) => calendar.id === shareCalendarId)
  const patch = useEditCalendar()

  const currentUserIsOwner =
    user?.uid &&
    calendar?.people?.some(
      (person) => person.owner && person.profile?.node.id === user.uid,
    )

  const queryContacts = useCallback(
    (value: string) => {
      if (value[0] === "@") value = value.slice(1)
      return contacts
        .filter(
          (contact) =>
            contact.profile?.node.displayName
              ?.toLowerCase()
              .includes(value.toLowerCase()) ||
            contact.profile?.node.username
              ?.toLowerCase()
              .includes(value.toLowerCase()),
        )
        .map((contact) => {
          const alreadyAdded = calendar?.people?.some(
            (p) => p.profile?.id === contact.id,
          )
          return (
            <ComboboxMenuItem
              key={contact.id}
              disabled={!contact.mutual || alreadyAdded}
              onSelect={() => {
                if (!calendar || !contact.mutual || alreadyAdded) return
                patch(
                  {
                    input: {
                      id: calendar.id,
                      patch: {
                        people: [
                          {
                            key: contact.id,
                          },
                        ],
                      },
                    },
                  },
                  {
                    loadingMessage: `Adding ${
                      contact.profile?.node.displayName || "user"
                    }...`,
                    successTitle: `${
                      contact.profile?.node.displayName || "user"
                    } added`,
                  },
                )
              }}
              className="py-2"
            >
              <div className="flex flex-row items-center space-x-3 w-full">
                <div
                  className="
                    w-9 h-9
                    flex items-center justify-center flex-shrink-0
                    bg-background
                    rounded-full
                  "
                >
                  <Avatar
                    url={contact.profile?.node.avatar || undefined}
                    defaultInitials={contact.profile?.node.displayName?.[0]}
                    className="text-lg"
                  />
                </div>
                <div className="flex flex-col justify-center truncate">
                  <div>{contact.profile?.node?.displayName}</div>
                  <div className="text-sm text-low-contrast truncate">
                    {alreadyAdded
                      ? "Already added"
                      : contact.mutual
                      ? `@${contact.profile?.node.username || ""}`
                      : "Doesn't have you in their contacts"}
                  </div>
                </div>
              </div>
            </ComboboxMenuItem>
          )
        })
    },
    [calendar, contacts, patch],
  )

  return (
    <div className="p-6 pb-4 pt-24">
      <div className="absolute rounded-t-xl overflow-hidden top-0 inset-x-0 z-20 bg-surface/80 backdrop-blur pl-6 p-4">
        <div className="flex items-center">
          <I
            type={IconId.Users}
            className="w-3 h-3 mr-3 flex-shrink-0 text-icon-decorative"
          />
          <div className="text-base text-low-contrast">
            {calendar?.people ? "Manage sharing" : "Share calendar"}
          </div>
          <ComboboxMenu
            align="center"
            loading={isLoading}
            className="!w-[325px]"
            placeholder="Search by username..."
            defaultText="Start typing to find people"
            noResultsText={`Couldn't find this person in your contacts. You can only search for exact usernames for now.`}
            items={queryContacts}
          >
            {() => (
              <Button
                variant={ButtonVariant.Outline}
                size={ButtonSize.Base}
                className="ml-auto mr-1.5"
              >
                <I type={IconId.Plus} className="w-2.5 h-2.5 mr-2" />
                Add
              </Button>
            )}
          </ComboboxMenu>
          <Button
            onPress={() => void setShareCalendarId(undefined)}
            variant={ButtonVariant.Clear}
            size={ButtonSize.IconAction}
          >
            <I type={IconId.Cross} />
          </Button>
        </div>

        <div className="text-high-contrast -mt-0.5 w-64 truncate font-semibold text-lg">
          {calendar?.name}
        </div>
      </div>

      {calendar?.people?.length ? (
        <div className="mt-1">
          {calendar.people
            .filter((p) => p.profile)
            .map((person) => {
              // Permissions
              const ownerViewingSelf = !!(person.owner && currentUserIsOwner)
              const ownerViewingOther = !!(!person.owner && currentUserIsOwner)
              const memberViewingSelf = !!(
                !currentUserIsOwner &&
                user?.uid &&
                person.profile?.id === user.uid
              )
              const memberViewingOther = !!(
                !currentUserIsOwner &&
                user?.uid &&
                person.profile?.id !== user.uid
              )

              return (
                <EntityWithOptions
                  key={(person.profile as ProfileEdge).id}
                  title={person.profile?.node.displayName || "User"}
                  description={person.owner ? "Owner" : undefined}
                  className="p-2"
                  icon={
                    <div className="w-10 flex-shrink-0">
                      <Avatar
                        defaultInitials={person.profile?.node.displayName?.[0]}
                        url={person.profile?.node.avatar || undefined}
                        className="text-lg"
                      />
                    </div>
                  }
                  options={
                    currentUserIsOwner || memberViewingSelf ? (
                      <Menu
                        side="right"
                        align="start"
                        content={
                          <>
                            {ownerViewingOther && (
                              <MenuItem
                                icon={IconId.ArrowRight}
                                onSelect={() =>
                                  setAlert({ type: "transfer", person })
                                }
                              >
                                Transfer ownership
                              </MenuItem>
                            )}
                            <Tooltip
                              content={
                                ownerViewingSelf
                                  ? "Transfer ownership to another member first."
                                  : undefined
                              }
                            >
                              <MenuItem
                                disabled={
                                  ownerViewingSelf || memberViewingOther
                                }
                                icon={IconId.Cross}
                                onSelect={() =>
                                  setAlert({ type: "remove", person })
                                }
                              >
                                {person.profile?.id === user.uid
                                  ? "Leave calendar"
                                  : "Remove from calendar"}
                              </MenuItem>
                            </Tooltip>
                          </>
                        }
                      >
                        <Button
                          variant={ButtonVariant.Clear}
                          size={ButtonSize.IconAction}
                        >
                          <I type={IconId.EllipsisVertical} />
                        </Button>
                      </Menu>
                    ) : undefined
                  }
                />
              )
            })}
        </div>
      ) : (
        <div className="h-40 text-base text-center text-low-contrast flex items-center justify-center">
          You haven&apos;t shared this calendar with anyone.
          <br />
          Press the + button above to add people.
        </div>
      )}

      <AlertDialog
        open={alert?.type === "transfer"}
        onOpenChange={() => setAlert(undefined)}
        onActionPress={() => {
          if (!calendar || !alert || !user) return
          patch(
            {
              input: {
                id: calendar.id,
                patch: {
                  people: [
                    {
                      key: (alert.person.profile as ProfileEdge).id,
                      value: { owner: true },
                    },
                    // Explicitly setting self to false for extra backend validation.
                    {
                      key: user.uid,
                      value: { owner: false },
                    },
                  ],
                },
              },
            },
            {
              loadingMessage: `Transferring ownership to ${
                alert.person.profile?.node.displayName || "user"
              }...`,
              successTitle: `Ownership transferred to ${
                alert.person.profile?.node.displayName || "user"
              }`,
            },
          )
        }}
        icon={IconId.ArrowRight}
        title={`Transfer ownership to ${
          alert?.person.profile?.node.displayName || "user"
        }`}
        description={`Are you sure you want to transfer ownership to ${
          alert?.person.profile?.node.displayName || "user"
        }? You'll no longer be able to manage this calendar.`}
        actionButtonVariant={ButtonVariant.Error}
        actionButtonText={"Transfer"}
      />

      <AlertDialog
        open={alert?.type === "remove"}
        onOpenChange={() => setAlert(undefined)}
        onActionPress={() => {
          if (!calendar || !alert || !user) return
          const isSelf = alert.person.profile?.id === user.uid
          patch(
            {
              input: {
                id: calendar.id,
                patch: {
                  people: [
                    {
                      key: (alert.person.profile as ProfileEdge).id,
                      value: null,
                    },
                  ],
                },
              },
            },
            {
              loadingMessage: `Removing ${
                isSelf
                  ? "you"
                  : alert.person.profile?.node.displayName || "user"
              } from calendar...`,
              successTitle: `Removed ${
                isSelf
                  ? "you"
                  : alert.person.profile?.node.displayName || "user"
              } from calendar`,
            },
          )
          // Close modal if leaving calendar.
          if (isSelf) {
            setShareCalendarId(undefined)
          }
        }}
        icon={IconId.Cross}
        title={`Remove ${alert?.person.profile?.node.displayName || "user"}`}
        description={
          alert?.person.profile?.id === user?.uid ? (
            <>
              Are you sure you want to leave
              <span className="font-semibold">
                {calendar?.name || "this calendar"}
              </span>
              ?
            </>
          ) : (
            <>
              Are you sure you want to remove{" "}
              {alert?.person.profile?.node.displayName || "user"} from{" "}
              <span className="font-semibold">
                {calendar?.name || "this calendar"}
              </span>
              ?
            </>
          )
        }
        actionButtonVariant={ButtonVariant.Error}
        actionButtonText={"Remove"}
      />
    </div>
  )
})
ShareCalendarModal.displayName = "ShareCalendarModal"
