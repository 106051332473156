import { IconId } from "@daybridge/client-api"
import { I } from "@daybridge/icons"
import { DateTime } from "luxon"
import React from "react"

interface BlockTimeLabelsProps {
  // Start and end times to render
  start: DateTime
  end: DateTime

  // If `alreadyStarted` is true then the start time won't be shown
  // and instead the label will read like "Ends at 5:00 PM"
  alreadyStarted?: boolean

  // If `continues` is true then the end time won't be shown
  // and instead the label will read like "Starts at 5:00 PM"
  continues?: boolean

  // `width` is the width of the block item.
  width: number

  // `singleLabelMinWidth` is the minimum width at which one
  // of the labels will be shown
  singleLabelMinWidth: number

  // `doubleLabelMinWidth` is the minimum block width at which two
  // of the labels will be shown. If false, then only one label
  // will ever be shown.
  doubleLabelMinWidth: number | false
}

export const BlockTimeLabels: React.FC<BlockTimeLabelsProps> = React.memo(
  (props: BlockTimeLabelsProps) => {
    let label: React.ReactNode = ""

    const showSingleLabel = props.width >= props.singleLabelMinWidth
    const showDoubleLabel =
      props.doubleLabelMinWidth !== false &&
      props.width >= props.doubleLabelMinWidth

    if (props.alreadyStarted && props.continues) {
      // This block runs for the whole day
      label = showSingleLabel ? "All day" : null
    } else if (props.alreadyStarted) {
      // This block has already started on a previous day
      label = showDoubleLabel
        ? `Ends at ${props.end.toLocaleString(DateTime.TIME_SIMPLE)}`
        : null
    } else if (props.continues) {
      // This block is continuing onto another day
      label = showDoubleLabel
        ? `Starts at ${props.start.toLocaleString(DateTime.TIME_SIMPLE)}`
        : props.width >= props.singleLabelMinWidth
        ? props.start.toLocaleString(DateTime.TIME_SIMPLE)
        : null
    } else {
      // The block starts and ends on this day
      if (showDoubleLabel) {
        label = (
          <>
            <span>{props.start.toLocaleString(DateTime.TIME_SIMPLE)}</span>
            <I type={IconId.ChevronRight} className="w-[6px] h-[6px]" />
            <span>{props.end.toLocaleString(DateTime.TIME_SIMPLE)}</span>
          </>
        )
      } else {
        label = showSingleLabel && (
          <span className="truncate">
            {props.start.toLocaleString(DateTime.TIME_SIMPLE)}
          </span>
        )
      }
    }

    return (
      <div
        className="
          flex items-center flex-row
          space-x-1
          text-sm
          truncate
        "
      >
        {label}
      </div>
    )
  },
)
BlockTimeLabels.displayName = "BlockTimeLabels"
