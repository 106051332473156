import { useAnalytics } from "@daybridge/analytics"
import { DateTime } from "luxon"
import { memo, useEffect, useMemo, useState } from "react"
import { Color } from "@daybridge/client-api"
import { useDeleteItem } from "../../hooks/useDeleteItem"
import { useItem } from "../../hooks/useItem"
import { useEditingItem } from "../../state/editing"
import { useOpenItem } from "../../state/opening"
import { useSharingItemId } from "../../state/sharing"
import { ItemWithResolvedTimes } from "../../types/itemWithResolvedTimes"
import { shouldRenderAsAllDayItem } from "../../utils/shouldRenderAsAllDayItem"
import usePreference from "../../../settings/hooks/preferences/usePreference"
import { BasicContextSkeleton } from "./BasicContextSkeleton"
import { BasicContext } from "./BasicContext"

interface ItemSidebarProps {
  startLocal: DateTime
}

// Detail pane that displays item details.
export const ItemContextPane: React.FC<ItemSidebarProps> = memo(
  ({ startLocal }: ItemSidebarProps) => {
    // TODO: implement DetailsPaneHeader in contexts so it's used by all detail panes.

    const { track } = useAnalytics()
    const [, setEditState] = useEditingItem()
    const [, setSharingItemId] = useSharingItemId()
    const deleteItem = useDeleteItem()
    const [openItem, setOpenItem] = useOpenItem()

    // Cache the loaded item so we can slide out the sidebar (otherwise the
    // sidebar would unmount the item before it's out of the viewport).
    const [cachedItem, setCachedItem] = useState<
      ItemWithResolvedTimes | undefined
    >(undefined)

    const { item, isLoading } = useItem(openItem?.id, startLocal, {
      // Temporary: If the item is not found, we'll close the sidebar.
      useErrorBoundary: false,
      onError: () => {
        // TODO: Remove this once the back-end returns the new id in the item
        // mutation, so we can use that to refetch the new id.
        setOpenItem(undefined)
      },
    })

    useEffect(() => {
      if (isLoading && !item) {
        // If the item query is loading, we're loading a new item. Reset the
        // cached item to the skeleton loader shows, rather than the previously
        // opened item.
        setCachedItem(undefined)
      } else if (item?.deleted) {
        // The `item` query returns deleted items too. If that happens, close
        // the sidebar.
        setOpenItem(undefined)
      } else if (item) {
        // If the item has been loaded or updated, update the cached item.
        setCachedItem({
          ...item,
          // This property isn't part of the item query, but it is used by the
          // detail pane because the item renderer would add it before.
          // Todo: Remove this property - we shouldn't need to add this manually.
          isAllDayItem: shouldRenderAsAllDayItem(item),
        })
      }
    }, [isLoading, item, setOpenItem])

    // Determine if the item is an all-day item.
    const isDayLevel = useMemo(() => {
      return cachedItem && shouldRenderAsAllDayItem(cachedItem)
    }, [cachedItem])

    const [colorByCategory] = usePreference("colorByCategory")

    return (
      <>
        {cachedItem && cachedItem.calendar ? (
          <BasicContext
            calendar={cachedItem.calendar.node}
            id={cachedItem.id}
            color={
              (colorByCategory
                ? cachedItem.category?.node.color
                : cachedItem.calendar.node.color) || Color.Stone
            }
            start={DateTime.fromISO(
              (cachedItem.start?.dateTime || cachedItem.start?.date) as string,
              { zone: cachedItem.start?.timeZone as string },
            )}
            end={DateTime.fromISO(
              (cachedItem.end?.dateTime || cachedItem.end?.date) as string,
              { zone: cachedItem.end?.timeZone as string },
            )}
            call={cachedItem.call}
            description={cachedItem.description}
            icon={cachedItem.icon?.id}
            title={cachedItem.title}
            category={cachedItem.category?.node}
            location={
              cachedItem.location?.place?.node || cachedItem.location?.name
            }
            series={cachedItem.series?.node}
            sharing={cachedItem.sharing}
            isDayLevel={isDayLevel}
            people={cachedItem.people}
            onShare={
              cachedItem.canShare
                ? () => {
                    if (cachedItem.sharing?.isPublic) {
                      void track("event-link.view", {
                        itemId: cachedItem.id,
                      })
                    } else {
                      void track("event-link-create.start", {
                        itemId: cachedItem.id,
                      })
                    }
                    setSharingItemId(cachedItem.id)
                  }
                : undefined
            }
            onEdit={
              cachedItem.canEdit ? () => setEditState(cachedItem) : undefined
            }
            onDelete={
              cachedItem.canDelete
                ? (mode) => deleteItem(cachedItem, mode)
                : undefined
            }
            onClose={() => setOpenItem(undefined)}
          />
        ) : openItem ? (
          <BasicContextSkeleton
            color={openItem.color}
            icon={openItem.icon}
            title={openItem.title}
            id={openItem.id}
            onClose={() => setOpenItem(undefined)}
          />
        ) : null}
      </>
    )
  },
)

ItemContextPane.displayName = "ItemContextPane"
