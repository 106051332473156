export function getAgendaItemHeight(
  hasLocation: boolean,
  isHiddenWorkItem: boolean,
  isAllDayItem: boolean,
) {
  return hasLocation && !isHiddenWorkItem && !isAllDayItem
    ? 200
    : isAllDayItem
    ? 40
    : 50
}
