import {
  Calendar,
  CalendarsFilterByArgs,
  CalendarsQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { groupBy } from "lodash-es"
import { UseInfiniteQueryOptions } from "@tanstack/react-query"
import { useCalendars } from "./useCalendars"

export interface UseGroupedCalendarOutput {
  groups: Calendar[][]
  isLoading: boolean
}

export const useGroupedCalendars = <
  TData extends CalendarsQuery,
  TError = string | GraphQLError,
>(
  filterBy?: CalendarsFilterByArgs,
  options?: UseInfiniteQueryOptions<CalendarsQuery, TError, TData>,
): UseGroupedCalendarOutput => {
  const { calendars, isLoading } = useCalendars(filterBy, options)

  // Create a mapping of Provider Type -> [Provider IDs]
  const calendarsByProviderId = groupBy(
    calendars,
    (calendar) => calendar.provider.node.id,
  )

  return {
    groups: Object.keys(calendarsByProviderId).map(
      (id) => calendarsByProviderId[id],
    ),
    isLoading,
  }
}
