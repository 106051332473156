import {
  Button,
  ButtonProps,
  ButtonSize,
  ButtonVariant,
} from "@daybridge/components-core"
import { error } from "@daybridge/toast"
import React, { useCallback, useEffect, useMemo, useState } from "react"

type CopyLinkButtonProps = Omit<ButtonProps, "children"> & {
  children:
    | string
    | React.ReactElement
    | ((isCopied: boolean) => React.ReactElement)
  link: string
  variant: ButtonVariant
  size: ButtonSize
}

const CopyLinkButtonFn = React.forwardRef(
  (props: CopyLinkButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { children, variant, size, onPress, className, link, ...rest } = props

    const [isCopied, setIsCopied] = useState(false)
    const [copiedTimeout, setCopiedTimeout] =
      useState<ReturnType<typeof setTimeout>>()

    // Clear the copy state after 3 seconds.
    useEffect(() => {
      if (isCopied) {
        if (copiedTimeout) clearTimeout(copiedTimeout)
        const newTimeout = setTimeout(() => {
          setIsCopied(false)
        }, 3000)
        setCopiedTimeout(newTimeout)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCopied])

    const copyLink = useCallback(
      (e: React.MouseEvent | React.KeyboardEvent) => {
        void navigator.clipboard
          .writeText(link)
          .then(() => {
            setIsCopied(true)
            onPress?.(e)
          })
          .catch(() => {
            error({
              title: "Could not copy",
              description: "Please copy the link manually",
            })
          })
      },
      [onPress, link],
    )

    const invokedChildren = useMemo(() => {
      return typeof children === "function" ? children(isCopied) : children
    }, [isCopied, children])

    return (
      <Button
        variant={variant}
        size={size}
        ref={ref}
        onPress={copyLink}
        className={className}
        {...rest}
      >
        {invokedChildren}
      </Button>
    )
  },
)
CopyLinkButtonFn.displayName = "CopyLinkButton"

export const CopyLinkButton = React.memo(
  CopyLinkButtonFn,
) as typeof CopyLinkButtonFn
