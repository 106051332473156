/**
 * `computeXPositionFromMouseEvent` takes a mouse event and determines
 * the Y coordinate of the cursor within the bounding box of the target.
 */
export const computeXPositionFromMouseEvent = (
  e: React.MouseEvent,
  container?: HTMLDivElement,
): number => {
  const dayX = (
    container || (e.target as HTMLDivElement)
  ).getBoundingClientRect().x
  const pageX = e.pageX

  return pageX - dayX
}

/**
 * `computeYPositionFromMouseEvent` takes a mouse event and determines
 * the Y coordinate of the cursor within the bounding box of the target.
 */
export const computeYPositionFromMouseEvent = (
  e: React.MouseEvent,
  container?: HTMLDivElement,
): number => {
  const dayY = (
    container || (e.target as HTMLDivElement)
  ).getBoundingClientRect().y
  const pageY = e.pageY

  return pageY - dayY
}
