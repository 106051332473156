import { useAuth } from "@daybridge/auth"
import {
  RespondToInviteMutation,
  RespondToInviteMutationVariables,
  ResponseType,
  useItemQuery,
  useItemsQuery,
  useRespondToInviteMutation,
  useSharedItemQuery,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import { error, fromPromise } from "@daybridge/toast"
import { useCallback } from "react"
import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  getLocalIdentifier,
  setLocalIdentifier,
} from "../../utils/localIdentifier"

export const useRespondToInvite = (
  id: string,
  options?: UseMutationOptions<
    RespondToInviteMutation,
    string | GraphQLError,
    RespondToInviteMutationVariables,
    unknown
  >,
) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  const { mutateAsync } = useRespondToInviteMutation({
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        useSharedItemQuery.getKey({ args: { id } }),
      )
      await queryClient.invalidateQueries(useItemQuery.getKey({ args: { id } }))
      await queryClient.invalidateQueries(useItemsQuery.getKey({ args: {} }))
    },
  })

  return useCallback(
    (
      response: ResponseType,
      seriesId?: string,
      newDisplayName?: string,
      calendarId?: string,
    ) => {
      let { uid, key, displayName } = getLocalIdentifier()
      if (!user) {
        // User is not authenticated, so we need to identify this person.
        if (!newDisplayName) {
          // Update previous response: to do this, we need a uid and key
          // stored in local storage. If not present, we display an error.
          const { uid, key } = getLocalIdentifier()
          if (!uid || !key) {
            error({
              title: "Could not update response",
              description:
                "Are you using the same browser as you responded with?",
            })
          }
        } else {
          // Create new response: check if a uid is already set from a
          // previous response, or if not, generate a new one and save it
          // in localStorage. This allows us to re-use it later to update
          // the response and respond to other events with the same uid.
          if (newDisplayName.length < 2)
            return error({ title: "Please enter your name" })
          if (!uid || !key) {
            const newIdentifier = setLocalIdentifier(newDisplayName)
            uid = newIdentifier.uid
            key = newIdentifier.key
          } else {
            // Update the locally saved name if this new one is different.
            if (newDisplayName !== displayName) {
              setLocalIdentifier(newDisplayName)
            }
          }
          // Use the new display name for submission.
          displayName = newDisplayName
        }
      }
      const input: RespondToInviteMutationVariables = {
        input: {
          id,
          series: seriesId,
          uid: user ? undefined : uid,
          key: user ? undefined : key,
          response,
          displayName: user ? undefined : displayName,
          calendar: calendarId,
        },
      }

      const resultPromise = mutateAsync(input)

      const toasts = calendarId
        ? {
            // If a calendarId is provided, we're moving the event to another
            // calendar.
            loadingMessage: "Moving...",
            successTitle: "Event moved!",
            errorTitle: "Could not move event",
          }
        : {
            loadingMessage: "Responding...",
            successTitle: "Response sent!",
            errorTitle: "Could not send response",
          }

      void fromPromise(resultPromise, toasts)

      return resultPromise
    },
    [id, mutateAsync, user],
  )
}
