import { IconId, Color } from "@daybridge/client-api"

export type DayPart = "Morning" | "Afternoon" | "Evening" | "All day"
export type DayPartInfo = {
  title: DayPart
  icon: IconId
  iconColor: Color
  createAtHour?: number
}

export const DAY_PARTS: DayPartInfo[] = [
  {
    title: "All day",
    icon: IconId.CalendarOutline,
    iconColor: Color.TealMedium,
  },
  {
    title: "Morning",
    icon: IconId.Sunrise,
    iconColor: Color.AmberMedium,
    createAtHour: 9,
  },
  {
    title: "Afternoon",
    icon: IconId.Sun,
    iconColor: Color.YellowMedium,
    createAtHour: 14,
  },
  {
    title: "Evening",
    icon: IconId.Night,
    iconColor: Color.BlueMedium,
    createAtHour: 20,
  },
]
