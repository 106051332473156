import { Calendar, IconId } from "@daybridge/client-api"
import {
  ComboboxMenu,
  ComboboxMenuItem,
  ComboboxMenuSeparator,
  ComboboxMenuLabel,
  ComboboxMenuProps,
  PopoverProps,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import { ForwardedRef, forwardRef, memo } from "react"

type CalendarComboboxProps = Partial<Omit<ComboboxMenuProps, "children">> & {
  children: PopoverProps["children"]
  selectableCalendars: Calendar[][]
  onSelect: (calendarId: string) => void
}

const CalendarComboboxFn = forwardRef(
  (props: CalendarComboboxProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { children, onSelect, selectableCalendars, ...rest } = props

    return (
      <ComboboxMenu
        {...rest}
        ref={ref}
        loading={!selectableCalendars}
        items={(searchValue: string) => {
          return selectableCalendars.flatMap((group) => {
            const filteredCalendars = group
              .filter(
                (a) =>
                  a.name?.toLowerCase().indexOf(searchValue.toLowerCase()) !==
                  -1,
              )
              .map((calendar) => (
                <ComboboxMenuItem
                  onSelect={() => onSelect(calendar.id)}
                  key={calendar.id}
                >
                  {calendar.name}
                </ComboboxMenuItem>
              ))
            return filteredCalendars.length > 0
              ? [
                  <ComboboxMenuSeparator
                    key={group[0].provider.node.id + "_separator"}
                  />,
                  <ComboboxMenuLabel
                    key={group[0].provider.node.id + "_label"}
                    className="flex flex-row items-center space-x-2"
                  >
                    <I
                      type={
                        group[0].provider.node.type === "google"
                          ? IconId.Google
                          : IconId.Daybridge
                      }
                      className="flex-shrink-0 w-3 h-3"
                    />
                    <span className="flex-1 truncate">
                      {group[0].provider.node.displayName}{" "}
                      {group[0].provider.node.username && (
                        <>({group[0].provider.node.username})</>
                      )}
                    </span>
                  </ComboboxMenuLabel>,
                  ...filteredCalendars,
                ]
              : []
          })
        }}
        side="bottom"
        align="start"
        noResultsText="No matches"
      >
        {props.children}
      </ComboboxMenu>
    )
  },
)
CalendarComboboxFn.displayName = "CalendarCombobox"

export const CalendarCombobox = memo(
  CalendarComboboxFn,
) as typeof CalendarComboboxFn
