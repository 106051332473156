import { DateTime } from "luxon"
import { useMemo } from "react"
import { ItemOrVirtualItem } from "../types/itemOrVirtualItem"
import { shouldRenderAsAllDayItem } from "../utils/shouldRenderAsAllDayItem"
import { useItems } from "./useItems"
import { useVirtualItems } from "./useVirtualItems"

/**
 * `useDayLevelItems` fetches the real and virtual items that need to be
 * rendered at the day level in the given time period.
 */
export const useDayLevelItems = (
  rangeStartLocal: DateTime,
  rangeEndLocal: DateTime,
): { items: ItemOrVirtualItem[]; isLoading: boolean } => {
  // Fetch real items for the period
  const { items, isLoading } = useItems(rangeStartLocal, rangeEndLocal)
  // Fetch virtual items for the period
  const virtualItems = useVirtualItems(rangeStartLocal, rangeEndLocal)

  // Keep only items & virtual items that need to be rendered at the day level
  const filteredItems = useMemo(
    () => items.filter(shouldRenderAsAllDayItem),
    [items],
  )
  const filteredVirtualItems = useMemo(
    () => virtualItems.filter(shouldRenderAsAllDayItem),
    [virtualItems],
  )

  // Combine result
  const filteredItemsAndVirtualItems = useMemo(
    () => [...filteredItems, ...filteredVirtualItems],
    [filteredItems, filteredVirtualItems],
  )

  return { items: filteredItemsAndVirtualItems, isLoading }
}
