import { Color, IconId } from "@daybridge/client-api"
import {
  TooltipWithKeyboardShortcut,
  Button,
  ButtonVariant,
  ButtonSize,
  TriggerType,
} from "@daybridge/components-core"
import { I } from "@daybridge/icons"
import React from "react"

interface DetailsPaneHeaderProps {
  onClose?: () => void
  closeButtonText?: string
  accentColor?: Color
  children?: React.ReactNode
  className?: string
  trigger?: TriggerType
}

const DetailsPaneHeaderFn = React.forwardRef(
  (props: DetailsPaneHeaderProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      onClose,
      accentColor,
      closeButtonText,
      children,
      className,
      trigger,
      ...rest
    } = props

    const MaybeTrigger = trigger || "div"
    const maybeTriggerProps = trigger ? { asChild: true } : {}

    return (
      <div className="flex flex-col">
        <div
          ref={ref}
          className={`
          w-full h-16
          px-4
          flex flex-row items-center flex-shrink-0
          ${className || ""}
        `}
          {...rest}
        >
          <div className="flex overflow-hidden p-1 items-center flex-1">
            {children}
          </div>
          <div className="flex-shrink-0">
            <MaybeTrigger {...maybeTriggerProps}>
              <TooltipWithKeyboardShortcut content="Close" shortcut="Esc">
                <Button
                  variant={ButtonVariant.ClearAlpha}
                  size={
                    closeButtonText ? ButtonSize.Base : ButtonSize.IconAction
                  }
                >
                  {closeButtonText || <I type={IconId.Cross} />}
                </Button>
              </TooltipWithKeyboardShortcut>
            </MaybeTrigger>
          </div>
        </div>
      </div>
    )
  },
)
DetailsPaneHeaderFn.displayName = "DetailsPaneHeader"

export const DetailsPaneHeader = React.memo(
  DetailsPaneHeaderFn,
) as typeof DetailsPaneHeaderFn
