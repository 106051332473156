import { useAuth } from "@daybridge/auth"
import { IconId, useMeQuery, useUserQuery } from "@daybridge/client-api"
import {
  Avatar,
  Button,
  ButtonSize,
  ButtonVariant,
  Menu,
  MenuItem,
  MenuSeparator,
  Tooltip,
} from "@daybridge/components-core"
import { useRouter } from "next/router"
import React from "react"
import { useQueryClient } from "@tanstack/react-query"

export const SidebarAccountMenu: React.FC = React.memo(() => {
  const { data: account } = useMeQuery()
  const { data: user } = useUserQuery(
    { args: { id: account?.me?.account.uid as string } },
    {
      enabled: !!account?.me?.account.uid,
    },
  )
  const queryClient = useQueryClient()
  const router = useRouter()
  const { signOut } = useAuth()
  return (
    <Menu
      side="right"
      align="end"
      content={
        <div className="w-72">
          <div className="p-3 flex items-center space-x-4">
            <div className="w-10 h-10 flex-shrink-0">
              <Avatar
                url={account?.me?.account.avatar || undefined}
                defaultInitials={account?.me?.account.preferredName.charAt(0)}
                className="text-lg"
              />
            </div>
            <div className="leading-5 overflow-hidden">
              <div className="truncate font-semibold text-high-contrast">
                {account?.me?.account.preferredName}
              </div>
              <div className="truncate text-base text-low-contrast">
                @{user?.user?.node.username}
              </div>
            </div>
          </div>
          <div>
            <MenuSeparator />
            <MenuItem
              icon={IconId.Settings}
              onSelect={() => {
                void router.push("/settings/account")
              }}
            >
              Settings
            </MenuItem>
            <MenuItem
              icon={IconId.Smartphone}
              onSelect={() => {
                void router.push("/settings/mobile")
              }}
            >
              Get Mobile Apps
            </MenuItem>
            <MenuItem
              icon={IconId.SignOut}
              onSelect={() => {
                void signOut().then(() => {
                  queryClient.clear()
                })
              }}
            >
              Log out
            </MenuItem>
          </div>
        </div>
      }
    >
      <Tooltip content="Account" side="right" align="center">
        <Button
          variant={ButtonVariant.Clear}
          size={ButtonSize.Custom}
          className="
            h-12 w-12 rounded-full
            flex flex-row items-center space-x-3
            text-high-contrast text-base font-semibold
          "
        >
          <div className="w-10 h-10">
            <Avatar
              className="rounded-full text-lg"
              url={account?.me?.account.avatar || undefined}
              defaultInitials={account?.me?.account.preferredName.charAt(0)}
            />
          </div>
        </Button>
      </Tooltip>
    </Menu>
  )
})
SidebarAccountMenu.displayName = "SidebarAccountMenu"
