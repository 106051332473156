import { useCallback, useMemo } from "react"
import { DateTime } from "luxon"
import { expandDaysInRange, fallsInTimeRange } from "@daybridge/datetime"
import { ItemOrVirtualItem } from "../../../items/types/itemOrVirtualItem"

// A `ColumnAllocation` describes which columns a day-level item should occupy.
export type ColumnAllocation = {
  id: string
  startColumn: number
  endColumn: number
  alreadyStarted: boolean
  continues: boolean
  excludeFromConflictResolution?: boolean
}

// `ColumnAllocations` is a map of Item ID -> ColumnAllocation
export type ColumnAllocations = { [id: string]: ColumnAllocation }

type DayLevelColumnAllocatorFunc = (
  item: ItemOrVirtualItem,
) => ColumnAllocation | undefined

export const useDayLevelColumnAllocator = (
  rangeStartLocal: DateTime,
  rangeEndLocal: DateTime,
): DayLevelColumnAllocatorFunc => {
  // Create a DateTime object for each one corresponding to the
  // start of the day that needs to be rendered.
  const days: DateTime[] = useMemo(
    () => expandDaysInRange(rangeStartLocal, rangeEndLocal),
    [rangeStartLocal, rangeEndLocal],
  )

  return useCallback(
    (item: ItemOrVirtualItem): ColumnAllocation | undefined => {
      if (
        !fallsInTimeRange(
          item.startLocal,
          item.endLocal,
          rangeStartLocal,
          rangeEndLocal,
        )
      ) {
        // This item isn't in the range at all - so it shouldn't occupy any columns.
        // This shouldn't really happen if we have filtered properly upstream,
        // but it's here for good measure.
        return undefined
      }

      const alreadyStarted = item.startLocal < rangeStartLocal
      const continues = item.endLocal > rangeEndLocal

      return {
        id: item.id,
        startColumn: dateToColumn(item.startLocal, false, days),
        endColumn: dateToColumn(item.endLocal, true, days),
        alreadyStarted,
        continues,
        excludeFromConflictResolution:
          item.__typename === "VirtualItem"
            ? item.excludeFromConflictResolution
            : false,
      }
    },
    [rangeStartLocal, rangeEndLocal, days],
  )
}

// The `exclusive` flag determines how midnight should be treated.
// If `exclusive is true then midnight will be assigned to the column
// corresponding to the day before. If false then it will be assigned to
// the next day.
const dateToColumn = (date: DateTime, exclusive: boolean, days: DateTime[]) => {
  // Start from the first column if the date is before the first day
  if (date < days[0]) {
    return 0
  }

  // Use the end column if the date is after the last day
  if (date > days[days.length - 1]) {
    return days.length - 1
  }

  // Otherwise, find the matching day in the list
  const index = days.findIndex((d) => {
    if (exclusive && date.equals(date.startOf("day"))) {
      return d.hasSame(date.minus({ days: 1 }), "day")
    } else {
      return d.hasSame(date, "day")
    }
  })

  if (index === -1) {
    throw new Error(
      `Date ${date.toISO()} not found in the range. Dates in range are: ${days
        .map((d) => d.toISO())
        .join(", ")}`,
    )
  }

  return index
}
