import { useCallback } from "react"

// `HourLevelItemPosition` describes where an item has been placed vertically.
export type HourLevelItemPosition = {
  id: string
  percentageYOffset: number
  percentageHeight: number
  alreadyStarted: boolean
  continues: boolean
  excludeFromConflictResolution?: boolean
}
export type HourLevelItemPositions = { [id: string]: HourLevelItemPosition }

// `HourLevelConflictResolution` describes how an item should be shifted
// horizontally and resized so as to not overlap with another item, or
// to overlap in a nice way so that both items are visible and the items
// don't entirely block each other.
export type HourLevelConflictResolution = {
  id: string
  xOffset: number
  width: number
  zIndex: number
}
export type HourLevelConflictResolutions = {
  [id: string]: HourLevelConflictResolution
}

// An `HourLevelConflictResolver` is a function that accepts a set of
// vertical item positions and returns a set of horizontal item positions.
export type HourLevelConflictResolverFunction = (
  items: HourLevelItemPositions,
) => HourLevelConflictResolutions

export const useHourLevelConflictResolver = (
  // `renderableAreaWidth` is the total width we have available to render over
  renderableAreaWidth: number,

  // `minWidth` is the minimum width items can be rendered at
  minWidth: number,
): HourLevelConflictResolverFunction => {
  return useCallback(
    (items: HourLevelItemPositions): HourLevelConflictResolutions => {
      const values = Object.values(items)
      const result: HourLevelConflictResolutions = {}

      // Assign "indentation values" to each item. This is the coefficient
      // by which we will multiply the indentation step.
      const indentationValues = assignIndentationValues(
        values.filter((item) => !item.excludeFromConflictResolution),
      )

      values.forEach((item: HourLevelItemPosition) => {
        if (item.excludeFromConflictResolution) {
          result[item.id] = {
            id: item.id,
            xOffset: 0,
            width: renderableAreaWidth,
            zIndex: 10000,
          }
        } else {
          // Figure out how much space we have to work with. This is
          // the renderable area width minus the width of the card.
          const availableIndentationSpacePixels = Math.max(
            renderableAreaWidth - minWidth,
            0,
          )

          // Define a minumum indentation step size. This is so that we don't
          // end up with cards being almost fully overlapped except for a tiny
          // bit, making them difficult to click
          const minimumIndentationSizePixels = 30

          // Figure out how many indentation steps we would ideally have if
          // we had an unlimited amount of space. Note that we add 1 because
          // the first indentation level is 0.
          const indentationValue = indentationValues[item.id]
          const idealNumberOfSteps = indentationValue.groupMaximumValue + 1

          // Calculate what the indentation size in pixels would be if we used
          // the ideal number of indentation steps
          const idealIndentationSizePixels =
            availableIndentationSpacePixels / idealNumberOfSteps

          // Figure out if this ideal indentation size would be too small. If so,
          // apply a lower bound to stop it from being impossible to click on things.
          let actualNumberOfSteps: number
          if (idealIndentationSizePixels < minimumIndentationSizePixels) {
            actualNumberOfSteps =
              Math.floor(
                availableIndentationSpacePixels / minimumIndentationSizePixels,
              ) + 1
          } else {
            actualNumberOfSteps = idealNumberOfSteps
          }

          // With this in mind, calculate how big each indentation step actually is
          const actualIndentationSizePixels = Math.floor(
            availableIndentationSpacePixels / actualNumberOfSteps,
          )

          // Don't allow items to be indented further than the number of steps
          // we have established we're allowed to have
          const cappedIndentationValue = Math.min(
            indentationValue.value,
            actualNumberOfSteps - 1,
          )

          const xOffset = cappedIndentationValue * actualIndentationSizePixels
          const width = renderableAreaWidth - xOffset

          result[item.id] = {
            id: item.id,
            xOffset,
            width,
            zIndex: indentationValue.value,
          }
        }
      })

      return result
    },
    [minWidth, renderableAreaWidth],
  )
}

interface IndentationValue {
  // `value` is the indentation value to assign
  // to this item
  value: number

  // `groupMaximumValue` is the maximum indentation
  // value of any item that conflicts with this item.
  groupMaximumValue: number
}

const assignIndentationValues = (
  items: HourLevelItemPosition[],
  startIndentation = 0,
): { [id: string]: IndentationValue } => {
  const result: { [id: string]: IndentationValue } = {}

  // Base case: there are no items left to assign indentation values to
  if (items.length === 0) {
    return result
  }

  // First, group the items into conflict groups
  const conflictGroups = computeConflictGroups(items)

  // For each conflict group...
  conflictGroups.forEach((group: HourLevelItemPosition[]) => {
    // ...separate the first item from the rest of the items
    // (there may be only one item, in which case `remainingItems` will be empty)
    const firstItem = group[0]
    const remainingItems = group.splice(1)

    // Assign the indentation value to the first item
    result[firstItem.id] = {
      value: startIndentation,
      groupMaximumValue: startIndentation,
    }

    // Now that one item has been removed, the remaining items may not actually
    // conflict with each other. This means that they can be assigned the same
    // indentation value. To test this, we will recurse and re-compute
    // the conflict groups without the removed item.
    const remainingIndentationValues = assignIndentationValues(
      remainingItems,
      startIndentation + 1,
    )

    // Merge the remaining indentation values into the result
    Object.assign(result, remainingIndentationValues)

    // Update the group maximum value
    if (Object.keys(remainingIndentationValues).length > 0) {
      result[firstItem.id].groupMaximumValue = Math.max(
        ...Object.keys(remainingIndentationValues).map(
          (key) => remainingIndentationValues[key].groupMaximumValue,
        ),
      )
    }
  })

  return result
}

/**
 * `computeConflictGroups` groups the `HourLevelItemPosition`s into batches of
 * conflicting items. For example, if A conflicts with B, and B conflicts
 * with C (but A does not conflict with C) then A, B, and C would be grouped
 * together in a group.
 *
 * Crucially the core part of this algorithm runs in O(n)
 * time by first sorting the list. In practice items are probably
 * already mostly or fully sorted so the sorting step should be fast.
 * A naive implementation of this algorithm that doesn't use sorting could
 * be as bad as O(n^2).
 */
const computeConflictGroups = (
  items: HourLevelItemPosition[],
): HourLevelItemPosition[][] => {
  // First sort the items by Y offset in ascending order
  const sortedItems = items
    .sort((a: HourLevelItemPosition, b: HourLevelItemPosition) =>
      a.percentageYOffset - b.percentageYOffset === 0
        ? b.percentageHeight - a.percentageHeight
        : a.percentageYOffset - b.percentageYOffset,
    )
    // Remove any items that should not be considered for conflict resolution
    .filter((i) => !i.excludeFromConflictResolution)

  // Initialise a variable to store the final result
  const groups: HourLevelItemPosition[][] = []

  // Start with an initial group, populated with the first item
  let groupUnderConstruction: HourLevelItemPosition[] = [sortedItems[0]]

  // Keep track of the conflict item with the latest end date seen so far
  let latestEndItem: HourLevelItemPosition = sortedItems[0]

  // Iterate over each item, starting with the second item
  for (let i = 1; i < sortedItems.length; i++) {
    if (
      sortedItems[i].percentageYOffset >=
      latestEndItem.percentageYOffset + latestEndItem.percentageHeight
    ) {
      // This item starts after the previous item ends
      // Therefore, there's no conflict here.
      // "Bank" the conflict group under construction
      // and move on to a new conflict group.
      groups.push(groupUnderConstruction)

      groupUnderConstruction = []
    }

    // If this item ends after the last seen end position,
    // push it out a bit further.
    latestEndItem =
      sortedItems[i].percentageYOffset + sortedItems[i].percentageHeight >=
      latestEndItem.percentageYOffset + latestEndItem.percentageHeight
        ? sortedItems[i]
        : latestEndItem

    // Add this item to the conflict group
    groupUnderConstruction.push(sortedItems[i])
  }

  // Bank the last group
  groups.push(groupUnderConstruction)

  return groups
}
