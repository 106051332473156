import { AnimatePresence, motion } from "framer-motion"
import React from "react"

interface DetailsPaneProps {
  open: boolean
  children: React.ReactNode
  className?: string
}

/**
 * DetailsPane opens to the right of the timeline.
 */
const DetailsPaneFn = React.forwardRef(
  (props: DetailsPaneProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { open, children, className, ...rest } = props
    const initialOpen = React.useRef(open)

    return (
      <AnimatePresence>
        <motion.div
          ref={ref}
          transition={{ duration: 0.3 }}
          initial={{ width: initialOpen.current ? 384 : 0 }}
          animate={{ width: open ? 384 : 0 }}
          exit={{ width: 0 }}
          className={`
              ${open ? "2xl:ml-2" : ""}
              transition-[margin-left]
              h-full
              2xl:rounded-2xl
              bg-surface
              ${open ? "border-l 2xl:border border-tint-alpha" : ""}
              overflow-hidden
              ${className || ""}
            `}
          {...rest}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    )
  },
)
DetailsPaneFn.displayName = "DetailsPane"

export const DetailsPane = React.memo(DetailsPaneFn) as typeof DetailsPaneFn
