import { UseInfiniteQueryOptions } from "@tanstack/react-query"
import { useEffect, useMemo } from "react"
import {
  resolveDateTimeInfo,
  fallsInTimeRange,
  durationIgnoringZoneDifferences,
} from "@daybridge/datetime"
import { DateTime } from "luxon"
import { ItemsQuery } from "@daybridge/client-api/src/gen/operations"
import { ItemWithResolvedTimes } from "../types/itemWithResolvedTimes"
import {
  isAllDayItem,
  shouldRenderAsAllDayItem,
} from "../utils/shouldRenderAsAllDayItem"
import { useCalendarSelected } from "../../timeline/hooks/selected-calendars/useCalendarSelected"
import { useInfiniteItemsQuery } from "./useInfiniteItemsQuery"

/**
 * `useItems` fetches all of a user's items from the server.
 * It resolves the items into the user's local time zone, and discards
 * any floating items that, after being resolved, don't actually fall in the
 * desired time range. The user's time zone is inferred based on the zone
 * name attached to the `startLocal` option.
 *
 * If `filter` is provided, only items that fall within that filter range
 * will be returned.
 */
export const useItems = (
  cacheStartLocal: DateTime,
  cacheEndLocal: DateTime,
  filter?: [DateTime, DateTime],
  options?: UseInfiniteQueryOptions<ItemsQuery>,
): { items: ItemWithResolvedTimes[]; isLoading: boolean } => {
  const calendarSelected = useCalendarSelected()

  const {
    data: items,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    isLoading,
  } = useInfiniteItemsQuery(
    {
      args: {
        filterBy: {
          endingAfter: cacheStartLocal.toISO(),
          startingBefore: cacheEndLocal.toISO(),
          deleted: false,
        },
      },
    },
    options,
  )

  useEffect(() => {
    if (hasNextPage && !isRefetching) {
      void fetchNextPage()
    }
  }, [items?.pageParams.length, hasNextPage, fetchNextPage, isRefetching])

  const allItems = useMemo(() => {
    return (
      items?.pages?.flatMap((page) => {
        if (!page.items || !page.items.edges) {
          return []
        }

        return page.items.edges
          .map((edge): ItemWithResolvedTimes | undefined => {
            const item = edge.node
            if (!item) {
              return undefined
            }

            if (!item.calendar) {
              return undefined
            }

            if (!calendarSelected(item.calendar.id)) {
              return undefined
            }

            if (!item.start) {
              return undefined
            }

            const start = resolveDateTimeInfo(
              item.start,
              cacheStartLocal.zoneName,
            )
            const end = resolveDateTimeInfo(
              item.end ? item.end : item.start,
              cacheEndLocal.zoneName,
            )

            if (
              !fallsInTimeRange(
                start,
                end,
                filter ? filter[0] : cacheStartLocal,
                filter ? filter[1] : cacheEndLocal,
              )
            ) {
              return undefined
            }

            const itemWithResolvedTimes = {
              ...item,
              startLocal: start,
              endLocal: end,
            }

            // We expect all all-day items to be at least one day long.
            // The backend should maintain this invariant.
            // If they aren't we can't render them, so we'll just skip them.
            if (
              isAllDayItem(itemWithResolvedTimes as ItemWithResolvedTimes) &&
              Math.abs(durationIgnoringZoneDifferences(start, end).as("days")) <
                1
            ) {
              return undefined
            }

            return {
              ...(itemWithResolvedTimes as ItemWithResolvedTimes),
              isAllDayItem: isAllDayItem(itemWithResolvedTimes),
              renderAsAllDay: shouldRenderAsAllDayItem(itemWithResolvedTimes),
            }
          })
          .filter((x): x is ItemWithResolvedTimes => !!x)
      }) || []
    )
  }, [items?.pages, calendarSelected, cacheStartLocal, filter, cacheEndLocal])

  return { items: allItems, isLoading }
}
