import { useCallback } from "react"
import { UseMutationOptions, useQueryClient } from "@tanstack/react-query"
import {
  IntegrationsQuery,
  MutationPatchIntegrationArgs,
  PatchIntegrationCalendarArgs,
  useCalendarsQuery,
  useIntegrationsQuery,
  useItemQuery,
  useItemsQuery,
  usePatchIntegrationMutation,
} from "@daybridge/client-api"
import { GraphQLError } from "@daybridge/graphql"
import {
  PatchIntegrationMutation,
  PatchIntegrationMutationVariables,
} from "@daybridge/client-api/src/gen/operations"
import { produce } from "immer"
import { useItemSchema } from "../../../items/hooks/useItemSchema"
import { useOpenItem } from "../../../items/state/opening"

type PatchCalendarFromIntegrationFunction = (
  integrationId: string,
  patch: PatchIntegrationCalendarArgs,
) => Promise<PatchIntegrationMutation>

export const usePatchCalendarIntegration = (
  options?: UseMutationOptions<
    PatchIntegrationMutation,
    string | GraphQLError,
    MutationPatchIntegrationArgs
  >,
): PatchCalendarFromIntegrationFunction => {
  const queryClient = useQueryClient()
  const { data: schema } = useItemSchema()

  const onMutate = useCallback(
    async (variables: PatchIntegrationMutationVariables) => {
      const queryKey = useIntegrationsQuery.getKey()

      // Cancel any in-flight queries
      await queryClient.cancelQueries(queryKey)

      // Fetch previous state
      const previousState =
        queryClient.getQueryData<IntegrationsQuery>(queryKey)

      // Produce new state
      const newState = produce(previousState, (draft) => {
        if (!draft || !draft.integrations) return draft // Data not loaded yet.

        const integrationIndex = draft?.integrations?.edges.findIndex(
          (integration) => integration.id === variables.input.id,
        )

        if (integrationIndex === undefined || integrationIndex === -1) {
          return draft
        }

        variables.input.patch.calendars?.forEach((calendarToPatch) => {
          if (!draft.integrations?.edges[integrationIndex].node.calendars)
            return

          const calendars =
            draft.integrations.edges[integrationIndex].node.calendars
          if (!calendars) {
            return
          }

          const calendarIndex = calendars.findIndex(
            (calendar) => calendar.id === calendarToPatch.id,
          )
          if (calendarIndex === undefined || calendarIndex === -1) {
            return
          }

          if (calendarToPatch.enabled !== undefined) {
            calendars[calendarIndex].enabled = calendarToPatch.enabled
          }

          if (calendarToPatch.displayName) {
            calendars[calendarIndex].displayName = calendarToPatch.displayName
          }

          const currentDefaultCategory =
            calendars[calendarIndex].defaultCategory
          if (
            calendarToPatch.defaultCategory &&
            currentDefaultCategory &&
            schema?.categories[calendarToPatch.defaultCategory]
          ) {
            currentDefaultCategory.node =
              schema?.categories[calendarToPatch.defaultCategory]
          }

          const currentIcon = calendars[calendarIndex].defaultIcon
          if (calendarToPatch.defaultIcon && currentIcon) {
            currentIcon.id = calendarToPatch.defaultIcon
            currentIcon.node.id = calendarToPatch.defaultIcon
          }
        })

        return draft
      })

      queryClient.setQueryData(queryKey, newState)
    },
    [queryClient, schema],
  )

  const [openItem] = useOpenItem()

  const { mutateAsync } = usePatchIntegrationMutation({
    onMutate,
    onSettled: () => {
      void queryClient.invalidateQueries(useIntegrationsQuery.getKey())
      if (openItem) {
        void queryClient.invalidateQueries(
          useItemQuery.getKey({ args: { id: openItem.id } }),
        )
      }
      void queryClient.invalidateQueries(useItemsQuery.getKey({ args: {} }))
      void queryClient.invalidateQueries(useCalendarsQuery.getKey({ args: {} }))
    },
    ...options,
  })

  return useCallback(
    (integrationId: string, patch: PatchIntegrationCalendarArgs) => {
      return mutateAsync({
        input: {
          id: integrationId,
          patch: {
            calendars: [patch],
          },
        },
      })
    },
    [mutateAsync],
  )
}
